/**
 * Callback used to build the cell
 *
 * @callback CellBuilder
 * @param {Array} values - Values resulting in the mapping between data and apiFieldNames
 * @param {Object} extra - Useful extra data to build the cell
 * @param {import('vue-i18n').IVueI18n} i18n
 * @return {{component?: string | (() => Promise<*>), props?: Object, value?: string | number, filterValue?: Array<string> | string}}
 */

/**
 * Callback used to compute an orderable value in order to sort datagrid data rows
 *
 * @callback SortValueBuilder
 * @param {import('./DataGrid.data.models').DataGridDataCell} dataCell
 * @return {*} - Orderable
 */

export class DataGridColumn {
  /**
   * Creates an instance of DataGridColumn.
   * @param {Object} args
   * @param {String} args.columnType - The column name
   * @param {Array} args.apiFieldNames - The field names defined in API
   * @param {String} args.localeKey - The key used to translate the label
   * @param {Boolean} [args.selectable = true] - Is the column selectable / unselectable ?
   * @param {Boolean} [args.sortable = true] - Is the column sortable ?
   * @param {Boolean} [args.defaultSelected = true] - Is the column selected by default ?
   * @param {Object} [args.style = {}] - Column inline style
   * @param {Object} [args.columnStyle = {}] - Column style
   * @param {boolean} [args.filterable] - Is the column filterable?
   * @param {?CellBuilder} [args.cellBuilder] - The function used to build the cell
   * @param {?HeaderCellBuilder} [args.headerCellBuilder] - The function used to build the header cell
   * @param {?SortValueBuilder} [args.sortValueBuilder] - The function used to compute an orderable value in order to sort datagrid data rows
   * @param {String} [args.i18nPrefix] - string used to add a prefix on possible filters values
   * @param {?boolean} [args.isActionCell] - Indicates if the cell is an action cell
   * @memberof DataGridColumn
   */
  constructor({
    columnType,
    apiFieldNames,
    localeKey,
    selectable = true,
    sortable = true,
    defaultSelected = true,
    style = {},
    filterable = null,
    cellBuilder,
    headerCellBuilder,
    sortValueBuilder,
    i18nPrefix = null,
    columnStyle,
    isActionCell = false,
  }) {
    this.columnType = columnType;
    this.apiFieldNames = apiFieldNames;
    this.localeKey = localeKey;
    this.selectable = selectable;
    this.sortable = sortable;
    this.defaultSelected = defaultSelected;
    this.style = style;
    this.filterable = filterable;
    this.cellBuilder = cellBuilder;
    this.headerCellBuilder = headerCellBuilder;
    this.sortValueBuilder = sortValueBuilder;
    this.i18nPrefix = i18nPrefix;
    this.columnStyle = columnStyle;
    this.isActionCell = isActionCell;
  }

  /**
   * Is the column selected by default ?
   *
   * @return {Boolean}
   * @memberof DataGridColumn
   */
  isDefaultSelected() {
    return this.defaultSelected;
  }

  /**
   * Is the column filterable?
   * @returns {boolean}
   */
  isFilterable() {
    return this.filterable;
  }

  /**
   * Is the column selectable / unselectable ?
   *
   * @return {Boolean}
   * @memberof DataGridColumn
   */
  isSelectable() {
    return this.selectable;
  }

  /**
   * Is the column sortable ?
   *
   * @return {Boolean}
   * @memberof DataGridColumn
   */
  isSortable() {
    return this.sortable;
  }

  /**
   * Is equal ?
   *
   * @param {DataGridColumn} column
   * @return {Boolean}
   * @memberof DataGridColumn
   */
  isEqual(column) {
    return Boolean(column && column.getType() === this.getType());
  }

  /**
   * Returns column type
   *
   * @return {String}
   * @memberof DataGridColumn
   */
  getType() {
    return this.columnType;
  }

  /**
   * Returns column inline style
   *
   * @return {Object}
   * @memberof DataGridColumn
   */
  getStyle() {
    return this.style;
  }

  /**
   * Returns column style
   *
   * @return {Object}
   * @memberof DataGridColumn
   */
  getColumnStyle() {
    return this.columnStyle;
  }

  /**
   * Returns string used to add a prefix on possible filters values
   *
   * @return {String}
   * @memberof DataGridColumn
   */
  getI18nPrefix() {
    return this.i18nPrefix;
  }

  /**
   * Returns boolean isActionCell
   * @returns
   */
  getIsActionCell() {
    return this.isActionCell;
  }

  /**
   * Returns column locale key
   *
   * @return {String}
   * @memberof DataGridColumn
   */
  getLocaleKey() {
    return this.localeKey;
  }

  /**
   * Returns field names
   *
   * @return {Array<String>}
   * @memberof DataGridColumn
   */
  getApiFieldNames() {
    return this.apiFieldNames;
  }

  /**
   * Returns an orderable value used in order to sort datagrid data rows
   *
   * @param {import('./DataGrid.data.models').DataGridDataCell} dataCell
   * @return {*}
   * @memberof DataGridColumn
   */
  getSortValue(dataCell) {
    return this.sortValueBuilder ? this.sortValueBuilder(dataCell) : dataCell?.value;
  }

  /**
   * Returns an object ready to use by DataGridDataCell constructor
   *
   * @param {Array<*>} values
   * @param {Object} extra
   * @param {import('vue-i18n').IVueI18n} i18n
   * @return {Object}
   * @memberof DataGridColumn
   */
  buildCell(values, extra, i18n) {
    return {
      column: this,
      value: values.join(' / '),
      ...(this.cellBuilder && this.cellBuilder(values, extra, i18n)),
    };
  }

  buildHeaderCell(extra, i18n) {
    return {
      column: this,
      ...(this.headerCellBuilder && this.headerCellBuilder(extra, i18n)),
    };
  }
}

export class DataGrid {
  /**
   * Creates an instance of DataGrid.
   * @param {Object} args
   * @param {?Array<DataGridColumn>} [args.columns=[]]
   * @param {?DataGridColumn} [args.defaultSortColumn]
   * @param {?import('./mixins/Sortable.js').SortTypes} [args.defaultSortType]
   * @param {Boolean} [args.sortable = true]
   * @param {Boolean} [args.columnSelectable = true]
   * @param {Boolean} [args.counter = true]
   * @param {string} [args.name = '']
   * @param {?Array<string>} [args.searchFields = null]
   * @param {Boolean} [args.saveFilter = false]
   * @param {?String} [args.columnSelectionLocalStorageKey] - used & required for inner columnSelectorDatagrid in Datagrid header
   * @memberof DataGrid
   */
  constructor({
    columns = [],
    defaultSortColumn,
    defaultSortType,
    sortable = true,
    columnSelectable = true,
    counter = true,
    name = '',
    searchFields = null,
    saveFilter = false,
    columnSelectionLocalStorageKey = null,
  }) {
    this.columns = columns;
    this.sortable = sortable;
    this.defaultSortColumn = defaultSortColumn;
    this.defaultSortType = defaultSortType;
    this.columnSelectable = columnSelectable;
    this.counter = counter;
    this.name = name;
    this.saveFilter = saveFilter;
    this.searchFields = searchFields;
    this.columnSelectionLocalStorageKey = columnSelectionLocalStorageKey;
  }

  /**
   * Has a counter ?
   *
   * @return {Boolean}
   * @memberof DataGrid
   */
  hasCounter() {
    return this.counter;
  }

  /**
   * Is a column selectable Datagrid ?
   *
   * @return {Boolean}
   * @memberof DataGrid
   */
  isColumnSelectable() {
    return this.columnSelectable;
  }

  /**
   * Is a sortable Datagrid ?
   *
   * @return {Boolean}
   * @memberof DataGrid
   */
  isSortable() {
    return this.sortable;
  }

  /**
   * Returns filterable columns
   *
   * @return {Array<DataGridColumn>}
   */
  getFilterableColumns() {
    return this.getColumns().filter(c => c.isFilterable());
  }

  /**
   * Returns selectable columns
   *
   * @return {Array<DataGridColumn>}
   * @memberof DataGrid
   */
  getSelectableColumns() {
    return this.getColumns().filter(c => c.isSelectable());
  }

  /**
   * Get Column selection LocalStorage key (used for inner Datagrid ColumnSelection)
   * @returns {?String}
   */
  getColumnSelectionLocalStorageKey() {
    return this.columnSelectionLocalStorageKey;
  }

  /**
   * Returns selected columns by default
   *
   * @return {Array<DataGridColumn>}
   * @memberof DataGrid
   */
  getSelectedColumnsByDefault() {
    return this.getSelectableColumns().filter(c => c.isDefaultSelected());
  }

  /**
   * Returns columns
   *
   * @return {Array<DataGridColumn>}
   * @memberof DataGrid
   */
  getColumns() {
    return this.columns;
  }

  /**
   * Return a column from a column name
   *
   * @param {String} columnType
   * @return {DataGridColumn}
   * @memberof DataGrid
   */
  getColumn(columnType) {
    return this.columns.find(c => c.columnType === columnType);
  }

  /**
   * Returns locales keys
   *
   * @return {Array<String>}
   * @memberof DataGrid
   */
  getLocaleKeys() {
    return this.columns.map(columns => columns.localeKey);
  }

  /**
   * Return datagrid Name (used to save filters)
   * @returns {string}
   * @memberof DataGrid
   */
  getName() {
    return this.name;
  }

  /**
   * Return true if filters from datagrid has to be saved in LS
   * @returns {Boolean}
   * @memberof DataGrid
   */
  getSaveFilter() {
    return this.saveFilter;
  }

  /**
   * Get fields used for searchBar
   * @returns {?Array<string>}
   */
  getSearchFields() {
    return this.searchFields;
  }

  /**
   * Returns api field names
   *
   * @return {Array<Array<String>>}
   * @memberof DataGrid
   */
  getApiFieldNames() {
    return this.columns.map(columns => columns.apiFieldNames);
  }
}

/**
 * @typedef {Object} ColumnFilterState
 * @property {{[key: string]: boolean}} state - contains current checked state for each value in `list`.
 * @property {Array<string>} list - contains all values, sorted.
 * @property {boolean} saveFilter - has to save filter in LS or not.
 * @property {string} datagridName - name of the datagrid.
 * @property {string} columnName - name of the column.
 * @property {string} groupId - groupId selected, in order to save filter in LS.
 * @property {boolean} isActive - is `false` when all values are checked in `state`. Automatically updated in `DataGridColumnFilter` component.
 */
