<template>
  <VueApexCharts type="donut" :options="options" :series="series" />
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';

export default {
  name: 'DonutChart',

  components: {
    VueApexCharts,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    /** @type {Vue.PropOptions<Array<DonutData>>} */
    values: {
      type: Array,
      required: true,
    },
  },

  computed: {
    /** @return {Object} */
    options() {
      return {
        colors: this.values.map(v => v.color),

        dataLabels: {
          formatter: (v, { seriesIndex }) =>
            `${this.values[seriesIndex].value} (${Math.round(v * 10) / 10}%)`,
        },

        labels: this.values.map(v => v.name),
        legend: { position: 'top' },

        plotOptions: {
          pie: {
            donut: {
              expandOnClick: false,
              size: 40,
            },
          },
        },

        states: { hover: { filter: { type: 'none' } } },

        title: {
          align: 'center',
          text: this.title,
        },

        tooltip: { enabled: false },
      };
    },

    /** @return {Array<number>} */
    series() {
      return this.values.map(v => v.value);
    },
  },
};

/**
 * @typedef {Object} DonutData
 * @property {string} color
 * @property {string} name
 * @property {number} value
 */
</script>

<i18n locale="fr">
{
  "total": "Total :"
}
</i18n>

<i18n locale="en">
{
  "total": "Total:"
}
</i18n>

<i18n locale="cz">
{
  "total": "Celkem:"
}
</i18n>

<i18n locale="de">
{
  "total": "Gesamt:"
}
</i18n>

<i18n locale="es">
{
  "total": "Total:"
}
</i18n>

<i18n locale="it">
{
  "total": "Totale:"
}
</i18n>

<i18n locale="pl">
{
  "total": "Razem:"
}
</i18n>
