<template>
  <div class="new-password">
    <div class="form-group">
      <label class="form-group__label" for="passwordNew">
        {{ $t('passwordNew') }}
      </label>
      <input
        id="passwordNew"
        v-model="passwordNew"
        class="form-group__input"
        :class="{ 'form-group__input--invalid': nonMatchingPasswords }"
        :type="viewPassword.new ? 'text' : 'password'"
        @blur="checkIdenticalPasswords()"
      />
      <font-awesome-icon
        v-if="passwordNew.length > 0"
        icon="fa-eye"
        @mousedown="showPassword('new')"
        @mouseup="hidePassword('new')"
      />
    </div>

    <InputValidationInfos :validate-conditions="validateNewPassword" />

    <div class="form-group">
      <label class="form-group__label" for="passwordConfirm">
        {{ $t('confirmNewPassword') }}
      </label>
      <input
        id="passwordConfirm"
        v-model="passwordConfirm"
        class="form-group__input"
        :class="{ 'form-group__input--invalid': nonMatchingPasswords }"
        :type="viewPassword.confirm ? 'text' : 'password'"
        @blur="checkIdenticalPasswords()"
      />
      <font-awesome-icon
        v-if="passwordConfirm.length > 0"
        icon="fa-eye"
        @mousedown="showPassword('confirm')"
        @mouseup="hidePassword('confirm')"
      />
    </div>

    <div v-if="nonMatchingPasswords" class="error-message__container">
      <span class="error-message__message">
        {{ $t('differentPasswords') }}
      </span>
    </div>
  </div>
</template>

<script>
import InputValidationInfos from './InputValidationInfos.vue';

export default {
  name: 'NewPassword',

  components: {
    InputValidationInfos,
  },

  emits: ['changeNewPasswordValidity', 'newPassword'],

  data: () => ({
    passwordConfirm: '',
    passwordNew: '',
    nonMatchingPasswords: false,
    showError: false,
    viewPassword: {
      new: false,
      confirm: false,
    },
  }),

  computed: {
    /** @return {boolean} */
    isConfirmIdentical() {
      return this.passwordNew === this.passwordConfirm;
    },

    /** @return {boolean} */
    isPasswordValid() {
      return this.validateNewPassword.every(condition => condition.isValid) && this.isConfirmIdentical;
    },

    /** @return {Array<import('@/components/common/InputValidationInfos.vue').ValidateCondition>} */
    validateNewPassword() {
      const hasEightCharacters = this.passwordNew.length >= 8;
      const hasOneNumber = /\d/.test(this.passwordNew);
      const hasOneUppercase = /[A-Z]/.test(this.passwordNew);

      return [
        {
          text: this.$t('eightCharacters'),
          isValid: hasEightCharacters,
        },
        {
          text: this.$t('oneNumber'),
          isValid: hasOneNumber,
        },
        {
          text: this.$t('oneUppercase'),
          isValid: hasOneUppercase,
        },
      ];
    },
  },

  watch: {
    isConfirmIdentical() {
      if (this.isConfirmIdentical) {
        this.nonMatchingPasswords = false;
      }
    },

    isPasswordValid() {
      this.$emit('changeNewPasswordValidity', this.isPasswordValid);
      if (this.isPasswordValid) {
        this.$emit('newPassword', this.passwordNew);
      }
    },
  },

  methods: {
    checkIdenticalPasswords() {
      if (!this.isConfirmIdentical && this.passwordConfirm.length > 0) {
        this.nonMatchingPasswords = true;
      } else {
        this.nonMatchingPasswords = false;
      }
    },

    hidePassword(type) {
      this.viewPassword[type] = false;
    },

    reset() {
      this.passwordNew = '';
      this.passwordConfirm = '';
    },

    showPassword(type) {
      this.viewPassword[type] = true;
    },
  },
};
</script>

<style lang="scss">
.new-password {
  .eye {
    position: absolute;
    top: 38px;
    left: 95%;
    color: $text-neutral;
  }

  .form-group {
    position: relative;

    &__input {
      position: relative;
    }
  }
}
</style>

<i18n locale="fr">
{
  "confirmNewPassword": "Confirmer le nouveau mot de passe",
  "differentPasswords": "Les mots de passe ne sont pas identiques",
  "eightCharacters": "8 caractères minimum",
  "oneNumber": "Dont 1 chiffre",
  "oneUppercase": "Dont 1 majuscule",
  "passwordNew": "Nouveau mot de passe"
}
</i18n>

<i18n locale="en">
{
  "confirmNewPassword": "Confirm new password",
  "differentPasswords": "Different passwords",
  "eightCharacters": "At least 8 characters",
  "oneNumber": "At least 1 number",
  "oneUppercase": "At least 1 uppercase",
  "passwordNew": "New password"
}
</i18n>
