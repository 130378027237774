<template>
  <div class="groups-admin">
    <DataGridVuetify
      v-model:renderedDataLength="renderedDataLength"
      :title="$t('groups.title', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="groupListFormatted"
      :loading="loading"
      :datagrid="datagrid"
      :is-in-edition-mode="inEditionId === NEW_LINE_ID"
      :line-id-in-edition="inEditionId"
    >
      <template #actions="propsAction">
        <ActionCell
          :edit-mode="[NEW_LINE_ID, inEditionId].includes(propsAction.object._id)"
          :actions="[NEW_LINE_ID, inEditionId].includes(propsAction.object._id) ? [] : ['edit', 'archive']"
          :object="propsAction.object"
          @archive="showModalArchive(propsAction.object)"
          @edit="toggleEditionMode(propsAction.object, true)"
          @save="saveEdits"
          @switchOffEditionMode="toggleEditionMode(propsAction.object, false)"
        />
      </template>
    </DataGridVuetify>

    <ModalArchive
      v-if="showModal"
      :title="$t('groups.header')"
      :body="$t('groups.body', [currentGroup._id])"
      @close="showModal = false"
      @submit="submitArchive"
    />
  </div>
</template>

<script>
import { admin as ApiAdmin } from '@/api.js';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import { ColumnKey, getDatagrid } from '@/pages/AdminPage/Groups.conf.js';
import ModalArchive from '@/components/ui/ModalArchiveRestore.vue';

/** @enum {string} */
export const NEW_LINE_ID = 'new-line';

export default {
  name: 'Groups',

  components: {
    ActionCell,
    DataGridVuetify,
    ModalArchive,
  },

  props: {
    groups: {
      required: true,
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    getDatagrid,
    NEW_LINE_ID,

    currentGroup: null,
    /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
    datagrid: getDatagrid(),
    /** @type {Array<import('@/store').Group>} */
    groupListFormatted: [],
    /** @type {?string} */
    inEditionId: null,
    /** @type {?import('@/store').Group} */
    originalElement: null,
    /** @type {number} */
    renderedDataLength: null,
    /** @type {boolean} */
    showModal: false,
  }),

  computed: {
    /**
     * @returns {Object} Mapping columns types to functions receiving an object with apiData key
     * and returning each columns' cellBuilder props value
     */
    buildCellInjectors() {
      const bindValueChanged =
        apiDataRow =>
        ({ value, field }) => {
          this.updateValue(apiDataRow, { field, value });
        };

      return {
        [ColumnKey.NAME]: ({ apiData }) => ({
          editMode: apiData._id === this.inEditionId,
          placeholder: this.$t('new.name'),
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.GROUP_ID]: ({ apiData }) => ({
          editMode: apiData._id === NEW_LINE_ID,
          placeholder: this.$t('new.group_id'),
          valueChanged: bindValueChanged(apiData),
        }),
      };
    },
  },

  watch: {
    groups: {
      immediate: true,
      handler() {
        this.groupListFormatted = [...this.groups];
      },
    },
  },

  methods: {
    async addElement() {
      if (this.inEditionId !== NEW_LINE_ID) {
        this.inEditionId = NEW_LINE_ID;
        this.groupListFormatted.unshift({
          _id: NEW_LINE_ID,
        });
      }
    },

    async submitArchive() {
      const modalContentCopy = { ...this.currentGroup };
      await ApiAdmin.groups.del(modalContentCopy);
      this.groupListFormatted.splice(
        this.groupListFormatted.findIndex(el => el._id === modalContentCopy._id),
        1
      );
      this.showModal = false;
    },

    /** @param {string} id */
    resetLine(id) {
      const elementIndex = this.groupListFormatted.findIndex(el => el._id === id);
      if (id === NEW_LINE_ID) {
        this.groupListFormatted.splice(elementIndex, 1);
      } else {
        // give the element its original values back
        const original = { ...this.originalElement };
        this.groupListFormatted.splice(elementIndex, 1, original);
      }
    },

    /**
     * Edit Groups
     * @param {import('@/store').Group} apiDataRow
     */
    async saveEdits(apiDataRow) {
      this.inEditionId = null;
      if (apiDataRow._id === NEW_LINE_ID) {
        const element = await ApiAdmin.groups.create(apiDataRow);
        this.groupListFormatted.push(element);
        // remove first line, dedicated to new data creation
        this.groupListFormatted.splice(0, 1);
      } else {
        await ApiAdmin.groups.update(apiDataRow);
        this.groupListFormatted.splice(
          this.groupListFormatted.findIndex(el => el._id === apiDataRow._id),
          1,
          apiDataRow
        );
      }
    },

    /**
     * show modal
     * @param {import('@/store').Group} group
     */
    showModalArchive(group) {
      this.currentGroup = group;
      this.showModal = true;
    },

    /**
     * Switch a line to edition mode or get out of edition mode and cancel previous changes made and not saved
     * @param {import('@/store').Group} apiDataRow
     * @param {boolean} editionMode
     */
    toggleEditionMode(apiDataRow, editionMode) {
      if (editionMode) {
        if (this.inEditionId === NEW_LINE_ID) {
          this.groupListFormatted.splice(
            this.groupListFormatted.findIndex(el => el._id === NEW_LINE_ID),
            1
          );
        }
        this.inEditionId = apiDataRow._id;
        this.originalElement = { ...apiDataRow };
      } else {
        this.resetLine(apiDataRow._id);
        this.inEditionId = null;
        this.originalElement = null;
      }
    },

    /**
     * update a value
     * @param {import('@/store').Group} apiDataRow
     * @param {{value: any, field:string}} param1
     */
    updateValue(apiDataRow, { value, field }) {
      apiDataRow[field] = value;
    },
  },
};
</script>

<i18n locale="fr">
  {
    "groups": {
      "header": "Archiver un groupe",
      "title": "Groupes",
      "body": "Voulez vous archiver le groupe {0} ?",
    },
    "new": {
      "name": "Nouveau nom",
      "group_id": "Nouvel id de groupe",
    }
  }
  </i18n>

<i18n locale="en">
  {
    "groups": {
      "header": "Archive a group",
      "title": "Groupe",
      "body": "Do you want to archive {0}?",
    },
    "new": {
      "name": "New name",
      "group_id": "New group id",
    },
  }
  </i18n>
