<template>
  <!-- Modal > Detailed Message -->
  <Modal modal-class="inbox-modal-details" @close="$emit('close')">
    <template #title>
      <div>
        {{ $t('inbox') }}
      </div>
    </template>

    <template #body>
      <!-- Sender detail message -->
      <div class="inbox-modal-details__line">
        <span>
          {{ $t('from') }}
        </span>
        <span>
          <!-- Add a link to device-view -->
          <router-link
            v-if="message.senderId"
            class="inbox-modal-details__link"
            :to="{
              name: GroupRoute.DEVICE_DETAILLED,
              params: { deviceId: message.senderId },
              query: { date: parseDateToQuery(message.sendDate) },
            }"
          >
            {{ message.senderName ?? '-' }}
          </router-link>

          <!-- No link to device-view -->
          <template v-else>
            {{ message.senderName ?? '-' }}
          </template>
        </span>
      </div>

      <!-- Content detail message -->
      <div class="inbox-modal-details__line" v-html="message.content" />

    </template>

    <template #cta>
      <Btn type="secondary" @click="$emit('mark-unread')">
        {{ $t('markAsUnread') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import { parseDateToQuery } from '@/store/messages';
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'InboxModalDetails',

  components: {
    Btn,
    Modal,
  },

  props: {
    message: {
      required: true,
      type: Object,
    },
  },

  emits: ['close', 'mark-unread'],

  data: () => ({
    GroupRoute,
    parseDateToQuery,
  }),
};
</script>

<style lang="scss" scoped>
.inbox-modal-details {
  &__link {
    margin-left: 5px;
    font-weight: $font-weight-semi-bold;
    text-decoration: underline;
  }

  &__line {
    overflow: hidden;
    max-width: 600px;
    padding-top: 20px;
    padding-bottom: 15px;
    text-overflow: ellipsis;
  }
}
</style>

<i18n locale="fr">
{
  "inbox": "Message reçu",
  "close": "Fermer",
  "from": "De",
  "markAsUnread": "Marquer comme non lu",
}
</i18n>

<i18n locale="en">
{
  "inbox": "Inbox",
  "close": "Close",
  "from": "From",
  "markAsUnread": "Mark as unread",
}
</i18n>

<i18n locale="cz">
{
  "close": "Zavřít",
  "from": "Od",
  "markAsUnread": "Označit jako nepřečtené",
}
</i18n>

<i18n locale="de">
{
  "close": "Schließen",
  "from": "Von",
  "markAsUnread": "Als gelesen markieren",
}
</i18n>

<i18n locale="es">
{
  "close": "Cerrar",
  "from": "De",
  "markAsUnread": "Marcar como no leído",
}
</i18n>

<i18n locale="it">
{
  "close": "Chiudi",
  "from": "Da",
  "markAsUnread": "Segna come non letto",
}
</i18n>

<i18n locale="pl">
{
  "close": "Zamknij",
  "from": "Od",
  "markAsUnread": "Zaznacz jako nieprzeczytaną",
}
</i18n>
