<template>
  <div class="tabs">
    <Btn
      v-for="(btn, idx) of tabs"
      :key="idx"
      type="secondary"
      :class="{ 'ui-btn--active': btn.active }"
      @click="btn.handleClick"
    >
      {{ btn.label }}
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'TabsContainer',
  components: { Btn },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.tabs {
  display: flex;
  transition: font-weight 800 ease-in-out 500;

  & > .ui-btn {
    position: relative;
    color: #b3b3b3;

    &:not(:first-child) {
      left: -15px;
      z-index: 0;
    }

    &:not(.ui-btn--active) {
      &:not(:first-child) {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:last-child) {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &--active {
      z-index: 1;
      border-radius: 5px;
      color: #4f4f4f;
    }
  }
}
</style>
