/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

/** @module */
/*
 * TODO: [Clean] Probably needs refactor:
 * Weird api: `throttleLoading` can be called with the same key but different callback, the second
 * will receive result of the first.
 */

import Deferred from '@/libs/deferred';

/** @typedef {ReturnType<typeof state>} State */
const state = () => ({
  /** @type {Object.<string, boolean>} */
  loading: {},

  /** @type {{[key: string]: Deferred<any>}} */
  $_loadingPromises: {},
});

export default /** @type {import('vuex').Module<State, *>} */ ({
  namespaced: true,
  state,

  mutations: {
    clear(state) {
      Object.keys(state.loading).forEach(key => {
        if (state.loading[key]) {
          state.$_loadingPromises[key].reject(`Loading for '${key}' was cancelled by a clear`);
        }

        state.loading = {};
        state.$_loadingPromises = {};
      });
    },

    /**
     * Set a `key` as correctly finished loading.
     * @param state
     * @param {Object} payload
     * @param {string} payload.key
     * @param {*} [payload.value]
     * @throws {Error} when `key` is not loading.
     */
    setEnded(state, { key, value }) {
      if (!state.loading[key]) {
        throw new Error(`Key '${key}' was not currently loading.`);
      }

      state.loading[key] = false;
      state.$_loadingPromises[key].resolve(value);
    },

    /**
     * Set a `key` as failed loading.
     * @param state
     * @param {Object} payload
     * @param {string} payload.key
     * @param {string|Error} [payload.reason]
     * @throws {Error} when `key` is not loading.
     */
    setFailed(state, { key, reason }) {
      if (!state.loading[key]) {
        throw new Error(`Key '${key}' was not currently loading.`);
      }

      state.loading[key] = false;

      if (!reason) reason = `Loading failed for '${key}'`;
      state.$_loadingPromises[key].reject(reason);
    },

    /**
     * Set a `key` as starting loading.
     * @param state
     * @param {string} key
     * @throws {Error} when `key` is aldready loading.
     */
    setStart(state, key) {
      if (state.loading[key]) throw new Error(`Key '${key}' already loading`);

      if (!Object.prototype.hasOwnProperty.call(state.loading, key)) {
        state.loading[key] = true;
      } else {
        state.loading[key] = true;
      }

      state.$_loadingPromises[key] = new Deferred();
    },
  },

  getters: {
    isLoading: state => Object.values(state.loading).some(v => v),
  },

  actions: {
    clear({ commit }) {
      commit('clear');
    },

    /**
     * Prevent loading same `key` multiple times at once.
     * Callback will be called only once on first invoke.
     * @template T
     * @param context
     * @param {{key: string, callback: () => Promise<T>}} payload
     * @return {Promise<T>} Anything returned by `callback`.
     * @throws {*} Anything thrown by `callback`.
     */
    async throttleLoading({ state, commit }, { key, callback }) {
      // TODO: [Style] Rename to `debounceLoading` (?)
      try {
        commit('setStart', key);
      } catch (e) {
        // TODO: [Bug] Add condition to prevent catching other errors than 'Already loading' (or refactor to avoid unnecessary commit).
        return state.$_loadingPromises[key].promise;
      }

      let cbReturn;
      try {
        cbReturn = await callback();
      } catch (e) {
        commit('setFailed', { key, reason: e });
        throw e;
      }

      commit('setEnded', { key, value: cbReturn });
      return cbReturn;
    },
  },
});
