<template>
  <div class="navbar navbar--right">
    <SearchBox v-if="$store.getters.hasPermission(Permission.RESEARCH)" />
    <ul class="nav">
      <li>
        <VueMultiselect
          :value="group._id"
          :options="groupsOptions"
          label="name"
          value-prop="_id"
          :can-clear="false"
          :can-deselect="false"
          searchable
          :no-results-text="$t('noResult')"
          :no-options-text="$t('noOption')"
          @select="groupSelect"
        />
      </li>

      <Language />
      <li
        v-if="group._id && $store.getters.hasPermission(Permission.VIEW_SETTINGS_BUTTON)"
        class="dropdown"
        :class="{ 'dropdown--open': openedDropdown === 'settings' }"
      >
        <router-link class="nav__item" :to="{ name: GroupRoute.SETTINGS }">
          <font-awesome-icon icon="fa-gears" class="param-icon" />
        </router-link>
      </li>

      <li class="dropdown" :class="{ 'dropdown--open': openedDropdown === 'profile' }">
        <button
          class="nav__item dropdown__button"
          aria-haspopup="true"
          aria-expanded="false"
          @click="dropdownToggle('profile')"
          @mousedown.prevent
        >
          <font-awesome-icon icon="fa-user" class="user-icon" />
        </button>

        <ul class="dropdown__menu">
          <li class="dropdown__item dropdown__item--header">
            {{ $store.state.user.email }}
          </li>

          <li>
            <router-link
              class="dropdown__item"
              :to="{ name: GroupRoute.PROFILE }"
              :title="$t('profile.profile')"
            >
              <font-awesome-icon icon="fa-user" />
              {{ $t('profile.profile') }}
            </router-link>
          </li>

          <li>
            <a class="dropdown__item" href="/" :title="$t('profile.logout')" @click="logout">
              <font-awesome-icon icon="fa-power-off" />
              {{ $t('profile.logout') }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueMultiselect from '@vueform/multiselect';

import api from '@/api';
import Language from '@/components/common/Language.vue';
import SearchBox from '@/components/common/SearchBox.vue';
import { Permission } from '@/store';
import { GroupRoute } from '@/libs/routing';

import NavBarsMixin from './NavBarsMixin';

/**
 * Handle app layout
 * @vue
 */
export default {
  name: 'NavBarTop',

  components: {
    Language,
    SearchBox,
    VueMultiselect,
  },

  mixins: [NavBarsMixin],

  data: () => ({
    GroupRoute,
    /** @type {string} */
    openedDropdown: '',
  }),

  computed: {
    ...(() => {
      const mapping = {
        /** @return {{[groupId: string]: import('@/store').Group}} */
        groups: state => state.groups,
        /** @return {import('@/store').User} */
        user: state => state.user,
      };

      return /** @type {typeof mapping} */ (mapState(mapping));
    })(),

    /** @return {Array<import('@/store').Group>} */
    groupsOptions() {
      return Object.values(this.groups).sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },

    /** @return {boolean} */
    hasViewGroupFeaturesPermission() {
      return this.$store.getters.hasPermission(Permission.VIEW_GROUP_FEATURES);
    },
  },

  methods: {
    dropdownClose() {
      this.openedDropdown = '';
      window.removeEventListener('click', this.dropdownClose);
    },

    /** @param {string} dropdown */
    dropdownOpen(dropdown) {
      this.openedDropdown = dropdown;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },

    /** @param {string} dropdown */
    dropdownToggle(dropdown) {
      if (this.openedDropdown !== dropdown) {
        this.dropdownOpen(dropdown);
      } else {
        this.dropdownClose();
      }
    },

    /** @param {string} groupId */
    async groupSelect(groupId) {
      // handle redirect if unauthorized
      if (this.$route.name === 'unauthorizedInternal') {
        await this.$store.dispatch('groupChange', groupId);
      }

      this.$router.push({ name: GroupRoute.GROUP, params: { groupId } });
    },

    /** Log out user */
    logout() {
      api.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar--right {
  justify-content: flex-end;
  height: 40px;
  background-color: $primary-light;

  .param-icon {
    height: 16px;
  }

  .user-icon {
    height: 14px;
  }
}

.dropdown__menu {
  right: 0;
}

.dropdown--open {
  .dropdown__button {
    background-color: rgb(0 0 0 / 12%);
    color: $text-light;
  }

  .nav__item {
    color: $text-light;
  }
}

.searchbox {
  flex: 1;
}
</style>

<i18n locale="fr">
{
  "profile": {
    "logout": "Déconnexion",
    "profile": "Profil"
  }
}
</i18n>

<i18n locale="en">
{
  "profile": {
    "logout": "Log off",
    "profile": "Profile"
  }
}
</i18n>

<i18n locale="cz">
{
  "profile": {
    "logout": "Odhlásit se",
    "profile": "Profil"
  }
}
</i18n>

<i18n locale="de">
{
  "profile": {
    "logout": "Abmelden",
    "profile": "Profil"
  }
}
</i18n>

<i18n locale="es">
{
  "profile": {
    "logout": "Cerrar sesión",
    "profile": "Perfil"
  }
}
</i18n>

<i18n locale="it">
{
  "profile": {
    "logout": "Disconnettiti",
    "profile": "Profilo"
  }
}
</i18n>

<i18n locale="pl">
{
  "profile": {
    "logout": "Wyloguj się",
    "profile": "Profil"
  }
}
</i18n>
