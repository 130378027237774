import { SortTypes } from '../mixins/Sortable';

export class DataGridDataCell {
  /**
   * Creates an instance of DataGridDataCell.
   * @param {Object} args
   * @param {?String} [args.component] - Component used to render data
   * @param {import('./DataGrid.models').DataGridColumn} [args.column] - Parent column
   * @param {?Object} [args.props] - Props passed to component
   * @param {?Object} [args.events] - Event listeners passed to component
   * @param {?String} [args.value] - Value displayed as a child of the component
   * @param {?Array<string> | string} [args.filterValue] - Value used in column filter
   */
  constructor({ component = 'div', column, props, events, value, filterValue }) {
    this.component = component;
    this.column = column;
    this.props = props;
    this.events = events;
    this.value = value;
    this.filterValue = filterValue;
  }

  /**
   * Get cell's column
   *
   * @return {import('./DataGrid.models').DataGridColumn}
   */
  getColumn() {
    return this.column;
  }
}

export class DataGridDataRow {
  /**
   * Creates an instance of DataGridDataRow.
   * @param {Array<DataGridDataCell>} [dataCells=[]]
   * @param {Array<DataGridDataRow>} [children]
   * @param {?String} [id]
   */
  constructor(dataCells = [], children = [], id = null) {
    this.children = children;
    this.dataCells = dataCells;
    this.id = id;

    this.showChildren = false;
  }

  /**
   * Get children rows
   *
   * @return {Array<DataGridDataRow>}
   */
  getChildren() {
    return this.children;
  }

  /**
   * Get data cells
   *
   * @return {Array<DataGridDataCell>}
   */
  getDataCells() {
    return this.dataCells;
  }

  /**
   * Get a data cell
   *
   * @param {import('./DataGrid.models').DataGridColumn} column
   * @return {DataGridDataCell}
   */
  getDataCell(column) {
    return this.dataCells.find(d => d.column.getType() === column.getType());
  }

  /**
   * Push a dataCell
   *
   * @param {DataGridDataCell} dataCell
   */
  push(dataCell) {
    this.dataCells.push(dataCell);
  }
}
export class DataGridData {
  /**
   * Creates an instance of DataGridData.
   * @param {Object} [args]
   * @param {Array<DataGridDataRow>} [args.dataRows=[]]
   * @param {import('./DataGrid.models').DataGridColumn} [args.sortColumn]
   * @param {Boolean} [args.sorting=false]
   * @param {import('../mixins/Sortable').SortTypes} [args.sortType]
   * @param {Array<import('./DataGrid.models').DataGridColumn>} [args.visibleColumns = []]
   */
  constructor({ dataRows = [], sortColumn, sorting = false, sortType, visibleColumns = [] } = {}) {
    this.dataRows = dataRows;
    this.sortColumn = sortColumn;
    this.sorting = sorting;
    this.sortType = sortType;
    this.visibleColumns = visibleColumns;
  }

  /**
   * Get data rows
   *
   * @return {Array<DataGridDataRow>}
   */
  getRenderedDataRows() {
    if (
      !this.sorting ||
      !this.visibleColumns.some(sc => sc.isEqual(this.sortColumn)) // Ensure that sortColumn is a visible column
    ) {
      return this.dataRows;
    }

    const sortRowValue = row => {
      const dataCell = row.getDataCell(this.sortColumn);
      return this.sortColumn.getSortValue(dataCell);
    };

    return this.dataRows.sort((rowA, rowB) => {
      const sortType = this.sortType === SortTypes.ASC ? -1 : 1;
      return sortRowValue(rowA) < sortRowValue(rowB) ? sortType : -sortType;
    });
  }

  /**
   * Push a dataRow
   *
   * @param {DataGridDataRow} dataRow
   */
  push(dataRow) {
    this.dataRows.push(dataRow);
  }
}
