/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

/** @module Store */
import deepmerge from 'deepmerge';
import { createStore } from 'vuex';

import Api, { OpWebSocket, Role } from '@/api';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { AdminRoute, GroupRoute } from '@/libs/routing';

import activityLog from './activity-log';
import alerts from './alerts';
import devices, { devicesUpdatePlugin } from './devices';
import drivers from './drivers';
import gtfs from './gtfs';
import integrations from './integrations';
import loading from './loading';
import messages, { messagesHotInboxUpdatePlugin } from './messages';
import trips from './trips';
import vehicles from './vehicles';
import tripDetailed from './trip-detailed';

/**
 * @enum {string}
 * @deprecated use integrationTypes instead
 */
export const EmbeddedInterface = {
  AEP: 'aep',
  DUHIP: 'duhip',
  HANOVER: 'hanover',
  HANOVER_IP: 'hanover_ip',
  KC650: 'kc650',
  KUBA: 'kuba',
  LUMIPLAN: 'lumiplan',
  NMEA: 'nmea',
  QIP: 'qip',
  SILV1: 'silv1',
};

/** @enum {string} */
export const Permission = {
  ADMIN_USERS: 'ADMIN_USERS',
  ACCESS_ADMIN: 'ACCESS_ADMIN',
  ACCESS_OP: 'ACCESS_OP',
  CREATE_MESSAGES: 'CREATE_MESSAGES',
  EDIT_PASSENGERS_MESSAGES: 'EDIT_PASSENGERS_MESSAGES',
  EDIT_GROUP_PARAMETERS: 'EDIT_GROUP_PARAMETERS',
  EDIT_INCIDENTS: 'EDIT_INCIDENTS',
  EDIT_STOP_INFO: 'EDIT_STOP_INFO',
  EDIT_TRIP_UPDATES: 'EDIT_TRIP_UPDATES',
  EXPORT_PASSENGERS_MESSAGES: 'EXPORT_PASSENGERS_MESSAGES',
  EXPORT_DRIVERS: 'EXPORT_DRIVERS',
  EXPORT_TRIP: 'EXPORT_TRIP',
  EXPORT_TRIP_LIST: 'EXPORT_TRIP_LIST',
  EXPORT_VEHICLES: 'EXPORT_VEHICLES',
  IMPORT_DRIVERS: 'IMPORT_DRIVERS',
  IMPORT_VEHICLES: 'IMPORT_VEHICLES',
  RESEARCH: 'RESEARCH',
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  VIEW_DUTIES: 'VIEW_DUTIES',
  VIEW_GROUP_FEATURES: 'VIEW_GROUP_FEATURES',
  VIEW_GTFS_RT: 'VIEW_GTFS_RT',
  VIEW_INCIDENTS: 'VIEW_INCIDENTS',
  VIEW_INTEGRATIONS: 'VIEW_INTEGRATIONS',
  VIEW_MESSAGES: 'VIEW_MESSAGES',
  VIEW_OVER_A_WEEK_HISTORY: 'VIEW_OVER_A_WEEK_HISTORY',
  VIEW_PASSENGERS_APP: 'VIEW_PASSENGERS_APP',
  VIEW_REPORT: 'VIEW_REPORT',
  VIEW_SETTINGS_BUTTON: 'VIEW_SETTINGS_BUTTON',
  VIEW_STOP_INFO: 'VIEW_STOP_INFO',
  VIEW_TEAM_COLUMN: 'VIEW_TEAM_COLUMN',
  VIEW_TRIP_COMMENTS: 'VIEW_TRIP_COMMENTS',
  VIEW_TRIP_DEVICES: 'VIEW_TRIP_DEVICES',
  VIEW_TRIP_DRIVERS: 'VIEW_TRIP_DRIVERS',
  VIEW_TRIP_VEHICLES: 'VIEW_TRIP_VEHICLES',
  VIEW_TRIP_VIEW: 'VIEW_TRIP_VIEW',
  VIEW_TRIP_KM: 'VIEW_TRIP_KM',
};

const cache = {
  /** @type {Api.WebSocket} */
  ws: null,
};

export const groupDefaults = Object.freeze({
  notifications: true,
  connection_advance: 0,
  delay_device_online: 120,
  delay_device_offline_visible: 300,
  driver_ontime_interval: [0, 300],
  driver_trip_format: '%dt - %th',
  incoming_distance_threshold: 200,
  retention_period: 36,
  shape_distance_threshold: 300,
  stop_distance_threshold: 50,
  teams: [],
  trip_validation_rules: {
    start_delay_max: 1800,
    start_delay_min: -1800,
    start_distance_max: 1000,
    vk_percent_min: 80,
  },
  tz: 'Europe/Paris',
});

/**
 * Get date range with default interval.
 * @param {number} defaultInterval
 * @return {GTFSDateRange}
 */
const getDefaultDateRange = defaultInterval => {
  const date = new Date();
  const endDate = dateObjToGtfsFormat(date);
  date.setDate(date.getDate() - defaultInterval);
  const startDate = dateObjToGtfsFormat(date);
  return { startDate, endDate };
};

/** @typedef {typeof state & ModuleStates} State */
const state = {
  /** Raw group config as on the api server, used to put update */
  groupServerSide: /** @type {Group} */ ({}),

  /** @type {{[groupId: string]: GroupMinimal}} */
  groups: {},

  /** @type {string|undefined} */
  dateFirstPublish: undefined,

  /** @type {Array<string>} */
  authorizedRoutes: [],

  /** @type {GTFSDateRange} */
  reportingDateRange: getDefaultDateRange(8),

  user: /** @type {User} */ ({}),

  userRole: /** @type {import('@/api').UserRole} */ ({}),

  /** @type {{[permission in Permission]: true}} */
  userPermissions: {},
};

/** @type {import('vuex').GetterTree<State, State>} */
const getters = {
  /**
   * Group config with default values when missing from api
   * @return {Group}
   */
  group(state) {
    const group = deepmerge(groupDefaults, state.groupServerSide, {
      arrayMerge: (_, source) => source.slice(),
    });

    // Do some checks:
    // Replace hex colors on 3 characters by 6 (ex: #4a5 -> #44aa55)
    /**
     * @param {string} short - Short hex format (ex: #4a5)
     * @return {string} Long hex format (ex: #44aa55)
     */
    const getLongHexColor = short => {
      const re = /#(.)(.)(.)/;
      return short.replace(re, '#$1$1$2$2$3$3');
    };

    if (group.teams) {
      group.teams.forEach(t => {
        if (t.color?.length === 4) {
          t.color = getLongHexColor(t.color);
        }
      });
    }

    return group;
  },
  getDateFirstPublish(state) {
    return state.dateFirstPublish;
  },

  /**
   * @callback GetTeamNameByIdCallback
   * @param {string} teamId
   * @return {string}
   */
  /**
   * @return {GetTeamNameByIdCallback}
   */ getTeamNameById: (state, getters) => teamId => {
    if (!getters.group?.teams) return teamId;
    const team = getters.group.teams.find(t => t.team_id === teamId);
    return team?.name ?? teamId;
  },

  /**
   * Indicate if the current user has this permission
   * @callback HasPermissionCallback
   * @param {Permission} permission
   * @return {boolean}
   */
  /** @return {HasPermissionCallback} */
  hasPermission: state => permission => permission in state.userPermissions,

  activeTeams: (_, getters) => getters.group.teams.filter(t => !t.archived),
};

/** @type {import('vuex').MutationTree<State>} */
const mutations = {
  patchGroup(state, updates) {
    Object.entries(updates).forEach(([key, value]) => {
      if (value === undefined && !(state.groupServerSide[key] === undefined)) {
        delete state.groupServerSide[key];
      } else {
        state.groupServerSide[key] = value;
      }
    });
  },

  setGroup(state, group) {
    state.groupServerSide = group;

    // Add notifications to group settings, handled in local storage
    state.groupServerSide.notifications = !localStorage.getItem('settings.op.disable_notif');
    localStorage.setItem('settings.op.lastGroupId', group._id);
  },

  setGroups(state, groups) {
    state.groups = groups;
  },

  setReportingDateRange(state, dateRange) {
    state.reportingDateRange = dateRange;
  },

  setSuperUserPermissions(state) {
    Object.keys(Permission).forEach(item => {
      state.userPermissions[item] = true;
    });
  },

  setTravelTimeRadius(state, radius) {
    state.travelTimeRadius = radius;
  },

  setUser(state, user) {
    state.user = user;
  },

  setUserRole(state, userRole) {
    state.userRole = userRole;
  },

  /**
   * Set the routes the current user can access
   * @param state
   */
  setAuthorizedRoutes(state) {
    state.authorizedRoutes = [];
    if (state.userPermissions.ACCESS_OP) {
      state.authorizedRoutes = [
        GroupRoute.PASSENGERS_MESSAGE,
        GroupRoute.PASSENGERS_SCREEN,
        GroupRoute.DEVICE_DETAILLED,
        GroupRoute.DUTY_LIST,
        GroupRoute.GROUP,
        GroupRoute.LIVE_MAP,
        GroupRoute.PROFILE,
        GroupRoute.PROFILE_GENERAL,
        GroupRoute.PROFILE_PASSWORD,
        GroupRoute.STOP_DETAILED,
        GroupRoute.TRIP_LIST,
        GroupRoute.TRIP_LIST_OLD,
      ];
    }
    if (state.userPermissions.EDIT_GROUP_PARAMETERS) {
      state.authorizedRoutes.push(GroupRoute.SETTINGS);
    }
    if (state.userPermissions.VIEW_DASHBOARD) {
      state.authorizedRoutes.push(GroupRoute.DASHBOARD);
    }
    if (state.userPermissions.VIEW_GROUP_FEATURES) {
      state.authorizedRoutes.push(
        GroupRoute.DUTY_LIST,
        GroupRoute.DEVICE_LIST,
        GroupRoute.DRIVER_LIST,
        GroupRoute.TRANSPORT_PLAN_LIST,
        GroupRoute.STOP_LIST,
        GroupRoute.USER_LIST,
        GroupRoute.VEHICLE_LIST
      );
    }
    if (state.userPermissions.VIEW_GTFS_RT) {
      state.authorizedRoutes.push(GroupRoute.GTFS_RT);
    }
    if (state.userPermissions.VIEW_INCIDENTS) {
      state.authorizedRoutes.push(GroupRoute.INCIDENT_LIST);
    }
    if (state.userPermissions.VIEW_INTEGRATIONS) {
      state.authorizedRoutes.push(GroupRoute.INTEGRATIONS);
    }
    if (state.userPermissions.VIEW_MESSAGES) {
      state.authorizedRoutes.push(GroupRoute.MESSAGE_LIST);
    }
    if (state.userPermissions.VIEW_PASSENGERS_APP) {
      state.authorizedRoutes.push(GroupRoute.PASSENGERS_APP);
    }
    if (state.userPermissions.VIEW_TRIP_VIEW) {
      state.authorizedRoutes.push(GroupRoute.TRIP_DETAILED);
    }
    if (state.userPermissions.VIEW_REPORT) {
      state.authorizedRoutes.push(GroupRoute.REPORTS);
      state.authorizedRoutes.push(GroupRoute.REPORTING_PASSENGERS_APP);
      state.authorizedRoutes.push(GroupRoute.REPORTING_CONNECTED_DEVICES);
      state.authorizedRoutes.push(GroupRoute.REPORTING_OLD_PUNCTUALITY);
      state.authorizedRoutes.push(GroupRoute.REPORTING_PASSENGER_COUNTS);
      state.authorizedRoutes.push(GroupRoute.REPORTING_PUNCTUALITY);
      state.authorizedRoutes.push(GroupRoute.REPORTING_TRAVEL_TIME);
      state.authorizedRoutes.push(GroupRoute.REPORTING_TRIP_KM);
      state.authorizedRoutes.push(GroupRoute.REPORTING_TRIP_TRACKING);
    }
    if (state.userPermissions.ACCESS_ADMIN) {
      state.authorizedRoutes.push(AdminRoute.ADMIN);
    }
  },

  /**
   * @param state
   * @param {import('@/api').UserRole} userRole
   */
  setUserPermissions(state, userRole) {
    switch (userRole.role) {
      case Role.ADMIN:
        state.userPermissions = {
          [Permission.ADMIN_USERS]: true,
          [Permission.ACCESS_OP]: true,
          [Permission.CREATE_MESSAGES]: true,
          [Permission.EDIT_PASSENGERS_MESSAGES]: true,
          [Permission.EDIT_GROUP_PARAMETERS]: true,
          [Permission.EDIT_INCIDENTS]: true,
          [Permission.EDIT_STOP_INFO]: true,
          [Permission.EDIT_TRIP_UPDATES]: true,
          [Permission.EXPORT_PASSENGERS_MESSAGES]: true,
          [Permission.EXPORT_DRIVERS]: true,
          [Permission.EXPORT_TRIP]: true,
          [Permission.EXPORT_TRIP_LIST]: true,
          [Permission.EXPORT_VEHICLES]: true,
          [Permission.IMPORT_DRIVERS]: true,
          [Permission.IMPORT_VEHICLES]: true,
          [Permission.RESEARCH]: true,
          [Permission.VIEW_DASHBOARD]: true,
          [Permission.VIEW_DUTIES]: true,
          [Permission.VIEW_GROUP_FEATURES]: true,
          [Permission.VIEW_GTFS_RT]: true,
          [Permission.VIEW_INCIDENTS]: true,
          [Permission.VIEW_INTEGRATIONS]: true,
          [Permission.VIEW_MESSAGES]: true,
          [Permission.VIEW_OVER_A_WEEK_HISTORY]: true,
          [Permission.VIEW_PASSENGERS_APP]: true,
          [Permission.VIEW_REPORT]: true,
          [Permission.VIEW_SETTINGS_BUTTON]: true,
          [Permission.VIEW_STOP_INFO]: true,
          [Permission.VIEW_TEAM_COLUMN]: true,
          [Permission.VIEW_TRIP_COMMENTS]: true,
          [Permission.VIEW_TRIP_DEVICES]: true,
          [Permission.VIEW_TRIP_DRIVERS]: true,
          [Permission.VIEW_TRIP_VEHICLES]: true,
          [Permission.VIEW_TRIP_VIEW]: true,
          [Permission.VIEW_TRIP_KM]: true,
        };
        break;
      case Role.EXTERNAL_READER:
        state.userPermissions = {
          [Permission.ACCESS_OP]: true,
        };
        break;
      case Role.OPERATION_MANAGER:
        state.userPermissions = {
          [Permission.ACCESS_OP]: true,
          [Permission.CREATE_MESSAGES]: true,
          [Permission.EDIT_PASSENGERS_MESSAGES]: true,
          [Permission.EDIT_INCIDENTS]: true,
          [Permission.EDIT_STOP_INFO]: true,
          [Permission.EDIT_TRIP_UPDATES]: true,
          [Permission.EXPORT_PASSENGERS_MESSAGES]: true,
          [Permission.EXPORT_DRIVERS]: true,
          [Permission.EXPORT_TRIP]: true,
          [Permission.EXPORT_TRIP_LIST]: true,
          [Permission.EXPORT_VEHICLES]: true,
          [Permission.IMPORT_DRIVERS]: true,
          [Permission.IMPORT_VEHICLES]: true,
          [Permission.RESEARCH]: true,
          [Permission.VIEW_DUTIES]: true,
          [Permission.VIEW_GROUP_FEATURES]: true,
          [Permission.VIEW_GTFS_RT]: true,
          [Permission.VIEW_INCIDENTS]: true,
          [Permission.VIEW_INTEGRATIONS]: true,
          [Permission.VIEW_MESSAGES]: true,
          [Permission.VIEW_OVER_A_WEEK_HISTORY]: true,
          [Permission.VIEW_DASHBOARD]: true,
          [Permission.VIEW_REPORT]: true,
          [Permission.VIEW_STOP_INFO]: true,
          [Permission.VIEW_TEAM_COLUMN]: true,
          [Permission.VIEW_TRIP_COMMENTS]: true,
          [Permission.VIEW_TRIP_DEVICES]: true,
          [Permission.VIEW_TRIP_DRIVERS]: true,
          [Permission.VIEW_TRIP_VEHICLES]: true,
          [Permission.VIEW_TRIP_VIEW]: true,
          [Permission.VIEW_TRIP_KM]: true,
        };
        break;
      case Role.READER:
        state.userPermissions = {
          [Permission.ACCESS_OP]: true,
          [Permission.EXPORT_PASSENGERS_MESSAGES]: true,
          [Permission.EXPORT_TRIP_LIST]: true,
          [Permission.RESEARCH]: true,
          [Permission.VIEW_GTFS_RT]: true,
          [Permission.VIEW_INCIDENTS]: true,
          [Permission.VIEW_MESSAGES]: true,
          [Permission.VIEW_OVER_A_WEEK_HISTORY]: true,
          [Permission.VIEW_DASHBOARD]: true,
          [Permission.VIEW_REPORT]: true,
          [Permission.VIEW_STOP_INFO]: true,
          [Permission.VIEW_TEAM_COLUMN]: true,
          [Permission.VIEW_TRIP_COMMENTS]: true,
          [Permission.VIEW_TRIP_DEVICES]: true,
          [Permission.VIEW_TRIP_DRIVERS]: true,
          [Permission.VIEW_TRIP_VEHICLES]: true,
          [Permission.VIEW_TRIP_VIEW]: true,
          [Permission.VIEW_TRIP_KM]: true,
        };
        break;
      default:
        break;
    }

    if (state.user.superuser) {
      store.commit('setSuperUserPermissions');
    }

    store.commit('setAuthorizedRoutes');
  },
};

/** @type {import('vuex').ActionTree<State, State>} */
const actions = {
  /**
   * Clear modules state.
   * @param context
   * @return {Promise<Array<void>>}
   */
  clearModules({ dispatch }) {
    return Promise.all([
      dispatch('alerts/clear'),
      dispatch('devices/clear'),
      dispatch('gtfs/clear'),
      dispatch('messages/clear'),
      dispatch('trips/clear'),
    ]);
  },

  /**
   * Get current group (and wait if none defined).
   * @param context
   * @return {Promise<Group>}
   */
  async getGroup({ state, getters }) {
    // TODO: [Clean] Change loading/waiting mechanism
    if (getters.group._id && !state.loading.loading.group) {
      return getters.group;
    }
    await new Promise(resolve => {
      (function wait() {
        if (getters.group._id && !state.loading.loading.group) resolve();
        else setTimeout(wait, 10);
      })();
    });

    return getters.group;
  },

  /**
   * Change group.
   * @param context
   * @param {string} groupId
   * @return {Promise<Group>}
   */
  async groupChange({ state, commit, dispatch, getters }, groupId) {
    if (getters.group._id === groupId) return;
    await dispatch('loading/throttleLoading', {
      key: 'group',
      callback: async () => {
        const [group, roles] = await Promise.all([Api.getGroup(groupId), Api.getRoles(groupId)]);
        commit('setGroup', {});
        await dispatch('stopWS');
        await dispatch('clearModules');

        // get the older gtfs for a group
        const gtfsFiles = await Api.gtfs.getGtfs(group._id);
        state.dateFirstPublish = gtfsFiles.reduce((acc, e) => {
          // TODO: Tornado format backward compatibility. To be removed to use e.mod_time directly.
          const modTime = e.mod_time.$date ?? e.mod_time;

          return acc < modTime ? acc : modTime;
        }, null);

        commit('setGroup', group);
        const userRole = roles.find(r => r.user_id === state.user._id) || {};
        commit('setUserRole', userRole);
        commit('setUserPermissions', userRole);

        if (getters.group._id) {
          dispatch('devices/getDevices');
          dispatch('integrations/loadData');
          dispatch('startWS');
          dispatch('messages/resetNotifications');
          dispatch('messages/setHotInboxMessages');
        }
      },
    });

    return getters.group;
  },

  async groupPatch({ state, commit }, updates) {
    commit('patchGroup', updates);
    await Api.putGroup(state.groupServerSide);
  },

  async groupPatchStateOnly({ commit }, updates) {
    commit('patchGroup', updates);
  },

  /**
   * Update current group.
   */
  async groupUpdate({ state, commit, getters }) {
    if (!getters.group._id) return;
    const group = await Api.getGroup(getters.group._id);
    commit('setGroup', group);
  },

  /**
   * Update groups list.
   * @param context
   * @return {Promise<{[groupId: string]: {_id: string, name: string}}>} Map<GroupId, Group>
   */
  async groupsUpdate({ state, commit, dispatch }) {
    await dispatch('loading/throttleLoading', {
      key: 'groups',
      callback: async () => {
        const groupsArray = await Api.getGroups();
        const groups = {};
        groupsArray.forEach(g => {
          groups[g._id] = g;
        });

        commit('setGroups', groups);
      },
    });

    return state.groups;
  },

  /**
   * Start listening for updates.
   * @param context
   */
  async startWS({ state, dispatch, getters }) {
    await dispatch('stopWS');
    if (getters.group._id == null) {
      throw new Error("Can't start WebSocket without a group id");
    }
    cache.ws = new OpWebSocket(getters.group._id, data => {
      data.sort((a, b) => a.ts - b.ts);
      dispatch('trips/updateVkHistories', data);
      dispatch('activityLog/updateEntries', data);
      dispatch('devices/updateDevices', data);
    });
  },

  /**
   * Stop listening for updates.
   */
  async stopWS() {
    if (cache.ws) {
      cache.ws.close();
      cache.ws = null;
    }
  },

  /**
   * @param context
   * @param {User} user
   */
  async setUser({ commit }, user) {
    await Api.putUser(user);
    commit('setUser', user);
  },
};

/**
 * @typedef {Object} ModuleStates
 * @property {import('./activity-log').State} activityLog
 * @property {import('./alerts').State} alerts
 * @property {import('./devices').State} devices
 * @property {import('./drivers').State} drivers
 * @property {import('./gtfs').State} gtfs
 * @property {import('./integrations').State} integrations
 * @property {import('./loading').State} loading
 * @property {import('./messages').State} messages
 * @property {import('./trips').State} trips
 * @property {import('./vehicles').State} vehicles
 */
/** @typedef {typeof modules} Modules */
const modules = {
  activityLog,
  alerts,
  devices,
  drivers,
  gtfs,
  integrations,
  loading,
  messages,
  trips,
  vehicles,
  tripDetailed,
};

/** @typedef {import('vuex').Store<State>} Store */
const store = createStore({
  strict: import.meta.env.NODE_ENV === 'development',
  state,
  getters,
  mutations,
  actions,
  modules,

  plugins: [devicesUpdatePlugin, messagesHotInboxUpdatePlugin],
});

export default store;

if (import.meta.env.NODE_ENV === 'development') {
  window.Store = store;
}

/**
 * @typedef {Object} DriverMessage
 * @property {string} [color] - Deprecated
 * @property {string} message
 */

/**
 * @typedef {Object} IncidentManager
 * @property {string} domain
 * @property {string} token
 * @property {string} type
 * @property {Array<string | {label: string, caption: string}>} [incident_label_values]
 */

/**
 * @typedef {Object} Group
 * @property {string} _id
 * @property {GeoJSON.GeoJSON} bounds
 * @property {string} current_file
 * @property {string} group_id - Deprecated, use `_id` instead.
 * @property {string} name
 * @property {number} next_state_load
 * @property {string} tz
 * @property {Array<string>} [applications]
 * @property {Object} [categories]
 * @property {string} [category]
 * @property {string} [color]
 * @property {number} [connection_advance]
 * @property {object} [contact]
 * @property {number} [delay_device_online]
 * @property {number} [delay_device_offline_visible]
 * @property {string} [driver_call_number]
 * @property {Array<DriverMessage>} [driver_message_values]
 * @property {Array<number>} [driver_ontime_interval]
 * @property {boolean} [driver_option_messages_block_send]
 * @property {boolean} [notifications]
 * @property {number} [driver_pass_count]
 * @property {boolean} [driver_stamp_button]
 * @property {string} [driver_trip_format]
 * @property {string} [email]
 * @property {boolean} [fakeclient]
 * @property {number} [incoming_distance_threshold]
 * @property {string} [latest_driver_version]
 * @property {string} [logo_url]
 * @property {Array<string>} [private_routes]
 * @property {string} [map_vehicle_tooltip_pattern]
 * @property {?number} [max_devices]
 * @property {boolean} [option_driver_break]
 * @property {IncidentManager} [option_trip_incidents]
 * @property {string} [phone_number]
 * @property {EmbeddedInterface} [embedded_interface]
 * @property {boolean} [pub]
 * @property {boolean} [punctuality_check]
 * @property {number} [retention_period]
 * @property {number} [shape_distance_threshold]
 * @property {boolean} [show_track] - Deprecated. Unused.
 * @property {number} [stop_distance_threshold]
 * @property {{[stopId: string]: number}} [stop_distance_threshold_exceptions]
 * @property {Array<Team>} [teams]
 * @property {TripListConfiguration} [trip_list_configuration]
 * @property {TripValidationRules} [trip_validation_rules]
 * @property {string} [date_first_publish]
 */

/**
 * @typedef {Object} GroupMinimal
 * @property {string} _id
 * @property {string} name
 */

/**
 * @typedef {Object} GTFSDateRange
 * @property {string} endDate
 * @property {string} startDate
 */

/**
 * @typedef {Object} OperationWarning
 * @property {boolean} active
 * @property {number} [threshold]
 */

/**
 * @typedef {Object} Team
 * @property {string} team_id
 * @property {string} name
 * @property {string} color
 * @property {boolean} archived
 */

/**
 * @typedef {Object} TripListConfiguration
 * @property {{[key in import('@/pages/TripsListPage/Trips.conf').ColumnTypes]: boolean}} [merging]
 */

/**
 * @typedef {Object} TripValidationRules
 * @property {number} [start_delay_max]
 * @property {number} [start_delay_min]
 * @property {number} [start_distance_max]
 * @property {number} [vk_percent_min]
 */

/**
 * @typedef {Object} UpcomingFiles
 * @property {string} current_file
 * @property {number} setup_ts
 * @property {number} ts
 * @property {string} user_id
 */

/**
 * @typedef {Object} User
 * @property {string} id
 * @property {string} _id
 * @property {string} email - Same as `_id`
 * @property {number} date_added
 * @property {string} [name]
 * @property {boolean} [superuser]
 * @property {Array<RoleObject>} roles
 */

/**
 * @typedef {Object} RoleObject
 * @property {string} role
 * @property {string} group_id
 */
