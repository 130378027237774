<template>
  <div v-if="device" class="live-map-device-card">
    <div class="live-map-device-card__container">
      <div v-if="device?.trip_id" class="live-map-device-card__trip">
        <RouteBadge
          v-if="device.route_id && gtfsRoutes[device.route_id]"
          class="live-map-device-card__trip-number"
          :route="gtfsRoutes[device.route_id]"
        />
        <div v-if="!$store.getters.hasPermission(Permission.VIEW_TRIP_VIEW)">
          {{ formattedTripName }}
        </div>
        <router-link
          v-else
          :to="{
            name: GroupRoute.TRIP_DETAILED,
            params: { tripId: device.trip_id },
            query: tripStartDate ? { date: tripStartDate } : null,
          }"
        >
          {{ formattedTripName }}
        </router-link>

        <DeviceDelay
          v-if="!device.trip_pending && device.trip_id !== null"
          class="live-map-device-card__trip-delay"
          :delay="device.delay"
        />
      </div>
      <div v-if="permission && vehicleAndDriverLabel.length > 0" class="live-map-device-card__driver">
        <span>
          {{ vehicleAndDriverLabel }}
        </span>
      </div>
      <div>
        <font-awesome-icon
          icon="fa-circle"
          class="live-map-device-card__status-indicator"
          :class="isOnline ? 'online' : 'offline'"
        />
        <router-link
          v-if="permission"
          class="live-map-device-card__device-name"
          :to="{ name: GroupRoute.DEVICE_DETAILLED, params: { deviceId } }"
        >
          {{ device.name || deviceId }}
        </router-link>
      </div>
      <div v-if="device?.off_itinerary" class="tag tag--warning live-map-device-card__deviation">
        <span>{{ $t('offItinerary') }}</span>
        <font-awesome-icon icon="fa-circle-exclamation" class="live-map-device-card__deviation-icon" />
      </div>
      <div v-if="device?.trip_id">
        <font-awesome-icon icon="fa-users" class="live-map-device-card__passenger-indicator" />
        <span class="live-map-device-card__count-passenger">{{ device?.vehicle_load || '-' }}</span>
      </div>
    </div>

    <div class="live-map-device-card__right">
      <div v-if="device.trip_id === null">
        {{ $t('HLP') }}
      </div>

      <div v-else-if="device.trip_pending">
        {{ $t('tripPending') }}
      </div>

      <div v-else-if="device.break">
        {{ $t('break') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DeviceDelay from '@/components/common/DeviceDelay.vue';
import RouteBadge from '@/components/common/RouteBadge.vue';
import { dateGtfsFormatToObj, getISODate } from '@/libs/helpers/dates';
import { Permission } from '@/store';
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'LiveMapDeviceRow',

  components: {
    DeviceDelay,
    RouteBadge,
  },

  props: {
    deviceId: {
      type: String,
      required: true,
    },
  },

  emits: ['change:formattedTripName', 'formatedDeviceItem'],

  data: () => ({
    GroupRoute,
    Permission,

    formattedTripName: '',
  }),

  computed: {
    ...(() => {
      const mapping = {
        /** @return {{[id: string]: import('@/store/drivers').Driver}} */
        driversList: (/** @type {import('@/store').State} */ state) => state.drivers.list,
        /** @return {{[id: string]: import('@/store/vehicles').Vehicle}} */
        vehiclesList: (/** @type {import('@/store').State} */ state) => state.vehicles.list,
      };

      return /** @type {typeof mapping} */ (mapState(mapping));
    })(),

    /** @return {?import('@/store/activity-log').ActivityLogEntry} */
    activityLogEntry() {
      if (this.deviceId && this.device?.ts)
        return this.$store.getters['activityLog/getEntry'](this.deviceId, this.device?.ts);
      return null;
    },

    deviceItem() {
      if (this.device) {
        return {
          _id: this.deviceId,
          driver: this.driver,
          vehicle: this.vehicle,
          formattedTripName: this.formattedTripName,
          route: this.gtfsRoutes[this.device.route_id],
        };
      }
      return null;
    },

    /** @return {?import('@/store/devices').Device} */
    device() {
      return this.$store.getters['devices/visibleDevices'][this.deviceId];
    },

    /** @return {?string} */
    driver() {
      if (this.activityLogEntry) {
        const driver = this.driversList[this.activityLogEntry.driver_id];

        if (driver) return driver.driver_name;
      }

      return null;
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters.group.current_file;
    },

    /** @return {{[routeId: string]: import('@/store/gtfs').Route}} */
    gtfsRoutes() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'routes');
    },

    /** @return {{[tripId: string]: import('@/store/gtfs').Trip}} */
    gtfsTrips() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'trips');
    },

    /** @return {boolean} */
    isOnline() {
      return !!this.$store.state.devices.online[this.device.device_id];
    },

    /** @return {boolean} */
    permission() {
      return (
        this.$store.getters.hasPermission(Permission.VIEW_TRIP_DEVICES) &&
        this.$store.getters.hasPermission(Permission.VIEW_TRIP_DRIVERS) &&
        this.$store.getters.hasPermission(Permission.VIEW_TRIP_VEHICLES)
      );
    },

    /** @return {string} */
    tripStartDate() {
      if (this.device && this.device.trip && this.device.trip.start_date) {
        return getISODate(dateGtfsFormatToObj(this.device.trip.start_date));
      }
      return null;
    },

    /** @return {?string} */
    vehicle() {
      if (this.activityLogEntry) {
        const vehicle = this.vehiclesList[this.activityLogEntry.vehicle_id];
        if (vehicle) return vehicle.license_plate;
      }

      return null;
    },
    vehicleAndDriverLabel() {
      let label = '';
      if (this.vehicle) label += this.vehicle;
      if (this.vehicle && this.driver) label += ' | ';
      if (this.driver) label += this.driver;
      return label;
    },
  },

  watch: {
    'device.trip_id': {
      immediate: true,
      handler(tripId) {
        this.generateFormattedTripName(tripId).then(name => {
          this.formattedTripName = name;
        });
      },
    },

    formattedTripName() {
      this.$emit('change:formattedTripName', this.formattedTripName);
    },
    deviceItem() {
      if (this.deviceItem) this.$emit('formatedDeviceItem', this.deviceItem);
    },
  },

  methods: {
    /**
     * @param {string} tripId
     * @return {Promise<string>}
     */
    async generateFormattedTripName(tripId) {
      if (tripId) {
        return this.$store.dispatch('gtfs/formatTripName', {
          tripId,
          date: new Date(),
        });
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.live-map-device-card {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  margin: 12px;
  padding: 10px;
  border: 1px solid $border;
  border-radius: 8px;
  background-color: $canvas;
  cursor: pointer;
  transition: 0.5s;

  &:first-child {
    margin-top: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  &:hover {
    box-shadow: -2px 2px 8px 0 rgb(0 0 0 / 21%);
  }

  &__right {
    margin-top: 8px;
    font-size: 12px;
    text-align: right;
  }

  &__status-indicator,
  &__passenger-indicator {
    margin-right: 3px;
    font-size: 0.8em;

    &.online {
      color: $primary-light;
    }

    &.offline {
      color: $danger;
    }
  }

  &__device-name {
    font-size: 12px;
  }

  &__count-passenger {
    font-size: 12px;
  }

  &__deviation {
    font-size: 12px !important;

    &-icon {
      margin-top: 3px;
      margin-left: 3px;
    }
  }

  &__trip {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 8px 0;
  }

  &__trip-delay {
    display: block;
    min-width: 55px;
    height: 100%;
    margin-top: 3px;
    margin-left: auto;
    font-size: 12px;
    text-align: right;
  }
}
</style>

<i18n locale="fr">
{
  "break": "En pause",
  "HLP": "Haut le pied",
  "offline": "Déconnecté",
  "tripPending": "Acheminement",
  "offItinerary": "Hors itinéraire",
}
</i18n>

<i18n locale="en">
{
  "break": "On break",
  "HLP": "Dead running",
  "offline": "Offline",
  "tripPending": "Routing",
  "offItinerary": "Off itinerary",
}
</i18n>

<i18n locale="cz">
{
  "offline": "Offline",
  "tripPending": "Pojíždění",
  "break": "Má přestávku",
  "HLP": "Na cestě z/do depa"
}
</i18n>

<i18n locale="de">
{
  "offline": "Offline",
  "tripPending": "Routing",
  "break": "Pause",
  "HLP": "Fahrt zum oder vom Busdepot"
}
</i18n>

<i18n locale="es">
{
  "offline": "Sin conexión",
  "tripPending": "Buscando ruta",
  "break": "En pausa",
  "HLP": "Sin pasajeros"
}
</i18n>

<i18n locale="it">
{
  "offline": "Offline",
  "tripPending": "Percorso",
  "break": "In pausa",
  "HLP": "In tragitto da o verso il deposito"
}
</i18n>

<i18n locale="pl">
{
  "offline": "W trybie offline",
  "tripPending": "Trasowanie",
  "break": "Przerwa",
  "HLP": "Przejazdy bez pasażerów"
}
</i18n>
