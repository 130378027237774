<template>
  <Modal modal-class="modal-cancel-publication" @close="close">
    <template #title>
      {{ $t('cancelPublicationOf', [gtfs.name]) }}
    </template>

    <template #cta>
      <Btn type="primary" class="modal-cancel-publication__btn" @click="cancelPublication">
        <i class="fas fa-times icon" />
        {{ $t('cancelPublication') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import { useToast } from 'vue-toastification';

import api from '@/api';
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

const toast = useToast();

export default {
  name: 'ModalCancelPublication',

  components: {
    Btn,
    Modal,
  },

  props: {
    groupId: {
      required: true,
      type: String,
    },

    /** @type {Vue.PropOptions<import('./index.vue').GtfsFormatted>} */
    gtfs: {
      required: true,
      type: Object,
    },
  },

  emits: ['close', 'closeModalCancelPublication'],

  methods: {
    async cancelPublication() {
      await api.gtfs.cancelScheduledGtfsPublication(this.groupId, this.gtfs._id);
      const toastId = toast.success(this.$t('cancelledToast'), {
        icon: 'fas fa-check',
        position: 'bottom-right',
      });
      setTimeout(() => toast.dismiss(toastId), 3000);
      this.$emit('closeModalCancelPublication', this.gtfs._id);
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.modal-cancel-publication {
  .icon {
    margin-right: 5px;
  }
}
</style>

<i18n locale="fr">
{
  "cancelledToast": "Annulation de la publication",
  "cancelPublication": "Annuler la publication",
  "cancelPublicationOf": "Annuler la publication de {0}",
  "keepPublication": "Conserver la publication",
}
</i18n>

<i18n locale="en">
{
  "cancelledToast": "Cancelled publication",
  "cancelPublication": "Cancel publication",
  "cancelPublicationOf": "Cancel publication of {0}",
  "keepPublication": "Keep publication",
}
</i18n>
