<template>
  <div class="error-403">
    <div class="error-403__text-block">
      <div class="error-403__title">
        <font-awesome-icon icon="fa-lock" />
        403
      </div>
      <div class="error-403__subtitle">
        {{ $t('access') }}
      </div>
      <Btn type="primary" @click="redirect">
        {{ $t('goBack') }}
      </Btn>
    </div>
    <div class="error-403__image-container">
      <img class="error-403__image" src="@/assets/img/403_illustration.svg" />
    </div>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import { Permission } from '@/store';
import { AuthRoute, GroupRoute } from '@/libs/routing';
import api from '@/api';

export default {
  name: 'Unauthorized',

  components: { Btn },

  computed: {
    /** @return {{[groupId: string]: import('@/store').Group}} */
    groupOptions() {
      return this.$store.state.groups;
    },
  },

  methods: {
    async redirect() {
      if (this.$store.getters.hasPermission(Permission.ACCESS_OP)) {
        this.$router.replace({
          name: GroupRoute.LIVE_MAP,
          params: { groupId: this.$store.getters.group._id },
        });
      } else if (Object.keys(this.groupOptions).length > 0) {
        // handle redirect to 1st group by default
        await this.$store.dispatch('groupChange', Object.values(this.groupOptions)[0]._id);
        this.$router.replace({
          name: GroupRoute.GROUP,
          params: { groupId: Object.values(this.groupOptions)[0]._id },
        });
      } else {
        // if user has no group, redirect = logout
        await api.logout();
        this.$router.push({ name: AuthRoute.LOGIN });
      }
    },
  },
};
</script>

<style lang="scss">
.error-403 {
  height: 100%;
  color: $text-dark-variant;

  &__image {
    height: 50vh;
  }

  &__image-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 50%;
  }

  &__subtitle {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 28px;
  }

  &__text-block {
    height: 50%;
    padding: 70px;
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    font-size: 96px;
  }
}
</style>

<i18n locale="fr">
{
  "access": "Vous n'avez pas accès à cette page.",
  "goBack": "Retour à la page d'accueil",
  "contact": "Veuillez contacter votre administrateur."
}
</i18n>

<i18n locale="en">
{
  "access": "You do not have access to this page.",
  "goBack": "Back to the home page",
  "contact": "Please contact your administrator."
}
</i18n>
