<template>
  <div class="device-list">
    <div class="device-list__header">
      <div class="device-list__header-side">
        <!-- Removing button temporarily due to a bug with API and driver -->
        <!-- <Btn type="primary" @click="addDeviceModal">
          {{ $t('newDevice') }}
        </Btn> -->
      </div>
      <!--  Download data -->
      <div class="device-list__header-side">
        <Btn type="secondary" @click="downloadDeviceData">
          {{ $t('download') }}
        </Btn>
        <Btn type="primary" @click="openRegistrationModal">
          <font-awesome-icon icon="fa-mobile-screen" class="device-list__icon" />
          {{ registrationCode ? $t('seeCode') : $t('register') }}
        </Btn>
      </div>
    </div>
    <DataGridVuetify
      ref="dataGrid"
      v-model:renderedDataLength="renderedDataLength"
      :title="$t('devices', { count: renderedDataLength })"
      :data="shownDevices"
      :datagrid="datagrid"
      :loading="loading"
      :show-header="false"
      :tabs="tabs"
    >
      <template #actions="propsAction">
        <ActionCell
          :actions="getActionsToShow(propsAction.object)"
          :object="propsAction.object"
          @edit="editModal"
          @restore="restoreModal"
          @archive="archiveModal"
        />
      </template>
      <template #selectedItemsActions="propsAction">
        <template v-if="propsAction.activeTab === Tabs.CURRENT">
          <Btn
            type="secondary"
            :smaller="true"
            :title="$t('archive')"
            @click="multiArchive(propsAction.objects)"
          >
            <span class="action-cell__publish-btn">
              <font-awesome-icon icon="fa-box-archive" class="mr-2" />
              {{ $t('archive') }}
            </span>
          </Btn>
        </template>
        <template v-else>
          <Btn
            type="secondary"
            :smaller="true"
            :title="$t('restore')"
            @click="multiRestore(propsAction.objects)"
          >
            <span class="action-cell__publish-btn">
              <font-awesome-icon icon="fa-boxes-packing" class="mr-2" />
              {{ $t('restore') }}
            </span>
          </Btn>
        </template>
      </template>
    </DataGridVuetify>

    <ModalDeviceAdd
      v-if="modalShown === ModalType.ADD"
      :existing-devices-ids="existingDevicesIds"
      @close="closeModal()"
      @submit="refreshList()"
    />

    <ModalDeviceEdit
      v-if="modalShown === ModalType.EDIT"
      :device-id="selectedDevice.device_id"
      @close="closeModal()"
    />

    <ModalRegisterDevices
      v-if="modalShown === ModalType.REGISTER"
      :code="registrationCode"
      @close="closeModal()"
    />

    <ModalArchiveRestore
      v-if="modalShown === ModalType.RESTORE"
      :title="$t('restoreDevice', { count: multipleSelectedDevice?.length || 1 })"
      is-restore-case
      :body="modalArchiveRestoreBodyText"
      @close="closeModal()"
      @submit="submitModalArchiveRestore"
    />

    <ModalArchiveRestore
      v-if="modalShown === ModalType.ARCHIVE"
      :title="$t('archiveDevice', { count: multipleSelectedDevice?.length || 1 })"
      :body="modalArchiveRestoreBodyText"
      @close="closeModal()"
      @submit="submitModalArchiveRestore"
    />
  </div>
</template>

<script>
import cloneDeep from 'clone-deep';
import dayjs from 'dayjs';

import { POSITION, useToast } from 'vue-toastification';
import api from '@/api';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import { ColumnKey, getDatagrid, DEVICE_LIST_LS_COLUMNS } from '@/pages/DeviceListPage/DeviceList.conf.js';
import { toCSV, triggerDownloadCSV } from '@/libs/csv';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';

import ModalDeviceEdit from './ModalDeviceEdit.vue';
import ModalDeviceAdd from './ModalDeviceAdd.vue';
import ModalRegisterDevices from './ModalRegisterDevices.vue';
import RetryToast from '@/components/common/RetryToast.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import { DeviceConnectionStatus } from './cells/ConnectionCell.vue';
import { GtfsUpdateState } from './cells/GtfsCell.vue';
import { DeviceStatus } from './cells/StatusCell.vue';

import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';

const toast = useToast();

const ModalType = {
  ADD: 'add',
  EDIT: 'edit',
  ARCHIVE: 'remove',
  REGISTER: 'register',
  RESTORE: 'restore',
};

/** @enum {string} */
export const Tabs = {
  CURRENT: 'current',
  ARCHIVE: 'archived',
};

export default {
  name: 'DeviceList',

  components: {
    ActionCell,
    Btn,
    DataGridVuetify,
    ModalDeviceEdit,
    ModalDeviceAdd,
    ModalRegisterDevices,
    ModalArchiveRestore,
  },

  data() {
    return {
      ColumnKey,
      DEVICE_LIST_LS_COLUMNS,
      ModalType,
      Tabs,

      /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),
      /** @type {Array<string>} */
      existingDevicesIds: [],
      /** @type {Boolean} */
      isSearchBarInUse: false,
      /** @type {Boolean} */
      loading: true,
      /** @type {Array<import('@/store/devices').DeviceExtended>} */
      shownDevices: [],
      /** @type {Array<import('@/store/devices').DeviceExtended>} */
      deviceListFormatted: [],
      /** @type {string} */
      modalShown: null,
      /** @type {Array<import('@/store/devices').DeviceExtended>} */
      devices: [],
      /** @type {import('@/api).RegistrationCode} */
      registrationCode: null,
      /** @type {number} */
      renderedDataLength: null,
      /** @type {import('@/store/devices').DeviceExtended} */
      selectedDevice: null,
      /** @type {Array<import('@/store/devices').DeviceExtended>} */
      multipleSelectedDevice: null,
      /** @type {Array<import('@/store/devices').DeviceExtended>} */
      updateFailedList: [],
      /** @type {Array<string>} */
      statusCategories: Object.values(Tabs),
      /** @type {Tabs} */
      selectedTab: Tabs.CURRENT,
    };
  },

  computed: {
    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    modalArchiveRestoreBodyText() {
      let contextText =
        this.modalShown === ModalType.ARCHIVE ? 'confirmArchiveDevice' : 'confirmRestoreDevice';
      if (this.selectedDevice) {
        const secondPart = this.selectedDevice.name ? `(${this.selectedDevice.name})` : '';
        return this.$t(contextText, [this.selectedDevice.device_id, secondPart]);
      }
      if (this.multipleSelectedDevice) {
        // i18n text variable with an s ('confirmArchiveDevices' : 'confirmRestoreDevices')
        contextText += 's';
        return this.$tc(contextText, this.multipleSelectedDevice.length, [
          this.multipleSelectedDevice.length,
        ]);
      }
      return null;
    },

    /** @return {Array<import('@/components/Table/DataGridVuetify/index.vue').Tab>} */
    tabs() {
      return this.statusCategories.map(category => ({
        value: category,
        name: this.$t(`tabs.${category}`),
        counter: null,
        dataList: [],
        filterField: ColumnKey.ARCHIVED,
        filterValues: Tabs.ARCHIVE === category ? [true] : [false],
        isDefaultActive: Tabs.CURRENT === category,
        icon: Tabs.ARCHIVE === category ? 'fa:fas fa-archive' : '',
      }));
    },
  },

  created() {
    this.loadData();
    this.getRegistrationCode();
  },

  methods: {
    editModal(apiDataRow) {
      this.selectedDevice = apiDataRow;
      this.modalShown = ModalType.EDIT;
    },
    archiveModal(apiDataRow) {
      this.selectedDevice = apiDataRow;
      this.modalShown = ModalType.ARCHIVE;
    },
    restoreModal(apiDataRow) {
      this.selectedDevice = apiDataRow;
      this.modalShown = ModalType.RESTORE;
    },
    getActionsToShow(object) {
      const actions = [];
      if (object.archived) {
        actions.push('restore');
      } else {
        actions.push('archive', 'edit');
      }
      return actions;
    },
    /**
     * Load routes list for assigned values.
     */
    async loadRoutes() {
      this.routes = Object.freeze(await this.$store.dispatch('gtfs/getRoutesMap'));
    },

    /**
     * Load drivers list for assigned values.
     */
    loadDrivers() {
      this.$store.dispatch('drivers/loadList');
    },

    /**
     * Load vehicles list for assigned values.
     */
    loadVehicles() {
      this.$store.dispatch('vehicles/loadList');
    },

    /**
     * Get unarchied devices from store and add extended infos
     * Set shownDevices & deviceListFormatted
     */
    getDevicesAndComplementaryInfos() {
      this.loading = true;
      const devices = cloneDeep(this.$store.getters['devices/getDevicesInfos']);
      const currentGtfsFile = this.group.current_file;
      this.existingDevicesIds = Object.values(devices).map(d => d.device_id);

      const formatSemverForSorting = version => {
        if (!version) return null;
        const [major, minor, patch] = version.split('.');
        // will work for versions up to 99.99.99 :
        const minorPrefixed = minor.length === 1 ? `0${minor}` : minor;
        const patchPrefixed = patch.length === 1 ? `0${patch}` : patch;
        return `${major}.${minorPrefixed}.${patchPrefixed}`;
      };

      // eslint-disable-next-line no-restricted-syntax
      for (const device of Object.values(devices)) {
        device.ts = device.ts ? new Date(device.ts * 1000) : null;
        device.teams = device.teams ? device.teams[0] : null;
        device.teamName = this.$store.getters.getTeamNameById(device.teams);
        device.installer = device.installer ? this.$t(`applicationSource.${device.installer}`) : null;
        device.drive_mode = device.drive_mode ? this.$t(`driveMode.${device.drive_mode}`) : '-';
        device.trip_filter = this.getTripAssignement(device.trip_filter);
        device.simulation_mode = device.simulation_mode ? this.$t(`yes`) : null;
        device.isGtfsUpToDate = this.checkGtfsUpToDate(currentGtfsFile, device.gtfs_id);
        device.vehicleInfos = this.fetchVehicle(device.assigned_vehicle_id);
        device.driverInfos = this.fetchDriver(device.assigned_driver_id);
        device.archived = !!device?.archived;
        device.status = this.calculateStatus(device);
        device.sortableAppVersion = formatSemverForSorting(device.app_version);

        device.connection = this.$store.state.devices.online[device.device_id]
          ? DeviceConnectionStatus.ONLINE
          : DeviceConnectionStatus.OFFLINE;
      }

      this.shownDevices = Object.values(devices);
      this.deviceListFormatted = Object.values(devices);
      this.loading = false;
    },

    calculateStatus(device) {
      if (device.archived) return DeviceStatus.ARCHIVED;
      const isOlderThanAYear = device.ts ? dayjs(new Date()).subtract(1, 'year').toDate() > device.ts : true;
      return isOlderThanAYear ? DeviceStatus.INACTIVE : DeviceStatus.ACTIVE;
    },

    async getRegistrationCode() {
      // We check if there is a valid code, if not, response will be null
      this.registrationCode = await api.devices.getDeviceRegistrationCode(this.group._id);
    },

    getTripAssignement(tripFilter) {
      let valueTranslated = this.$t('driveMode.deadRun');
      if (tripFilter && (tripFilter.trip_id || tripFilter.block_id))
        valueTranslated = tripFilter.trip_id || tripFilter.block_id;
      return valueTranslated;
    },

    /**
     * Check if a device GtfsId is equal to currentGtfs
     * @param {string} currentGtfs
     * @param {string} deviceGtfsId
     * @return {string} yes/no/noData
     */
    checkGtfsUpToDate(currentGtfs, deviceGtfsId) {
      let result = GtfsUpdateState.NO_DATA;
      if (deviceGtfsId) {
        currentGtfs === deviceGtfsId
          ? (result = GtfsUpdateState.UPTODATE)
          : (result = GtfsUpdateState.OUTDATED);
      }
      return result;
    },

    /**
     * fetch a Vehicle representation in format 'license_plate (fleet_number)'
     * @param {*} vehicleId
     * @return {string}
     */
    fetchVehicle(vehicleId) {
      if (vehicleId) {
        const vehicleFound = this.$store.getters['vehicles/getVehicleInfosById'](vehicleId);
        return vehicleFound || this.$t('unknownVehicle');
      }
      return '-';
    },

    /**
     * fetch a Driver representation in format 'driver_name (staff_number)'
     * @param {*} driverId
     * @return {string}
     */
    fetchDriver(driverId) {
      if (driverId) {
        const driverFound = this.$store.getters['drivers/getDriverInfosById'](driverId);
        return driverFound || this.$t('unknownDriver');
      }
      return '-';
    },

    /**
     * Close the open modal
     */
    closeModal() {
      this.modalShown = null;
      this.selectedDevice = null;
      this.getDevicesAndComplementaryInfos();
    },

    addDeviceModal() {
      this.modalShown = ModalType.ADD;
    },

    /**
     * On modal click, try to archive or restore selected devices
     */
    async submitModalArchiveRestore() {
      if (this.selectedDevice) {
        await this.archiveOrRestoreDevices([this.selectedDevice]);
      } else if (this.multipleSelectedDevice) {
        await this.archiveOrRestoreDevices(this.multipleSelectedDevice);
      }
    },

    /**
     * Archive or Restore selected devices
     * @param {Array<import('@/store/devices').DeviceExtended>} devices
     */
    async archiveOrRestoreDevices(devices) {
      const toArchive = this.modalShown === 'remove';
      this.updateFailedList = [];
      await Promise.all(
        devices.map(async device => {
          try {
            await this.$store.dispatch('devices/archiveRestoreById', {
              groupId: this.group._id,
              deviceId: device.device_id,
              toArchive,
            });
          } catch {
            this.updateFailedList.push(device);
          }
        })
      );
      if (this.updateFailedList.length > 0) {
        const toastId = toast.error(
          {
            component: RetryToast,
            props: {
              text: this.$tc('updateElementFailed', this.updateFailedList.length, [
                this.updateFailedList.length,
              ]),
            },
            listeners: { toastRetry: () => this.retryArchive() },
          },
          {
            position: POSITION.BOTTOM_RIGHT,
          }
        );
        setTimeout(() => toast.dismiss(toastId), 5000);
      }
      this.refreshList();
      this.closeModal();
    },
    // Retry archiving failed devices
    retryArchive() {
      this.multipleSelectedDevice = this.updateFailedList;
      this.archiveModal(null);
    },

    async refreshList() {
      await this.$store.dispatch('devices/getDevices');
      this.getDevicesAndComplementaryInfos();
    },

    async loadData() {
      // Load all necessary datas
      await Promise.all([
        this.$store.dispatch('devices/getDevices'),
        this.loadRoutes(),
        this.loadDrivers(),
        this.loadVehicles(),
      ]);

      this.getDevicesAndComplementaryInfos();
    },

    /**
     * Get data from dataGrid and create download
     */
    async downloadDeviceData() {
      const result = this.$refs.dataGrid.exportData();
      if (result.data.length > 0) {
        // Iterate through data to format some properties
        result.data.map(item => {
          // Format to time if ts
          item.ts = new Date(item.ts).toLocaleString(this.$i18n.locale);
          // Format gtfsUpToDate
          item.isGtfsUpToDate = this.$t(item.isGtfsUpToDate);
          // Format status
          item.status = this.$t(item.status);
          // Remove 'Connection' data
          delete item.connection;
          return item;
        });

        // Remove 'Connection' column
        const connectionIndex = result.translatedKeys.indexOf(this.$t(`column.${ColumnKey.CONNECTION}`));
        result.translatedKeys.splice(connectionIndex, 1);

        // creating csv
        const data = [
          result.translatedKeys,
          ...result.data.map(row => Object.values(row).map(value => value?.toString() || '-')),
        ];
        const link = toCSV(data);
        // Trigger download
        triggerDownloadCSV(
          link,
          this.$t('deviceListDownloadName', [this.group.group_id, dateObjToGtfsFormat(new Date())])
        );
      }
    },

    async openRegistrationModal() {
      // if no valid code, we request code generation
      if (!this.registrationCode) {
        this.registrationCode = await api.devices.generateDeviceRegistrationCode(this.group._id);
      }
      this.modalShown = ModalType.REGISTER;
    },
    multiArchive(obj) {
      this.modalShown = ModalType.ARCHIVE;
      this.multipleSelectedDevice = obj;
    },
    multiRestore(obj) {
      this.modalShown = ModalType.RESTORE;
      this.multipleSelectedDevice = obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.device-list {
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__header-side {
    display: flex;
  }

  &__icon {
    margin-right: 5px;
  }
}
</style>

<i18n locale="fr">
{
  "archiveDevice": "Archiver l'appareil | Archiver les appareils",
  "restoreDevice": "Restaurer l'appareil | Restaurer les appareils",
  "confirmArchiveDevice": "Êtes-vous sûr de vouloir archiver l'appareil {0} {1} ?",
  "confirmArchiveDevices": "Êtes-vous sûr de vouloir archiver {0} appareil ? | Êtes-vous sûr de vouloir archiver {0} appareils ?",
  "confirmRestoreDevice": "Êtes-vous sûr de vouloir restaurer l'appareil {0} {1} ?",
  "confirmRestoreDevices": "Êtes-vous sûr de vouloir restaurer {0} appareil ? | Êtes-vous sûr de vouloir restaurer {0} appareils ?",
  "deviceListDownloadName": "liste-appareils_{0}_{1}.csv",
  "register": "Connecter des appareils",
  "seeCode": "Voir le code d'authentification",
  "devices": "appareil | appareils",
  "newDevice": "Nouvel appareil",
  "no": "non",
  "yes": "oui",
  "active": "Actif",
  "inactive": "Inactif",
  "archived": "Archivé",
  "updateElementFailed": "Les modifications ont échoué pour {0} élément. | Les modifications ont échoué pour {0} éléments.",
  "tabs": {
    "archived": "Archives",
    "current": "Appareils"
  }

}
</i18n>

<i18n locale="en">
{
  "archiveDevice": "Archive device | Archive devices",
  "restoreDevice": "Restore device | Restore devices",
  "confirmArchiveDevice": "Do you want to archive the device {0} {1} ?",
  "confirmArchiveDevices": "Do you want to archive {0} device ? | Do you want to archive {0} devices ?",
  "confirmRestoreDevice": "Do you want to restore the device {0} {1} ?",
  "confirmRestoreDevices": "Do you want to restore {0} device ? | Do you want to restore {0} devices ?",
  "deviceListDownloadName": "device-list_{0}_{1}.csv",
  "register": "Register devices",
  "seeCode": "See authentification code",
  "devices": "device | devices",
  "newDevice": "New device",
  "no": "no",
  "yes": "yes",
  "active": "Active",
  "inactive": "Inactive",
  "archived": "Archived",
  "updateElementFailed": "Updates failed for {0} item. | Updates failed for {0} items.",
  "tabs": {
    "archived": "Archives",
    "current": "Devices"
  }
}
</i18n>
