<template>
  <div class="no-incident">
    <div class="no-incident__header">
      <div class="no-incident__header-side">
        <Btn v-if="!zendeskConnectorActive" type="primary" @click="showModal = true">
          {{ $t('newIncident') }}
        </Btn>
        <Btn v-else type="primary" route="trip-list">
          {{ $t('newIncident') }}
        </Btn>
        <Btn type="secondary" :disabled="true">
          <font-awesome-icon icon="fa-angle-down" />
          {{ $t('columns') }}
        </Btn>
        <TableSearchBar
          class="no-incident__search-bar"
          :disabled="true"
          :search-fields="[]"
          :search-list="[]"
        />
      </div>
      <div class="no-incident__header-side">
        <Btn type="secondary" :disabled="true">
          {{ $t('download') }}
        </Btn>
      </div>
    </div>

    <div class="no-incident__body">
      <div class="no-incident__message">
        <div>{{ $t('noZendesk') }}</div>
        <div>{{ $t('createNewIncident') }}</div>
      </div>
      <img alt="illustration" class="illustration" src="@/assets/img/no_incident.svg" />
    </div>

    <ActivateFeatureModal v-if="showModal" :title="$t('incident')" @close="showModal = false" />
  </div>
</template>

<script>
import TableSearchBar from '@/components/Table/TableSearchBar.vue';
import Btn from '@/components/ui/Btn.vue';
import ActivateFeatureModal from '@/components/common/ActivateFeatureModal.vue';

export default {
  name: 'NoIncident',

  components: {
    ActivateFeatureModal,
    Btn,
    TableSearchBar,
  },

  props: {
    zendeskConnectorActive: {
      required: true,
      type: Boolean,
    },
  },

  data: () => ({
    /** @type {Boolean} */
    showModal: false,
  }),
};
</script>

<style lang="scss">
.no-incident {
  padding: $view-standard-padding;

  &__body {
    position: relative;
    margin-left: 60px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__header-side {
    display: flex;
  }

  &__message {
    position: absolute;
    top: 110px;
    left: 200px;
    color: $secondary;
    font-weight: $font-weight-semi-bold;
    font-size: 21px;
    line-height: 31px;
  }

  &__modal-body {
    padding: 10px;
  }

  &__search-bar {
    padding-left: 10px;
  }

  .illustration {
    width: auto;
    height: auto;
    max-height: 800px;
  }
}
</style>

<i18n locale="fr">
{
  "activateFeature": "Pour activer cette fonctionnalité, veuillez nous contacter.",
  "columns": "Colonnes",
  "createNewIncident": "Créez un nouvel incident avec ce bouton.",
  "incident": "Incident",
  "newIncident": "Nouvel incident",
  "noZendesk": "Aucune donnée Zendesk disponible."
}
</i18n>

<i18n locale="en">
{
  "activateFeature": "To activate the feature please contact us.",
  "columns": "Columns",
  "createNewIncident": "Create a new incident with this button.",
  "incident": "Incident",
  "newIncident": "New incident",
  "noZendesk": "No Zendesk data available."
}
</i18n>
