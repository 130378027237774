/**
 * @member {Array<import('../models/DataGrid.models').DataGridColumn>} visibleColumns
 * @member {import('../models/DataGrid.models').DataGrid} datagrid
 */

import VueMultiselect from '@vueform/multiselect';

export default {
  name: 'ColumnSelectable',

  components: {
    VueMultiselect,
  },

  emits: ['changeColumnsSelection'],

  data() {
    return {
      selectedColumns: [],
    };
  },

  created() {
    this.selectedColumns = this.datagrid.getSelectedColumnsByDefault();
  },

  methods: {
    /**
     * Return column class names
     *
     * @param {import('../models/DataGrid.models').DataGridColumn} column
     * @return {String}
     */
    getColumnClass(column) {
      let baseClass = 'hiddable-column';
      if (column.getIsActionCell()) baseClass = [baseClass, 'action-column'].join(' ');
      return !this.visibleColumns.find(rc => column.isEqual(rc))
        ? [baseClass, 'hidden-column'].join(' ')
        : baseClass;
    },
    /**
     * Return if true if a column is visible
     * @param {import('../models/DataGrid.models').DataGridColumn} column
     * @return {boolean}
     */
    isVisible(column) {
      return this.visibleColumns.find(rc => column.isEqual(rc));
    },

    /**
     * Change columns selection handler
     *
     * @param {import('../models/DataGrid.models').DataGridColumn} selection
     */
    onChangeColumnsSelection(selection) {
      this.selectedColumns = selection;
      this.$emit('changeColumnsSelection', selection);
    },
  },
};
