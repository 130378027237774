<template>
  <div class="dashboard">
    <div class="dashboard__row">
      <div class="flex">
        <div class="flex__3">
          <DonutChart :title="$t('punctuality')" :values="livePunctuality" />
        </div>

        <div class="flex__5">
          <BarChart
            :categories="categories"
            :series="historyPunctuality"
            :title="$t('metrics.punctuality')"
          />
        </div>

        <div class="flex__5">
          <BarChart :categories="categories" :series="historyKilometers" :title="$t('kilometers')" />
        </div>
      </div>
    </div>

    <div class="dashboard__row">
      <div class="flex">
        <div class="flex__3">
          <div class="dashboard__title">
            {{ $t('metrics.trip-tracking') }}
          </div>
          <div class="dashboard__card">
            <Btn
              class="dashboard__btn"
              :route="{ name: GroupRoute.TRIP_LIST, query: date ? { date: dateOfDayGtfsTimer.value } : {} }"
              type="secondary"
            >
              {{ $t('goToTripList') }}
            </Btn>
          </div>
        </div>

        <div class="flex__5">
          <BarChart
            :categories="categories"
            percentage
            :series="historyStartTrip"
            :title="$t('startTripsRate')"
          />
        </div>

        <div class="flex__5">
          <BarChart
            :categories="categories"
            :series="historyPassengerCounts"
            :title="$t('metrics.passenger-counts')"
          />
        </div>
      </div>
    </div>

    <div class="dashboard__row">
      <div class="flex">
        <div class="flex__3">
          <DonutChart :title="$t('liveDevices')" :values="liveDevices" />
        </div>

        <div class="flex__5">
          <BarChart
            :categories="categories"
            :series="historyDevices"
            :title="$t('metrics.connected-devices')"
          />
        </div>

        <div class="flex__5">
          <BarChart :categories="categories" :series="historyConsumption" :title="$t('consumption')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stats as ApiStats } from '@/api';
import BarChart from '@/components/ui/BarChart.vue';
import Btn from '@/components/ui/Btn.vue';
import DonutChart from '@/components/ui/DonutChart.vue';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { GroupBy } from '@/libs/reports';
import { DelayState } from '@/store/devices';
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'Dashboard',

  components: {
    BarChart,
    Btn,
    DonutChart,
  },

  props: {
    date: {
      type: [String, Number, Date],
    },
  },

  data() {
    return {
      GroupRoute,
      /** @type {Array<number>} */
      historyDates: [],

      dateOfDayGtfsTimer: {
        _id: null,
        value: dateObjToGtfsFormat(this.date ? new Date(this.date) : new Date()),
      },

      /** @type {Array<import('@/components/ui/DonutChart.vue').DonutData>} */
      liveDevices: [],

      /** @type {Array<import('@/components/ui/DonutChart.vue').DonutData>} */
      livePunctuality: [],

      /** @type {{[dateGtfs: string]: number}} */
      statsDailyDevices: {},

      /** @type {{[dateGtfs: string]: number}} */
      statsDailyPassengerCounts: {},

      /** @type {{[dateGtfs: string]: Punctuality}} */
      statsDailyPunctuality: {},

      /** @type {{[dateGtfs: string]: number}} */
      statsDailyTrips: {},

      /** @type {{[dateGtfs: string]: number}} */
      statsDailyVk: {},

      /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
      tripsOfDay: {},

      /** @type {NodeJS.Timeout} */
      updateHistoryTimer: null,
    };
  },

  computed: {
    /** @return {Array<string>} */
    categories() {
      return this.historyDates.map(ts => this.$d(ts, 'dateShort'));
    },

    /** @return {{[state in DelayState|'deadRun']: number}} */
    devicesStatus() {
      return Object.values(this.onlineDevices).reduce(
        (acc, device) => {
          if (device.delay != null) {
            const delayState = this.$store.getters['devices/getDelayState'](device.delay);
            acc[delayState] += 1;
          }

          if (device.trip_id == null) {
            acc.deadRun += 1;
          }

          return acc;
        },
        {
          [DelayState.EARLY]: 0,
          [DelayState.LATE]: 0,
          [DelayState.ON_TIME]: 0,
          deadRun: 0,
        }
      );
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {Array<import('@/components/ui/BarChart.vue').BarSerie>} */
    historyConsumption() {
      const data = this.historyKilometers[0].data.map(d => Math.round((d * 56.5) / 100));
      return [{ data }];
    },

    /** @return {Array<import('@/components/ui/BarChart.vue').BarSerie>} */
    historyDevices() {
      return [
        {
          data: this.historyDates.map(ts => {
            const dateGtfs = dateObjToGtfsFormat(new Date(ts));
            return this.statsDailyDevices[dateGtfs] || 0;
          }),
        },
      ];
    },

    /** @return {Array<import('@/components/ui/BarChart.vue').BarSerie>} */
    historyPassengerCounts() {
      return [
        {
          data: this.historyDates.map(ts => {
            const dateGtfs = dateObjToGtfsFormat(new Date(ts));
            return this.statsDailyPassengerCounts[dateGtfs] || 0;
          }),
        },
      ];
    },

    /** @return {Array<import('@/components/ui/BarChart.vue').BarSerie>} */
    historyPunctuality() {
      const data = this.historyDates.reduce(
        (acc, ts) => {
          const dateGtfs = dateObjToGtfsFormat(new Date(ts));
          const punctuality = this.statsDailyPunctuality[dateGtfs] || {
            onTime: 0,
            early: 0,
            late: 0,
          };
          const total = Object.values(punctuality).reduce((sum, x) => sum + (x || 0));

          acc.onTime.push(Math.round((punctuality.onTime / total) * 100 || 0));
          acc.early.push(Math.round((punctuality.early / total) * 100 || 0));
          acc.late.push(Math.round((punctuality.late / total) * 100 || 0));

          return acc;
        },
        { onTime: [], early: [], late: [] }
      );

      return [
        {
          color: '#00b871', // $primary-light
          data: data.onTime,
          name: /** @type {string} */ (this.$t(`delayState.${DelayState.ON_TIME}`)),
        },
        {
          color: '#EB5757', // $danger
          data: data.early,
          name: /** @type {string} */ (this.$t(`delayState.${DelayState.EARLY}`)),
        },
        {
          color: '#f99c49', // $warn
          data: data.late,
          name: /** @type {string} */ (this.$t(`delayState.${DelayState.LATE}`)),
        },
      ];
    },

    /** @return {Array<import('@/components/ui/BarChart.vue').BarSerie>} */
    historyStartTrip() {
      return [
        {
          data: this.historyDates.map(ts => {
            const dateGtfs = dateObjToGtfsFormat(new Date(ts));
            return this.statsDailyTrips[dateGtfs] || 0;
          }),
        },
      ];
    },

    /** @return {Array<import('@/components/ui/BarChart.vue').BarSerie>} */
    historyKilometers() {
      return [
        {
          data: this.historyDates.map(ts => {
            const dateGtfs = dateObjToGtfsFormat(new Date(ts));
            return this.statsDailyVk[dateGtfs] || 0;
          }),
        },
      ];
    },

    /** @return {{[deviceId: string]: import('@/store/devices').Device}} */
    onlineDevices() {
      return this.$store.getters['devices/onlineDevices'];
    },
  },

  watch: {
    '[dateOfDayGtfsTimer.value, date]': {
      immediate: true,
      handler() {
        this.updateHistoryDates();
        this.updateTripsOfDay();
      },
    },

    devicesStatus: {
      deep: true,
      immediate: true,
      handler(newValues, oldValues) {
        const isDifferent = key => newValues[key] !== oldValues[key];
        if (oldValues == null || Object.values(DelayState).some(isDifferent)) {
          this.updateLivePunctuality();
        }

        if (oldValues == null || newValues.deadRun !== oldValues.deadRun) {
          this.updateLiveDevices();
        }
      },
    },

    historyDates: {
      immediate: true,
      deep: true,
      handler() {
        this.updateStatsDailyDevices();
        this.updateStatsDailyPassengerCounts();
        this.updateStatsDailyPunctuality();
        this.updateStatsDailyTrips();
        this.updateStatsDailyVk();
      },
    },

    onlineDevices: {
      immediate: true,
      handler(newOnline, oldOnline) {
        if (oldOnline == null || Object.keys(newOnline).length !== Object.keys(oldOnline).length) {
          this.updateLiveDevices();
        }
      },
    },
  },

  created() {
    const updateTimer = () => {
      const tomorrow = this.date ? new Date(this.date) : new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      const waitTime = tomorrow.getTime() - Date.now();

      this.dateOfDayGtfsTimer._id = setTimeout(() => {
        this.dateOfDayGtfsTimer.value = dateObjToGtfsFormat(this.date ? this.date : new Date());
        updateTimer();
      }, waitTime);
    };

    updateTimer();

    this.updateHistoryTimer = setInterval(() => {
      this.updateStatsDailyDevices();
      this.updateStatsDailyPassengerCounts();
      this.updateStatsDailyPunctuality();
      this.updateStatsDailyTrips();
      this.updateStatsDailyVk();
    }, 300000);
  },

  beforeUnmount() {
    clearInterval(this.updateHistoryTimer);
    clearTimeout(this.dateOfDayGtfsTimer._id);
  },

  methods: {
    updateHistoryDates() {
      const today = dateGtfsFormatToObj(this.dateOfDayGtfsTimer.value);
      const dates = [];

      for (let i = 7; i >= 0; i -= 1) {
        dates.push(new Date(today.getFullYear(), today.getMonth(), today.getDate() - i).getTime());
      }

      this.historyDates = dates;
    },

    updateLiveDevices() {
      const nbDevicesOnTrip = Object.keys(this.onlineDevices).length - this.devicesStatus.deadRun;

      this.liveDevices = [
        {
          name: /** @type {string} */ (this.$t('onTrip')),
          value: nbDevicesOnTrip,
          // $secondary
          color: '#0A4B4D',
        },
        {
          name: /** @type {string} */ (this.$t('deadRun')),
          value: this.devicesStatus.deadRun,
          color: '#c2d2df',
        },
      ];
    },

    updateLivePunctuality() {
      this.livePunctuality = [
        {
          color: '#00b871', // $primary-light
          name: /** @type {string} */ (this.$t(`delayState.${DelayState.ON_TIME}`)),
          value: this.devicesStatus[DelayState.ON_TIME],
        },
        {
          color: '#EB5757', // $danger
          name: /** @type {string} */ (this.$t(`delayState.${DelayState.EARLY}`)),
          value: this.devicesStatus[DelayState.EARLY],
        },
        {
          color: '#f99c49', // $warn
          name: /** @type {string} */ (this.$t(`delayState.${DelayState.LATE}`)),
          value: this.devicesStatus[DelayState.LATE],
        },
      ];
    },

    async updateStatsDailyDevices() {
      const from = dateObjToGtfsFormat(new Date(this.historyDates[0]));
      const to = dateObjToGtfsFormat(new Date(this.historyDates[this.historyDates.length - 1]));
      this.statsDailyDevices = (await ApiStats.getDailyDevices(this.group._id, from, to)).reduce(
        (acc, dailyDevices) => {
          acc[dailyDevices.date] = dailyDevices.devices;

          return acc;
        },
        /** @type {{[dateGtfs: string]: number}} */ ({})
      );
    },

    async updateStatsDailyPassengerCounts() {
      const from = dateObjToGtfsFormat(new Date(this.historyDates[0]));
      const to = dateObjToGtfsFormat(new Date(this.historyDates[this.historyDates.length - 1]));
      this.statsDailyPassengerCounts = (
        await ApiStats.getPassengerCounts(this.group._id, GroupBy.DAY, from, to)
      ).reduce((acc, dailyPassengerCounts) => {
        acc[dailyPassengerCounts.date] = dailyPassengerCounts.total_boarding;

        return acc;
      }, /** @type {{[dateGtfs: string]: number}} */ ({}));
    },

    async updateStatsDailyPunctuality() {
      const today = dateGtfsFormatToObj(this.dateOfDayGtfsTimer.value);
      const sevenDaysAgoTs = new Date(today).setDate(today.getDate() - 7) / 1000;
      const tomorrowTs = new Date(today).setDate(today.getDate() + 1) / 1000;

      /** @type {Array<import('@/api').PunctualityByDay>} */
      const dailyPunctualityData = await ApiStats.getPunctualityStats(
        this.group._id,
        sevenDaysAgoTs,
        tomorrowTs,
        'day'
      );

      this.statsDailyPunctuality = dailyPunctualityData.reduce((acc, dailyPunctuality) => {
        acc[dailyPunctuality.start_date] = {
          onTime: dailyPunctuality.on_time,
          early: dailyPunctuality.too_early,
          late: dailyPunctuality.too_late,
        };

        return acc;
      }, /** @type {{[dateGtfs: string]: Punctuality}} */ ({}));
    },

    async updateStatsDailyTrips() {
      const from = dateObjToGtfsFormat(new Date(this.historyDates[0]));
      const to = dateObjToGtfsFormat(new Date(this.historyDates[this.historyDates.length - 1]));
      const responsePayload = await ApiStats.getTripTracking(this.group._id, GroupBy.DAY, from, to);
      this.statsDailyTrips = (responsePayload || []).reduce((acc, dailyTrips) => {
        acc[dailyTrips.start_date] = Math.round((dailyTrips.tracked / dailyTrips.scheduled) * 100);
        return acc;
      }, /** @type {{[dateGtfs: string]: number}} */ ({}));
    },

    async updateStatsDailyVk() {
      const from = dateObjToGtfsFormat(new Date(this.historyDates[0]));
      const to = dateObjToGtfsFormat(new Date(this.historyDates[this.historyDates.length - 1]));
      this.statsDailyVk = (await ApiStats.getTripKM(this.group._id, GroupBy.DAY, from, to)).reduce(
        (acc, dailyVk) => {
          if (dailyVk.reliable_km) {
            acc[dailyVk.date] = Math.round(dailyVk.reliable_km);
          } else {
            acc[dailyVk.date] = Math.round(dailyVk.recorded_commercial_km);
          }

          return acc;
        },
        /** @type {{[dateGtfs: string]: number}} */ ({})
      );
    },

    async updateTripsOfDay() {
      this.tripsOfDay = await this.$store.dispatch('trips/getTripsInterval', {
        gtfsId: this.group.current_file,
        dateGtfs: this.dateOfDayGtfsTimer.value,
      });
    },
  },
};

/**
 * @typedef {Object} Punctuality
 * @property {number} onTime
 * @property {number} early
 * @property {number} late
 */
</script>

<style lang="scss">
.flex {
  display: flex;
  text-align: center;

  &__3 {
    flex: 3;
  }

  &__5 {
    flex: 5;
  }
}

.dashboard {
  min-width: 1400px;
  max-width: 1700px;
  margin: 20px auto;

  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__card {
    position: relative;
    height: 70%;
    margin: 20px;
    border: solid 1px $border;
    border-radius: 5px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin: 0 1.5em;
    padding: 0.5em 0;

    &:not(:first-child) {
      border-top: 1px solid $border;
    }

    & > * {
      flex: 1;
      min-width: 0;
    }
  }

  &__title {
    color: $text-dark;
    font-weight: $font-weight-semi-bold;
  }
}
</style>

<i18n locale="fr">
{
  "consumption": "Consommation de carburant (en litres)",
  "deadRun": "Haut-le-pied",
  "goToTripList": "Voir la liste des courses",
  "kilometers": "Kilomètres enregistrés (commerciaux)",
  "liveDevices": "Véhicules",
  "onTrip": "En course",
  "punctuality": "Avance/retard en temps réel",
  "startTripsRate": "Taux de prise de course",
}
</i18n>

<i18n locale="en">
{
  "consumption": "Fuel consomption (in liters)",
  "deadRun": "Dead running",
  "goToTripList": "Go to trip list",
  "kilometers": "Trip kilometers",
  "liveDevices": "Vehicles",
  "onTrip": "On trip",
  "punctuality": "Advance/delay in real-time",
  "startTripsRate": "Trips monitored",
}
</i18n>

<i18n locale="cz">
{
  "consumption": "Spotřeba paliva (v litrech)",
  "onTrip": "Na cestě",
  "punctuality": "Předstih/zpoždění v reálném čase",
  "kilometers": "Zaznamenané kilometry (při obsluze linky)",
  "deadRun": "Na cestě z/do depa",
  "liveDevices": "Vozidla",
  "startTripsRate": "Poměr přijatých a nepřijatých jízd",
}
</i18n>

<i18n locale="de">
{
  "consumption": "Kraftstoffverbrauch (in Litern)",
  "onTrip": "Unterwegs",
  "punctuality": "Verfrühung/Verspätung in Echtzeit",
  "kilometers": "Erfasste Kilometer (kommerziell)",
  "deadRun": "Fahrt zum oder vom Busdepot",
  "liveDevices": "Fahrzeuge",
  "startTripsRate": "Rate der Fahrtenannahme",
}
</i18n>

<i18n locale="es">
{
  "consumption": "Consumo de combustible (en litros)",
  "onTrip": "Viaje en curso",
  "punctuality": "Adelanto/retraso en tiempo real",
  "kilometers": "Kilómetros registrados (comerciales)",
  "deadRun": "Sin pasajeros",
  "liveDevices": "Vehículos",
  "startTripsRate": "Tasa de toma de servicio",
}
</i18n>

<i18n locale="it">
{
  "consumption": "Consumo di carburante (in litri)",
  "onTrip": "In viaggio",
  "punctuality": "Anticipo/ritardo in tempo reale",
  "kilometers": "Chilometri registrati (in servizio)",
  "deadRun": "In tragitto da o verso il deposito",
  "liveDevices": "Veicoli",
  "startTripsRate": "Percentuale di servizi svolti",
}
</i18n>

<i18n locale="pl">
{
  "consumption": "Zużycie paliwa (w litrach)",
  "onTrip": "W podróży",
  "punctuality": "Przejazd przedwczesny/opóźniony w czasie rzeczywistym",
  "kilometers": "Zarejestrowane kilometry (komercyjne)",
  "deadRun": "Przejazdy bez pasażerów",
  "liveDevices": "Pojazdy",
  "startTripsRate": "Wskaźnik wykonywania usług",
}
</i18n>
