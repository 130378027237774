/**
 * Handle redirection to group's main page
 * @vue
 */

import { GroupRoute } from '@/libs/routing';

// @vue/component
export default {
  name: 'Home',

  async created() {
    if (this.$route.path === '/') {
      let groupId = localStorage.getItem('settings.op.lastGroupId');
      const groups = await this.$store.dispatch('groupsUpdate');

      if (!groupId || !groups[groupId]) {
        groupId = Object.values(groups)[0]._id;
      }

      this.$router.push({ name: GroupRoute.GROUP, params: { groupId } });
    }
  },

  render: () => '',
};
