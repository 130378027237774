export default {
  name: 'ApiErrorsMixin',

  data() {
    return {
      /** @type {{[contextId: string]: Array<ApiError>}} */
      apiErrors: {},
    };
  },

  computed: {
    /** @return {Object} */
    apiErrorMessages() {
      const contextIds = Object.keys(this.apiErrors);
      return contextIds.reduce((acc, contextId) => {
        acc[contextId] = this._getApiErrorMessages(contextId);
        return acc;
      }, {});
    },
  },

  methods: {
    /**
     * @param {string} contextId
     * @param {Array<ApiError>} apiErrors
     * Defines the errors returned by the API for a given context
     */
    setApiError(contextId, apiErrors) {
      this.apiErrors[contextId] = apiErrors;
    },
    /**
     * @param {string} contextId
     * Removes API errors defined for a given context
     */
    unsetApiError(contextId) {
      if (!this.apiErrors[contextId]) return;
      this.apiErrors[contextId] = null;
      delete this.apiErrors[contextId];
    },
    /**
     * @param {string} contextId
     * @return {?ApiErrorMessages}
     * Returns API errors grouped by code for a given context
     */
    _getApiErrorMessages(contextId) {
      if (!this.apiErrors[contextId]) return null;
      const apiErrors = [...this.apiErrors[contextId]];
      return apiErrors.reduce((acc, { code, ...others }) => {
        acc[code] = [...(acc[code] || []), others];
        return acc;
      }, {});
    },
  },
};

/**
 * @typedef {Object} ApiError
 * @property {string} code - Error code returned by the API
 * @property {string} details - Error details returned by the API
 * @property {string} value - Error value returned by the API
 */

/** @typedef {{[code: string]: Array<{details: string, value: string}>}} ApiErrorMessages */
