<template>
  <MainLayout>
    <router-view v-if="loaded" />
  </MainLayout>
</template>

<script>
import { datadogLogs } from '@datadog/browser-logs';
import { AuthRoute, GroupRoute, HomeRoute } from '@/libs/routing';

import Api from '@/api';
import MainLayout from '@/components/layout/MainLayout.vue';

/**
 * Handle group update from url and redirection to group's main page.
 * @vue
 */
export default {
  name: 'Group',

  components: {
    MainLayout,
  },

  async beforeRouteEnter(to, from, next) {
    await Api.checkAuth().then(
      user => {
        next(vm => {
          vm.$store.commit('setUser', user);

          // Datadog logs
          datadogLogs.logger.addContext('user', user);
        });
      },
      err => {
        if (to.name !== AuthRoute.LOGIN)
          next({ name: AuthRoute.LOGIN, query: { next: window.location.hash } });
      }
    );
  },

  props: {
    groupId: {
      required: true,
      type: String,
    },
  },

  data: () => ({
    loaded: false,
  }),

  watch: {
    $route() {
      this.checkRedirect();
    },

    async groupId() {
      await this.selectGroup();
      this.checkRedirect();
    },
  },

  async created() {
    await this.selectGroup();
    this.checkRedirect();
  },

  methods: {
    checkRedirect() {
      if (!this.$store.state.authorizedRoutes.includes(this.$route.name)) {
        this.$router.replace({ name: 'unauthorizedInternal' });
      }
      if (this.$route.name === GroupRoute.GROUP) {
        this.$router.replace({ name: GroupRoute.LIVE_MAP });
      }
    },

    async selectGroup() {
      this.loaded = false;
      const groups = await this.$store.dispatch('groupsUpdate');

      if (groups[this.groupId]) {
        await this.$store.dispatch('groupChange', this.groupId);

        // Datadog logs
        datadogLogs.logger.addContext(GroupRoute.GROUP, this.groupId);
      } else {
        this.$router.push({ name: HomeRoute.HOME });
      }

      this.loaded = true;
    },
  },
};
</script>
