import { createRouter, createWebHashHistory } from 'vue-router';

import { AdminRoute, AuthRoute, GroupRoute, HomeRoute } from '@/libs/routing';
import DutyList from '@/pages/DutyListPage/index.vue';
import Admin from '@/pages/AdminPage/index.vue';
import PassengersMessage from '@/pages/PassengersMessage/index.vue';
import DeviceDetail from '@/pages/DeviceDetailPage/index.vue';
import DeviceList from '@/pages/DeviceListPage/index.vue';
import DriverList from '@/pages/DriverListPage/index.vue';
import Group from '@/pages/GroupPage/index.vue';
import GtfsRT from '@/pages/GtfsRTPage/index.vue';
import Home from '@/pages/Home';
import IncidentList from '@/pages/IncidentListPage/index.vue';
import IntegrationsPage from '@/pages/IntegrationsPage/index.vue';
import LiveMap from '@/pages/LiveMapPage/index.vue';
import Login from '@/pages/LoginPage/index.vue';
import MessageList from '@/pages/MessageListPage/index.vue';
import NotFoundPage from '@/pages/NotFoundPage/index.vue';
import Dashboard from '@/pages/DashboardPage/index.vue';
import PassengersApp from '@/pages/PassengersAppPage/index.vue';
import PassengersScreen from '@/pages/PassengersScreenPage/index.vue';
import PasswordChange from '@/pages/PasswordChangePage/index.vue';
import PasswordReset from '@/pages/PasswordResetPage/index.vue';
import Profile from '@/pages/ProfilePage/index.vue';
import TransportPlanList from '@/pages/TransportPlanListPage/index.vue';
import Settings from '@/pages/SettingsPage/index.vue';
import StopDetailed from '@/pages/StopDetailedPage/index.vue';
import StopList from '@/pages/StopListPage/index.vue';
import TripDetailed from '@/pages/TripDetailedPage/index.vue';
import TripsList from '@/pages/TripsListPage/index.vue';
import TripsListOld from '@/pages/TripsListPageOld/index.vue';
import UnauthorizedPage from '@/pages/UnauthorizedPage/index.vue';
import UserList from '@/pages/UserListPage/index.vue';
import VehicleList from '@/pages/VehicleListPage/index.vue';

import Reports from '@/pages/ReportsPage/index.vue';
import NewReportsPunctuality from '@/pages/ReportsPage/NewReportsPunctuality.vue';
import ReportsConnectedDevice from '@/pages/ReportsPage/ReportsConnectedDevice.vue';
import ReportsPassengersApp from '@/pages/ReportsPage/ReportsPassengersApp.vue';
import ReportsPassengersCounts from '@/pages/ReportsPage/ReportsPassengersCounts.vue';
import ReportsPunctuality from '@/pages/ReportsPage/ReportsPunctuality.vue';
import ReportsTravelTime from '@/pages/ReportsPage/ReportsTravelTime/index.vue';
import ReportsTripKm from '@/pages/ReportsPage/ReportsTripKm.vue';
import ReportsTripTracking from '@/pages/ReportsPage/ReportsTripTracking.vue';

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: 'nav__item--active',
  linkExactActiveClass: 'exact-active',

  routes: [
    {
      component: Home,
      name: HomeRoute.HOME,
      path: '/',
    },

    {
      component: Login,
      name: AuthRoute.LOGIN,
      path: '/login',
      props: route => ({
        query: route.query,
      }),
    },

    {
      component: PasswordChange,
      name: AuthRoute.PASSWORD_CHANGE,
      path: '/password/reset',
    },

    {
      component: PasswordReset,
      name: AuthRoute.PASSWORD_RESET,
      path: '/login/reset',
    },

    {
      component: Admin,
      name: AdminRoute.ADMIN,
      path: '/admin/:resource?',
      props: true,
    },

    {
      component: Group,
      name: GroupRoute.GROUP,
      path: '/:groupId',
      props: true,

      children: [
        {
          component: DutyList,
          name: GroupRoute.DUTY_LIST,
          path: GroupRoute.DUTY_LIST,
          props: route => ({
            query: route.query,
          }),
        },

        {
          component: PassengersMessage,
          name: GroupRoute.PASSENGERS_MESSAGE,
          path: GroupRoute.PASSENGERS_MESSAGE,
        },

        {
          component: Dashboard,
          name: GroupRoute.DASHBOARD,
          path: GroupRoute.DASHBOARD,
          props: route => ({
            date: route.query.date,
          }),
        },

        {
          component: DeviceDetail,
          name: GroupRoute.DEVICE_DETAILLED,
          path: `${GroupRoute.DEVICE_DETAILLED}/:deviceId`,
          props: route => ({
            deviceId: route.params.deviceId,
            query: route.query,
          }),
        },

        {
          component: DeviceList,
          name: GroupRoute.DEVICE_LIST,
          path: GroupRoute.DEVICE_LIST,
        },

        {
          component: DriverList,
          name: GroupRoute.DRIVER_LIST,
          path: GroupRoute.DRIVER_LIST,
        },

        {
          component: GtfsRT,
          name: GroupRoute.GTFS_RT,
          path: GroupRoute.GTFS_RT,
        },

        {
          component: IncidentList,
          name: GroupRoute.INCIDENT_LIST,
          path: GroupRoute.INCIDENT_LIST,
        },

        {
          component: IntegrationsPage,
          name: GroupRoute.INTEGRATIONS,
          path: GroupRoute.INTEGRATIONS,
        },

        {
          component: LiveMap,
          name: GroupRoute.LIVE_MAP,
          path: GroupRoute.LIVE_MAP,
          props: route => ({
            date: route.query.date,
          }),
        },
        {
          component: MessageList,
          name: GroupRoute.MESSAGE_LIST,
          path: GroupRoute.MESSAGE_LIST,
        },

        {
          component: PassengersApp,
          name: GroupRoute.PASSENGERS_APP,
          path: GroupRoute.PASSENGERS_APP,
        },

        {
          component: PassengersScreen,
          name: GroupRoute.PASSENGERS_SCREEN,
          path: GroupRoute.PASSENGERS_SCREEN,
        },

        {
          component: Profile,
          name: GroupRoute.PROFILE,
          path: GroupRoute.PROFILE,
        },

        {
          component: Reports,
          name: GroupRoute.REPORTS,
          path: GroupRoute.REPORTS,
          children: [
            {
              component: NewReportsPunctuality,
              name: GroupRoute.REPORTING_PUNCTUALITY,
              path: GroupRoute.REPORTING_PUNCTUALITY,
            },
            {
              component: ReportsConnectedDevice,
              name: GroupRoute.REPORTING_CONNECTED_DEVICES,
              path: GroupRoute.REPORTING_CONNECTED_DEVICES,
            },
            {
              component: ReportsPassengersApp,
              name: GroupRoute.REPORTING_PASSENGERS_APP,
              path: GroupRoute.REPORTING_PASSENGERS_APP,
            },
            {
              component: ReportsPassengersCounts,
              name: GroupRoute.REPORTING_PASSENGER_COUNTS,
              path: GroupRoute.REPORTING_PASSENGER_COUNTS,
            },
            {
              component: ReportsPunctuality,
              name: GroupRoute.REPORTING_OLD_PUNCTUALITY,
              path: GroupRoute.REPORTING_OLD_PUNCTUALITY,
            },
            {
              component: ReportsTravelTime,
              name: GroupRoute.REPORTING_TRAVEL_TIME,
              path: GroupRoute.REPORTING_TRAVEL_TIME,
            },
            {
              component: ReportsTripKm,
              name: GroupRoute.REPORTING_TRIP_KM,
              path: GroupRoute.REPORTING_TRIP_KM,
            },
            {
              component: ReportsTripTracking,
              name: GroupRoute.REPORTING_TRIP_TRACKING,
              path: GroupRoute.REPORTING_TRIP_TRACKING,
            },
          ],
        },

        {
          component: TransportPlanList,
          name: GroupRoute.TRANSPORT_PLAN_LIST,
          path: GroupRoute.TRANSPORT_PLAN_LIST,
        },

        {
          component: Settings,
          name: GroupRoute.SETTINGS,
          path: GroupRoute.SETTINGS,
        },

        {
          component: StopDetailed,
          name: GroupRoute.STOP_DETAILED,
          path: `${GroupRoute.STOP_DETAILED}/:stopId`,
          props: route => ({
            stopId: route.params.stopId,
            query: route.query,
          }),
        },

        {
          component: StopList,
          name: GroupRoute.STOP_LIST,
          path: GroupRoute.STOP_LIST,
        },

        {
          component: TripDetailed,
          name: GroupRoute.TRIP_DETAILED,
          path: `${GroupRoute.TRIP_DETAILED}/:tripId`,
          props: route => ({
            tripId: route.params.tripId,
            query: route.query,
          }),
        },

        {
          component: TripsList,
          name: GroupRoute.TRIP_LIST,
          path: GroupRoute.TRIP_LIST,
          props: route => ({
            query: route.query,
          }),
        },

        {
          component: TripsListOld,
          name: GroupRoute.TRIP_LIST_OLD,
          path: GroupRoute.TRIP_LIST_OLD,
          props: route => ({
            query: route.query,
          }),
        },

        {
          component: UserList,
          name: GroupRoute.USER_LIST,
          path: GroupRoute.USER_LIST,
        },

        {
          component: VehicleList,
          name: GroupRoute.VEHICLE_LIST,
          path: GroupRoute.VEHICLE_LIST,
        },

        {
          component: UnauthorizedPage,
          name: 'unauthorizedInternal',
          path: '403-error',
        },
      ],
    },

    {
      component: UnauthorizedPage,
      name: 'unauthorizedExternal',
      path: '/403-error',
    },

    {
      component: NotFoundPage,
      name: GroupRoute.NOT_FOUND,
      path: '/:pathMatch(.*)*',
    },
  ],
});

export default router;
