<template>
  <div class="mapbox-stops"></div>
</template>

<script>
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { MapboxHelper } from '@/components/map/mapboxHelper';
import { createGeoJSONCircle } from '@/libs/helpers/geo';
import { AppUrl } from '@/libs/helpers/url';
import { LocationType } from '@/store/gtfs.js';

import { INTERPOLATE } from './map-const.js';
import { GroupRoute } from '@/libs/routing';

const STATIONS_SOURCE_ID = 'stationsSource';
const STOPS_SOURCE_ID = 'stopsSource';
const STOPS_ZONE_SOURCE_ID = 'stopsZoneSource';

const STATIONS_ICON_LAYER_ID = 'stationsIconLayer';
const STOPS_ICON_LAYER_ID = 'stopsIconLayer';
const STOPS_ZONE_LAYER_ID = 'stopsZoneLayer';
const STATIONS_LABEL_LAYER = 'stationsLabelLayer';
const STOPS_LABEL_LAYER = 'stopsLabelLayer';

export default {
  name: 'MapboxStops',

  props: {
    /** @type {import('vue').Prop<mapboxgl.Map>} */
    map: {
      type: Object,
      required: true,
    },

    gtfsId: {
      type: String,
      required: true,
    },

    /** @type {import('vue').Prop<import('./MapboxMap.vue').StopsOptions>} */
    options: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').Prop<Array<import('./MapboxMap.vue').MapStop>>} */
    stops: {
      type: Array,
      required: true,
    },
    displayTooltip: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'mouseenter', 'mouseleave', 'update:bounds', 'isLoaded'],
  data: () => ({
    /** @type {string} */
    hoveredId: null,

    loaded: false,
  }),

  computed: {
    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },
    /**
     * @return {import('@/store/gtfs').Stop[]}
     * */
    allStopsFiltered() {
      if (!this.loaded) return [];

      /** @type {{[stopId: string]: import('@/store/gtfs').Stop}} */
      const stopsGtfs = this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'stops');
      return Object.values(
        this.stops.reduce((acc, elem) => {
          if (stopsGtfs[elem.id]) {
            acc[elem.id] = stopsGtfs[elem.id];
          }

          return acc;
        }, {})
      );
    },

    /** @return {?LngLatBounds} */
    bounds() {
      const list = this.allStopsFiltered;
      if (list.length === 0) return null;

      const bounds = new LngLatBounds();
      list.forEach(stop => bounds.extend(new LngLat(stop.stop_lon, stop.stop_lat)));

      return bounds;
    },

    /** @return {{[stopId: string]: boolean}} */
    highlightStops() {
      return this.stops.reduce((acc, elem) => {
        if (elem.highlight) {
          acc[elem.id] = true;
        }

        return acc;
      }, {});
    },

    /** @return {{[stopId: string]: import('@/store/gtfs').Stop}} */
    stationsFiltered() {
      return this.stopsByType[LocationType.STATION];
    },

    /** @return {mapboxgl.Layer} */
    stationsLabelLayer() {
      return this.stopsLabelLayerByType(LocationType.STATION);
    },

    /** @return {Array<GeoJSON.Feature<GeoJSON.Point>>} */
    stationsSource() {
      return this.stopsSourceByType(LocationType.STATION);
    },

    /** @return {{[locationType: string]: {[stopId: string]: import('@/store/gtfs').Stop}}} * */
    stopsByType() {
      const allStops = this.allStopsFiltered;

      return allStops.reduce(
        (acc, stop) => {
          const type = stop.location_type || LocationType.STOP;
          if (!acc[type]) acc[type] = {};
          acc[type][stop.stop_id] = stop;

          return acc;
        },
        { [LocationType.STATION]: {}, [LocationType.STOP]: {} }
      );
    },

    /** @return {{[stopId: string]: import('@/store/gtfs').Stop}} */
    stopsFiltered() {
      return this.stopsByType[LocationType.STOP];
    },

    /** @return {mapboxgl.Layer} */
    stopsLabelLayer() {
      return this.stopsLabelLayerByType(LocationType.STOP);
    },

    /** @return {Array<GeoJSON.Feature<GeoJSON.Point>>} */
    stopsSource() {
      return this.stopsSourceByType(LocationType.STOP);
    },

    /** @return {{[stopId: string]: number}} */
    stopZoneRadiusExceptions() {
      return this.$store.getters.group.stop_distance_threshold_exceptions || {};
    },

    /** @return {number} */
    stopZoneRadiusDefault() {
      return this.$store.getters.group.stop_distance_threshold;
    },

    /** @return {Array<GeoJSON.Feature<GeoJSON.Polygon>>} */
    stopsZoneSource() {
      const zones = !this.options.stopsZones ? [] : this.allStopsFiltered;

      return zones.map(
        stop =>
          /** @type {GeoJSON.Feature<GeoJSON.Polygon>} */ ({
            type: 'Feature',

            geometry: createGeoJSONCircle(
              [stop.stop_lon, stop.stop_lat],
              this.stopZoneRadiusExceptions[stop.stop_id] || this.stopZoneRadiusDefault
            ),

            properties: {
              id: stop.stop_id,
            },
          })
      );
    },
  },

  watch: {
    bounds: {
      immediate: true,
      handler(newBounds, oldBounds) {
        if (newBounds && (!oldBounds || newBounds.toString() !== oldBounds.toString())) {
          this.$emit('update:bounds', this.bounds);
        }
      },
    },
    stopsSource: {
      deep: true,
      handler() {
        MapboxHelper.updateSource(this.map, STOPS_SOURCE_ID, this.stopsSource);
      },
    },
    stationsSource: {
      deep: true,
      handler() {
        MapboxHelper.updateSource(this.map, STATIONS_SOURCE_ID, this.stationsSource);
      },
    },
    stopsZoneSource: {
      deep: true,
      handler() {
        MapboxHelper.updateSource(this.map, STOPS_ZONE_SOURCE_ID, this.stopsZoneSource);
      },
    },
  },

  created() {
    this.map.once('idle', () => {
      this.loaded = true;
    });
    this.initSourceAndLayer();
  },

  unmounted() {
    MapboxHelper.cleanLayersAndSources(
      this.map,
      [
        STATIONS_LABEL_LAYER,
        STATIONS_ICON_LAYER_ID,
        STOPS_LABEL_LAYER,
        STOPS_ICON_LAYER_ID,
        STOPS_ZONE_LAYER_ID,
      ],
      [STATIONS_SOURCE_ID, STOPS_SOURCE_ID, STOPS_ZONE_SOURCE_ID]
    );
  },

  methods: {
    addStationsIconLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: STATIONS_ICON_LAYER_ID,
          type: 'circle',
          source: STATIONS_SOURCE_ID,
          paint: {
            'circle-radius': ['interpolate', ['linear'], ['zoom'], ...INTERPOLATE.stations.circleRadius],
            'circle-color': [
              'case',
              ['to-boolean', ['get', 'highlight']],
              ...INTERPOLATE.stations.circleColor,
            ],
            'circle-stroke-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              ...INTERPOLATE.stations.circleStrokeWidth,
            ],
            'circle-opacity': [
              'interpolate',
              ['exponential', 0.5],
              ['zoom'],
              ...INTERPOLATE.stations.circleOpacity,
            ],
            'circle-stroke-color': [
              'case',
              ['to-boolean', ['get', 'highlight']],
              ...INTERPOLATE.stations.circleStrokeColor,
            ],
          },
        })
      );
      this.addLayerActions(STATIONS_ICON_LAYER_ID);
    },
    addStopsIconLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: STOPS_ICON_LAYER_ID,
          type: 'circle',
          source: STOPS_SOURCE_ID,
          paint: {
            'circle-radius': ['interpolate', ['linear'], ['zoom'], ...INTERPOLATE.stops.circleRadius],
            'circle-color': ['case', ['to-boolean', ['get', 'highlight']], ...INTERPOLATE.stops.circleColor],
            'circle-stroke-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              ...INTERPOLATE.stops.circleStrokeWidth,
            ],
            'circle-opacity': [
              'interpolate',
              ['exponential', 0.5],
              ['zoom'],
              ...INTERPOLATE.stops.circleOpacity,
            ],
            'circle-stroke-color': [
              'case',
              ['to-boolean', ['get', 'highlight']],
              ...INTERPOLATE.stops.circleStrokeColor,
            ],
          },
        })
      );
      this.addLayerActions(STOPS_ICON_LAYER_ID);
    },
    addStopsZoneLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: STOPS_ZONE_LAYER_ID,
          type: 'fill',
          source: STOPS_ZONE_SOURCE_ID,
          paint: {
            'fill-color': '#ff0000',
            'fill-opacity': 0.35,
          },
        })
      );
    },
    addStopsLabelLayer() {
      MapboxHelper.addLayer(this.map, this.stopsLabelLayer);
    },
    addStationsLabelLayer() {
      MapboxHelper.addLayer(this.map, this.stationsLabelLayer);
    },
    initSourceAndLayer() {
      MapboxHelper.createEmptySource(this.map, STATIONS_SOURCE_ID);
      MapboxHelper.createEmptySource(this.map, STOPS_SOURCE_ID);
      MapboxHelper.createEmptySource(this.map, STOPS_ZONE_SOURCE_ID);

      this.addStopsZoneLayer();
      this.addStopsIconLayer();
      this.addStationsIconLayer();
      this.addStopsLabelLayer();
      this.addStationsLabelLayer();

      this.$emit('isLoaded', true);
    },

    addLayerActions(layerName) {
      this.map.on('mouseenter', layerName, e => {
        if (e.features && e.features.length > 0) {
          this.$emit('mouseenter', e);
        }
      });
      this.map.on('mouseleave', layerName, e => {
        this.onStopMouseLeave(e, layerName);
      });
      this.map.on('mousemove', layerName, e => {
        if (e.features && e.features.length > 0) {
          this.onStopMouseMove(e, layerName);
        }
      });
      this.map.on('click', layerName, e => {
        if (e.features && e.features.length > 0) {
          this.$emit('click', e);
          if (this.displayTooltip) {
            MapboxHelper.displayTooltipOnClick(e, this.map, 'tooltip-map');
          }
        }
      });
    },

    /** Generic method to create stopsLabel by type
     * @param {import('@/store/gtfs.js').LocationType} type
     * @return {mapboxgl.Layer}
     * */
    stopsLabelLayerByType(type) {
      const isStation = LocationType.STATION === type;

      const id = isStation ? STATIONS_LABEL_LAYER : STOPS_LABEL_LAYER;
      const sourceId = isStation ? STATIONS_SOURCE_ID : STOPS_SOURCE_ID;

      return {
        id,
        type: 'symbol',
        source: sourceId,
        filter: ['any', ['==', ['get', 'id'], this.hoveredId]],

        layout: {
          'text-allow-overlap': true,
          'text-anchor': 'top-left',
          'text-field': ['get', 'label'],
          'text-justify': 'left',
          'text-offset': [0.8, -0.4],
        },

        paint: {
          'text-halo-color': '#ffffff',
          'text-halo-width': 3,
        },
      };
    },
    /**
     * @param {import('@/store/gtfs.js').LocationType} type
     * @return {Array<GeoJSON.Feature<GeoJSON.Point>>}
     * */
    stopsSourceByType(type) {
      const isStation = LocationType.STATION === type;

      const markers = isStation
        ? !this.options.stationsMarkers
          ? []
          : Object.values(this.stationsFiltered)
        : !this.options.stopsMarkers
        ? []
        : Object.values(this.stopsFiltered);

      return markers.map(
        (stop, index) =>
          /** @type {GeoJSON.Feature<GeoJSON.Point>} */ ({
            type: 'Feature',

            geometry: {
              type: 'Point',
              coordinates: [stop.stop_lon, stop.stop_lat],
            },

            properties: {
              highlight: this.highlightStops[stop.stop_id],
              id: stop.stop_id,
              label: stop.stop_name,
              description: this.generateStopDescription(stop),
            },
          })
      );
    },
    /**
     * @param {mapboxgl.MapLayerMouseEvent} event
     * @param {string} layerName
     */
    onStopMouseLeave(event, layerName) {
      this.$emit('mouseleave', event);
      this.hoveredId = null;
      this.setLabelFilterUpdate(layerName);
    },

    /**
     * @param {mapboxgl.MapLayerMouseEvent} event
     * @param {string} layerName
     */
    onStopMouseMove(event, layerName) {
      if (event?.features.length > 0) {
        const stopId = event.features[0]?.properties?.id;

        if (this.hoveredId !== stopId) {
          this.hoveredId = stopId;
        }
        this.setLabelFilterUpdate(layerName);
      }
    },
    /**
     * Generate stop tooltip HTML based on stop informations
     * @param {import('@/store/gtfs').Stop} stop
     */
    generateStopDescription(stop) {
      const tooltipCtaUrl = `${AppUrl}/#/${this.groupId}/${GroupRoute.STOP_DETAILED}/${stop.stop_id}`;

      // Stop name
      let description = `<span class="tooltip-map__title-text">${stop.stop_name}</span>`;
      // Parent station if exists
      if (stop.parent_station && this.stationsFiltered[stop.parent_station]) {
        const relatedStationName = this.stationsFiltered[stop.parent_station]?.stop_name;
        description += `<span class="tooltip-map__sub-text">${relatedStationName}</span>`;
      }
      // CTA button
      description += `<a class="tooltip-map__button" href="${tooltipCtaUrl}">${this.$t(
        'detail'
      )} <font-awesome-icon icon="fa-arrow-right" class="ui-btn__redirect-icon" /></a>`;
      return description;
    },

    /** @param {string} layerName */
    setLabelFilterUpdate(layerName) {
      // select layer name for Labels
      const labelLayerName = layerName === STATIONS_ICON_LAYER_ID ? STATIONS_LABEL_LAYER : STOPS_LABEL_LAYER;
      this.map.setFilter(labelLayerName, ['any', ['==', ['get', 'id'], this.hoveredId]]);
    },
  },
};
</script>
