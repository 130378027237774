<template>
  <div class="period-select">
    <div class="period-select__selector">
      <div class="period-select__label">
        {{ $t('from') }}
      </div>
      <div data-testid="datepicker-start">
        <Datepicker
          ref="datepickerStart"
          v-model:value="startDateValue"
          class="datepicker__arrow"
          :class="{ 'datepicker__arrow--open': isOpenStart }"
          :disabled-dates="getDisabledDates('start')"
          input-class="period-select__input"
          :text-footer="limitedPeriod ? $t('limitReportingPeriodInfo') : null"
          @opened="isOpenStart = true"
          @closed="isOpenStart = false"
        />
      </div>
    </div>
    <div class="period-select__selector">
      <div class="period-select__label">
        {{ $t('to') }}
      </div>
      <div data-testid="datepicker-end">
        <Datepicker
          ref="datepickerEnd"
          v-model:value="endDateValue"
          class="datepicker__arrow"
          :class="{ 'datepicker__arrow--open': isOpenEnd }"
          :disabled-dates="getDisabledDates('end')"
          input-class="period-select__input"
          :text-footer="limitedPeriod ? $t('limitReportingPeriodInfo') : null"
          @opened="isOpenEnd = true"
          @closed="isOpenEnd = false"
        />
      </div>
    </div>
    <button
      v-if="isNewDateSelected"
      class="period-select__apply"
      @click="$emit('dates-selected', { from: startDateValue, to: endDateValue })"
    >
      {{ $t('apply') }}
    </button>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';

import Datepicker from './Datepicker.vue';

export default {
  name: 'PeriodSelect',

  components: {
    Datepicker,
  },

  props: {
    /** @type {Vue.PropOptions<Date>} */
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    /** @type {Vue.PropOptions<Date>} */
    endDate: {
      type: Date,
      default: () => new Date(),
    },
    limitedPeriod: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['dates-selected'],

  data() {
    return {
      /** @type {Date} */
      endDateValue: this.endDate,

      /** @type {boolean} */
      isOpenStart: false,

      /** @type {boolean} */
      isOpenEnd: false,

      /** @type {Date} */
      startDateValue: this.startDate,
    };
  },

  computed: {
    /** @return {Boolean} */
    isNewDateSelected() {
      return (
        dateObjToGtfsFormat(this.startDateValue) !== dateObjToGtfsFormat(this.startDate) ||
        dateObjToGtfsFormat(this.endDateValue) !== dateObjToGtfsFormat(this.endDate)
      );
    },
    endDateMax() {
      const currentDate = new Date();
      // If punctuality mode, block to 1 month max the interval periode
      if (this.limitedPeriod) {
        const copyDate = dayjs(this.startDateValue);
        const startDatePlusOneMonth = copyDate.add(30, 'day').toDate();

        return currentDate < startDatePlusOneMonth ? currentDate : startDatePlusOneMonth;
      }
      // else block to current date
      return currentDate;
    },
  },

  watch: {
    isOpenStart() {
      if (this.isOpenStart && this.isOpenEnd) {
        this.$refs.datepickerEnd.forceClose();
      }
    },

    isOpenEnd() {
      if (this.isOpenEnd && this.isOpenStart) {
        this.$refs.datepickerStart.forceClose();
      }
    },
    startDate() {
      this.startDateValue = this.startDate;
    },
    endDate() {
      this.endDateValue = this.endDate;
    },
    startDateValue() {
      if (this.limitedPeriod && this.endDateValue > this.endDateMax) {
        this.endDateValue = this.endDateMax;
      } else if (this.startDateValue > this.endDateValue) {
        this.endDateValue = this.startDateValue;
      }
    },
  },

  methods: {
    /**
     * @param {'start'|'end'} calendarType
     * @return {import('./Datepicker.vue').DisabledDates}
     */
    getDisabledDates(calendarType) {
      const dateFirstPublish = this.$store.getters.getDateFirstPublish;
      const dfp = dateFirstPublish.split('T')[0].replaceAll('-', '');

      if (calendarType === 'start') return { minDate: dateGtfsFormatToObj(dfp), maxDate: new Date() };
      if (calendarType === 'end') return { minDate: this.startDateValue, maxDate: this.endDateMax };

      return {};
    },
  },
};
</script>

<style lang="scss">
.period-select {
  display: flex;

  &__apply {
    align-self: flex-end;
    height: 40px;
    padding: 0 8px;
    border: 2px solid $primary-light;
    border-radius: 9px;
    background: $canvas;
    color: $primary-light;
    box-shadow: 0 4px 7px rgb(0 0 0 / 10%);
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
    font-family: $font-poppins;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-property: all;

    &:hover {
      background: $primary-light;
      color: $text-light;
    }
  }

  &__input {
    width: 120px;
    height: 40px;
    padding-left: 1em;
    border: 2px solid $primary-light;
    border-radius: 9px;
    background: $canvas;
    color: $primary-light;
    box-shadow: 0 4px 7px rgb(0 0 0 / 10%);
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
    font-family: $font-poppins;
    cursor: pointer;

    &:focus-visible {
      outline: 0;
    }
  }

  &__label {
    @include font-default-small($text-neutral);

    height: 20px;
    margin-left: 5px;
    font-weight: $font-weight-semi-bold;
    font-style: normal;
    text-transform: uppercase;
  }

  &__selector {
    margin-right: 5px;
  }
}
</style>

<i18n locale="fr">
{
  "from": "Du",
  "to": "Au",
  "apply": "Appliquer"
}
</i18n>

<i18n locale="en">
{
  "from": "From",
  "to": "To",
  "apply": "Apply"
}
</i18n>
