/**
 * To use mixin, components must override `getDefaultValues` method to return `Group` properties
 * that should be copied here to be safely modified.
 * Some others properties can be overridden.
 *
 * Computed:
 * @member {() => boolean} additionalChanges
 * @member {() => boolean} hasError
 * Methods:
 * @member {() => Options} getDefaultValues
 */

import deepmerge from 'deepmerge';

const arrayMerge = (_, source) =>
  source.map(element => {
    if (typeof element === 'object' && element !== null) {
      return deepmerge(null, element, { arrayMerge });
    }
    return element;
  });

export default {
  name: 'SettingsMixin',

  emits: ['change', 'save'],

  data() {
    return {
      values: this.getDefaultValues(),
    };
  },

  computed: {
    /** @return {boolean} */
    additionalChanges() {
      // May be overridden
      return false;
    },

    /** @return {?import('@/store').Group} */
    group() {
      return /** @type {import('@/store').State} */ (this.$store.state).groupServerSide;
    },

    /** @return {boolean} */
    hasChanged() {
      return Object.keys(this.updates).length > 0 || this.additionalChanges;
    },

    /** @return {boolean} */
    hasError() {
      // May be overridden
      return false;
    },

    /** @return {Partial<Options>} */
    updates() {
      return Object.keys(this.values).reduce((acc, /** @type {keyof Options} */ key) => {
        const value = this.clearEmptyValues(this.values[key]);

        if (this.valueIsDefined(value)) {
          if (!(key in this.group) || this.valueHasChanged(value, this.group[key])) {
            acc[key] = value;
          }
        } else if (key in this.group) {
          acc[key] = undefined;
        }

        return acc;
      }, {});
    },
  },

  watch: {
    group: {
      deep: true,
      immediate: true,
      handler() {
        Object.keys(this.values).forEach((/** @type {keyof Options} */ key) => {
          if (key in this.group) {
            if (typeof this.group[key] === 'object') {
              this.values[key] = deepmerge(this.values[key], this.group[key], {
                arrayMerge,
              });
            } else {
              this.values[key] = this.group[key];
            }
          }
        });
      },
    },

    hasChanged() {
      this.$emit('change', this.hasChanged);
    },
  },

  methods: {
    /**
     * @template T
     * @param {T} value
     * @return {T | Partial<T>}
     */
    clearEmptyValues(value) {
      if (!(value instanceof Array) && typeof value === 'object' && value != null) {
        return Object.entries(value).reduce((acc, [k, v]) => {
          acc[k] = this.clearEmptyValues(v);

          if (
            acc[k] == null ||
            acc[k] === '' ||
            acc[k] === false ||
            (typeof acc[k] === 'object' && Object.keys(acc[k]).length === 0)
          ) {
            delete acc[k];
          }

          return acc;
        }, {});
      }

      return value;
    },

    /**
     * Returns default values (must be overriden)
     *
     * @returns {Options}
     */
    getDefaultValues() {
      return {};
    },

    async save() {
      if (Object.prototype.hasOwnProperty.call(this.updates, 'notifications')) {
        if (!this.updates.notifications) {
          localStorage.setItem('settings.op.disable_notif', 'disabled');
        } else {
          localStorage.removeItem('settings.op.disable_notif');
        }
      }
      await this.$store.dispatch('groupPatch', this.updates);
      this.$emit('save');
    },

    /**
     * @template T
     * @param {T} value
     * @param {T} orig
     * @return {boolean}
     */
    valueHasChanged(value, orig) {
      if (value instanceof Array) {
        return value.length !== orig.length || value.some((v, i) => this.valueHasChanged(v, orig[i]));
      }
      if (typeof value === 'object' && value != null && orig !== undefined) {
        const valueEntries = Object.entries(value);
        const origEntries = Object.entries(orig);
        return (
          valueEntries.length !== origEntries.length ||
          Object.entries(value).some(([k, v]) => this.valueHasChanged(v, orig[k]))
        );
      }
      return value !== orig;
    },

    /**
     * @param {*} value
     * @return {boolean}
     */
    valueIsDefined(value) {
      if (value instanceof Array) {
        return value.some(v => this.valueIsDefined(v));
      }
      if (typeof value === 'object' && value != null) {
        return Object.values(value).some(v => this.valueIsDefined(v));
      }
      if (typeof value === 'boolean' && !value) {
        return true;
      }
      return !(value == null || value === false || value === '');
    },
  },
};

/** @typedef {{[key: string]: *}} Options */
