<template>
  <div class="password-reset">
    <nav>
      <div class="container-link">
        <router-link :to="{ name: AuthRoute.LOGIN }" class="link-public-site">
          {{ $t('backToLogin') }}
        </router-link>
        <Language class="language-menu" />
      </div>
    </nav>
    <div class="logo" />
    <div class="container">
      <div class="container-title">
        <span>{{ $t('passwordReset') }}</span>
      </div>
      <div
        v-if="resetMessage"
        id="msg"
        class="alert"
        :class="{
          'alert-success': !error,
          'alert-danger': error,
        }"
      >
        {{ resetMessage }}
      </div>
      <form class="panel-body" @submit.prevent="submit">
        <div class="form-group">
          <label for="mail">{{ $t('email') }}</label>
          <br />
          <input
            id="mail"
            v-model="email"
            type="email"
            name="email"
            :placeholder="$t('enterEmail')"
            required
          />
        </div>
        <div class="form-group">
          <vue-recaptcha
            ref="recaptcha"
            sitekey="6LdQ8A8TAAAAAN8cnIzvUGe7zv8WRar7KwOyIsOE"
            @verify="saveCaptchaAnswer"
          />
        </div>
        <div class="container-submit">
          <button type="submit" class="validate-button">
            {{ $t('resetlink') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

import Api from '@/api';
import Language from '@/components/common/Language.vue';
import { AuthRoute } from '@/libs/routing';

export default {
  name: 'PasswordReset',

  components: {
    Language,
    VueRecaptcha,
  },

  data: () => ({
    AuthRoute,
    /** @type {string} */
    captcha: null,
    /** @type {string} */
    email: null,
    /** @type {Boolean} */
    error: false,
    /** @type {string} */
    resetMessage: null,
  }),

  methods: {
    /** @param {string} response */
    saveCaptchaAnswer(response) {
      this.captcha = response;
    },

    /** Send reset password information to the api */
    async submit() {
      this.resetMessage = null;
      const result = await Api.requestPasswordReset(this.email, this.captcha);
      if (result.message) {
        this.error = true;
        if (result.message === 'user not found') {
          this.resetMessage = this.$t('emailNotFound');
        } else if (result.message === 'bad captcha') {
          this.resetMessage = this.$t('captchaError');
        } else if (result.message === 'missing captcha') {
          this.resetMessage = this.$t('missingCaptcha');
        } else if (result.message === 'missing argument') {
          this.resetMessage = this.$t('noEmail');
        }
        this.$refs.recaptcha.reset();
      } else {
        this.resetMessage = this.$t('emailSent');
        this.error = false;
      }
    },
  },
};
</script>

<style lang="scss">
.password-reset {
  input {
    width: 100%;
    padding: 5px;
    border: solid 1px $border;
  }

  nav {
    height: 50px;
    background: $primary-light;
  }
}
</style>

<i18n locale="fr">
{
  "backToLogin": "Retour à la page de connexion",
  "captchaError": "Erreur de captcha",
  "email": "Email",
  "emailSent": "Un email vous a été envoyé",
  "emailNotFound": "L'adresse email n'a pas pu être trouvée",
  "enterEmail": "Entrez votre adresse e-mail",
  "missingCaptcha": "Veuiller valider la captcha",
  "noEmail": "Veuiller remplir la case 'Email'",
  "passwordReset": "Réinitialiser le mot de passe",
  "resetlink": "Récupérer le lien de réinitialisation"
}
</i18n>

<i18n locale="en">
{
  "backToLogin": "Back to the login page",
  "captchaError": "Captcha error",
  "email": "Email",
  "emailNotFound": "Email not found",
  "emailSent": "An email has been sent",
  "enterEmail": "Enter your email",
  "missingCaptcha": "Please make sure to validate captcha",
  "noEmail": "Email must be filled",
  "passwordReset": "Password reset",
  "resetlink": "Get reinitialisation link"
}
</i18n>

<i18n locale="cz">
{
  "backToLogin": "Zpátky na přihlášení",
  "passwordReset": "Obnovit heslo",
  "captchaError": "Chyba captchy",
  "email": "E-mail",
  "emailSent": "E-mail byl odeslán",
  "emailNotFound": "E-mailová adresa nenalezena",
  "enterEmail": "Zadej svou e-mailovou adresu",
  "missingCaptcha": "Vložte prosím kontrolní kód captcha",
  "noEmail": "Zadejte e-mailovou adresu",
  "resetlink": "Poslat odkaz pro obnovení hesla"
}
</i18n>

<i18n locale="de">
{
  "backToLogin": "Zurück zur Anmeldeseite",
  "passwordReset": "Kennwort zurücksetzen",
  "captchaError": "Captcha-Fehler",
  "email": "E-Mail",
  "emailSent": "Eine E-Mail wurde gesendet",
  "emailNotFound": "E-Mail-Adresse nicht gefunden",
  "enterEmail": "E-Mail-Adresse eingeben",
  "missingCaptcha": "Bitte bestätige das Captcha",
  "noEmail": "E-Mail-Adresse fehlt",
  "resetlink": "Link zum Zurücksetzen anfordern"
}
</i18n>

<i18n locale="es">
{
  "backToLogin": "Volver a la página de inicio de sesión",
  "passwordReset": "Restablecer contraseña",
  "captchaError": "Error de catptcha",
  "email": "Email",
  "emailSent": "Se ha enviado un email",
  "emailNotFound": "Email no encontrado",
  "enterEmail": "Introduce tu email",
  "missingCaptcha": "Asegúrate de validar correctamente el captcha",
  "noEmail": "Introduce el email",
  "resetlink": "Obtener el enlace de restablecimiento"
}
</i18n>

<i18n locale="it">
{
  "backToLogin": "Torna alla pagina di accesso",
  "passwordReset": "Ripristino della password",
  "captchaError": "Captcha errato",
  "email": "Email",
  "emailSent": "È stata inviata un'e-mail",
  "emailNotFound": "E-mail non trovata",
  "enterEmail": "Inserisci il tuo indirizzo e-mail",
  "missingCaptcha": "Assicurati di confermare il captcha",
  "noEmail": "Inserisci l'e-mail",
  "resetlink": "Vai al link di ripristino"
}
</i18n>

<i18n locale="pl">
{
  "backToLogin": "Powrót do strony logowania",
  "passwordReset": "Resetowanie hasła",
  "captchaError": "Błąd uwierzytelnienia CAPTCHA",
  "email": "E-mail",
  "emailSent": "Wysłano e-mail",
  "emailNotFound": "Nie znaleziono adresu e-mail",
  "enterEmail": "Wpisz swój adres e-mail",
  "missingCaptcha": "Podaj prawidłowy kod CAPTCHA",
  "noEmail": "Podaj adres e-mail",
  "resetlink": "Uzyskaj link umożliwiający zresetowanie hasła"
}
</i18n>
