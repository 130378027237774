/* eslint-disable no-use-before-define */
import { dateGtfsFormatToObj, getISODate } from '@/libs/helpers/dates';

/** @enum {string} */
export const EventType = {
  BLOCK_SELECTION: 'blockSelection',
  BREAK_END: 'breakEnd',
  BREAK_START: 'breakStart',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  HLP_END: 'HLPEnd',
  HLP_START: 'HLPStart',
  ROUTE_SELECTION: 'routeSelection',
  TRIP_END: 'tripEnd',
  TRIP_PENDING: 'tripPending',
  TRIP_START: 'tripStart',
  UPDATED_APP: 'updatedApp',
};

/** @enum {string} */
export const SegmentType = {
  HLP: 'HLP',
  TRIP: 'trip',
};

/**
 * @param {import("@/store/devices").Device} state - Device state to start from.
 * @param {Array<Event>} deviceHistory - Events to apply
 * @param {number} offlineDelay - Seconds between events to generate a disconnected event.
 * @return {Array<EventsSegment>}
 */
export function generateEvents(state, deviceHistory, offlineDelay) {
  if (deviceHistory.length === 0) return [];

  const eventsSegments = /** @type {Array<EventsSegment>} */ ([]);

  deviceHistory.forEach(event => {
    if (eventsSegments.length > 0) {
      // On disconnected:
      if (event.ts - state.ts > offlineDelay) {
        createDisconnectedEvent(eventsSegments, state.ts);
        createConnectedEvent(eventsSegments, event.ts);
      }

      // On app update:
      if (event.app_version !== state.app_version && !event.snapshot) {
        createUpdatedAppEvent(eventsSegments, event);
      }

      // On trip_filter changed:
      if (event.trip_filter) {
        if (
          event.trip_filter.route_id != null &&
          (!state.trip_filter || event.trip_filter.route_id !== state.trip_filter.route_id)
        ) {
          createRouteSelectionEvent(eventsSegments, event);
        } else if (
          event.trip_filter.block_id != null &&
          (!state.trip_filter || event.trip_filter.block_id !== state.trip_filter.block_id)
        ) {
          createBlockSelectionEvent(eventsSegments, event);
        }
      }
    }
    // State break
    if (event.break && !state.break) {
      createBreakStartEvent(eventsSegments, event);
    } else if (!event.break && state.break) {
      createBreakEndEvent(eventsSegments, event);
    }
    if (!Object.prototype.hasOwnProperty.call(state, 'trip_id')) {
      // State initialisation

      testTripId(eventsSegments, state, event);
    } else if (state.trip_id == null) {
      // State: HLP

      if (event.trip_id !== state.trip_id) {
        // On trip_id changed

        createHLPEndEvent(eventsSegments, event);
        testTripPending(eventsSegments, state, event);
      }
    } else if (state.trip_pending) {
      // State: Trip pending

      if (event.trip_id !== state.trip_id) {
        // On trip_id changed

        createTripEndEvent(eventsSegments, event);
        testTripId(eventsSegments, state, event);
      } else if (!event.trip_pending) {
        // On trip_pending changed

        transitionToTrip(eventsSegments, event);
      }
      // State: Trip
    } else if (event.trip_id !== state.trip_id) {
      // On trip_id changed
      createTripEndEvent(eventsSegments, event);
      testTripId(eventsSegments, state, event);
    } else if (event.trip_pending) {
      // On trip_pending changed

      transitionToTripPending(eventsSegments, event);
    }

    Object.assign(state, event);

    // fix: add trip_filter if missing.
    const lastSegment = eventsSegments[eventsSegments.length - 1];
    if (lastSegment.type === SegmentType.TRIP && !lastSegment.tripFilter && state.trip_filter) {
      lastSegment.tripFilter = getTripFilter(state.trip_filter);
    }
  });

  const lastIndex = deviceHistory.length - 1;
  if (Date.now() / 1000 - deviceHistory[lastIndex].ts > offlineDelay) {
    createDisconnectedEvent(eventsSegments, deviceHistory[lastIndex].ts);
  }

  return eventsSegments;
}

export default {
  EventType,
  SegmentType,
  generateEvents,
};

/**
 * Create a block selection event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createBlockSelectionEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const blockSelectionEvent = {
    ts: event.ts,
    type: EventType.BLOCK_SELECTION,
    blockId: event.trip_filter.block_id,
  };

  eventsSegments[eventsSegments.length - 1].events.push(blockSelectionEvent);
}

/**
 * Create a break end event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createBreakEndEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const endBreakEvent = {
    ts: event.ts,
    type: EventType.BREAK_END,
  };

  eventsSegments[eventsSegments.length - 1].events.push(endBreakEvent);
}

/**
 * Create a start end event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createBreakStartEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const startBreakEvent = {
    ts: event.break.start_ts,
    type: EventType.BREAK_START,
  };

  eventsSegments[eventsSegments.length - 1].events.push(startBreakEvent);
}

/**
 * Create a connected event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {number} ts
 */
function createConnectedEvent(eventsSegments, ts) {
  /** @type {EnhancedEvent} */
  const connectedEvent = {
    ts,
    type: EventType.CONNECTED,
  };

  eventsSegments[eventsSegments.length - 1].events.push(connectedEvent);
}

/**
 * Create a disconnected event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {number} ts
 */
function createDisconnectedEvent(eventsSegments, ts) {
  /** @type {EnhancedEvent} */
  const disconnectedEvent = {
    ts,
    type: EventType.DISCONNECTED,
  };

  eventsSegments[eventsSegments.length - 1].events.push(disconnectedEvent);
}

/**
 * Create an HLP end event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createHLPEndEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const hlpEndEvent = {
    ts: event.ts,
    type: EventType.HLP_END,
  };

  eventsSegments[eventsSegments.length - 1].events.push(hlpEndEvent);
}

/**
 * Create an HLP segment.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createHLPSegment(eventsSegments, event) {
  /** @type {EventsSegment} */
  const hlpSegment = {
    events: [],
    type: SegmentType.HLP,
  };

  eventsSegments.push(hlpSegment);
}

/**
 * Create an HLP start event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createHLPStartEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const hlpStartEvent = {
    ts: event.ts,
    type: EventType.HLP_START,
  };

  eventsSegments[eventsSegments.length - 1].events.push(hlpStartEvent);
}

/**
 * Create a route selection event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createRouteSelectionEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const routeSelectionEvent = {
    ts: event.ts,
    type: EventType.ROUTE_SELECTION,
    routeId: event.trip_filter.route_id,
  };

  eventsSegments[eventsSegments.length - 1].events.push(routeSelectionEvent);
}

/**
 * Create a Trip end event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createTripEndEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const tripEndEvent = {
    ts: event.ts,
    type: EventType.TRIP_END,
  };

  eventsSegments[eventsSegments.length - 1].events.push(tripEndEvent);
}

/**
 * Create a Trip pending event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createTripPendingEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const tripPendingEvent = {
    ts: event.ts,
    type: EventType.TRIP_PENDING,
  };

  eventsSegments[eventsSegments.length - 1].events.push(tripPendingEvent);
}

/**
 * Create a Trip segment.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {import("@/store/devices").Device} state
 * @param {Event} event
 */
function createTripSegment(eventsSegments, state, event) {
  /** @type {EventsSegment} */
  const tripSegment = {
    events: [],
    tripFilter: getTripFilter(event.trip_filter || state.trip_filter),
    tripId: event.trip_id,
    ts: event.ts,
    type: SegmentType.TRIP,
  };

  if (event.trip) {
    tripSegment.startDate = getISODate(dateGtfsFormatToObj(event.trip.start_date));
  }

  eventsSegments.push(tripSegment);
}

/**
 * Create a Trip start event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createTripStartEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const tripStartEvent = {
    ts: event.ts,
    type: EventType.TRIP_START,
  };

  eventsSegments[eventsSegments.length - 1].events.push(tripStartEvent);
}

/**
 * Create an Updated app event.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function createUpdatedAppEvent(eventsSegments, event) {
  /** @type {EnhancedEvent} */
  const updatedAppEvent = {
    ts: event.ts,
    type: EventType.UPDATED_APP,
    versionApp: event.app_version,
  };

  eventsSegments[eventsSegments.length - 1].events.push(updatedAppEvent);
}

/**
 * @param {Object} tripFilter
 * @return {?TripFilter}
 */
function getTripFilter(tripFilter) {
  if (!tripFilter) return null;

  let mode;
  let id;

  if (tripFilter.trip_id) {
    mode = 'trip';
    id = tripFilter.trip_id;
  } else if (tripFilter.route_id) {
    mode = 'route';
    id = tripFilter.route_id;
  } else if (tripFilter.block_id) {
    mode = 'block';
    id = tripFilter.block_id;
  }

  if (!mode) return null;

  return { mode, id };
}

/**
 * Test for trip id and transition to HLP or Trip state.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {import("@/store/devices").Device} state
 * @param {Event} event
 */
function testTripId(eventsSegments, state, event) {
  if (event.trip_id == null) {
    transitionToHLP(eventsSegments, event);
  } else {
    testTripPending(eventsSegments, state, event);
  }
}

/**
 * Test for trip pending and transition to TripPending or Trip state.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {import("@/store/devices").Device} state
 * @param {Event} event
 */
function testTripPending(eventsSegments, state, event) {
  createTripSegment(eventsSegments, state, event);
  if (event.trip_pending) {
    transitionToTripPending(eventsSegments, event);
  } else {
    transitionToTrip(eventsSegments, event);
  }
}

/**
 * Handle transition to HLP state.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function transitionToHLP(eventsSegments, event) {
  createHLPSegment(eventsSegments, event);
  createHLPStartEvent(eventsSegments, event);
}

/**
 * Handle transition to Trip state.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function transitionToTrip(eventsSegments, event) {
  createTripStartEvent(eventsSegments, event);
}

/**
 * Handle transition to Trip pending state.
 * @param {Array<EventsSegment>} eventsSegments
 * @param {Event} event
 */
function transitionToTripPending(eventsSegments, event) {
  createTripPendingEvent(eventsSegments, event);
}

/**
 * @typedef {Object} EnhancedEvent
 * @property {EventType} type
 * @property {number} ts
 */

/** @typedef {import("@/store/devices").Event} Event */

/**
 * @typedef {Object} EventsSegment
 * @property {Array<EnhancedEvent>} events
 * @property {string} type
 * @property {string} [formattedTripName]
 * @property {string} [startDate] - ISO format
 * @property {TripFilter} [tripFilter]
 * @property {string} [tripId]
 * @property {number} [ts] - First ts of the segment
 */

/**
 * @typedef {Object} TripFilter
 * @property {string} tripFilter.mode
 * @property {string} tripFilter.id
 */
