<template>
  <component :is="dataCell.component" v-bind="dataCell.props" v-on="dataCell.events">
    {{ dataCell.value }}
  </component>
</template>

<script>
export default {
  name: 'DataGridCell',

  props: {
    /** @type {import('vue').Prop<import('./models/DataGrid.models').DataGridDataCell>} */
    dataCell: {
      required: true,
      type: Object,
    },
  },
};
</script>
