<template>
  <div class="table-search-bar">
    <font-awesome-icon icon="fa-search" class="table-search-bar__icon" />
    <input
      v-model="input"
      class="table-search-bar__input"
      :class="disabled ? 'table-search-bar__input--disabled' : ''"
      :disabled="disabled"
      :placeholder="$t('search')"
      type="text"
      @input="handleInput"
    />
  </div>
</template>

<script>
import { normalize } from '@/libs/helpers/strings';

const DEBOUNCE_MS = 400;

export default {
  name: 'TableSearchBar',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    /** @type {Vue.PropOptions<Array<string>>} */
    searchFields: {
      type: Array,
      required: true,
    },

    /** @type {Vue.PropOptions<Array<Object>>} */
    searchList: {
      type: Array,
      required: true,
    },

    idKey: {
      type: String,
      required: false,
      default: '_id',
    },
  },

  emits: ['filteredList', 'update:hasInput'],

  data() {
    return {
      DEBOUNCE_MS,

      /** @type {?Map<{[id: string]: string}>} */
      concatValuesMap: null,
      /** @type {String} */
      input: '',
      timeoutID: undefined,
      timer: null,
    };
  },

  watch: {
    input() {
      this.triggerResearch();
    },

    searchList: {
      immediate: true,
      handler() {
        if (this.searchList?.length > 0) {
          this.createConcatValuesMap();
        }
      },
      deep: true,
    },
  },

  methods: {
    triggerResearch() {
      // If a timeout job is already started, cancel it and start a new one with the last input
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      // Trigger timeout after 500ms
      this.timeoutID = setTimeout(() => {
        this.search();
      }, 500);

      if (this.input.length > 0) {
        this.$emit('update:hasInput', true);
      } else {
        this.$emit('update:hasInput', false);
      }
    },

    handleInput() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.search, DEBOUNCE_MS);
    },

    updateSearchResults() {
      this.triggerResearch();
    },

    search() {
      if (this.input.length === 0 || !this.concatValuesMap) {
        this.$emit('filteredList', this.searchList);
      } else {
        const inputs = normalize(this.input).split(' ');
        const filteredList = this.searchList.reduce((acc, elem) => {
          // search every 'inputs' (spaced by ' ') in values to look for searched words
          const element = this.concatValuesMap.get(elem[this.idKey]);
          if (inputs.every(input => element.includes(input))) acc.push(elem);
          return acc;
        }, []);
        this.timeoutID = undefined;
        this.$emit('filteredList', filteredList);
      }
    },

    /**
     * Create a concateneted string of all the fields that will be searched for each object and store it in a Map with the object id
     */
    createConcatValuesMap() {
      this.concatValuesMap = new Map();
      this.searchList.forEach(element => {
        const concatValues = this.searchFields
          .reduce((acc, field) => {
            if (element[field]) {
              acc.push(normalize(element[field]));
            }
            return acc;
          }, [])
          .join(' ');
        this.concatValuesMap.set(element[this.idKey], concatValues);
      });
    },

    /**
     * Reset the search bar
     */
    resetSearch() {
      this.input = '';
    },
  },
};
</script>

<style lang="scss">
.table-search-bar {
  position: relative;
  margin-left: 10px;

  &__icon {
    position: absolute;
    padding: 15px 8px;
  }

  &__input {
    height: 44px;
    padding: 4px 10px 4px 25px;
    border: 1px solid $border;
    border-radius: 4px;
    background-color: $canvas;
    font: inherit;

    &--disabled {
      opacity: 0.6;
    }

    &::placeholder {
      color: $text-neutral;
    }
  }
}
</style>
