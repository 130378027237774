<template>
  <Modal modal-class="modal-trip-modification" :width="1200" @close="$emit('close')">
    <template #title>
      {{ $t('tripModificationTitle') }}
    </template>
    <template #subtitle>
      {{ tripFormattedName }}
    </template>

    <template #body>
      <div class="modal-trip-modification__modal-body">
        <div v-if="loading">
          <AnimatedDots />
        </div>

        <template v-else>
          <div class="modal-trip-modification__delay">
            <label class="form-group__label modal-trip-modification__part-title" for="delay">
              {{ $t('addDelay') }}
            </label>
            <div class="modal-trip-modification__delay-input">
              <font-awesome-icon icon="fa-clock" :class="delay ? 'orange-icon' : ''" />
              <input
                id="delay"
                v-model.number="limitedDelay"
                type="number"
                :min="-999"
                placeholder="00"
                :max="999"
                class="form-group__input form-group__small-number"
                @keydown="limitDelayLength"
              />
              {{ delayWording }}
            </div>
          </div>

          <div class="modal-trip-modification__stops">
            <div class="modal-trip-modification__part-title mt-3">
              {{ $t('modifyServicing') }}
            </div>
            <div class="modal-trip-modification__stops-container">
              <StopTimesFeed
                v-model:unscheduled-stops="unscheduledStops"
                :route-color="routeColor"
                :neutralized-stops="neutralizedStops"
                :stop-times="trip.stop_times"
                :stops="stops"
                :delay="delay"
                :date="date"
                @mapFocusStop="flyTo"
              />

              <div class="modal-trip-modification__map">
                <MapboxMap
                  v-model:bounds="mapBounds"
                  border-radius="0 4px 0 0"
                  :gtfs-id="group.current_file"
                  :stops="mapStops"
                  :trips="[{ id: tripId, highlight: false }]"
                  :stops-options="{
                    stopsZones: true,
                  }"
                  @load="onMapLoad"
                >
                  <div v-if="isCanceledTrip" class="modal-trip-modification__disabled-trip">
                    <span class="modal-trip-modification__disabled-trip__title">
                      {{ $t('cancelledTrip') }}
                    </span>
                    <Btn type="secondary" @click="isCanceledTrip = !isCanceledTrip">
                      <font-awesome-icon icon="fa-rotate-right" />
                      <span>{{ $t('restoreTrip') }}</span>
                    </Btn>
                  </div>
                </MapboxMap>
              </div>
              <div class="modal-trip-modification__cancel-all">
                <v-checkbox id="cancel-trip" v-model="isCanceledTrip" color="success" hide-details>
                  <template #label>
                    <span class="modal-trip-modification__part-title">
                      {{ $t('cancelTrip') }}
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </div>
            <v-checkbox id="next-days" v-model="applyOnNextDays" color="success" hide-details>
              <template #label>
                <span class="modal-trip-modification__part-title">
                  {{ $t('nextDays') }}
                </span>
              </template>
            </v-checkbox>
          </div>
        </template>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" :disabled="!hasSomethingChanged" @click="submitModalModify">
        {{ $t('apply') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import api, { UpdateType } from '@/api';
import MapboxMap from '@/components/map/MapboxMap.vue';
import StopTimesFeed from '@/components/common/ModalTripModification/StopTimesFeed.vue';
import Modal from '@/components/layout/Modal.vue';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import Btn from '@/components/ui/Btn.vue';
import { GroupRoute } from '@/libs/routing';
import { ScheduleRelationship } from '@/store/trips';

export default {
  name: 'ModalTripModification',

  components: {
    AnimatedDots,
    Btn,
    MapboxMap,
    Modal,
    StopTimesFeed,
  },

  props: {
    date: {
      required: true,
      type: String,
    },

    gtfsId: {
      required: true,
      type: String,
    },

    tripFormattedName: {
      required: true,
      type: String,
    },

    tripId: {
      required: true,
      type: String,
    },

    tripUpdates: {
      default: () => [],
      type: Array,
    },
  },

  emits: ['close', 'refresh'],

  data: () => ({
    GroupRoute,
    /** @type {Array<import('@/store/gtfs').StopTime>} */
    unscheduledStops: [],

    /** @type {Number} */
    delay: null,

    /** @type {boolean} */
    isCanceledTrip: false,

    /** @type {Array<import('@/store/gtfs').StopTime>} */
    initialUnscheduledStops: [],

    /** @type {boolean} */
    applyOnNextDays: false,

    /** @type {boolean} */
    loading: false,

    /** @type {{[stopId: string]: import('@/store/gtfs').Stop}} */
    stops: {},

    // TODO, get real data when implemented
    /** @type {Array<import('@/store/gtfs').Stop>} */
    neutralizedStops: [],

    /** @type {?import('@/store/gtfs').Trip} */
    trip: null,

    /** @type {string} */
    routeColor: null,

    /** @type {?mapboxgl.LngLatBounds} */
    mapBounds: null,

    /** @type {mapboxgl.Map} */
    map: null,
  }),

  computed: {
    /** @return {boolean} */
    canceled() {
      return this.getUpdate(UpdateType.TRIP_CANCELED);
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {boolean} */
    hasSomethingChanged() {
      return this.isModifyServingValid || this.isCanceledTrip !== this.canceled || this.checkDelayChange();
    },

    /** @return {boolean} */
    isModifyServingValid() {
      const { unscheduledStops } = this;
      if (this.trip && Object.keys(this.trip).length) {
        unscheduledStops.sort((a, b) => a.stop_sequence - b.stop_sequence);

        if (this.initialUnscheduledStops.length !== this.unscheduledStops.length) {
          return true;
        }
        return this.initialUnscheduledStops.some(
          (element, index) =>
            this.initialUnscheduledStops[index].stop_sequence !== this.unscheduledStops[index].stop_sequence
        );
      }

      return false;
    },

    limitedDelay: {
      /** @return {Number} */
      get() {
        return this.delay;
      },

      // prevent range exceeding values from
      // others input types than keydown (eg. paste)
      /** @param {Number} val */
      set(val) {
        if (val > 999) this.delay = 999;
        else if (val < -999) this.delay = -999;
        else if (!val) this.delay = null;
        else this.delay = val;
      },
    },

    delayWording() {
      if (this.delay > 0) {
        return this.$tc('delayMinutes', this.delay);
      }
      if (this.delay < 0) {
        return this.$tc('advanceMinutes', -this.delay);
      }
      return this.$t('minutes');
    },

    mapStops() {
      const mapStopList = [];
      this.trip.stop_times.forEach(st => {
        mapStopList.push({ id: st.stop_id, highlight: false });
      });
      return mapStopList;
    },

    /** @return {Number} */
    previousDelay() {
      return this.getUpdate(UpdateType.DELAY);
    },
  },

  async created() {
    this.loading = true;

    // TODO, init neutralizedStops based on endpoint

    this.isCanceledTrip = this.canceled === ScheduleRelationship.CANCELED;
    this.delay = this.previousDelay;

    const [trip, stops, routes] = await Promise.all([
      api.trips.getTripFromGtfs(this.group._id, this.gtfsId, this.tripId),
      this.$store.dispatch('gtfs/getStopsMap', { gtfsId: this.gtfsId }),
      this.$store.dispatch('gtfs/getRoutesMap', {
        gtfsId: this.gtfsId,
      }),
    ]);

    this.trip = trip;
    this.stops = stops;

    // route color for design
    const route = routes[trip.route_id] || null;
    this.routeColor = route?.route_color ? `#${route.route_color}` : null;

    this.initialUnscheduledStops = this.checkUnscheduledStops(this.getUpdate(UpdateType.DO_NOT_SERVE));
    this.unscheduledStops = this.initialUnscheduledStops.slice();

    this.loading = false;
  },

  methods: {
    /** @param {{map: mapboxgl.Map}} event */
    onMapLoad({ map }) {
      map.once('idle', () => {
        this.map = map;
      });
    },

    /**
     * Create the initial unscheduled stopTime list based on stopTimeUpdate
     * @param {Array<import('@/api').StopTimeUpdate>} stopTimeUpdate
     * @return {Array<import('@/store/gtfs').StopTime>}
     */
    checkUnscheduledStops(stopTimeUpdate) {
      if (this.trip && Object.keys(this.trip).length) {
        const scheduledStops = this.trip.stop_times.slice();
        const unscheduledStops = [];

        if (stopTimeUpdate) {
          stopTimeUpdate.forEach(update => {
            if (update.schedule_relationship === ScheduleRelationship.SKIPPED) {
              const canceledStop = scheduledStops.find(stop => stop.stop_sequence === update.stop_sequence);
              if (canceledStop) unscheduledStops.push(canceledStop);
            }
          });
        }
        unscheduledStops.sort((a, b) => a.stop_sequence - b.stop_sequence);
        return unscheduledStops;
      }
      return [];
    },

    limitDelayLength(e) {
      const maxLen = this.delay < 0 ? 4 : 3;
      if (this.delay && this.delay.toString().length === maxLen && e.key !== 'Backspace') {
        e.preventDefault();
      }
    },

    async submitModalModify() {
      const skippedStopTimeSeqs = /** @type {Array<import('@/api').StopTimeUpdate>} */ ([]);

      // Cancelled stops
      this.unscheduledStops.forEach(scheduledStop => {
        skippedStopTimeSeqs.push(scheduledStop.stop_sequence);
      });

      const tripUpdates = {
        query: {
          gtfs_id: this.gtfsId,
          trip_id: this.tripId,
          start_date: this.date,
        },
        body: {
          delay: this.delay * 60,
          is_canceled: this.isCanceledTrip,
          skipped_stop_time_seqs: skippedStopTimeSeqs,
          comment: this.getUpdate(UpdateType.COMMENT),
          stop_infos: this.getUpdate(UpdateType.STOP_INFO),
        },
        many: this.applyOnNextDays,
      };

      await this.$store.dispatch('trips/updateTrip', tripUpdates);

      this.$emit('close');
      this.$emit('refresh');
    },

    /**
     * Fly action on map based on stopId
     * @param {string} stopId
     */
    flyTo(stopId) {
      const relatedStop = this.stops[stopId];
      this.map.flyTo({
        center: [relatedStop.stop_lon, relatedStop.stop_lat],
        zoom: 15,
        speed: 0.8,
      });
    },

    /** Verify if delay has changed (compared to previous delay coming from API) */
    checkDelayChange() {
      // check if delay has value (not "" or undefined or null)
      if (this.delay) {
        // then compare to previousDelay
        return this.delay !== this.previousDelay;
      }
      // else if delay has no value but previous had one before
      if (this.previousDelay) return true;
      return false;
    },

    /** @param {UpdateType} type */
    getUpdate(type) {
      return this.tripUpdates?.find(e => e.info_type === type)?.content || null;
    },
  },
};
</script>

<style lang="scss">
.modal-trip-modification {
  .modal__header {
    padding: 0;
  }

  &__delay-input {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__disabled-trip {
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgb(235 87 87 / 80%);
    color: white;
    backdrop-filter: blur(5px);

    &__title {
      font-weight: $font-weight-semi-bold;
      font-size: 36px;
      font-family: $font-poppins;
    }
  }

  &__map {
    position: relative;
    width: 60%;

    // 96vh because 2vh margin top&bottom, 68px = header, 40px = modifyService title, 49px = delay input, 42px = cancel trip, 25px = next days,  98px = footer btn, -20px bonus
    height: calc(96vh - 68px - 40px - 49px - 42px - 25px - 98px - 20px);
  }

  &__cancel-all {
    width: 100%;
    padding: 10px 15px;
    border-top: solid 1px $border-variant;
  }

  &__stops-container {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;
    border: solid 1px $border-variant;
    border-radius: 5px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 98px;
    margin: 12px 0;

    button {
      margin-left: 0 !important;
    }
  }

  &__part-title {
    color: $text-dark;
    font-weight: $font-weight-semi-bold;
  }

  // utilites
  .margin-auto {
    margin: auto;
  }

  .orange-icon {
    color: $warn;
  }

  .btn-display-on-hover {
    opacity: 0;
  }

  .modal__body {
    overflow-y: auto;
  }
}
</style>

<i18n locale="fr">
{
  "apply": "Appliquer les modifications",
  "dontApply": "Ne pas appliquer",
  "tripModificationTitle": "Modification de la course",
  "minutes": "minutes",
  "advanceMinutes": "minute d'avance | minutes d'avance",
  "delayMinutes": "minute de retard | minutes de retard",
  "addDelay": "Indiquer un retard",
  "cancelTrip": "Annuler la course",
  "modifyServicing": "Modifier la desserte",
  "cancelledTrip": "Course annulée",
  "restoreTrip": "Restaurer la course",
  "neutralized": "Neutralisé",
  "nextDays": "Appliquer aux jours suivants"
}
</i18n>

<i18n locale="en">
{
  "apply": "Apply changes",
  "dontApply": "Don't apply",
  "tripModificationTitle": "Trip modification",
  "minutes": "minutes",
  "advanceMinutes": "minute early | minutes early",
  "delayMinutes": "minute late | minutes late",
  "addDelay": "Apply a delay",
  "cancelTrip": "Cancel trip",
  "modifyServicing": "Modify service",
  "cancelledTrip": "Cancelled trip",
  "restoreTrip": "Restore trip",
  "neutralized": "Neutralized",
  "nextDays": "Apply to the next days"
}
</i18n>
