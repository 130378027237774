<template>
  <div class="subnav">
    <div class="subnav__wrapper">
      <router-link
        v-for="(item, index) in items"
        :key="index"
        :to="item.route"
        :class="{
          subnav__item: true,
          'subnav__item--active':
            item.route.name === 'reporting'
              ? item.route.query.metric === $route.query.metric
              : item.route.name === $route.name,
        }"
        :title="item.translation"
      >
        <div>
          <span
            v-if="item.route.name === GroupRoute.GTFS_RT || item.route.name === GroupRoute.PASSENGERS_APP"
            class="round-dot"
            :class="{
              'round-dot--inactive':
                !$store.state.groupServerSide.pub && item.route.name === GroupRoute.PASSENGERS_APP,
            }"
          />
          {{ item.translation }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'NavbarSubmenu',

  props: {
    /** @type {Vue.PropOptions<Array<import('@/components/layout/NavBarLeft.vue').FormattedSubmenuItem>>} */
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      GroupRoute,
    };
  },
};
</script>

<style lang="scss">
.subnav {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: $navbar-submenu;
  width: 200px;
  background-color: transparent;

  &__item {
    display: block;
    padding: 0 12px;
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
    line-height: 2.5;

    &:hover,
    &--active {
      background-color: darken($secondary, 2%);
      color: $text-light;
    }

    .round-dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 2px 5px 0 0;
      border-radius: 50%;
      background-color: $primary-light;

      &--inactive {
        background-color: $danger;
      }
    }
  }

  &__wrapper {
    margin-left: 7px;
    padding: 10px 0;
    border-radius: 4px;
    background-color: $secondary;
    box-shadow: 0 4px 40px rgb(0 0 0 / 51%);
  }
}
</style>
