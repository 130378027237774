// Source: https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/

const colors = [
  /* $danger */
  '#EB5757',
  /* $primary-dark */
  '#00894e',
  /* $warn */
  '#f99c49',
];

export const Charts = {
  /**
   * @param {Array<number>} value
   * @return {string}
   */
  createDonutChart(value) {
    const offsets = [];
    const counts = [value[0], value[1], value[2]];
    let total = 0;
    for (let i = 0; i < counts.length; i += 1) {
      offsets.push(total);
      total += counts[i];
    }
    const fontSize = 16;
    const r = 12;
    const r0 = Math.round(r * 0.4);
    const w = r * 2;

    let html = `<div><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif">`;

    html += Charts.donutSegment(0, 1, r, Math.round(r * 0.3), '#000000');
    if (total === 0) {
      html += Charts.donutSegment(0, 1, r, r0, '#00894e');
    } else {
      for (let j = 0; j < counts.length; j += 1) {
        html += Charts.donutSegment(offsets[j] / total, (offsets[j] + counts[j]) / total, r, r0, colors[j]);
      }
    }
    html += `<circle cx="${r}" cy="${r}" r="${Math.round(
      r * 0.3
    )}" fill="white" /><text dominant-baseline="central" transform="translate(${r}, ${r})"></text></svg></div>`;

    const el = document.createElement('div');
    el.innerHTML = html;
    return html;
  },

  /**
   * @param {number} start
   * @param {number} end
   * @param {number} r
   * @param {number} r0
   * @param {string} color
   */
  donutSegment(start, end, r, r0, color) {
    let checkedEnd = end;
    if (end - start === 1) checkedEnd -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (checkedEnd - 0.25);
    const x0 = Math.cos(a0);
    const y0 = Math.sin(a0);
    const x1 = Math.cos(a1);
    const y1 = Math.sin(a1);
    const largeArc = checkedEnd - start > 0.5 ? 1 : 0;

    return [
      '<path d="M',
      r + r0 * x0,
      r + r0 * y0,
      'L',
      r + r * x0,
      r + r * y0,
      'A',
      r,
      r,
      0,
      largeArc,
      1,
      r + r * x1,
      r + r * y1,
      'L',
      r + r0 * x1,
      r + r0 * y1,
      'A',
      r0,
      r0,
      0,
      largeArc,
      0,
      r + r0 * x0,
      r + r0 * y0,
      `" fill="${color}" />`,
    ].join(' ');
  },
};

export default Charts;
