<template>
  <div class="travel-time">
    <div class="travel-time__data">
      <template v-if="error !== null || (points.length === 2 && pointsInfo.length > 0)">
        <DataGridVuetify
          ref="dataGrid"
          :title="$t('total', { count: pointsInfo.length }) + ' - ' + dataSummary.average"
          :data="pointsInfo"
          :datagrid="datagrid"
        />
      </template>
      <AnimatedDots v-else-if="isLoadingPointInfos" />
      <NoReportData v-else-if="!isLoadingPointInfos" :custom-content="noDataText" class="travel-time__data" />
    </div>

    <div class="travel-time__interface">
      <TravelTimeOptions v-model:options="options" v-model:points="points" />
      <TravelTimeMap v-model:points="points" :options="options" />
    </div>
  </div>
</template>

<script>
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import NoReportData from '@/components/ui/NoReportData.vue';
import { distanceCoords } from '@/libs/helpers/geo';
import { formatSecondsToHHMMSS } from '@/libs/helpers/dates';
import { toCSV } from '@/libs/csv';

import Api from '@/api';
import { getDatagrid } from './ReportsTravelTime.conf.js';
import TravelTimeMap from './TravelTimeMap.vue';
import { TravelTimeDataFormatter } from './TravelTimeDataFormatterHelper.js';
import TravelTimeOptions, { DEFAULT_OPTIONS } from './TravelTimeOptions.vue';
import { TravelsTimesError } from '@/errors';

/** @type {{[key: string]: Promise<{[deviceId: string]: Array<import('@/store/devices').Event>}>}} */
let promises = {};

export default {
  name: 'ReportsTravelTime',
  components: {
    AnimatedDots,
    DataGridVuetify,
    NoReportData,
    TravelTimeMap,
    TravelTimeOptions,
  },

  props: {
    /** @type {import('vue').Prop<{start: number, end: number}>} */
    dateInterval: {
      type: Object,
      required: true,
    },
  },

  emits: ['downloadLink', 'hasData'],

  data() {
    return {
      /** @type {import('@/components/Table/DataGrid/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),

      /** @type {import('./TravelTimeOptions.vue').TravelTimeOptions} */
      options: { ...DEFAULT_OPTIONS },

      /** @type {Array<import('./TravelTimeMap.vue').SelectedPoint>} */
      points: [],

      /** @type {string} */
      error: null,

      /** @type {{[deviceId: string]: {name: string, team: string}}} */
      devices: {},

      /** @type {boolean} */
      errorHistory: false,

      /** @type {Array<boolean>} */
      isLoadingPoint: [false, false],

      /** @type {boolean} */
      isLoadingPointInfos: false,

      /** @type {?Array<PointInfo>} */
      pointsInfo: [],

      /** @type {{[key: string]: import('./TravelTimeMap.vue').SelectedPoint}} */
      selectedPoints: {},
    };
  },

  computed: {
    /** @return {Date} */
    beginningDate() {
      return new Date(this.dateInterval.start * 1000);
    },

    /** @return {?import('@/components/ui/NoReportData.vue').NoDataContent} */
    noDataText() {
      if (this.points.length < 2) {
        return {
          tip: this.$t('tipMissingPoint'),
        };
      }
      return null;
    },

    /** @return {Date} */
    endDate() {
      return new Date(this.dateInterval.end * 1000);
    },

    /** @return {import('@/components/Table/DataGrid/index.vue').DataSummary} */
    dataSummary() {
      return {
        total: this.$t('total', [this.pointsInfo.length]),
        average: this.$t('average', [formatSecondsToHHMMSS(this.getAverageTime())]),
      };
    },

    /** @return {{[tripId: string]: import('@/store/gtfs').Trip}} */
    gtfsTrips() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.$store.getters.group.current_file, 'trips');
    },

    /** @return {boolean} */
    hasData() {
      return this.points.length === 2 && this.pointsInfo.length > 0;
    },
  },

  watch: {
    // Not a computed to avoid listening to all devices updates.
    '$store.state.devices.list': {
      immediate: true,
      deep: true,
      handler() {
        const devices = Object.keys(this.$store.state.devices.list).reduce((acc, deviceId) => {
          const device = this.$store.state.devices.list[deviceId];
          acc[deviceId] = {
            name: device.name,
            team: device.team,
          };

          return acc;
        }, /** @type {{[deviceId: string]: {name: string, team: string}}} */ ({}));

        this.devices = devices;
      },
    },

    hasData: {
      immediate: true,
      handler() {
        this.$emit('hasData', this.hasData);
        if (this.hasData) {
          this.$emit('downloadLink', this.getDownloadLink());
        }
      },
    },

    points: {
      deep: true,
      handler() {
        this.updateSelectedPoints();
      },
    },

    selectedPoints: {
      deep: true,
      handler() {
        this.updatePromises();
      },
    },

    'options.radius': function optionRadius() {
      this.resetData();
    },

    'options.speed': function optionsSpeed() {
      this.checkPointsInfos();
    },
    dateInterval: {
      immediate: true,
      handler(value, old) {
        const currValue = value || {};
        const oldValue = old || {};

        if (currValue.start !== oldValue.start || currValue.end !== oldValue.end) {
          this.pointsInfo = [];
          this.updatePromises(true);
        }
      },
    },
  },

  mounted() {
    this.resetData();
  },

  methods: {
    /**
     * Wait until promises are loaded to process points infos.
     */
    async checkPointsInfos() {
      // get points data from API
      if (this.points.length === 2) {
        this.isLoadingPointInfos = true;
        const maxDuration = distanceCoords(this.points[0], this.points[1]) / (this.options.speed / 3.6); // km/h -> m/s
        const devicesData = await Promise.all(Object.values(promises));
        const pointsInfo = await this.processPointsInfo(devicesData, maxDuration);
        this.pointsInfo = pointsInfo.map(d => TravelTimeDataFormatter.formatData(d));
      } else {
        this.pointsInfo = [];
      }

      this.isLoadingPointInfos = false;
    },

    /**
     * Get events history for an area.
     * @param {string} groupId
     * @param {number} fromTs
     * @param {number} toTs
     * @param {number} minLat
     * @param {number} maxLat
     * @param {number} minLng
     * @param {number} maxLng
     * @return {Promise<Array<import('@/store/devices').Event>>}
     * @throw {Error} when history is too long.
     */
    async getPointHistory(groupId, fromTs, toTs, minLat, maxLat, minLng, maxLng) {
      let result = null;
      try {
        result = await Api.getPointHistory(groupId, fromTs, toTs, minLat, maxLat, minLng, maxLng);
      } catch (e) {
        if (e.message === TravelsTimesError.LIMITE_EXCEEDED) {
          this.error = this.$t('toManyResultError');
        } else {
          this.error = this.$t('unexpectedApiError');
        }
        this.isLoadingPointInfos = false;
      }
      return result;
    },

    /** @return {number} */
    getAverageTime() {
      let total = 0;
      this.pointsInfo.forEach(point => {
        total += point.duration;
      });
      return total / this.pointsInfo.length;
    },

    /** @return {string} */
    getDownloadLink() {
      const columns2 = [
        'date',
        'timePoint1',
        'timePoint2',
        'recordedTravelTime',
        'theoreticalDuration',
        'deviceName',
        'formatTripName',
      ].map(translationKey => this.$t(`exportColumn.${translationKey}`));

      const formattedRows = this.pointsInfo.map(row => {
        const date = this.$d(row.date);
        const point1Ts = this.$d(row.point1Ts * 1000, 'timeLong');
        const point2Ts = this.$d(row.point2Ts * 1000, 'timeLong');
        const duration = formatSecondsToHHMMSS(row.duration);
        const thTime = formatSecondsToHHMMSS(row.thTime);
        const device = this.devices[row.deviceId] || /** @type {{name: string, team: string}} */ ({});
        const { tripName } = row;
        return {
          date,
          point1Ts,
          point2Ts,
          duration,
          thTime,
          device: device.name || row.deviceId,
          tripName,
        };
      });

      const rowsData = formattedRows.map(row => Object.values(row).map(value => value?.toString() || '-'));
      const data = [columns2, ...rowsData];

      const link = toCSV(data); // Blob URL
      return link;
    },

    /**
     * Fetch point history from API.
     * @param {import('./TravelTimeMap.vue').SelectedPoint} point
     * @return {Promise<Array<import('@/store/devices').Event>>}
     */
    async fetchHistory(point) {
      const fromTs = this.beginningDate.getTime() / 1000;
      const toTs = new Date(this.endDate.getTime() + 24 * 60 * 60 * 1000).getTime() / 1000; // + 1 day (24h)

      const latGap = (360 / 40075e3) * this.options.radius;
      const lngGap = (360 / 40075e3 / Math.cos((point.lat * Math.PI) / 180)) * this.options.radius;

      const minLat = point.lat - latGap;
      const maxLat = point.lat + latGap;
      const minLng = point.lng - lngGap;
      const maxLng = point.lng + lngGap;

      const groupId = this.$store.getters.group._id;
      this.error = null;
      const result = await this.getPointHistory(groupId, fromTs, toTs, minLat, maxLat, minLng, maxLng);
      return result;
    },

    /**
     * Refresh promises data.
     */
    reloadPromises() {
      promises = {};
      this.updatePromises();
    },

    resetData() {
      this.points = [];
      this.pointsInfo = [];
      this.selectedPoints = {};
      this.updatePromises();
    },

    /**
     * Process points infos.
     * @param {Array<{[deviceId: string]: Array<import('@/store/devices').Event>}>} devicesData
     * @param {number} maxDuration - Max duration in seconds.
     * @return {Promise<Array.<PointInfo>>}
     */
    async processPointsInfo(devicesData, maxDuration) {
      if (devicesData.length < 2) return null;

      const infos = [];
      await Promise.all(
        Object.keys(devicesData[0]).map(async deviceId => {
          await Promise.all(
            devicesData[0][deviceId].map(async (data, index) => {
              if (!devicesData[1][deviceId]) return;
              // Find the time in the second list that is right after the time of the first list
              const startTs = data.ts;
              const indexAfter = devicesData[1][deviceId].findIndex(data2 => data2.ts > startTs);
              if (indexAfter < 0) return;

              let add = true;
              const data2 = devicesData[1][deviceId][indexAfter];
              const duration = data2.ts - data.ts;

              // Skip when duration longer than maxDuration
              if (duration > maxDuration) {
                add = false;
              }

              // Skip this time if the following time in the first list is also lower
              // than the following time in the second list: 1 -> 1 -> 2 case.
              const dataAfter = devicesData[0][deviceId][index + 1];
              if (dataAfter && dataAfter.ts < data2.ts) {
                add = false;
              }

              const tripId = data.trip_id === data2.trip_id ? data.trip_id : null;
              let tripName = '- - - - - -';
              let thTime = null;
              if (tripId && this.gtfsTrips[data.trip_id]) {
                tripName = await this.$store.dispatch('gtfs/formatTripName', {
                  tripId: data.trip_id,
                  date: new Date(),
                });
                const point1 = this.gtfsTrips[data.trip_id].stop_times.find(
                  stop => stop.stop_id === this.points[1].stopId
                );
                const point2 = this.gtfsTrips[data.trip_id].stop_times.find(
                  stop => stop.stop_id === this.points[0].stopId
                );
                if (point1 && point2) {
                  thTime = Math.abs(point1.departure_time - point2.departure_time);
                }
              }

              if (add) {
                infos.push({
                  date: new Date(data.ts * 1000),
                  point1Ts: data.ts,
                  point2Ts: data2.ts,
                  deviceId,
                  duration,
                  tripName,
                  thTime,
                  tripId,
                });
              }
            })
          );
        }, /** @type {Array<PointInfo>} */ ([]))
      );

      infos.sort((a, b) => a.point1Ts - b.point1Ts);

      return infos;
    },

    /**
     * Start a promise for each selected points.
     */
    updatePromises(dateChanged = false) {
      this.errorHistory = false;

      Object.keys(this.selectedPoints).forEach((key, index) => {
        if (!promises[key] || dateChanged) {
          this.isLoadingPoint[key] = true;
          promises[key] = this.fetchHistory(this.selectedPoints[key])
            .catch(e => {
              this.errorHistory = true;

              return /** @type {Array<import('@/store/devices').Event>} */ ([]);
            })
            .then(response => {
              response.sort((a, b) => a.ts - b.ts);
              // Split points by device
              const devicesData = response.reduce((devices, point) => {
                if (!devices[point.device_id]) {
                  devices[point.device_id] = [];
                }
                devices[point.device_id].push(point);
                return devices;
              }, /** @type {{[deviceId: string]: Array<import('@/store/devices').Event>}} */ ({}));

              Object.keys(devicesData).forEach(deviceId => {
                const fullList = devicesData[deviceId];
                const filteredList = [fullList[0]];
                const MIN_DURATION = 300; // 5 minutes

                for (let i = 1; i < fullList.length; i += 1) {
                  if (fullList[i].ts > fullList[i - 1].ts + MIN_DURATION) {
                    filteredList.push(fullList[i]);
                  } else if (index === 0) {
                    filteredList[filteredList.length - 1] = fullList[i];
                  }
                }

                devicesData[deviceId] = filteredList;
              });

              this.isLoadingPoint[key] = false;

              return devicesData;
            });
        }
      });

      this.checkPointsInfos();
    },

    /**
     * Remove old points and add new ones from `selectedPoints`.
     */

    updateSelectedPoints() {
      Object.keys(this.selectedPoints).forEach(key => {
        if (!this.points.find(point => `${point.lat}_${point.lng}` === key)) {
          delete promises[key];
          delete this.isLoadingPoint[key];
          delete this.selectedPoints[key];
        }
      });

      this.points.forEach(point => {
        const key = `${point.lat}_${point.lng}`;
        if (!this.selectedPoints[key]) {
          this.selectedPoints[key] = point;
        }
      });
    },
  },
};

/**
 * @typedef {Object} PointInfo
 * @property {Date} date
 * @property {number} duration
 * @property {number} point1Ts
 * @property {number} point2Ts
 * @property {string} deviceId
 * @property {string} tripName
 * @property {number} thTime
 * @property {number} tripId
 */
</script>

<style lang="scss">
.travel-time {
  display: flex;
  height: calc(100vh - 160px);

  &__data,
  &__interface {
    flex: 1;
    padding: 20px;
  }

  &__data {
    padding: 10px 5px 20px 20px;

    .no-report-data {
      height: 100%;
      margin: 0;
      padding-top: 50%;
    }

    .datagrid-vuetify {
      &__body {
        overflow-y: auto;
        height: calc(100vh - 190px);
        border-radius: 8px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  &__interface {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px 5px;
  }
}
</style>

<i18n locale="fr">
  {
    "exportColumn": {
      "date": "Date",
      "timePoint1": "Date-heure au point 1",
      "timePoint2": "Date-heure au point 2",
      "recordedTravelTime": "Temps de parcours enregistré (min)",
      "theoreticalDuration" : "Temps de parcours théorique (min)",
      "formatTripName": "Nom de course formaté",
      "deviceName": "Nom de l'appareil",
    },
    "tipMissingPoint": "Veuillez choisir 2 points (arrêts ou points sur un tracé).",
    total: "{0} résultats",
    average: "Temps de parcours moyen : {0}",
    "toManyResultError": "Volume de données trop important. Veuillez réduire la période.",
    "unexpectedApiError": "Une erreur s'est produite, veuillez réessayer.",
  }
  </i18n>

<i18n locale="en">
  {
    "exportColumn": {
      "date": "Date",
      "timePoint1": "Date-time at point 1",
      "timePoint2": "Date-time at point 2",
      "recordedTravelTime": "Recorded travel time (min)",
      "theoreticalDuration" : "Theoretical travel time (min)",
      "formatTripName": "Formatted trip name",
      "deviceName": "Device name",
    },
    "tipMissingPoint": "Please select 2 points (stops or points on a plot).",
    total: "{0} results",
    average: "Average travel time : {0}",
    "toManyResultError": "Too much data. Please reduce the period.",
    "unexpectedApiError": "Unexpected error, please try again.",
  }
  </i18n>
