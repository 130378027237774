/* eslint-disable no-use-before-define */
/** @module */
import LinkHeader from 'http-link-header';
import { maintenance } from '@/libs/maintenanceChecker';
import { TravelsTimesError } from './errors';
import { InvalidResponseError, PysaeApiError, PysaeApiErrorContent, request } from '@/libs/api/client';
import i18n from '@/i18n';

const { t } = i18n.global;

let errorHandler;

/**
 * Set global API error handler.
 *
 * @param {function(Error): (boolean)} apiErrorHandler
 */
export function setApiErrorHandler(apiErrorHandler) {
  errorHandler = apiErrorHandler;
}

/**
 * Error handler based on toast that automatically generate an translated message based on `code` field
 * received in the error response.
 *
 * Add apiErrors.codes.<code> inside translation files for this handler to support an error code coming
 * from API
 *
 * @param toast
 * @return {function(Error): (boolean)}
 */
export function toastApiErrorHandlerFactory(toast) {
  /**
   * @param {Error} error
   */
  return function toastApiErrorHandler(error) {
    let key;
    if (error instanceof PysaeApiError && error.data instanceof PysaeApiErrorContent) {
      key = `apiErrors.codes.${error.data.code}`;
    }

    if (key === undefined) {
      return;
    }

    const translatedError = t(key);
    if (translatedError === key || translatedError === '') {
      return;
    }

    const toastId = toast.error(translatedError, { position: 'bottom-right' });
    setTimeout(() => toast.dismiss(toastId), 3000);
  };
}

/**
 * @param {number} ms
 * @return {Promise<void>}
 */
function sleep(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Automatically retry HTTP request multiple times and optionally handle error if errorHandler is set.
 *
 * @param {string} url
 * @param {RetriedFetchOptions & RequestInit} [options]
 * @return {Promise<Response>} Promise is resolved with HTTP Response or rejected with error informations.
 */
// eslint-disable-next-line consistent-return
async function retriedFetch(url, options = {}) {
  let maxRetries = options.maxRetries ?? -1;
  const retryInterval = options.retryInterval ?? 1000;
  options.credentials = 'include';
  const keepRetry = true;
  // TODO - Check this while
  while (keepRetry) {
    try {
      return await request(url, options);
    } catch (e) {
      if (e instanceof InvalidResponseError) {
        if (e.response !== undefined && maintenance.check(e.response)) {
          maintenance.goTo();
        }
      }

      errorHandler?.(e);

      if (e instanceof InvalidResponseError || maxRetries === 0) {
        throw e;
      }

      // Retries are only performed on network errors
      maxRetries -= 1;
      await sleep(retryInterval);
    }
  }
}

/**
 * @typedef {Object} RetriedFetchOptions
 * @property {number} [retryInterval=1000]
 * @property {number} [maxRetries=-1]
 */

export const alerts = {
  /**
   * Delete an alert.
   * @param {string} groupId
   * @param {string} alertId
   * @throws {InvalidResponseError}
   */
  async delete(groupId, alertId) {
    await retriedFetch(`/api/v2/groups/${groupId}/alerts/${alertId}`, {
      method: 'DELETE',
    });
  },

  /**
   * Get an alert.
   * @param {string} groupId
   * @param {string} alertId
   * @return {Promise<import('@/store/alerts').Alert>}
   * @throws {Response}
   */
  async get(groupId, alertId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/alerts/${alertId}`);
    return response.json();
  },

  /**
   * Get alerts.
   * @param {string} groupId
   * @return {Promise<Array<import('@/store/alerts').Alert>>}
   * @throws {Response}
   */
  async getList(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/alerts`);
    return response.json();
  },

  /**
   * Create a new alert.
   * @param {string} groupId
   * @param {import('@/store/alerts').Alert} alert - Alert object without id.
   * @return {Promise<string>} Id of the new alert.
   * @throws {Response}
   */
  async post(groupId, alert) {
    // fix post a duplicate alert since API does not allow extra fields
    if (alert.id) {
      delete alert.id;
    }
    const response = /** @type {import('@/store/alerts').Alert} */ (
      await retriedFetch(`/api/v2/groups/${groupId}/alerts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(alert),
      }).then(r => r.json())
    );

    return response._id;
  },

  /**
   * Change an existing alert.
   * @param {string} groupId
   * @param {import('@/store/alerts').Alert} alert
   * @throws {Response}
   */
  async put(groupId, alert) {
    await retriedFetch(`/api/v2/groups/${groupId}/alerts/${alert._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(alert),
    });
  },

  /**
   * @param {string} groupId
   * @param {string} alertId
   * @return {Promise<{last_push: number}>}
   */
  async sendPushAlert(groupId, alertId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/alerts/${alertId}/push`, {
      method: 'POST',
    });
    return response.json();
  },
};

export const config = {
  /**
   * Get versions
   * @return {Promise<Array<Config>>}
   * @throws {Response}
   */
  async getVersion() {
    const response = await retriedFetch(`/api/v3/config/driver/versions`);
    return response.json();
  },

  /**
   * Put versions
   * @param {Object} payload
   * @param {string[]} payload.deprecated
   * @param {string[]} payload.to_update
   * @return {Promise<Array<Config>>}
   * @throws {Response}
   */
  async putVersions(payload) {
    const response = await retriedFetch(`/api/v3/config/driver/versions`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  },
};

export const drivers = {
  /**
   * Import or compare uploaded csv file with existing drivers for a group.
   * @param {string} groupId
   * @param {File} file
   * @param {boolean} [compareOnly=false]
   * @return {Promise<{drivers_to_add: number, drivers_to_update: number, drivers_to_archive: number}>}
   * @throws {Response}
   */
  async uploadCsv(groupId, file, compareOnly = false) {
    const qParams = compareOnly ? '?compare-only=true' : '';

    const data = new FormData();
    data.append('file', file);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/driver-list${qParams}`, {
      method: 'POST',
      body: data,
    });
    return response.json();
  },

  /**
   * Add driver in a group.
   * @param {string} groupId
   * @param {import('@/store/drivers').Driver} driverInfos
   * @return {Promise<{}>}
   * @throws {Response}
   */
  async post(groupId, driverInfos) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/drivers`, {
      method: 'POST',
      body: JSON.stringify(driverInfos),
      headers: { 'Content-Type': 'application/json' },
    });
    return response.json();
  },

  /**
   * Edit driver in a group.
   * @param {string} groupId
   * @param {string} driverId
   * @param {import('@/store/drivers').Driver} driverInfos
   * @return {Promise<void>}
   * @throws {Response}
   */
  async put(groupId, driverId, driverInfos) {
    await retriedFetch(`/api/v3/groups/${groupId}/drivers/${driverId}`, {
      method: 'PUT',
      body: JSON.stringify(driverInfos),
      headers: { 'Content-Type': 'application/json' },
    });
  },

  /**
   * Delete driver in a group.
   * @param {string} groupId
   * @param {string} driverId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async delete(groupId, driverId) {
    await retriedFetch(`/api/v3/groups/${groupId}/drivers/${driverId}`, {
      method: 'PATCH',
      body: JSON.stringify({ archived: true }),
      headers: { 'Content-Type': 'application/json' },
    });
  },
};

export const gtfs = {
  /**
   * Add a gtfs
   * @param {string} groupId
   * @param {string} gtfsName
   * @return {Promise<number>}
   * @throws {Response}
   */
  async addGtfs(groupId, gtfsName) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs?file_name=${gtfsName}`, {
      method: 'POST',
    });
    return response.json();
  },

  /**
   * Delete gtfs in a group
   * @param {string} groupId
   * @param {string} fileId
   * @return {Promise<number>}
   * @throws {Response}
   */
  async deleteGtfs(groupId, fileId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${fileId}`, {
      method: 'DELETE',
    });
    return response.status;
  },

  /**
   * Copy a gtfs from another gtfs
   * @param {string} groupId
   * @param {string} gtfsName
   * @param {string} gtfsSourceId
   * @return {Promise<number>}
   * @throws {Response}
   */
  async duplicateGtfs(groupId, gtfsName, gtfsSourceId) {
    const response = await retriedFetch(
      `/api/v2/groups/${groupId}/gtfs?file_name=${gtfsName}&source=${gtfsSourceId}`,
      {
        method: 'POST',
      }
    );
    return response.status;
  },

  /**
   * Get calendar of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Service>>}
   * @throws {Response}
   */
  async getCalendar(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/calendar`);
    return response.json();
  },

  /**
   * Get calendar exceptions of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Service>>}
   * @throws {Response}
   */
  async getCalendarDates(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/calendar_dates`);
    return response.json();
  },

  /**
   * Get list of all gtfs
   * @param {string} groupId
   * @return {Promise<Array<GtfsSchedule>>}
   * @throws {Response}
   */
  async getGtfs(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs`);
    if (!response) {
      return [];
    }

    return response.json();
  },

  /**
   * @param {string} groupId
   * @throws {Response}
   */
  async getGtfsPlainText(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs-rt?format=plaintext`);
    if (!response) {
      return '';
    }

    return response.json();
  },

  /**
   * Get list of gtfs publications.
   * @param {string} groupId
   * @return {Promise<Array<import('@/store/gtfs').Publication>>}
   * @throws {Response}
   */
  async getGtfsPublications(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/history/gtfs`);
    const publications = await response.json();
    publications.map(e => {
      e.ts = e._id;
      return e;
    });

    return publications;
  },

  /**
   * Get routes list of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Route>>}
   * @throws {Response}
   */
  async getRoutes(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/routes`);
    return response.json();
  },

  /**
   * Get stops list of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Stop>>}
   * @throws {Response}
   */
  async getStops(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/stops`);
    return response.json();
  },

  /**
   * Get stop of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @param {string} stopId
   * @return {Promise<import('@/store/gtfs').Stop>}
   * @throws {Response}
   */
  async getStop(groupId, gtfsId, stopId) {
    const response = await retriedFetch(`api/v3/groups/${groupId}/gtfs/${gtfsId}/stops/${stopId}`);
    return response.json();
  },

  /**
   * Get trips list of a gtfs publication.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Trip>>}
   * @throws {Response}
   */
  async getTrips(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/trips`);
    return response.json();
  },

  /**
   * Get shapes list.
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<Array<import('@/store/gtfs').Shape>>}
   * @throws {Response}
   */
  async getShapes(groupId, gtfsId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/gtfs/${gtfsId}/shapes`);
    return response.json();
  },

  /**
   * Publish a gtfs
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async publishGtfs(groupId, gtfsId) {
    await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/publish`, {
      method: 'POST',
    });
  },

  /**
   * Schedule a gtfs publication
   * @param {string} groupId
   * @param {string} gtfsId
   * @param {Date} scheduleTime
   * @return {Promise<void>}
   * @throws {Response}
   */
  async scheduleGtfs(groupId, gtfsId, scheduleTime) {
    await retriedFetch(
      `/api/v3/groups/${groupId}/gtfs/${gtfsId}/schedule?schedule=${scheduleTime.toISOString()}`,
      {
        method: 'PUT',
      }
    );
  },

  /**
   * Cancel a scheduled gtfs publication
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async cancelScheduledGtfsPublication(groupId, gtfsId) {
    await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/schedule`, {
      method: 'PUT',
    });
  },

  /**
   * Archive a gtfs
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async archiveGtfs(groupId, gtfsId) {
    await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/archive`, {
      method: 'POST',
    });
  },

  /**
   * Restore a gtfs
   * @param {string} groupId
   * @param {string} gtfsId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async restoreGtfs(groupId, gtfsId) {
    await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/restore`, {
      method: 'POST',
    });
  },
};

export const logo = {
  /** @param {string} groupId */
  async delete(groupId) {
    await retriedFetch(`/api/v2/groups/${groupId}/logo`, { method: 'DELETE' });
  },

  /**
   * @param {string} groupId
   * @param {FormData} form - Form containing an entry `logo` with an image file.
   */
  async put(groupId, form) {
    await retriedFetch(`/api/v2/groups/${groupId}/logo`, {
      method: 'PUT',
      body: form,
    });
  },
};

export const admin = {
  users: {
    async get() {
      const response = await retriedFetch('/api/v3/users');
      return response.json();
    },

    async create(user) {
      delete user._id; // api does not allow to send an id
      const response = await retriedFetch('/api/v3/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
      // if response ok, add _id because api does not return any
      // TODO, fix api to return complete object on creation
      const userResponse = await response.json();
      userResponse._id = user.email;
      return userResponse;
    },

    async update(user) {
      const response = await retriedFetch(`/api/v3/users/${encodeURIComponent(user._id)}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
      return response.json();
    },

    async del({ _id }) {
      const response = await retriedFetch(`/api/v3/users/${encodeURIComponent(_id)}`, { method: 'DELETE' });
      return response.json();
    },

    async getResetLink({ email }) {
      const response = await retriedFetch(`/api/v2/password/reset?email=${email}&api=true`, {
        method: 'POST',
      });
      return response.json();
    },
  },

  groups: {
    async get() {
      const response = await retriedFetch('/api/v2/groups');
      return response.json();
    },

    async create(group) {
      group._id = group.group_id;
      const response = await retriedFetch(`/api/v2/groups/${group._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(group),
      });
      return response.json();
    },

    async update(group) {
      const response = await retriedFetch(`/api/v2/groups/${group._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(group),
      });
      return response.json();
    },

    async del({ _id }) {
      const response = await retriedFetch(`/api/v2/groups/${_id}`, {
        method: 'DELETE',
      });
      return response.json();
    },
  },
};

export const messages = {
  /**
   * Archive a message.
   * @param {string} groupId
   * @param {string} messageId - ID Message to archive.
   * @return {Promise<boolean>} result on archive message process.
   * @throws {Response}
   */
  async archive(groupId, messageId) {
    await retriedFetch(`/api/v2/groups/${groupId}/messages/${messageId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        archived: true,
      }),
    });
    return true;
  },

  /**
   * Get Hot Inbox  info.
   * @param {string} groupId - Group Id.
   * @return {Promise<Array<import('@/store/messages').ApiMessage>>}
   * @throws {Response}
   */
  async getHotInBoxMessages(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/messages?to=op`);
    return response.json();
  },

  /**
   * Get messages info.
   * @param {string} groupId - Group Id.
   * @return {Promise<Array<import('@/store/messages').ApiMessage>>}
   * @throws {Response}
   */
  async getMessages(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/messages`);
    return response.json();
  },

  /**
   * Patch a message.
   * @param {string} groupId
   * @param {Object} $0 - Message to patch.
   * @param {string} $0.messageId
   * @param {Partial<import('@/store/messages').ApiMessage>} $0.message
   * @return {Promise<object>} result on patched message process.
   * @throws {Response}
   */
  async patch(groupId, { messageId, message }) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/messages/${messageId}/recipients/op`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
    return response;
  },

  /**
   * Create a new message.
   * @param {string} groupId
   * @param {import('@/store/messages').ApiMessage} message
   * @throws {Response}
   */
  async post(groupId, message) {
    await retriedFetch(`/api/v2/groups/${groupId}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
  },
};

/**
 * Fetch helper for stats
 * @param {object} args
 * @param {string} args.baseUrl - Base URL
 * @param {string} args.groupBy - Group by value
 * @param {string} args.startDate - Date in format YYYYMMDD
 * @param {string} args.endDate - Date in format YYYYMMDD
 * @return {Promise}
 */
const statsFetch = async ({ baseUrl, groupBy, startDate, endDate }) => {
  const qParams = new URLSearchParams();
  qParams.append('group_by', groupBy);
  if (startDate !== undefined) qParams.append('start_date', startDate);
  if (endDate !== undefined) qParams.append('end_date', endDate);

  const response = await retriedFetch(`${baseUrl}?${qParams}`);
  return response.json();
};

export const stats = {
  /**
   * Get stats of devices.
   * @param {string} groupId
   * @param {string} from
   * @param {string} to
   * @return {Promise<Array<DailyDevices>>}
   */
  async getDailyDevices(groupId, from, to) {
    const response = await retriedFetch(
      `/api/v2/groups/${groupId}/stats/daily_devices?from=${from}&to=${to}`
    );
    return response.json();
  },

  /**
   * Get stats of punctuality by day or route.
   * @param {string} groupId
   * @param {number} from - Start date in timestamp seconds (included in the interval)
   * @param {number} to - Limit date in timestamp seconds (excluded from the interval)
   * @param {string} groupBy - "day" or "route"
   * @return {Promise<Array<PunctualityByDay | PunctualityByRoute>>}
   */
  async getPunctualityStats(groupId, from, to, groupBy) {
    const response = await retriedFetch(
      `/api/v3/groups/${groupId}/stats/punctuality?from=${from}&to=${to}&group_by=${groupBy}`
    );
    return response.json();
  },

  /**
   * Get stats of VK.
   * @param {string} groupId
   * @param {string} groupBy - Group by value
   * @param {string} [startDate] - Date in format YYYYMMDD
   * @param {string} [endDate] - Date in format YYYYMMDD
   * @return {Promise<Array<TripKM>>}
   */
  getTripKM(groupId, groupBy, startDate, endDate) {
    const baseUrl = `/api/v3/groups/${groupId}/stats/trip-km`;
    return statsFetch({
      baseUrl,
      groupBy,
      startDate,
      endDate,
    });
  },

  /**
   * Get stats of passenger counts.
   * @param {string} groupId
   * @param {string} groupBy - Group by value
   * @param {string} [startDate] - Date in format YYYYMMDD
   * @param {string} [endDate] - Date in format YYYYMMDD
   * @return {Promise<Array<PassengerCounts>>}
   */
  getPassengerCounts(groupId, groupBy, startDate, endDate) {
    const baseUrl = `/api/v3/groups/${groupId}/stats/passenger-counts`;
    return statsFetch({
      baseUrl,
      groupBy,
      startDate,
      endDate,
    });
  },

  /**
   * Get stats of trip tracking.
   * @param {string} groupId
   * @param {string} groupBy - Group by value
   * @param {string} [startDate] - Date in format YYYYMMDD
   * @param {string} [endDate] - Date in format YYYYMMDD
   * @return {Promise<Array<TripTracking>>}
   */
  getTripTracking(groupId, groupBy, startDate, endDate) {
    const baseUrl = `/api/v3/groups/${groupId}/stats/trip-tracking`;
    return statsFetch({
      baseUrl,
      groupBy,
      startDate,
      endDate,
    });
  },

  /**
   * Export report
   * @param {string} groupId
   * @param {string} metric
   * @param {string} startDate - Date in format YYYYMMDD
   * @param {string} endDate - Date in format YYYYMMDD
   * @return {Promise<Response>}
   */
  async exportReport(groupId, metric, startDate, endDate) {
    const response = await retriedFetch(
      `/api/v3/groups/${groupId}/export/${metric}?start_date=${startDate}&end_date=${endDate}`
    );
    return response;
  },
};

export const trips = {
  /**
   * @param {string} groupId
   * @return {Promise<Array<TripIncident>>}
   */
  async getIncidents(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/trip-incidents`);
    return response.json();
  },

  /**
   * Get stop times history.
   * @param {string} groupId
   * @param {string|number} dateGtfsOrFrom - Start date or from interval.
   * @param {number} [to] - To interval.
   * @param {string} [stopId] - stop id filter.
   * @return {Promise<Array<StopTimeHistory>>}
   * @throws {Response}
   */
  async getHistoryStopTimes(groupId, dateGtfsOrFrom, to, stopId = null) {
    const qParams = new URLSearchParams();
    qParams.append('event', 'departure');
    qParams.append('include_last_stop_arrival', 'true');
    if (to == null) {
      qParams.append('start_date', dateGtfsOrFrom);
    } else {
      qParams.append('from', dateGtfsOrFrom);
      qParams.append('to', to);
    }
    if (stopId) qParams.append('stop_id', stopId);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/history/stop_times?${qParams}`);

    return response.json();
  },

  /**
   * Get a trip from a gtfs.
   * @param {string} groupId
   * @param {string} gtfsId
   * @param {string} tripId
   * @returns {Promise<import('@/store/gtfs').Trip>}
   */
  async getTripFromGtfs(groupId, gtfsId, tripId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/gtfs/${gtfsId}/trips/${tripId}`);
    return response.json();
  },

  /**
   * Get a trip's event feed
   * @param {string} groupId
   * @param {string} gtfsId
   * @param {string} tripId
   * @param {string} startDate
   */
  async getTripEventFeed(groupId, gtfsId, tripId, startDate) {
    const response = await retriedFetch(
      `/api/v3/groups/${groupId}/trip-details-feed?trip_id=${tripId}&start_date=${startDate}&gtfs_id=${gtfsId}`
    );
    return response.json();
  },

  /**
   * Get trip list.
   * @param {Object} groupId
   * @param {string} date - Date (Gtfs format).
   * @param {Object} [options = {}] - Options.
   * @return {Promise<Array<TripListItem>>}
   * @throws {Response}
   */
  async getTripList(groupId, date, options = {}) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/trip-list?date=${date}`, options);
    return response.json();
  },

  /**
   * Get trip list.
   * @param {Object} groupId
   * @param {string} date - Date (Gtfs format).
   * @param {Object} [options = {}] - Options.
   * @return {Promise<Array<TripListItemV4>>}
   * @throws {Response}
   */
  async getTripListV4(groupId, date, options = {}) {
    const response = await retriedFetch(`/api/v4/groups/${groupId}/trips?date=${date}`, options);
    return response.json();
  },

  /**
   * Get one trip (same data format as get Trips V4)
   * @param {Object} groupId
   * @param {string} date - Date (Gtfs format).
   * @param {string} tripId
   * @param {Object} [options = {}] - Options.
   * @return {Promise<TripListItemV4>}
   * @throws {Response}
   */
  async getTripFromTripList(groupId, date, tripId, options = {}) {
    const response = await retriedFetch(`/api/v4/groups/${groupId}/trips/${tripId}?date=${date}`, options);
    return response.json();
  },

  /**
   * Get trip vkHistory.
   * @param {string} groupId
   * @param {string} startDateGtfs - Date Gtfs.
   * @param {string} [endDateGtfs] - Date Gtfs. Will use `startDateGtfs` when omitted.
   * @return {Promise<Array<import('@/store/trips').VkHistory>>}
   * @throws {Response}
   */
  async getVkHistory(groupId, startDateGtfs, endDateGtfs) {
    const response = await retriedFetch(
      `/api/v3/groups/${groupId}/history/vk?from=${startDateGtfs}&to=${endDateGtfs || startDateGtfs}`
    );
    return response.json();
  },

  /**
   * Get trip-km stats.
   * @param {string} groupId
   * @param {string} groupBy - Group by value
   * @param {string} [startDateGtfs] - Date Gtfs
   * @param {string} [endDateGtfs] - Date Gtfs

   * @return {Promise<Array<KmStatsGroupByDay | KmStatsGroupByRoute >>}
   * @throws {Response}
   */
  async getTripKMStats(groupId, groupBy, startDateGtfs, endDateGtfs) {
    const response = await retriedFetch(
      `/api/v3/groups/${groupId}/stats/trip-km?start_date=${startDateGtfs}&end_date=${endDateGtfs}&group_by=${groupBy}`
    );
    return response.json();
  },

  /**
   * Create a new incident on a trip
   * @param {String} groupId
   * @param {Object} payload
   * @param {String} payload.gtfs_id
   * @param {String} payload.trip_id
   * @param {String} payload.start_date
   * @param {String} payload.incident_label
   */
  async postIncident(groupId, payload) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/trip-incidents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  },

  /**
   * Change an existing trip.
   * @param {string} groupId
   * @param {Object} query
   * @param {Object} body
   * @param {Boolean} many
   * @return {Promise<Response>}
   * @throws {Response}
   */
  async updateTrip(groupId, query, body, many) {
    const qParams = new URLSearchParams();
    const dateParam = many ? 'start_date' : 'date';
    qParams.append('trip_id', query.trip_id);
    qParams.append('gtfs_id', query.gtfs_id);
    qParams.append(dateParam, query.start_date);

    const caseRouteMany = many ? '/many' : '';

    return retriedFetch(`/api/v4/groups/${groupId}/trip-updates${caseRouteMany}?${qParams}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  },
};

export const vehicles = {
  /**
   * Import or compare uploaded csv file with existing vehicles for a group.
   * @param {string} groupId
   * @param {File} file
   * @param {boolean} [compareOnly=false]
   * @return {Promise<{vehicles_to_add: number, vehicles_to_update: number, vehicles_to_archive: number}>}
   * @throws {Response}
   */
  async uploadCsv(groupId, file, compareOnly = false) {
    const qParams = compareOnly ? '?compare-only=true' : '';

    const data = new FormData();
    data.append('file', file);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/vehicle-list${qParams}`, {
      method: 'POST',
      body: data,
    });
    return response.json();
  },

  /**
   * Add vehicle in a group.
   * @param {string} groupId
   * @param {import('@/store/vehicles').Vehicle} vehicleInfos
   * @return {Promise<{_id: string}>}
   * @throws {Response}
   */
  async post(groupId, vehicleInfos) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/vehicles`, {
      method: 'POST',
      body: JSON.stringify(vehicleInfos),
      headers: { 'Content-Type': 'application/json' },
    });
    return response.json();
  },

  /**
   * Edit vehicle in a group.
   * @param {string} groupId
   * @param {string} vehicleId
   * @param {import('@/store/vehicles').Vehicle} vehicleInfos
   * @return {Promise<void>}
   * @throws {Response}
   */
  async put(groupId, vehicleId, vehicleInfos) {
    await retriedFetch(`/api/v3/groups/${groupId}/vehicles/${vehicleId}`, {
      method: 'PUT',
      body: JSON.stringify(vehicleInfos),
      headers: { 'Content-Type': 'application/json' },
    });
  },

  /**
   * Delete vehicle in a group.
   * @param {string} groupId
   * @param {string} vehicleId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async delete(groupId, vehicleId) {
    await retriedFetch(`/api/v3/groups/${groupId}/vehicles/${vehicleId}`, {
      method: 'PATCH',
      body: JSON.stringify({ archived: true }),
      headers: { 'Content-Type': 'application/json' },
    });
  },

  async get(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/vehicles`);
    return response.json();
  },
};

export const devices = {
  /**
   * Add device in a group.
   * @param {string} groupId
   * @param {Partial<import('@/store/devices').Device>} deviceInfos
   * @return {Promise<void>}
   * @throws {Response}
   */
  async post(groupId, deviceInfos) {
    await retriedFetch(`/api/v3/groups/${groupId}/devices`, {
      method: 'POST',
      body: JSON.stringify(deviceInfos),
      headers: { 'Content-Type': 'application/json' },
    });
  },

  /**
   * Delete a device.
   * @param {string} groupId
   * @param {string} deviceId
   * @throws {Response}
   */
  async deleteDevice(groupId, deviceId) {
    await retriedFetch(`/api/v2/groups/${groupId}/devices/${deviceId}`, {
      method: 'DELETE',
    });
  },

  /**
   * Get device info.
   * @param {string} groupId - Group Id.
   * @param {string} deviceId - Device Id.
   * @return {Promise<import('@/store/devices').Device>}
   * @throws {Response}
   */
  async getDevice(groupId, deviceId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/devices/${deviceId}`);
    return response.json();
  },

  /**
   * Get devices list
   * @param {string} groupId - Group Id.
   * @return {Promise<Array<import('@/store/devices').Device>>}
   * @throws {Response}
   */
  async getDevices(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/devices`);
    return response.json();
  },

  /**
   * Get events for a specific device starting at a specific timestamp from server.
   * @param {string} groupId - Group Id.
   * @param {string} [deviceId] - Device Id.
   * @param {number} [fromTs] - Timestamp at lower limit.
   * @param {number} [toTs] - Timestamp at upper limit.
   * @param {string} [tripId]
   * @return {Promise<Array<import('@/store/devices').Event>>}
   * @throws {Response}
   */
  async getEvents(groupId, deviceId = null, fromTs = null, toTs = null, tripId = null) {
    const params = new URLSearchParams();

    if (deviceId) params.append('device_id', deviceId);
    if (fromTs) params.append('from', String(Math.floor(fromTs)));
    if (toTs) params.append('to', String(Math.ceil(toTs)));
    if (tripId) params.append('trip_id', tripId);

    const response = await retriedFetch(`/api/v3/groups/${groupId}/history/events?${params.toString()}`);
    return response.json();
  },

  /**
   * Change an existing device.
   * @param {string} groupId
   * @param {import('@/store/devices').Device} device
   * @return {Promise<import('@/store/devices').Device>}
   * @throws {Response}
   */
  async put(groupId, device) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/devices/${device.device_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(device),
    });

    return response.json();
  },

  /**
   * Archive an existing device
   * @param {string} groupId
   * @param {string} deviceId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async archive(groupId, deviceId) {
    await retriedFetch(`/api/v3/groups/${groupId}/devices/${deviceId}/archive`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  /**
   * Unarchive an existing device
   * @param {string} groupId
   * @param {string} deviceId
   * @return {Promise<void>}
   * @throws {Response}
   */
  async unarchive(groupId, deviceId) {
    await retriedFetch(`/api/v3/groups/${groupId}/devices/${deviceId}/unarchive`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  /**
   * Get device registration code and expiration time
   * @param {string} groupId
   * @return {Promise<RegistrationCode>}
   */
  async getDeviceRegistrationCode(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/code`);
    if (response.status === 404) return null;
    return response.json();
  },

  /**
   * Request new device registration code
   * @param {string} groupId
   * @return {Promise<RegistrationCode>}
   */
  async generateDeviceRegistrationCode(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/code/generate`, {
      method: 'POST',
    });
    return response.json();
  },
};

export const integrations = {
  /**
   * Get integrations.
   *
   * @param {string} groupId - Group Id.
   * @return {Promise<Record<string, *>>}
   */
  async getAllGroupIntegrations(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/integrations`);
    return response.json();
  },

  /**
   * Create or replace group integration.
   * @param {string} groupId
   * @param {string} integrationId
   * @param {*} configuration
   * @returns {Promise<Record<string, *>>}
   */
  async replaceOrCreateGroupIntegration(groupId, integrationId, configuration) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/integrations/${integrationId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(configuration),
    });
    return response.json();
  },

  /**
   * Delete group integration.
   *
   * @param {string} groupId
   * @param {string} integrationId
   * @returns {Promise<void>}
   */
  async deleteGroupIntegration(groupId, integrationId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/integrations/${integrationId}`, {
      method: 'DELETE',
    });
    return response.json();
  },
};

export default {
  alerts,
  config,
  devices,
  drivers,
  gtfs,
  logo,
  messages,
  stats,
  trips,
  vehicles,
  integrations,

  /**
   * change a password
   * @param {string} newPassword
   * @param {string} newPassword2
   * @param {string} token
   * @return {Promise<Response>}
   * @throws {Response}
   */
  async resetPassword(newPassword, newPassword2, token) {
    const formData = new FormData();
    formData.append('api', null);
    formData.append('token', token);
    formData.append('new_password', newPassword);
    formData.append('new_password2', newPassword2);
    const response = await retriedFetch('/api/v2/password/reset', {
      method: 'POST',
      body: formData,
    });
    return response;
  },

  /**
   * Check authentication validity.
   * @return {Promise<import('@/store').User>} Authenticated user's data.
   * @throws {Error} URL of login page.
   */
  async checkAuth() {
    /** @type {User|undefined} */
    let user;
    try {
      const response = await retriedFetch('/api/v3/users/me');
      user = await response.json();
    } catch {
      // Do nothing
    }

    if (!user) {
      throw new Error(`/login?next=${encodeURIComponent(window.location.pathname + window.location.hash)}`);
    }

    return user;
  },

  /**
   * Get activity log entries for a date.
   * @param {string} groupId
   * @param {string} date - in GTFS format.
   * @return {Promise<Array<import('@/store/activity-log').ActivityLogEntry>>}
   */
  async getActivityLog(groupId, date) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/duties?start_date=${date}`);
    return response.json();
  },

  /**
   * Get activity log entries for a date.
   * @param {string} groupId
   * @param {string} entryId
   * @return {Promise<import('@/store/activity-log').ActivityLogEntry>}
   */
  async getActivityLogEntry(groupId, entryId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/duties/${entryId}`);
    return response.json();
  },

  /**
   * @param {string} groupId
   * @param {Number} fromTs
   * @param {Number} toTs
   * @return {Promise<Array>}
   * @throws {Response}
   */
  async getAppEvents(groupId, fromTs, toTs) {
    const from = Math.floor(fromTs);
    const to = Math.floor(toTs);
    const response = await retriedFetch(
      `/api/v3/groups/${groupId}/info-session-counter?start_date=${from}&end_date=${to}`
    );
    return response.json();
  },

  /**
   * Get drivers' list of a group.
   * @param {string} groupId
   * @return {Promise<Array<import('@/store/drivers').Driver>>}
   * @throws {Response}
   */
  async getDriverList(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/drivers`);
    return response.json();
  },

  /**
   * Get group info.
   * @param {string} groupId
   * @return {Promise<import('@/store').Group>}
   * @throws {Response}
   */
  async getGroup(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}`);
    return response.json();
  },

  /**
   * Get groups list.
   * @return {Promise<Array<import('@/store').GroupMinimal>>}
   * @throws {Promise<Response>}
   */
  async getGroups() {
    const response = /** @type {Array<import('@/store').Group>} */ (
      await retriedFetch('/api/v2/groups').then(r => r.json())
    );
    if (!response) {
      return [];
    }

    return response.map(g => ({
      _id: g._id,
      name: g.name,
    }));
  },

  /**
   * Get oldest duty data available
   * @param {string} groupId
   * @returns {Promise<Array<import('@/store/activity-log').ActivityLogEntry>>}
   */
  async getOldestDutyData(groupId) {
    const response = await retriedFetch(
      `/api/v3/groups/${groupId}/duties?anonymized=false&sort=check_in:asc&limit=1`
    );
    return response.json();
  },

  /**
   * Get events for an area.
   * @param {string} groupId
   * @param {number} fromTs
   * @param {number} toTs
   * @param {number} minLat
   * @param {number} maxLat
   * @param {number} minLng
   * @param {number} maxLng
   * @return {Promise<Array<import('@/store/devices').Event>>}
   */
  async getPointHistory(groupId, fromTs, toTs, minLat, maxLat, minLng, maxLng) {
    const qParams = new URLSearchParams([
      ['from', fromTs],
      ['to', toTs],
      ['min_lat', minLat],
      ['max_lat', maxLat],
      ['min_lng', minLng],
      ['max_lng', maxLng],
    ]);
    const countEndpoint = `/api/v3/groups/${groupId}/history/events/count?${qParams}`;
    const countResponse = await retriedFetch(countEndpoint);
    const countResult = await countResponse.json();
    if (countResult.count > 150000) {
      throw new Error(TravelsTimesError.LIMITE_EXCEEDED);
    }
    const pages = [];

    let next = `/api/v3/groups/${groupId}/history/events?${qParams}`;
    let idx = 0;
    while (next && idx < 3) {
      const response = await retriedFetch(next);
      pages.push(response.json());
      next = null;

      const headerLink = response.headers.get('link');
      if (headerLink) {
        const links = LinkHeader.parse(headerLink);
        const headerNext = links.rel('next');

        if (headerNext.length > 0) {
          next = headerNext[0].uri;
        }
      }

      idx += 1;
    }

    const result = Promise.all(pages).then(results => results.shift().concat(...results));
    return result;
  },

  /**
   * @param {String} groupId
   * @return {Promise<Array<UserRole>>}
   * @throws {Response}
   */
  async getRoles(groupId) {
    const response = await retriedFetch(`/api/v3/groups/${groupId}/roles`);
    return response.json();
  },

  /**
   * @param {String} groupId
   * @return {Promise<UserRole>}
   * @throws {Response}
   */
  async updateRole(groupId, role) {
    const updates = { role: role.role, teams: role.teams };
    const response = await retriedFetch(`/api/v3/groups/${groupId}/roles/${role.user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    });
    return response.json();
  },

  /**
   * Delete an role.
   * @param {string} groupId
   * @param {string} roleId
   * @throws {Response}
   */
  async deleteRole(groupId, roleId) {
    await retriedFetch(`/api/v2/groups/${groupId}/roles/${roleId}`, {
      method: 'DELETE',
    });
  },

  /**
   * Get vehicles' list of a group.
   * @param {string} groupId
   * @return {Promise<Array<import('@/store/vehicles').Vehicle>>}
   * @throws {Response}
   */
  async getVehicleList(groupId) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/vehicle_list`);
    return response.json();
  },

  /**
   * Log out current user
   * @throws {Response}
   */
  async logout() {
    await retriedFetch('/api/v2/logout');
  },

  /**
   * request email to reset a password
   * @param {string} email
   * @param {string} captcha
   * @return {Promise<Response>}
   * @throws {Response}
   */
  async requestPasswordReset(email, captcha) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('api', null);
    formData.append('g-recaptcha-response', captcha);
    const response = await retriedFetch('/api/v2/password/reset', {
      method: 'POST',
      body: formData,
    });
    return response.json();
  },

  /**
   * Log a user
   * @param {string} email
   * @param {string} password
   * @return {Promise<Response>}
   * @throws {Response}
   */
  async sendAuth(email, password) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    const response = await retriedFetch('/api/v2/login', {
      method: 'POST',
      body: formData,
    });
    return response;
  },

  /**
   * @param {string} oldPassword
   * @param {string} newPassword
   * @param {string} confirmPassword
   */
  async passwordChange(oldPassword, newPassword, confirmPassword) {
    const form = new FormData();
    form.append('old_password', oldPassword);
    form.append('new_password', newPassword);
    form.append('new_password2', confirmPassword);

    await retriedFetch('/api/v2/password/change', {
      method: 'POST',
      body: form,
    });
  },

  /**
   * @param {import('@/store').Group} group
   * @return {Promise<import('@/store').Group>}
   */
  async putGroup(group) {
    const response = await retriedFetch(`/api/v2/groups/${group._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(group),
    });
    return response.json();
  },

  /**
   * @param {string} groupId
   * @param {Object} role
   * @return {Promise<Response>}
   * */
  async addRoles(groupId, role) {
    const response = await retriedFetch(`/api/v2/groups/${groupId}/roles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(role),
    });
    return response.json();
  },

  /**
   * @param {string} groupId
   * @param {Object} role
   * */
  async putRoles(groupId, role) {
    await retriedFetch(`/api/v2/groups/${groupId}/roles/${role.user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(role),
    });
  },

  /** @param {import('@/store').User} user */
  async putUser(user) {
    await retriedFetch(`/api/v2/users/${user._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
  },

  /**
   * @param {string} groupId
   * @param {import('@/store').Team} team
   * */
  async createNewTeam(groupId, team) {
    await retriedFetch(`/api/v3/groups/${groupId}/teams`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(team),
    });
  },

  /**
   * @param {string} groupId
   * @param {import('@/store').Team} team
   * */
  async modifyTeam(groupId, team) {
    await retriedFetch(`/api/v3/groups/${groupId}/teams/${team.team_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(team),
    });
  },
};

/**
 * WebSocket updates.
 * @class
 * @param {string} groupId
 * @param {WSCallback} onData
 */
export class OpWebSocket {
  constructor(groupId, onData) {
    this.groupId = groupId;
    this.onData = onData;
    this.ws = null;
    this.currentWSTimeout = null;
    this.wsProto = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
    this.url = `${this.wsProto}//${window.location.host}/api/v2/groups/${this.groupId}/updates/devices`;
    this.openWs();
    this.close = function close() {
      this.ws.onclose = function onClose() {
        console.log('WebSocket closed');
      };
      this.clearWsTimeout();

      this.ws.close();
    };
  }

  openWs() {
    this.ws = new WebSocket(this.url);
    this.ws.onerror = () => {
      console.warn('WebSocket closed, reopening in 5s');
      setTimeout(() => {
        this.openWs();
      }, 5000);
      this.ws.onclose = null;
    };
    this.ws.onopen = () => {
      console.log('WebSocket opened to: ', this.url);
    };
    if (this.onData) {
      this.ws.onmessage = e => {
        this.clearWsTimeout();
        this.currentWSTimeout = setTimeout(() => {
          this.ws.close();
          this.openWs();
          console.log('Websocket reload since no message timeout');
        }, 120000);

        this.onData(JSON.parse(e.data));
      };
    }
  }

  clearWsTimeout() {
    if (this.currentWSTimeout) {
      clearTimeout(this.currentWSTimeout);
    }
  }
}

/**
 * @typedef {Object} DailyDevices
 * @property {string} date - In GTFS format
 * @property {number} devices - Number of devices.
 */

/**
 * @typedef {Object} DailyPunctuality
 * @property {string} date - In GTFS format
 * @property {{[upperBound: string]: number}} delays - Number of passage by delay category.
 */

/**
 * @typedef {Object} Punctuality
 * @property {number} on_time
 * @property {number} too_early
 * @property {number} too_late
 * @property {number} on_time_percentage
 * @property {number} too_early_percentage
 * @property {number} too_late_percentage
 * @property {number} total_count
 */

/**
 * @typedef {Object} PunctualityByDay
 * @extends Punctuality
 * @property {string} start_date
 */

/**
 * @typedef {Object} PunctualityByRoute
 * @extends Punctuality
 * @property {string} route_id
 * @property {string} route_long_name
 * @property {string} route_short_name
 */

/**
 * @typedef {Object} GtfsSchedule
 * @property {string} _id
 * @property {number} archived
 * @property {string} group_id
 * @property {string} name
 * @property {string} mod_user
 * @property {string} mod_time Date in ISO format
 * @property {number} published
 * @property {number} scheduled
 * @property {boolean} blocked
 */

/**
 * @typedef {Object} TripKM
 * @property {string} date - In GTFS format
 * @property {string} [gtfs_id] - GTFS Id
 * @property {string} [gtfs_name] - GTFS name
 * @property {number} percent_of_theoretical_km_recorded - Percentage of theoretical kilometers recorded
 * @property {number} recorded_commercial_km - Recorded kilometers
 * @property {?number} reliable_km - Reliable kilometers
 * @property {string} [route_id] - Route Id
 * @property {string} [route_long_name] - Route long name
 * @property {string} [route_short_name] - Route short name
 * @property {number} theoretical_commercial_km - Theoretical kilometers
 */

/**
 * @typedef {Object} PassengerCounts
 * @property {number} alighting - Alighting (Total alighting)
 * @property {number} boarding - Default boarding count
 * @property {{[name: string]: number}} custom_boarding - Custom boarding counts
 * @property {number} total_boarding - Total boarding
 * @property {string} [date] - Date
 * @property {string} [route_id] - Route Id
 * @property {string} [route_long_name] - Route long name
 * @property {string} [route_short_name] - Route short name
 */

/**
 * @typedef {Object} StopTimeHistory
 * @property {string} _id
 * @property {number} delay
 * @property {string} device_id
 * @property {"arrival"|"departure"} event
 * @property {string} gtfs_id
 * @property {string} start_date
 * @property {string} stop_id
 * @property {number} stop_sequence
 * @property {string} trip_id
 * @property {number} ts
 * @property {boolean} [last_stop]
 */

/**
 * @typedef {Object} TripIncident
 * @see https://developer.zendesk.com/rest_api/docs/support/tickets#list-tickets
 */

/**
 * @typedef {Object} TripTracking
 * @property {string} [start_date] - start_date
 * @property {number} scheduled - scheduled
 * @property {number} tracked - tracked
 * @property {string} [gtfs_id] - gtfs_id
 * @property {string} [gtfs_name] - gtfs_name
 * @property {string} [route_id] - route_id
 * @property {string} [route_short_name] - route_short_name
 * @property {string} [route_long_name] - route_long_name
 * @property {number} [percent_tracked] - percent_tracked
 */

/**
 * WebSocket callback when data is received.
 * @callback WSCallback
 * @param {Array<import('@/store/devices').Device>} data
 */

/** @enum {string} */
export const Role = {
  ADMIN: 'admin',
  EXTERNAL_READER: 'external_reader',
  OPERATION_MANAGER: 'operator',
  READER: 'reader',
  DRIVER: 'driver',
};

/** @enum {string} */
export const TemporalityType = {
  UNDERWAY: 'current',
  COMPLETED: 'passed',
  SCHEDULED: 'future',
};

/** @enum {string} */
export const TripStatusType = {
  OK: 'ok',
  TRACKED: 'tracked',
  ROUTING: 'routing',
  UNTRACKED: 'untracked',
  PROBLEM: 'problem',
  NO_DATA: 'no data',
  SCHEDULED: 'scheduled',
};

/** @enum {string} */
export const UpdateType = {
  COMMENT: 'comment',
  DO_NOT_SERVE: 'stop_time_update',
  DELAY: 'delay',
  TRIP_CANCELED: 'schedule_relationship',
  STOP_INFO: 'stop_info',
};

/** @enum {string} */
export const UpdateTypeV2 = {
  COMMENT: 'comment',
  DO_NOT_SERVE: 'skipped_stop_sequences',
  DELAY: 'delay',
  TRIP_CANCELED: 'canceled',
  STOP_INFO: 'stop_infos',
};

/**
 * @typedef {Object} RegistrationCode
 * @property {string} code
 * @property {number} expire
 */

/**
 * @typedef {Object} Service
 * @property {number} recorded_stops
 * @property {number} planned_stops
 */

/**
 * @typedef {Object} StopInfo
 * @property {string} information
 * @property {string} stop_id
 * @property {string} stop_name
 * @property {number} stop_sequence
 */

/**
 * @typedef {Object} Team
 * @property {string} id
 * @property {string} color
 */

/**
 * @typedef {Object} TripListDevice
 * @property {string} id
 * @property {string} name
 * @property {string} [team_id]
 * @property {string} [team_color]
 */

/**
 * @typedef {Object} TripListDeviceV4
 * @property {string} id
 * @property {string} name
 * @property {Team} team
 * @property {?boolean} status
 */

/**
 * @typedef {Object} TripListGtfs
 * @property {string} id
 * @property {string} name
 * @property {string} publication_date - ISO format
 */

/**
 * @typedef {Object} TripListIncident
 * @property {string} incident_label
 * @property {{type: string, domain: string, id: number}} ticket
 */

/**
 * @typedef {Object} TripListItem
 * @property {string} arrival_stored
 * @property {string} arrival_time
 * @property {string} block_id
 * @property {?number | Array<?number>} delay
 * @property {string} departure_stored
 * @property {string} departure_time
 * @property {Array<TripListDevice>} device
 * @property {TripListStop} first_stop
 * @property {Array<TripListGtfs>} gtfs
 * @property {TripListStop} last_stop
 * @property {TripListPassengerCount} passenger_count
 * @property {number} percent_km
 * @property {TripListProblems} problems
 * @property {?number} reliable_km
 * @property {TripListRoute} route
 * @property {Service} service
 * @property {string} service_date - GTFS date
 * @property {TemporalityType} temporality
 * @property {number} tk
 * @property {string} trip_formatted_name
 * @property {string} trip_id
 * @property {TripStatusType} trip_status
 * @property {string} trip_team_id
 * @property {string} trip_team_color
 * @property {Array<TripListItem>} [trips]
 * @property {number} unreliable_km
 * @property {Array<Update>} [updates]
 * @property {number} vk
 * @property {number} vk_no_status
 * @property {Array<import('@/store/drivers').Driver>} drivers
 * @property {Array<import('@/store/vehicles').Vehicle>} vehicles
 *
 */

/**
 * @typedef {Object} TripListItemV4
 * @property {number} arrival_stored
 * @property {number} arrival_time
 * @property {string} block_id
 * @property {?number | Array<?number>} delay
 * @property {number} departure_stored
 * @property {number} departure_time
 * @property {Array<TripListDeviceV4>} devices
 * @property {TripListStop} first_stop
 * @property {Array<TripListGtfs>} gtfs
 * @property {TripListStop} last_stop
 * @property {TripListPassengerCount} passenger_count
 * @property {number} percent_km
 * @property {TripListProblems} problems
 * @property {?number} reliable_km
 * @property {TripListRoute} route
 * @property {Service} service
 * @property {string} service_date - GTFS date
 * @property {TemporalityType} temporality
 * @property {number} tk
 * @property {string} formatted_name
 * @property {string} id
 * @property {TripStatusType} status
 * @property {Team} team
 * @property {Array<TripListItemV4>} [trips]
 * @property {number} unreliable_km
 * @property {TripUpdatesV2} updates
 * @property {number} vk
 * @property {string} headsign
 * @property {Array<import('@/store/drivers').Driver>} drivers
 * @property {Array<import('@/store/vehicles').Vehicle>} vehicles
 */

/**
 * @typedef {Object} TripListPassengerCount
 * @property {number} [alightings]
 * @property {number} [boardings]
 * @property {number} [loading]
 */

/**
 * @typedef {Object} TripListProblems
 * @property {boolean} [distance]
 * @property {boolean} [kilometers]
 * @property {boolean} [time]
 */

/**
 * @typedef {Object} TripListRoute
 * @property {string} color
 * @property {string} id
 * @property {string} [long_name]
 * @property {string} [short_name]
 * @property {string} [text_color]
 */

/**
 * @typedef {Object} TripListStop
 * @property {string} code
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} KmStatsGroupByDay
 * @property {string} date - In GTFS format
 * @property {number} recorded_commercial_km
 * @property {number} theoretical_commercial_km
 * @property {number} percent_of_theoretical_km_recorded
 */

/**
 * @typedef {Object} KmStatsGroupByRoute
 * @property {string} gtfs_id
 * @property {string} [gtfs_name]
 * @property {string} route_id
 * @property {string} [route_short_name]
 * @property {string} [route_long_name]
 * @property {number} recorded_commercial_km
 * @property {number} theoretical_commercial_km
 * @property {number} percent_of_theoretical_km_recorded
 * /

/**
 * @typedef {Object} StopTimeUpdate
 * @property {string} stop_id
 * @property {string} stop_name
 * @property {number} stop_sequence
 * @property {string} schedule_relationship
 */

/**
 * @typedef {Object} Update
 * @property {Date} date
 * @property {string} source
 * @property {string} trip_id
 * @property {string} group_id
 * @property {string} gtfs_id
 * @property {string} start_date
 * @property {UpdateType} info_type
 * @property {Array<StopTimeUpdate> | Number | Array<TripListIncident> | String | import('@/store/trips').ScheduleRelationship | Array<StopInfo> } content
 */

/**
 * @typedef {Object} UserRole
 * @property {Role} role
 * @property {Array<string>} [teams]
 * @property {string} user_id
 * @property {string} _id
 */

/**
 * @typedef {Object} TripUpdatesV2
 * @property {string} comment
 * @property {number} delay
 * @property {boolean} canceled
 * @property {Array<number>} skipped_stop_sequences
 * @property {Array<StopInfoV2>} stop_infos
 */

/**
 * @typedef {Object} StopInfoV2
 * @property {number} stop_sequence
 * @property {string} information
 */

/**
 * @typedef {Object} Config
 * @property {Semver} [semver]
 */

/**
 * Semver object coming from API.
 * @typedef {{deprecated: string[], to_update: string[]}} Semver
 */
