import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const CellProgress = defineAsyncComponent(() => import('@/components/Table/cells/CellProgress.vue'));

/** @enum {string} */
export const ColumnKey = {
  ID: 'group_id',
  NAME: 'name',
  TRIP_TRACKING_CATEGORY: 'tripTrackingCategory',
};

/** @enum {string} */
export const TripTrackingCategory = {
  LOW: 'low',
  MID: 'mid',
  HIGH: 'high',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'adminTripTrackingDatagrid',
    searchFields: [ColumnKey.NAME, ColumnKey.ID],
    defaultSortBy: [{ key: ColumnKey.NAME, order: SortOrder.ASC }],
    rowIdKey: 'group_id',
    hasActions: false,
    showColumnSelector: false,

    columns: [
      // ID
      new DataGridColumn({
        key: ColumnKey.ID,
        title: 'column.groupId',
        selectable: false,
      }),

      // NAME
      new DataGridColumn({
        key: ColumnKey.NAME,
        title: 'column.groupName',
        selectable: false,
      }),

      // TRIP TRACKING
      new DataGridColumn({
        key: ColumnKey.TRIP_TRACKING_CATEGORY,
        title: 'column.tripTracking',
        selectable: false,
        filterable: true,
        sortable: false,
        i18nPrefix: 'tripTrackingCategory',
        cellBuilder([value, object]) {
          let color = '#00894e'; // $primary-dark
          if (value === TripTrackingCategory.LOW) {
            color = '#a41414'; // $danger-dark
          } else if (value === TripTrackingCategory.MID) {
            color = '#00b871'; // $primary-light
          }
          return {
            component: CellProgress,
            props: {
              value: object.tripTracking,
              color,
            },
            events: {},
          };
        },
      }),
    ],
  });
};
