import mapboxgl from 'mapbox-gl';

export const MapboxHelper = {
  /**
   * Check if already exist, then add an empty source with specific name id
   * @param {mapboxgl.Map} map
   * @param {string} sourceId
   */
  createEmptySource(map, sourceId) {
    if (!map?.getSource(sourceId))
      map?.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
  },
  /**
   * Check if already exist, then add a layer with specific name id
   * @param {mapboxgl.Map} map
   * @param {mapboxgl.Layer} layerObject
   */
  addLayer(map, layerObject) {
    if (!map?.getLayer(layerObject.id)) {
      map?.addLayer(layerObject);
    }
  },
  /**
   * Update the feature object of a source item
   * @param {mapboxgl.Map} map
   * @param {string} sourceId
   * @param {Array<GeoJSON.Feature>} features
   * @returns
   */
  updateSource(map, sourceId, features) {
    if (!map?.getSource(sourceId)) {
      this.createEmptySource(map, sourceId);
    }
    /** @type {mapboxgl.GeoJSONSource} */ (map.getSource(sourceId)).setData({
      type: 'FeatureCollection',
      features,
    });
  },

  /**
   * Remove layers and source from map object
   * @param {mapboxgl.Map} map
   * @param {Array<string>} layersIds
   * @param {Array<string>} sourcesIds
   */
  cleanLayersAndSources(map, layersIds, sourcesIds) {
    const onError = () => {};
    map.on('error', onError);
    layersIds.forEach(layerName => map.removeLayer(layerName));
    sourcesIds.forEach(sourceName => map.removeSource(sourceName));
    map.off('error', onError);
  },
  /**
   * Remove image from map object
   * @param {mapboxgl.Map} map
   * @param {string} imageName
   */
  cleanImage(map, imageName) {
    if (map?.hasImage(imageName)) {
      map?.removeImage(imageName);
    }
  },
  /**
   * Add an image to map Object
   * @param {mapboxgl.Map} map
   * @param {string} imagePath
   * @param {string} imageName
   */
  addImage(map, imagePath, imageName) {
    map?.loadImage(imagePath, (error, image) => {
      if (error) console.warn(error);
      map?.addImage(imageName, image);
    });
  },
  /**
   * Save layers to localStorage
   * @param {string} name
   * @param {import('@/components/map/MapLayersDropdown.vue').LayerOptions} object
   */
  saveLayerOptionstoLS(name, object) {
    localStorage.setItem(name, JSON.stringify(object));
  },

  /**
   *
   * @param {string} name
   * @returns {import('@/components/map/MapLayersDropdown.vue').LayerOptions}
   */
  getLayersOptionsFromLS(name) {
    const savedItem = localStorage.getItem(name);
    if (savedItem) {
      const storage = JSON.parse(savedItem);
      const layerObject = {};
      layerObject.vehicles = true;
      if (storage.vehiclesLabels !== null) layerObject.vehiclesLabels = storage.vehiclesLabels;
      if (storage.stops !== null) layerObject.stops = storage.stops;
      if (storage.stations !== null) layerObject.stations = storage.stations;
      if (storage.traffic !== null) layerObject.traffic = storage.traffic;
      if (storage.linesShapes !== null) layerObject.linesShapes = storage.linesShapes;
      if (storage.stopsZones !== null) layerObject.stopsZones = storage.stopsZones;
      if (Object.keys(layerObject).length === 0) return null;
      return layerObject;
    }
    return null;
  },

  /**
   * Display a tooltip based on a click event
   * @param {any} event
   * @param {mapboxgl.Map} map
   * @param {string} tooltipClassName
   */
  displayTooltipOnClick(event, map, tooltipClassName) {
    // remove potential previous tooltip
    MapboxHelper.removeAllTooltips(map);
    // Check if source has a description, otherwise display nothing
    const description = event.features[0].properties.description;
    if (description) {
      // Copy coordinates array.
      const coordinates = event.features[0].geometry.coordinates.slice();

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup({
        className: tooltipClassName,
        closeButton: false,
        anchor: 'top',
      })
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);
    }
  },
  /**
   * remove potential existing tooltips
   * @param {mapboxgl.Map} map
   */
  removeAllTooltips(map) {
    if (map) {
      // access private _popups since mapbox does not have any getPopups method...
      if (map._popups?.length > 0) map._popups.forEach(popup => popup.remove());
    }
  },
};
