<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
import { setLocale } from 'yup';
import { useToast } from 'vue-toastification';
import detectLang from '@/libs/detect-lang';
import i18n from '@/i18n';
import { GroupRoute, HomeRoute } from '@/libs/routing';
import yupFr from '@/locales/yup/fr';
import yupEn from '@/locales/yup/en';
import { setApiErrorHandler, toastApiErrorHandlerFactory } from '@/api';

const toast = useToast();
const apiErrorHandler = toastApiErrorHandlerFactory(toast);
setApiErrorHandler(apiErrorHandler);

/**
 * Main component
 * @vue
 */
export default {
  name: 'App',

  watch: {
    '$route.name': function routeName() {
      this.trackView();
    },

    '$i18n.locale': function i18nLocale() {
      document.getElementsByTagName('html')[0].lang = this.$i18n.locale;
    },

    // used by GTM for Intercom
    '$store.state.user': function storeStateUser() {
      window.userEmail = this.$store.state.user.email;
    },
  },

  created() {
    // Initialize localization
    const locale = localStorage.getItem('locale') || detectLang(this.$i18n.availableLocales);
    if (locale) {
      i18n.global.locale = locale;
      if (locale === 'fr') {
        setLocale(yupFr);
      } else {
        setLocale(yupEn);
      }
    }

    this.trackView();
  },

  methods: {
    // send pageview to analytics with url formed as: /op2/route-name
    trackView() {
      const isViewIgnored = [HomeRoute.GROUP, HomeRoute.HOME].includes(this.$route.name);

      if (this.$matomo && !isViewIgnored) {
        // handle specific case of reports
        let url = '';
        if (this.$route.matched[1]?.name === GroupRoute.REPORTS) {
          url = `/op2/reporting/${this.$route.matched[2]?.name}`;
        } else {
          url = `/op2/${this.$route.name}`;
        }
        this.$matomo.trackPageView(url);
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" src="./assets/scss/base.scss"></style>
<style>
.app {
  height: 100%;
}
</style>
