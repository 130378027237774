<template>
  <Modal class="modal-import-vehicles" @close="closeModal">
    <template #title>
      {{ $t('importVehicles') }}
    </template>

    <template #body>
      <div class="modal-import-vehicles__body">
        <span>{{ $t('importVehicle') }}</span>
        <a :href="$t('link')" class="modal-import-vehicles__link" target="_blank">
          {{ $t('documentation') }}
        </a>

        <div v-if="fileCompared === true" class="modal-import-vehicles__comparision">
          <div>{{ $t('vehiclesToAdd', [vehiclesToAdd]) }}</div>
          <div>{{ $t('vehiclesToArchive', [vehiclesToArchive]) }}</div>
          <div>{{ $t('vehiclesToEdit', [vehiclesToEdit]) }}</div>
        </div>

        <div v-else-if="apiErrorMessages.importVehicle">
          <div
            v-for="[errorCode, errorList] of Object.entries(apiErrorMessages.importVehicle)"
            :key="errorCode"
            class="warning"
          >
            <p>
              <strong>{{ $t(`apiErrors.${errorCode}.title`) }}</strong>
            </p>
            <p v-for="({ value }, index) in errorList" :key="index">
              <code>{{ $t(`apiErrors.${errorCode}.body`, value) }}</code>
            </p>
          </div>
        </div>

        <div v-else-if="fileComparing" class="modal-import-vehicles__loading">
          <AnimatedDots />
        </div>

        <div class="modal-import-vehicles__input">
          <input
            id="csv_file"
            accept=".csv"
            type="file"
            name="csv_file"
            class="form-control"
            @change="compareFile($event)"
          />
        </div>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" :disabled="!fileCompared" @click="submitImportCsv">
        {{ $t('submit') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import api from '@/api';
import Modal from '@/components/layout/Modal.vue';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import Btn from '@/components/ui/Btn.vue';

import ApiErrorsMixin from './ApiErrorsMixin';

export default {
  name: 'ModalImportVehicle',

  components: {
    AnimatedDots,
    Btn,
    Modal,
  },

  mixins: [ApiErrorsMixin],

  props: {
    body: {
      default: '',
      type: String,
    },
  },

  emits: ['close'],

  data() {
    return {
      csvFile: null,
      /** @type {boolean} */
      fileCompared: false,
      /** @type {boolean} */
      fileComparing: false,
      /** @type {number} */
      vehiclesToAdd: 0,
      /** @type {number} */
      vehiclesToArchive: 0,
      /** @type {number} */
      vehiclesToEdit: 0,
    };
  },

  methods: {
    /** Reset all variable used for csv import */
    cleanCsv() {
      this.vehiclesToEdit = 0;
      this.vehiclesToAdd = 0;
      this.vehiclesToArchive = 0;
      this.fileCompared = false;
      this.csvFile = null;
      this.unsetApiError('importVehicle');
    },

    closeModal() {
      this.cleanCsv();
      this.$emit('close');
    },

    /**
     * @param {event} e
     * Compare csv uploaded file data with current vehicle list
     */
    async compareFile(e) {
      if (!e.target.files[0]) {
        this.unsetApiError('importVehicle');
        this.fileCompared = false;
        return;
      }
      // File is not compared yet
      this.fileCompared = false;
      // File is being compared
      this.fileComparing = true;
      // @ts-ignore
      this.unsetApiError('importVehicle');
      // Get csv file from user event
      // @ts-ignore
      [this.csvFile] = e.target.files;

      // Send csv file to the API and get results
      api.vehicles
        .uploadCsv(this.$store.getters.group._id, this.csvFile, true)
        .then(data => {
          // Update data in component
          this.vehiclesToAdd = data.vehicles_to_add;
          this.vehiclesToEdit = data.vehicles_to_update;
          this.vehiclesToArchive = data.vehicles_to_archive;
          // File is now compared
          this.fileCompared = true;
        })
        .catch(e => {
          if (e && e.errors) {
            // @ts-ignore
            this.setApiError('importVehicle', e.errors);
          }
        })
        .finally(() => {
          this.fileComparing = false;
        });
    },

    /** Send request to the api to import csv file */
    async submitImportCsv() {
      if (!this.csvFile) return;

      this.$store
        .dispatch('vehicles/uploadCsv', this.csvFile)
        .then(() => {
          this.$emit('close');
          // @ts-ignore
          this.unsetApiError('importVehicle');
        })
        .catch(e => {
          if (!(e && e.errors)) return;
          // @ts-ignore
          this.setApiError('importVehicle', e.errors);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-import-vehicles {
  &__body {
    margin-bottom: 15px;
  }

  &__comparision {
    margin: 20px 0;
  }

  &__input {
    margin-top: 10px;
  }

  &__link {
    color: $text-dark-variant;
    text-decoration: underline;
  }

  &__loading {
    margin: 20px 0;
  }
}
</style>

<i18n locale="fr">
{
  "documentation": "documentation en ligne",
  "importVehicle": "Pour importer votre fichier, veuillez consulter la ",
  "importVehicles": "Importer des véhicules",
  "link": "https://support.pysae.com/fr/articles/4768315-gestion-des-vehicules",
  "vehiclesToAdd": "Véhicules à ajouter : {0}",
  "vehiclesToArchive": "Véhicules à archiver : {0}",
  "vehiclesToEdit": "Véhicules à mettre à jour : {0}",

  "apiErrors": {
    "ID_NOT_IN_DATABASE": {
      "title": "Ids invalides",
      "body": "Certains Ids ne sont pas présents dans la base de données"
    },
    "INVALID_CSV_DELIMITER": {
      "title": "Délimiteur CSV invalide",
      "body": "N'accepte que les points-virgules [;] comme délimiteurs csv."
    },
    "MISSING_COLUMN": {
      "title": "Colonne manquante",
      "body": "Il manque une ou plusieurs colonnes dans le fichier csv"
    },
    "MISSING_DATA": {
      "title": "Données manquantes",
      "body": "Il manque une ou plusieurs données dans le fichier csv"
    },
    "NO_CSV_DELIMITER": {
      "title": "Pas de délimiteur CSV",
      "body": "Pas de délimiteurs sur la ligne {line}."
    },
    "DUPLICATED_PLATES": {
      "title": "Plaques dupliquées",
      "body": "Les plaques suivantes sont présentes plusieurs fois dans le fichier téléchargé {duplicated_plates}. Veuillez supprimer les doublons du csv et réessayer."
    }
  }
}
</i18n>
<i18n locale="en">
{
  "documentation": "online documentation",
  "importVehicle": "To import your file, please refer to the ",
  "importVehicles": "Import vehicles",
  "link": "https://support.pysae.com/en/articles/4768315-gestion-des-vehicules",
  "vehiclesToAdd": "Vehicle to add: {0}",
  "vehiclesToArchive": "Vehicle to archive: {0}",
  "vehiclesToEdit": "Vehicle to update: {0}",

  "apiErrors": {
    "ID_NOT_IN_DATABASE": {
      "title": "Invalid Ids",
      "body": "Some Ids are not present in the database"
    },
    "INVALID_CSV_DELIMITER": {
      "title": "Invalid CSV delimiter",
      "body": "Only accepts semicolons [;] as csv delimiters."
    },
    "MISSING_COLUMN": {
      "title": "Missing column",
      "body": "There are one or more columns missing"
    },
    "MISSING_DATA": {
      "title": "Missing data",
      "body": "There are one or more required data missing"
    },
    "NO_CSV_DELIMITER": {
      "title": "No CSV delimiter",
      "body": "No delimiters at line {line}."
    },
    "DUPLICATED_PLATES": {
      "title": "Duplicate plates",
      "body": "The following plates are present several times in the downloaded file {duplicated_plates}. Please remove the duplicates from the csv and try again."
    }
  }
}
</i18n>
