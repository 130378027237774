<template>
  <div class="layout">
    <NavBarLeft />
    <div class="layout__right">
      <NavBarTop />
      <div class="layout__bottom">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import NavBarLeft from './NavBarLeft.vue';
import NavBarTop from './NavBarTop.vue';

/**
 * Handle app navigation
 * @vue
 */
export default {
  name: 'MainLayout',

  components: {
    NavBarLeft,
    NavBarTop,
  },

  created() {
    this.$store.dispatch('groupsUpdate');
  },
};
</script>

<style lang="scss" scoped>
/**
 * layout
 *   __right
 *   __bottom
 */
.layout {
  display: flex;
  height: 100%;

  &__right {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    height: 100%;
  }

  &__bottom {
    flex: 1;
    overflow: auto;
  }
}
</style>
