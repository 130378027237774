import { defineAsyncComponent } from 'vue';
import { TripStatusType } from '@/api';
import store, { Permission } from '@/store';
import { GroupRoute } from '@/libs/routing';

import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { TagTypes } from '@/components/ui/Tag.vue';

const CellDelay = defineAsyncComponent(() => import('./cells/CellDelay.vue'));
const CellDevice = defineAsyncComponent(() => import('./cells/CellDevice.vue'));
const CellDropList = defineAsyncComponent(() => import('@/components/Table/cells/DropListCell.vue'));
const CellGtfs = defineAsyncComponent(() => import('./cells/CellGtfs.vue'));
const CellIncidents = defineAsyncComponent(() => import('./cells/CellIncidents.vue'));
const CellMessages = defineAsyncComponent(() => import('./cells/CellMessages.vue'));
const CellProgress = defineAsyncComponent(() => import('./cells/CellProgress.vue'));
const CellStatus = defineAsyncComponent(() => import('./cells/CellStatus.vue'));
const CellStopInfo = defineAsyncComponent(() => import('./cells/CellStopInfo.vue'));
const CellTripModification = defineAsyncComponent(() => import('./cells/CellTripModification.vue'));

const RouteBadgeV2 = defineAsyncComponent(() =>
  import('@/components/Table/DataGridVuetify/cellsV2/RouteBadge.vue')
);
const CommentCell = defineAsyncComponent(() => import('@/components/Table/cells/CommentCell.vue'));
const DateCell = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));
const TeamDisplay = defineAsyncComponent(() => import('@/components/ui/SingleTeamSelector.vue'));

const NO_DATA = '-';

export const STATUSES = {
  [TripStatusType.OK]: {
    localeKey: 'over',
    icon: 'fa-check-circle',
    color: TagTypes.SUCCESS.color,
    backgroundColor: TagTypes.SUCCESS.backgroundColor,
  },

  [TripStatusType.TRACKED]: {
    localeKey: 'tracked',
    icon: 'fa-bus',
    color: TagTypes.SUCCESS.color,
    backgroundColor: TagTypes.SUCCESS.backgroundColor,
  },

  [TripStatusType.ROUTING]: {
    localeKey: 'routing',
    icon: 'fa-bus',
    color: TagTypes.SUCCESS.color,
    backgroundColor: TagTypes.SUCCESS.backgroundColor,
  },

  [TripStatusType.UNTRACKED]: {
    localeKey: 'untracked',
    icon: 'fa-circle-exclamation',
    color: TagTypes.DANGER.color,
    backgroundColor: TagTypes.DANGER.backgroundColor,
  },

  [TripStatusType.PROBLEM]: {
    localeKey: 'problem',
    icon: 'fa-circle-exclamation',
    color: TagTypes.WARNING.color,
    backgroundColor: TagTypes.WARNING.backgroundColor,
  },

  [TripStatusType.NO_DATA]: {
    localeKey: 'noData',
    icon: 'fa-circle-question',
    color: TagTypes.NEUTRAL.color,
    backgroundColor: TagTypes.NEUTRAL.backgroundColor,
  },

  [TripStatusType.SCHEDULED]: {
    localeKey: 'scheduled',
    icon: 'fa-clock',
    color: TagTypes.NEUTRAL.color,
    backgroundColor: TagTypes.NEUTRAL.backgroundColor,
  },
};

export const TRIPS_LS_COLUMNS = 'tripListNew/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  ARRIVAL_TIME: 'arrivalTimeValue',
  COMMENT: 'commentValue',
  DELAY: 'delay',
  DEPARTURE_DATE: 'departure_time',
  DEPARTURE_TIME: 'departureTimeValue',
  DETOUR_KM: 'detour km',
  DEVICE: 'devicesName',
  DEVICE_TEAM: 'deviceTeamName',
  DRIVERS: 'formattedDrivers',
  DUTY: 'block_id',
  FIRST_STOP: 'firstStopValue',
  GTFS: 'gtfsValue',
  INCIDENTS: 'incidents',
  LAST_STOP: 'lastStopValue',
  MESSAGES: 'messages',
  PASSENGER_COUNT_ALIGHTINGS: 'passengerCountAlightingsValue',
  PASSENGER_COUNT_BOARDINGS: 'passengerCountBoardingValue',
  PASSENGER_COUNT_LOADING: 'passengerCountLoadingValue',
  PERCENT_KM: 'percentKmValue',
  RECORDED_ARRIVAL_TIME: 'recordedArrivalTimeValue',
  RECORDED_DEPARTURE_TIME: 'recordedDepartureTimeValue',
  ROUTE: 'routeValue',
  SERVICE: 'serviceValue',
  STOP_INFO: 'stopInfoValue',
  TAB_TRIP_STATUS: 'tabTripStatus',
  TK: 'tkValue',
  TRIP_HEADSIGN: 'trip_headsign',
  TRIP_FORMATTED_NAME: 'trip_formatted_name',
  TRIP_MODIFICATION: 'updates',
  TRIP_STATUS: 'tripStatusValue',
  TRIP_TEAM: 'tripTeamName',
  UNRELIABLE_KM: 'unreliableKmValue',
  VALIDATION: 'validation',
  VEHICLES: 'formattedVehicles',
  VK: 'vkOrReliableKm',
  VK_NO_STATUS: 'vkNoStatusValue',
};

/** @enum {import('@/components/Table/DataGrid/models/DataGrid.models').CellBuilder} */
const CellBuilders = {
  STOP([value, object], { groupId, stopKey }) {
    if (!value) return null;
    return {
      component: 'router-link',
      events: {},
      props: {
        to: {
          name: GroupRoute.STOP_DETAILED,
          params: { groupId, stopId: object[stopKey].id },
          query: { date: object.departure_time.slice(0, 10) },
        },
      },
      value,
    };
  },
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(TRIPS_LS_COLUMNS));

  // Helpers
  const defaultNotSelected = columnType => ls && ls.includes(columnType);
  const defaultSelected = columnType => !ls || ls.includes(columnType);

  return new DataGrid({
    name: 'tripListDatagrid',
    searchFields: ['trip_formatted_name'],
    defaultSortBy: [{ key: ColumnKey.DEPARTURE_DATE, order: SortOrder.ASC }],
    rowIdKey: 'trip_id',
    columnSelectionLocalStorageKey: TRIPS_LS_COLUMNS,
    hasActions: false,

    columns: [
      // TRIP_FORMATTED_NAME
      new DataGridColumn({
        key: ColumnKey.TRIP_FORMATTED_NAME,
        title: 'columnTripList.tripName',
        defaultSelected: defaultSelected(ColumnKey.TRIP_FORMATTED_NAME),
        selectable: false,
        cellBuilder([value, object], { groupId, tripId }) {
          return store.getters.hasPermission(Permission.VIEW_TRIP_VIEW)
            ? {
                component: 'RouterLink',
                events: {},
                props: {
                  to: {
                    name: GroupRoute.TRIP_DETAILED,
                    params: { groupId, tripId },
                    query: { date: object.tripDate },
                  },
                },
                value,
              }
            : { value, events: {} };
        },
      }),

      // TRIP STATUS
      new DataGridColumn({
        key: ColumnKey.TRIP_STATUS,
        title: 'columnTripList.tripStatus',
        defaultSelected: defaultSelected(ColumnKey.TRIP_STATUS),
        filterable: true,
        selectable: false,
        cellBuilder([value, object], { toggleChildren }) {
          return object.trip_status || object.trips
            ? {
                component: CellStatus,
                props: {
                  status: object.trip_status,
                  problems: object.problems,
                  hasChildren: !!object.trips,
                },
                events: { toggleChildren },
                value: object.trip_status,
              }
            : {
                value: NO_DATA,
                events: {},
              };
        },
      }),

      // ROUTE
      new DataGridColumn({
        key: ColumnKey.ROUTE,
        title: 'columnTripList.route',
        defaultSelected: defaultSelected(ColumnKey.ROUTE),
        filterable: true,
        cellBuilder([value, object]) {
          const route = object.route;
          return {
            component: RouteBadgeV2,
            events: {},
            props: {
              route: {
                route_color: route.color,
                route_text_color: route.text_color,
              },
              value,
            },
          };
        },
      }),

      // PERCENT_KM
      new DataGridColumn({
        key: ColumnKey.PERCENT_KM,
        title: 'columnTripList.progression',
        defaultSelected: defaultSelected(ColumnKey.PERCENT_KM),
        cellBuilder([value]) {
          if (!value) return null;
          return {
            component: CellProgress,
            events: {},
            props: {
              value,
            },
          };
        },
      }),

      // DEPARTURE_DATE
      new DataGridColumn({
        key: ColumnKey.DEPARTURE_DATE,
        title: 'columnTripList.departureDate',
        defaultSelected: defaultNotSelected(ColumnKey.DEPARTURE_DATE),
        cellBuilder([value]) {
          if (!value) return null;
          return {
            component: DateCell,
            events: {},
            props: {
              date: new Date(value),
              options: { dateStyle: 'short' },
            },
          };
        },
      }),

      // GTFS
      new DataGridColumn({
        key: ColumnKey.GTFS,
        title: 'columnTripList.transportPlan',
        defaultSelected: defaultNotSelected(ColumnKey.GTFS),
        /** @param {[import('@/api').TripListItem['gtfs']]} apiValues */
        cellBuilder([value]) {
          return {
            component: CellGtfs,
            events: {},
            props: { gtfs: value },
          };
        },
      }),

      // TRIP_TEAM
      ...(store.getters.hasPermission(Permission.VIEW_TEAM_COLUMN)
        ? [
            new DataGridColumn({
              key: ColumnKey.TRIP_TEAM,
              title: 'columnTripList.tripTeam',
              defaultSelected: defaultNotSelected(ColumnKey.TRIP_TEAM),
              filterable: true,
              cellBuilder([value, object]) {
                if (!value) return null;
                return {
                  component: TeamDisplay,
                  events: {},
                  props: {
                    teamId: object.trip_team_id,
                    color: object.trip_team_color,
                  },
                };
              },
            }),
          ]
        : []),

      // DUTY
      new DataGridColumn({
        key: ColumnKey.DUTY,
        title: 'columnTripList.duty',
        defaultSelected: defaultNotSelected(ColumnKey.DUTY),
        filterable: true,
      }),

      // DEVICE
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_DEVICES)
        ? [
            new DataGridColumn({
              key: ColumnKey.DEVICE,
              title: 'columnTripList.device',
              defaultSelected: defaultNotSelected(ColumnKey.DEVICE),
              filterable: true,
              cellBuilder([value, object], { groupId, date }) {
                if (!value || value.length === 0) return null;
                return {
                  component: CellDevice,
                  events: {},
                  props: {
                    date,
                    device: object.device,
                    groupId,
                  },
                };
              },
            }),
          ]
        : []),

      // DEVICE_TEAM
      new DataGridColumn({
        key: ColumnKey.DEVICE_TEAM,
        title: 'columnTripList.deviceTeam',
        defaultSelected: defaultNotSelected(ColumnKey.DEVICE_TEAM),
        filterable: true,
        cellBuilder([value, object]) {
          if (!value || value.length === 0) return null;
          return {
            component: TeamDisplay,
            events: {},
            props: {
              teamId: object.deviceTeamId,
              color: object.deviceTeamColor,
            },
          };
        },
      }),

      // DELAY
      new DataGridColumn({
        key: ColumnKey.DELAY,
        title: 'columnTripList.delay',
        defaultSelected: defaultNotSelected(ColumnKey.DELAY),
        cellBuilder([value, object]) {
          if (!value || value.every(delay => delay === 0)) return null;
          return {
            component: CellDelay,
            events: {},
            props: { delay: value },
          };
        },
      }),

      // VK
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_KM)
        ? [
            new DataGridColumn({
              key: ColumnKey.VK,
              title: 'columnTripList.vk',
              defaultSelected: defaultNotSelected(ColumnKey.VK),
            }),
          ]
        : []),

      // TK
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_KM)
        ? [
            new DataGridColumn({
              key: ColumnKey.TK,
              title: 'columnTripList.tk',
              defaultSelected: defaultNotSelected(ColumnKey.TK),
            }),
          ]
        : []),

      // UNRELIABLE KM
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_KM)
        ? [
            new DataGridColumn({
              key: ColumnKey.UNRELIABLE_KM,
              title: 'columnTripList.unreliable_km',
              defaultSelected: defaultNotSelected(ColumnKey.UNRELIABLE_KM),
            }),
          ]
        : []),

      // VK NO STATUS
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_KM)
        ? [
            new DataGridColumn({
              key: ColumnKey.VK_NO_STATUS,
              title: 'columnTripList.vkNoStatus',
              defaultSelected: defaultNotSelected(ColumnKey.VK_NO_STATUS),
            }),
          ]
        : []),

      // TRIP HEADSIGN
      new DataGridColumn({
        key: ColumnKey.TRIP_HEADSIGN,
        title: 'columnTripList.headsign',
        defaultSelected: defaultNotSelected(ColumnKey.TRIP_HEADSIGN),
      }),

      // INCIDENTS
      new DataGridColumn({
        key: ColumnKey.INCIDENTS,
        title: 'columnTripList.incidents',
        defaultSelected: defaultNotSelected(ColumnKey.INCIDENTS),
        cellBuilder([value], { showModal }) {
          return {
            component: CellIncidents,
            props: {
              hasChildren: !!value,
              hasPermission: store.getters.hasPermission(Permission.EDIT_INCIDENTS),
              incidents: [],
            },
            events: { showModal },
          };
        },
      }),

      // SERVICE
      new DataGridColumn({
        key: ColumnKey.SERVICE,
        title: 'columnTripList.service',
        defaultSelected: defaultNotSelected(ColumnKey.SERVICE),
        cellBuilder([value, object]) {
          if (!object.service) return { value: NO_DATA, events: {} };
          return {
            component: CellProgress,
            events: {},
            props: {
              value,
              service: object.service,
            },
          };
        },
      }),

      // FIRST_STOP
      new DataGridColumn({
        key: ColumnKey.FIRST_STOP,
        title: 'columnTripList.firstStop',
        defaultSelected: defaultNotSelected(ColumnKey.FIRST_STOP),
        cellBuilder: CellBuilders.STOP,
      }),

      // DEPARTURE_TIME
      new DataGridColumn({
        key: ColumnKey.DEPARTURE_TIME,
        title: 'columnTripList.departureTime',
        defaultSelected: defaultNotSelected(ColumnKey.DEPARTURE_TIME),
      }),

      // RECORDED_DEPARTURE_TIME
      new DataGridColumn({
        key: ColumnKey.RECORDED_DEPARTURE_TIME,
        title: 'columnTripList.recordedDepartureTime',
        defaultSelected: defaultNotSelected(ColumnKey.RECORDED_DEPARTURE_TIME),
      }),

      // LAST_STOP
      new DataGridColumn({
        key: ColumnKey.LAST_STOP,
        title: 'columnTripList.lastStop',
        defaultSelected: defaultNotSelected(ColumnKey.LAST_STOP),
        cellBuilder: CellBuilders.STOP,
      }),

      // ARRIVAL_TIME
      new DataGridColumn({
        key: ColumnKey.ARRIVAL_TIME,
        title: 'columnTripList.arrivalTime',
        defaultSelected: defaultNotSelected(ColumnKey.ARRIVAL_TIME),
      }),

      // RECORDED_ARRIVAL_TIME
      new DataGridColumn({
        key: ColumnKey.RECORDED_ARRIVAL_TIME,
        title: 'columnTripList.recordedArrivalTime',
        defaultSelected: defaultNotSelected(ColumnKey.RECORDED_ARRIVAL_TIME),
      }),

      // DRIVERS
      new DataGridColumn({
        key: ColumnKey.DRIVERS,
        value: item => (item.formattedDrivers ? item.formattedDrivers[0].text : NO_DATA),
        title: 'columnTripList.driver',
        sortable: false,
        defaultSelected: defaultNotSelected(ColumnKey.DRIVERS),
        cellBuilder([value, object]) {
          if (!object.formattedDrivers) return null;
          return {
            component: CellDropList,
            props: { values: object.formattedDrivers },
            events: {},
          };
        },
      }),

      // VEHICLES
      new DataGridColumn({
        key: ColumnKey.VEHICLES,
        value: item => (item.formattedVehicles ? item.formattedVehicles[0].text : NO_DATA),
        title: 'columnTripList.vehicle',
        sortable: false,
        defaultSelected: defaultNotSelected(ColumnKey.VEHICLES),
        cellBuilder([value, object]) {
          if (!object.formattedVehicles) return null;
          return {
            component: CellDropList,
            props: { values: object.formattedVehicles },
            events: {},
          };
        },
      }),

      // PASSENGER COUNT BOARDINGS
      new DataGridColumn({
        key: ColumnKey.PASSENGER_COUNT_BOARDINGS,
        title: 'columnTripList.passengerCountBoardings',
        defaultSelected: defaultNotSelected(ColumnKey.PASSENGER_COUNT_BOARDINGS),
      }),

      // PASSENGER COUNT ALIGHTINGS
      new DataGridColumn({
        key: ColumnKey.PASSENGER_COUNT_ALIGHTINGS,
        title: 'columnTripList.passengerCountAlightings',
        defaultSelected: defaultNotSelected(ColumnKey.PASSENGER_COUNT_ALIGHTINGS),
      }),

      // PASSENGER COUNT LOADING
      new DataGridColumn({
        key: ColumnKey.PASSENGER_COUNT_LOADING,
        title: 'columnTripList.passengerCountLoading',
        defaultSelected: defaultNotSelected(ColumnKey.PASSENGER_COUNT_LOADING),
      }),

      // TRIP MODIFICATION
      new DataGridColumn({
        key: ColumnKey.TRIP_MODIFICATION,
        value: item => item.updates?.length, // ['updates', 'trips', 'gtfs'],
        title: 'columnTripList.tripModification',
        defaultSelected: defaultNotSelected(ColumnKey.TRIP_MODIFICATION),
        cellBuilder([value, object], { showModal }) {
          return {
            component: CellTripModification,
            props: {
              updates: object.updates,
              hasChildren: !!object.trips,
              hasEditPermission: store.getters.hasPermission(Permission.EDIT_TRIP_UPDATES),
            },
            events: { showModal },
          };
        },
      }),

      // STOP INFO
      ...(store.getters.hasPermission(Permission.VIEW_STOP_INFO)
        ? [
            new DataGridColumn({
              key: ColumnKey.STOP_INFO,
              value: item => item.stopInfoValue?.length,
              defaultSelected: defaultNotSelected(ColumnKey.STOP_INFO),
              title: 'columnTripList.stopInfo',
              cellBuilder([value, object], { showModal }) {
                return {
                  component: CellStopInfo,
                  props: {
                    hasChildren: !!object.trips,
                    hasEditPermission: store.getters.hasPermission(Permission.EDIT_TRIP_UPDATES),
                    stopInfo: object.stopInfoValue || [],
                  },
                  events: { showModal },
                };
              },
            }),
          ]
        : []),

      // MESSAGES
      ...(store.getters.hasPermission(Permission.CREATE_MESSAGES)
        ? [
            new DataGridColumn({
              key: ColumnKey.MESSAGES,
              title: 'columnTripList.messages',
              sortable: false,
              defaultSelected: defaultNotSelected(ColumnKey.MESSAGES),
              cellBuilder([value], { showModal }) {
                return {
                  component: CellMessages,
                  props: { hasChildren: !!value },
                  events: { showModal },
                };
              },
            }),
          ]
        : []),

      // COMMENT
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_COMMENTS)
        ? [
            new DataGridColumn({
              key: ColumnKey.COMMENT,
              title: 'columnTripList.comment',
              defaultSelected: defaultNotSelected(ColumnKey.COMMENT),
              cellBuilder([value], { showModal }) {
                return {
                  component: CommentCell,
                  props: { comment: value },
                  events: { showModal },
                };
              },
            }),
          ]
        : []),
    ],
  });
};
