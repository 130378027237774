<template>
  <div class="checkbox-component">
    <div v-if="noAutoUpdate" class="clickable" @click="$emit('click', !checked)"></div>
    <v-checkbox
      v-model="checkedLocal"
      :class="{ 'not-clickable': noAutoUpdate }"
      color="success"
      hide-details
      :disabled="disabled"
      :ripple="false"
      :indeterminate="indeterminate"
      @change="$emit('change', checkedLocal)"
      @click="$emit('click', $event)"
    >
      <template #label>
        <slot name="label" />
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',

  props: {
    id: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    noAutoUpdate: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change', 'click'],

  data() {
    return {
      checkedLocal: this.checked,
    };
  },

  watch: {
    checked() {
      if (!this.indeterminate || this.noAutoUpdate) {
        this.checkedLocal = this.checked;
      }
    },

    indeterminate() {
      if (this.indeterminate) {
        this.checkedLocal = true;
      }
    },
  },

  created() {
    if (this.indeterminate) {
      this.checkedLocal = true;
    }
  },
};
</script>
<style lang="scss">
.checkbox-component {
  position: relative;

  .clickable {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .not-clickable {
    pointer-events: none;
  }
}
</style>
