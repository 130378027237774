<template>
  <Modal modal-class="modal-stop-info" @close="$emit('close')">
    <template #title>
      <div v-if="mode === StopInfoModalMode.CONFIRM_DELETE">
        {{ $t('deleteStopInfo') }}
      </div>
      <div v-else-if="mode === StopInfoModalMode.EDIT">
        {{ $t('addStopInfo') }}
      </div>
      <div v-else-if="mode === StopInfoModalMode.VIEW">
        {{ $t('showStopInfo') }}
      </div>
    </template>

    <template #body>
      <template v-if="mode === StopInfoModalMode.CONFIRM_DELETE">
        <div class="modal-stop-info__info-line">
          {{ $t('confirmDeleteStopInfo') }}
        </div>
        <div class="modal-stop-info__info-line">
          {{ $t('confirmDeleteStopInfoDetail', [currentInfoContent, currentInfoStop.stop_name]) }}
        </div>
      </template>

      <template v-else-if="mode === StopInfoModalMode.EDIT && hasEditPermission">
        <div class="modal-stop-info__row">
          <label class="modal-stop-info__label">
            {{ $t('forStop') }}
          </label>
          <Selector
            id="stop"
            v-model:value="currentInfoStop.stop_id"
            :options="stopOptionsMultiselect"
            :placeholder="$t('stop')"
            mode="single"
          />
        </div>
        <div class="modal-stop-info__row">
          <label class="modal-stop-info__label">
            <div>
              {{ $t('addInfo') }}
            </div>
            <div class="modal-stop-info__indication">
              {{ $t('maxCharacters', [20]) }}
            </div>
          </label>
          <input
            v-model="currentInfoContent"
            class="form-group__input"
            maxlength="20"
            :placeholder="$t('example')"
          />
        </div>
        <v-checkbox
          id="next-days"
          v-model="applyOnNextDays"
          color="success"
          class="modal-stop-info__checkbox"
          hide-details
        >
          <template #label>
            <span>
              {{ $t('nextDays') }}
            </span>
          </template>
        </v-checkbox>
      </template>

      <template v-else-if="mode === StopInfoModalMode.VIEW">
        <div v-for="(info, index) in infoList" :key="index" class="modal-stop-info__info-line">
          <div class="modal-stop-info__info-content">
            {{ info.information }}
            <span>{{ $t('for') }}</span>
            {{ info.stop_name }}
          </div>
          <span v-if="hasEditPermission" class="modal-stop-info__cta">
            <button @click="editInfo(info)" @mousedown.prevent>
              <font-awesome-icon icon="fa-pencil" />
            </button>
            <button @click="switchToDeleteInfoMode(info)" @mousedown.prevent>
              <font-awesome-icon icon="fa-trash" />
            </button>
          </span>
        </div>
      </template>
    </template>

    <template #cta>
      <Btn
        v-if="mode === StopInfoModalMode.CONFIRM_DELETE"
        type="danger"
        @click="confirmDeleteInfo(currentInfoStop)"
      >
        {{ $t('delete') }}
      </Btn>
      <Btn
        v-else-if="mode === StopInfoModalMode.EDIT"
        :disabled="!isFormValid"
        type="primary"
        @click="addNewInfo()"
      >
        {{ $t('send') }}
      </Btn>
      <Btn
        v-else-if="mode === StopInfoModalMode.VIEW && hasEditPermission"
        type="primary"
        @click="initNewInfo()"
      >
        {{ $t('addNew') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import Api, { UpdateTypeV2 } from '@/api';
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import Selector from '@/components/ui/Selector.vue';
import { Permission } from '@/store';
/** @enum {string} */
const StopInfoModalMode = {
  CONFIRM_DELETE: 'confirmDeleteMode',
  EDIT: 'editMode',
  VIEW: 'viewMode',
};

export default {
  name: 'ModalStopInfo',

  components: {
    Btn,
    Modal,
    Selector,
  },

  props: {
    date: {
      type: String,
      required: true,
    },

    groupId: {
      type: String,
      required: true,
    },

    gtfsId: {
      type: String,
      required: true,
    },

    tripId: {
      type: String,
      required: true,
    },

    tripUpdates: {
      default: () => ({}),
      type: Object,
    },
  },

  emits: ['close', 'refresh'],

  data() {
    return {
      StopInfoModalMode,

      /** @type {boolean} */
      applyOnNextDays: false,

      /** @type {?string} */
      currentInfoContent: null,

      /** @type {?Object} */
      currentInfoStop: null,

      /** @type {Array<import('@/api').StopInfo>} */
      infoList: [],

      /** @type {string} */
      mode: StopInfoModalMode.EDIT,

      /** @type {{[stopId: string]: import('@/store/gtfs').Stop}} */
      stops: {},

      /** @type {?import('@/store/gtfs').Trip} */
      trip: null,
    };
  },

  computed: {
    /** @return {boolean} */
    hasEditPermission() {
      return this.$store.getters.hasPermission(Permission.EDIT_STOP_INFO);
    },

    /** @return {boolean} */
    isFormValid() {
      return this.currentInfoStop && this.currentInfoContent && this.currentInfoContent.length > 0;
    },

    /** @return {Array<import('@/api').StopInfoV2>} */
    tripUpdatesStopInfos() {
      return this.getUpdate(UpdateTypeV2.STOP_INFO) || [];
    },

    /** @return {Array<StopOption>} */
    stopOptions() {
      return this.trip?.stop_times.map(stop => ({
        stop_name: this.stops[stop.stop_id].stop_name,
        stop_id: stop.stop_id,
        stop_sequence: stop.stop_sequence,
      }));
    },

    stopOptionsMultiselect() {
      return this.trip?.stop_times.map(stop => ({
        label: this.stops[stop.stop_id].stop_name,
        value: stop.stop_id,
      }));
    },
  },

  async created() {
    if (this.tripUpdatesStopInfos?.length === 0) {
      this.initNewInfo();
    } else {
      this.mode = StopInfoModalMode.VIEW;
    }

    const [trip, stops] = await Promise.all([
      Api.trips.getTripFromGtfs(this.groupId, this.gtfsId, this.tripId),
      this.$store.dispatch('gtfs/getStopsMap', { gtfsId: this.gtfsId }),
    ]);
    this.trip = trip;
    this.stops = stops;

    const infoListFormatted = this.tripUpdatesStopInfos.map(info => {
      const stop = trip.stop_times.find(stop => stop.stop_sequence === info.stop_sequence);
      const stopName = this.stops[stop.stop_id]?.stop_name || '';
      return { ...info, stop_id: stop.stop_id, stop_name: stopName };
    });

    this.infoList = infoListFormatted;
  },

  methods: {
    /** Create new info or replace if info on same stop already exists */
    addNewInfo() {
      let preExistingInfo;

      this.infoList.forEach(info => {
        if (info.stop_id === this.currentInfoStop.stop_id) {
          info.information = this.currentInfoContent;
          preExistingInfo = true;
        }
      });

      if (!preExistingInfo) {
        // Get remaining infos for stop
        const stopInfos = this.stopOptions.find(stop => stop.stop_id === this.currentInfoStop.stop_id);
        this.infoList.push({
          information: this.currentInfoContent,
          stop_id: this.currentInfoStop.stop_id,
          stop_name: stopInfos.stop_name,
          stop_sequence: stopInfos.stop_sequence,
        });
      }

      this.updateStopInfo(this.infoList);
    },
    /** Init new stopInfo */
    initNewInfo() {
      this.mode = StopInfoModalMode.EDIT;
      this.currentInfoStop = {
        stop_id: null,
      };
    },
    /**
     * Delete info from the list
     * @param {import('@/api').StopInfo} info
     */
    confirmDeleteInfo(info) {
      const index = this.infoList.findIndex(i => i.stop_sequence === info.stop_sequence);
      this.infoList[index].information = '';
      this.updateStopInfo(this.infoList);
    },

    /**
     * Edit info
     * @param {import('@/api').StopInfo} info
     */
    editInfo(info) {
      this.currentInfoContent = info.information;
      this.currentInfoStop = info;
      const index = this.infoList.findIndex(i => i.stop_sequence === info.stop_sequence);
      this.infoList[index].information = '';
      this.mode = StopInfoModalMode.EDIT;
    },

    /** @param {UpdateTypeV2} type */
    getUpdate(type) {
      return this.tripUpdates[type] || null;
    },

    /**
     * Switch modal to info deletion confirmation mode
     * @param {import('@/api').StopInfo} info
     */
    switchToDeleteInfoMode(info) {
      this.currentInfoContent = info.information;
      this.currentInfoStop = info;
      this.mode = StopInfoModalMode.CONFIRM_DELETE;
    },

    /**
     * Save infos on stops
     * @param {Array<import('@/api').StopInfo>} infoList
     */
    async updateStopInfo(infoList) {
      const updatedStopInfos = infoList
        .map(info => ({
          information: info.information,
          stop_sequence: info.stop_sequence,
        }))
        .filter(info => info.information.length > 0);
      const tripUpdates = {
        query: {
          gtfs_id: this.gtfsId,
          trip_id: this.tripId,
          start_date: this.date,
        },
        body: {
          stop_infos: updatedStopInfos,
          delay: this.getUpdate(UpdateTypeV2.DELAY) ?? 0,
          is_canceled: this.getUpdate(UpdateTypeV2.TRIP_CANCELED) ?? false,
          skipped_stop_time_seqs: this.getUpdate(UpdateTypeV2.DO_NOT_SERVE) ?? [],
          comment: this.getUpdate(UpdateTypeV2.COMMENT) ?? null,
        },
        many: this.applyOnNextDays,
      };

      await this.$store.dispatch('trips/updateTrip', tripUpdates);

      this.$emit('close');
      this.$emit('refresh');
    },
  },
};

/**
 * @typedef {Object} StopOption
 * @property {string} stop_id
 * @property {string} stop_name
 * @property {number} stop_sequence
 */
</script>

<style lang="scss">
.modal-stop-info {
  .form-group {
    &__input {
      font-size: 14px;
    }
  }

  .modal__body {
    overflow-y: initial;
  }

  &__checkbox {
    margin-top: 10px;
  }

  &__cta {
    margin-left: 20px;

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &__indication {
    color: $text-neutral;
    font-size: 8px;
  }

  &__info-content {
    display: inline-block;
    width: 250px;

    span {
      font-weight: $font-weight-light;
    }
  }

  &__info-line {
    margin-bottom: 10px;
    text-align: center;
  }

  &__label {
    width: 100px;
    margin-right: 20px;
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
}
</style>

<i18n locale="fr">
{
 "addInfo": "Ajouter une information",
 "addNew": "Ajouter une nouvelle information",
 "addStopInfo": "Ajouter des informations sur un arrêt",
 "confirmDeleteStopInfo": "Êtes-vous sûr de vouloir supprimer l'information suivante :",
 "confirmDeleteStopInfoDetail": "{0} pour {1} ?",
 "delete": "Supprimer",
 "deleteStopInfo": "Supprimer l'information d'un arrêt",
 "emptyList": "La liste est vide",
 "example": "e.g : Quai 2",
 "for": "pour",
 "forStop": "Pour l'arrêt",
 "maxCharacters": "{0} caractères maximum",
 "nextDays": "Appliquer aux jours suivants",
 "send": "Envoyer",
 "showStopInfo": "Informations sur les arrêts",
 "stop": "Arrêt"
}
</i18n>

<i18n locale="en">
{
  "addInfo": "Add info",
  "addNew": "Add new stop info",
  "addStopInfo": "Add stop info",
  "confirmDeleteStopInfo": "Are you sure you cant to delete the stop info:",
  "confirmDeleteStopInfoDetail": "{0} for {1}?",
  "delete": "Delete",
  "deleteStopInfo": "Delete stop information",
  "emptyList": "List is empty",
  "example": "e.g : Stop 2",
  "for": "for",
  "forStop": "For stop",
  "maxCharacters": "{0} characters max",
  "nextDays": "Apply to the next days",
  "send": "Send",
  "showStopInfo": "Show stop information",
  "stop": "Stop"
}
</i18n>
