<template>
  <div class="datagrid__column-selector dropdown" :class="{ 'dropdown--open': dropdownOpened }">
    <Btn type="secondary" :disabled="disabled" @click="dropdownToggle" @mousedown.prevent>
      <font-awesome-icon icon="fa-columns" />
      <span>{{ $t('columns.columns') }}</span>
    </Btn>

    <ul class="dropdown__menu" :class="{ 'dropdown__menu--columns': displayOnLeft }" @click.stop>
      <li class="dropdown__item dropdown__item--separator">
        <Checkbox
          id="columns"
          :checked="selectedColumns.filter(v => v.selectable).length === options.length"
          :indeterminate="
            selectedColumns.length > 0 && selectedColumns.filter(v => v.selectable).length !== options.length
          "
          @change="checked => changeSelectedColumns(checked ? options : [])"
        >
          <template #label>
            {{ $t('columns.allChecked') }}
          </template>
        </Checkbox>
      </li>

      <li v-for="column in options" :key="`${column[trackBy].toString()}_dropdown`" class="dropdown__item">
        <Checkbox
          :id="column[trackBy].toString()"
          :checked="selectedColumns.some(v => v.isEqual(column))"
          @change="
            checked =>
              changeSelectedColumns(
                checked ? [...selectedColumns, column] : selectedColumns.filter(v => !v.isEqual(column))
              )
          "
        >
          <template #label>
            {{ modelI18n.t(column[label]) }}
          </template>
        </Checkbox>
      </li>
    </ul>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import Checkbox from '@/components/ui/Checkbox.vue';

export default {
  name: 'DataGridColumnSelector',

  components: {
    Btn,
    Checkbox,
  },

  inject: ['modelI18n'],

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    displayOnLeft: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
      required: false,
      default: 'localeKey',
    },
    /** @type {Vue.PropOptions<Array<import('@/components/Table/DataGrid/models/DataGrid.models').DataGridColumn>>} */
    options: {
      type: Array,
      required: true,
    },
    trackBy: {
      type: String,
      required: false,
      default: 'columnType',
    },
    localStorageKey: {
      type: String,
      required: true,
    },
    /** THIS PROPS is needed if DataGridColumnSelector is called outside of DataGrid, $refs.datagrid must be passed  */
    dataGrid: {
      type: Object,
      default: null,
    },
  },

  emits: ['change'],

  data() {
    return {
      dropdownOpened: false,
      /** @type {Array<import('@/components/Table/DataGrid/models/DataGrid.models').DataGridColumn>} */
      selectedColumns: [],
    };
  },

  created() {
    const localStorageColumnSelection = JSON.parse(localStorage.getItem(this.localStorageKey));
    // if selected columns are saved in localStorage, apply, otherwise show all columns
    if (localStorageColumnSelection) {
      this.selectedColumns = this.options.filter(col => localStorageColumnSelection.includes(col.columnType));
    } else {
      // set defaultSelected if no saved filters
      this.selectedColumns = this.options.filter(col => col.defaultSelected);
      // this.selectedColumns = this.options;
    }
  },

  methods: {
    /**
     * Close the opened dropdown
     */
    dropdownClose() {
      this.dropdownOpened = false;
      window.removeEventListener('click', this.dropdownClose);
    },

    /**
     * Open a dropdown
     */
    dropdownOpen() {
      this.dropdownOpened = true;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },

    /**
     * Call dropdownOpen or dropdownClose
     */
    dropdownToggle() {
      if (!this.dropdownOpened) {
        this.dropdownOpen();
      } else {
        this.dropdownClose();
      }
    },

    changeSelectedColumns(selection) {
      if (this.dataGrid) this.dataGrid.onChangeColumnsSelection(selection);
      // handle case where DataGridColumnSelector is called directly from DataGrid component
      else this.$parent.onChangeColumnsSelection(selection);

      // save to localStorage && update selectedColumns in component
      localStorage.setItem(this.localStorageKey, JSON.stringify(selection.map(s => s.columnType)));
      this.selectedColumns = selection;
    },
  },
};
</script>

<style lang="scss">
.datagrid__column-selector {
  label {
    font-size: calc(0.6em + 0.3vw);
  }

  svg {
    font-size: 14px;
  }

  .dropdown__menu--columns {
    right: 0;
  }
}
</style>

<i18n locale="fr">
{
  "columns": {
    "columns": "Colonnes",
    "allChecked": "Tout cocher"
  }
}
</i18n>

<i18n locale="en">
{
  "columns": {
    "columns": "Columns",
    "allChecked": "Check all"
  }
}
</i18n>

<i18n locale="cz">
{
  "columns": {
    "columns": "Sloupce",
    "allChecked": "Ověřit vše"
  }
}
</i18n>

<i18n locale="de">
{
  "columns": {
    "columns": "Spalten",
    "allChecked": "Alle auswählen"
  }
}
</i18n>

<i18n locale="es">
{
  "columns": {
    "columns": "Columnas",
    "allChecked": "Verificar todo"
  }
}
</i18n>

<i18n locale="it">
{
  "columns": {
    "columns": "Colonne",
    "allChecked": "Contrassegna tutto"
  }
}
</i18n>

<i18n locale="pl">
{
  "columns": {
    "columns": "Kolumny",
    "allChecked": "Zaznacz wszystkie"
  }
}
</i18n>
