<template>
  <th scope="col" class="datagrid__table-header-cell" :style="columnStyle">
    <div class="header-cell" :class="sortable && 'header-cell--sortable'">
      <slot :sort-class="sortClass" />
    </div>
  </th>
</template>

<script>
import { SortTypes } from '@/components/Table/DataGrid/mixins/Sortable';

export default {
  name: 'DataGridHeaderCell',

  props: {
    sortable: {
      default: false,
      type: Boolean,
    },
    sorting: {
      default: false,
      type: Boolean,
    },
    sortType: {
      default: null,
      type: Symbol,
    },
    columnStyle: {
      default: () => ({}),
      type: Object,
    },
  },

  computed: {
    /** @return {Array<string>} */
    classNames() {
      const sortableClassName = 'header-cell--sortable';
      const sortingClassName = 'header-cell--sorting';
      const sortingAscClassName = 'header-cell--sorting-asc';
      const sortingDescClassName = 'header-cell--sorting-desc';

      if (!this.sortable) return [];
      if (!this.sorting) return [sortableClassName];

      return [
        sortableClassName,
        sortingClassName,
        this.sortType === SortTypes.ASC ? sortingAscClassName : sortingDescClassName,
      ];
    },

    /** @return {Array<string>} */
    sortClass() {
      return (
        this.sorting && [
          'header-cell__sorting',
          this.sortType === SortTypes.ASC ? 'header-cell__sorting--asc' : 'header-cell__sorting--desc',
        ]
      );
    },
  },
};
</script>

<style lang="scss">
.datagrid__table-header-cell {
  position: relative;
  padding: var(--head-cell-py) var(--head-cell-px);
  font-weight: $font-weight-light;
  text-align: left;

  .header-cell {
    text-overflow: ellipsis;

    &--sortable {
      cursor: pointer;
    }

    &__sorting {
      $size: 0.7em;

      &::after {
        content: '';
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: $size;
        height: $size;
        margin: 0 calc($size * 0.8);
        border-width: 0.15em 0.15em 0 0;
        border-style: solid;
      }

      &--asc::after {
        top: calc(-1 * ($size / 3));
        transform: rotate(135deg);
      }

      &--desc::after {
        top: calc($size / 3);
        transform: rotate(-45deg);
      }
    }
  }
}
</style>
