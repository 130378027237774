<template>
  <div class="trip-detailed">
    <!-- Toolbar -->
    <div class="trip-detailed__header">
      <div>
        <Btn type="primary" @click="showModal(ModalType.MODIFY)">
          {{ $t('modifyTrip') }}
        </Btn>
        <Btn type="secondary" @click="showModal(ModalType.COMMENT)">
          {{ $t('addComment') }}
        </Btn>
        <Btn type="secondary" @click="showModal(ModalType.STOP_INFO)">
          {{ $t('addStopInfo') }}
        </Btn>
      </div>

      <div>
        <Btn type="secondary" :class="isCalendarOpen ? 'no-click-event' : ''" @click="toggleDatepicker(true)">
          <font-awesome-icon icon="fa-calendar-days" />
          {{ $d(selectedDate, 'dateShort') }}
          <Datepicker
            ref="trip-detailed-datepicker"
            v-model:value="selectedDate"
            :disabled-dates="calendarDisabledDates"
            input-class="trip-detailed__datepicker"
            @closed="toggleDatepicker(false)"
          />
        </Btn>
        <Btn type="secondary" @click="showModal('config')">
          {{ $t('viewMore') }}
        </Btn>
        <Btn type="secondary" :title="$t('sendMessage')" @click="showModal('newMessage')">
          <font-awesome-icon icon="fa-envelope" />
        </Btn>
        <a
          class="trip-detailed__export-btn"
          :disabled="!$store.getters.hasPermission(Permission.EXPORT_TRIP)"
          :download="downloadFileName"
          :href="downloadLink"
          @click="createDownloadLink()"
        >
          <Btn
            type="secondary"
            :disabled="!$store.getters.hasPermission(Permission.EXPORT_TRIP)"
            :title="$t('download')"
          >
            <font-awesome-icon icon="fa-download" />
          </Btn>
        </a>
      </div>
    </div>

    <!-- Trip Information -->
    <TripInfo
      v-if="trip && tripInGtfs"
      ref="tripInfo"
      :trip="trip"
      :date="selectedDate"
      :group-id="group._id"
      class="trip-detailed__info"
      @showModal="showModal"
    />

    <div v-if="isLoaded && tripInGtfs && stopTimes.length > 0" class="trip-detailed__main">
      <div class="trip-detailed__center-container">
        <!-- Event feed -->
        <div id="trip-detailed-feed" class="trip-detailed__left-side">
          <EventFeed
            ref="eventFeed"
            :trip-id="tripId"
            :gtfs-id="gtfsId"
            :is-trip-running="isTripRunning"
            :stop-times="stopTimes"
            :stops="stops"
            :selected-date="selectedDate"
            :trip-updates="trip?.updates"
          />
        </div>

        <!-- Map -->
        <div id="trip-detailed-map" class="trip-detailed__right-side">
          <TripDetailedMap
            :trip="trip"
            :device="mapboxDevice"
            :is-trip-running="isTripRunning"
            show-map-layers-dropdown
            :show-track-display-switch="showTimeline"
            :stop-times="stopTimes"
            :stops="stops"
          />
        </div>
      </div>
    </div>

    <!-- Timeline -->
    <TimelineContainer
      v-if="isLoaded && tripInGtfs"
      ref="timeline"
      v-model:mapbox-device="mapboxDevice"
      class="trip-detailed__timeline"
      :is-trip-running="isTripRunning"
      :selected-date="selectedDate"
      :show-timeline="showTimeline"
      :stop-times="stopTimes"
      :trip-id="trip.id"
    />

    <div v-if="isLoaded && !tripInGtfs" class="trip-detailed__no-trip">
      {{ $t('noTrip') }}
    </div>

    <!-- Modals -->
    <ModalConfig
      v-if="modalShown === 'config' && trip"
      :trip="trip"
      :date="selectedDate"
      :group-id="group._id"
      @close="
        closeModal(true);
        $refs['tripInfo'].updateListFromLocalStorage();
      "
    />
    <ModalMessageNew v-if="modalShown === 'newMessage'" :recipients="messageRecipients" @close="closeModal" />
    <ModalStopInfoV2
      v-if="modalShown === ModalType.STOP_INFO"
      :date="gtfsDate"
      :group-id="group._id"
      :gtfs-id="trip.gtfs[0].id"
      :trip-id="trip.id"
      :trip-updates="trip?.updates"
      @close="closeModal"
    />
    <ModalTripComment
      v-if="modalShown === ModalType.COMMENT"
      :comment="getUpdate(UpdateType.COMMENT)"
      :title-name="trip.formatted_name"
      @close="closeModal"
      @submit="setTripComment"
    >
      <template #extra-input>
        <v-checkbox id="next-days" v-model="applyOnNextDays" color="success" hide-details>
          <template #label>
            <span>
              {{ $t('nextDays') }}
            </span>
          </template>
        </v-checkbox>
      </template>
    </ModalTripComment>
    <ModalTripModificationV2
      v-if="modalShown === ModalType.MODIFY"
      :date="gtfsDate"
      :gtfs-id="trip.gtfs[0].id"
      :trip-formatted-name="trip.formatted_name"
      :trip-id="trip.id"
      :trip-updates="trip?.updates"
      @close="closeModal"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { trips, TemporalityType, UpdateType, UpdateTypeV2 } from '@/api';
import ModalTripComment from '@/components/common/ModalComment.vue';
import ModalStopInfoV2 from '@/components/common/ModalStopInfoV2.vue';
import ModalTripModificationV2 from '@/components/common/ModalTripModificationV2/index.vue';
import Btn from '@/components/ui/Btn.vue';
import Datepicker from '@/components/ui/Datepicker.vue';
import ModalMessageNew, { RecipientType } from '@/components/ui/ModalMessageNew.vue';
import Calendar from '@/libs/calendar';
import { toCSV } from '@/libs/csv.js';
import { dateObjToGtfsFormat, getISODate, timestampFormatHHMM } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';
import { Permission } from '@/store';
import { ModalType, ScheduleRelationship } from '@/store/trips';

import EventFeed from './EventFeed.vue';
import ModalConfig from './ModalTripDetailConfig.vue';
import TimelineContainer from './TimelineContainer.vue';
import TripDetailedMap from './TripDetailedMap.vue';
import TripInfo from './TripInfo.vue';
import { FeedEventType, ExportColumns, formatDelay, getRowContent } from './EventFeedShared.js';

dayjs.extend(utc);

export default {
  name: 'TripDetailedPage',

  components: {
    Btn,
    Datepicker,
    EventFeed,
    ModalConfig,
    ModalMessageNew,
    ModalStopInfoV2,
    ModalTripComment,
    ModalTripModificationV2,
    TimelineContainer,
    TripDetailedMap,
    TripInfo,
  },

  props: {
    /** @type {import('vue').Prop<{date?: string}>} */
    query: {
      type: Object,
      default: () => ({}),
    },

    tripId: {
      required: true,
      type: String,
    },
  },

  data: () => ({
    Calendar,
    ExportColumns,
    formatDelay,
    getRowContent,
    GroupRoute,
    ModalType,
    Permission,
    ScheduleRelationship,
    UpdateType,

    /** @type {boolean} */
    applyOnNextDays: false,
    /** @type {import('@/components/ui/Datepicker.vue').DisabledDates} */
    calendarDisabledDates: {
      minDate: null,
      maxDate: null,
    },
    /** @type {?string} */
    downloadLink: null,
    /** @type {boolean} */
    isLoaded: false,
    /** @type {import('@/components/map/HistoryMap.vue').HistoryMapDevice} */
    mapboxDevice: null,
    /** @type {?ModalType} */
    modalShown: null,
    /** @type {Object.<string, import('@/store/gtfs').Stop>} */
    stops: null,
    /** @type {Array<import('@/store/gtfs').StopTime>} */
    stopTimes: [],
    /** @type {import('@/api').TripListItemV4} */
    trip: null,
    /** @type {?import('@/store/gtfs').Trip} */
    tripInGtfs: null,
    /** @type {Array<import('@/api').TripListItem>} */
    trips: null,
    isCalendarOpen: false,
  }),

  computed: {
    /** @return {string} */
    downloadFileName() {
      return `trip-detailed_${this.tripId}_${this.gtfsDate}.csv`;
    },

    /** @return {Array<import('./EventFeed.vue').EventFeedRow>} */
    eventFeedRows() {
      return this.$store.state.tripDetailed.eventFeed;
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {string} */
    gtfsDate() {
      return dateObjToGtfsFormat(this.selectedDate);
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters['gtfs/getGtfsAt'](this.selectedTs) || this.group.current_file;
    },

    /**
     * Return true if the trip is underway and a device is connected to the trip
     * @return {boolean}
     */
    isTripRunning() {
      return (
        this.mapboxDevice &&
        this.mapboxDevice.events.length > 0 &&
        this.trip?.temporality === TemporalityType.UNDERWAY
      );
    },

    /** @return {Array<import('@/components/ui/ModalMessageNew.vue').Recipient>} */
    messageRecipients() {
      const devices = this.trip?.devices || [];
      /** @type {Array<import('@/components/ui/ModalMessageNew.vue').Recipient>} */
      const recipients = [];
      devices.forEach(device => {
        recipients.push({
          id: device.id,
          label: device.name,
          type: RecipientType.DEVICE,
        });
      });
      return recipients;
    },

    /** @return {Date} */
    selectedDate: {
      /** @return {Date} */
      get() {
        if (this.query.date) {
          const date = dayjs(this.query.date).hour(23).minute(59).second(59).utc().toDate();
          return date;
        }
        return new Date();
      },

      /** @param {Date} value */
      set(value) {
        this.$router.push({
          name: GroupRoute.TRIP_DETAILED,
          params: {
            groupId: this.group._id,
            tripId: this.tripId,
          },
          query: {
            date: getISODate(value),
          },
        });
      },
    },

    /** @return {number} */
    selectedTs() {
      const offset = this.stopTimes.length > 0 ? this.stopTimes[0].departure_time : 0;
      // `this.selectedDate` is at 23:59:59 of that day
      const defaultTs = this.selectedDate.getTime() / 1000 - 86399 + offset;

      return defaultTs;
    },

    /** @return {string} GTFS Date */
    serviceDate() {
      return dateObjToGtfsFormat(this.selectedDate);
    },

    /** @return {boolean} */
    showTimeline() {
      return this.tripInGtfs && this.mapboxDevice?.events.length > 0;
    },
  },

  watch: {
    isTripRunning() {
      if (this.isTripRunning) {
        this.startRealTimeMode();
      } else {
        clearInterval(this.updateTripInfo);
      }
    },

    async selectedDate() {
      this.refreshPage();
    },

    async trips() {
      if (this.trip && this.trips && this.tripInGtfs) {
        this.onTripChange();
      }
    },

    gtfsId() {
      this.getStops();
      this.refreshPage();
    },

    groupId: {
      immediate: true,
      handler() {
        if (this.groupId != null) {
          this.fetchCollections();
        }
      },
    },

    // Get the stopTimes from the trip in the gtfs
    tripInGtfs: {
      immediate: true,
      handler() {
        if (this.tripInGtfs) {
          const stopTimes = this.tripInGtfs.stop_times;
          this.stopTimes = stopTimes.sort((a, b) => a.stop_sequence - b.stop_sequence);
        }
      },
    },
  },

  async created() {
    this.isLoaded = false;

    await Promise.all([this.getTrip(), this.getStops()]);

    this.isLoaded = true;

    if (this.tripInGtfs) {
      this.observeContentHeight();
    }
  },

  beforeUnmount() {
    clearInterval(this.updateTripInfo);
  },

  methods: {
    /** CSS trick because it's not possible to set feed max size only with classic css... */
    observeContentHeight() {
      this.$nextTick(() => {
        const tripDetailMapElement = document.getElementById('trip-detailed-map');
        const resizeObserver = new ResizeObserver(() => {
          if (document.getElementById('trip-detailed-feed')) {
            // set feed to 0px to avoid bug if header zone increases
            document.getElementById('trip-detailed-feed').style.height = `0px`;
            // Get map height & set it to feed
            const mapHeight = tripDetailMapElement.offsetHeight;
            document.getElementById('trip-detailed-feed').style.height = `${mapHeight}px`;
          }
        });
        if (tripDetailMapElement) resizeObserver.observe(tripDetailMapElement);
      });
    },

    /** Close the modal, clear temporary data & refresh page */
    closeModal(withoutRefresh) {
      if (!withoutRefresh) {
        this.refreshPage();
        this.$refs.eventFeed.getEventsList();
      }
      this.modalShown = null;
      this.modalData = null;
      this.applyOnNextDays = false;
    },

    createDownloadLink() {
      if (this.downloadLink != null) {
        URL.revokeObjectURL(this.downloadLink);
        this.downloadLink = null;
      }

      const tableData = [];

      this.eventFeedRows.forEach(row => {
        const newRow = [];
        // Event
        newRow.push(this.getRowContent(row));
        // Time
        newRow.push(this.formatHHMM(row.time));
        // Real time
        if (row.type === FeedEventType.ARRIVAL) {
          newRow.push(this.formatHHMM(row.theoreticalArrivalTime));
        } else if (row.type === FeedEventType.DEPARTURE) {
          newRow.push(this.formatHHMM(row.theoreticalDepartureTime));
        } else {
          newRow.push(null);
        }
        // Delay
        newRow.push(this.formatDelay(row.delay));

        tableData.push(newRow);
      });

      const columnsTitles = Object.values(ExportColumns).reduce((acc, title) => {
        acc.push(this.$t(`eventFeed.${title}`));
        return acc;
      }, []);

      const data = [columnsTitles, ...tableData];

      this.downloadLink = toCSV(data);
    },

    /** Find the trip in the gtfs - if the trip can't be find in any gtfs, the page does not display */
    findTripInGtfs() {
      this.tripInGtfs = this.trips[this.gtfsId] ? this.trips[this.gtfsId][this.trip.id] : false;
      // For days when the gtfs changes
      if (!this.tripInGtfs) {
        const defaultGtfs = Object.values(this.trips).find(gtfs => gtfs[this.trip.id] !== undefined);
        if (defaultGtfs) {
          this.tripInGtfs = defaultGtfs[this.trip.id];
        }
      }
    },

    /**
     * @param {number} ts
     * @return {string}
     */
    formatHHMM(ts) {
      return timestampFormatHHMM(ts, { refDate: this.selectedDate, tz: this.group.tz });
    },

    /** Get one trip from the trip list */
    async getTrip() {
      this.trips = null;

      try {
        const data = await trips.getTripFromTripList(this.group._id, this.serviceDate, this.tripId);

        // check security on serviceDate
        if (data && data.service_date === this.serviceDate) {
          this.trip = data;

          // needed to get the service_id of the trip to get the calendar
          const publishedTrips = await this.$store.dispatch('trips/getPublishedTripsMapOn', {
            dateGtfs: this.serviceDate,
          });
          this.trips = publishedTrips;

          this.findTripInGtfs();
        } else {
          this.tripInGtfs = null;
        }
      } catch (e) {
        // Check if error 404, it means user has no access to this ressource. (team rights)
        if (e?.response?.status === 404) {
          this.$router.replace({ name: 'unauthorizedInternal' });
        }
      }
    },

    async getStops() {
      this.stops = await this.$store.dispatch('gtfs/getStopsMap', {
        gtfsId: this.gtfsId,
      });
    },

    /** @param {UpdateTypeV2} type */
    getUpdate(type) {
      return this.trip.updates?.[type] || null;
    },

    /** Get the calendar's inactive dates */
    async onTripChange() {
      this.calendarDisabledDates = await Calendar.getInactiveDatesForATrip(
        this.tripInGtfs.service_id,
        this.gtfsId
      );
    },

    async refreshPage() {
      await this.getTrip();
      if (this.tripInGtfs) {
        this.observeContentHeight();
      }
    },

    /**
     * Set a comment on the trip
     * @param {string} comment
     */
    async setTripComment(comment) {
      const tripUpdates = {
        query: {
          gtfs_id: this.trip.gtfs[0].id,
          trip_id: this.tripId,
          start_date: this.gtfsDate,
        },
        body: {
          comment,
          delay: this.getUpdate(UpdateType.DELAY) * 60,
          is_canceled: this.getUpdate(UpdateType.TRIP_CANCELED) === ScheduleRelationship.CANCELED,
          skipped_stop_time_seqs: this.getUpdate(UpdateType.DO_NOT_SERVE)?.map(stop => stop.stop_sequence),
          stop_infos: this.getUpdate(UpdateType.STOP_INFO),
        },
        many: this.applyOnNextDays,
      };

      await this.$store.dispatch('trips/updateTrip', tripUpdates);

      this.closeModal();
    },

    /** @param {ModalType} type */
    showModal(type) {
      this.modalShown = type;
    },

    /** Get trip info every 5 seconds when trip status is running */
    startRealTimeMode() {
      this.updateTripInfo = setInterval(() => {
        this.getTrip();
      }, 5000);
    },
    /**
     * This method is aimed to allow to close datepicker when clicking on datepicker cta, otherwise it always opens itself
     * on close/blur on this precise zone
     * @param {boolean} actionOpen
     */
    toggleDatepicker(actionOpen) {
      if (actionOpen) {
        this.isCalendarOpen = true;
        this.$refs['trip-detailed-datepicker'].openMenu();
      } else {
        this.isCalendarOpen = false;
      }
    },
  },
};
</script>

<style lang="scss">
.trip-detailed {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: calc(100vh - 40px);
  padding: $view-standard-padding;
  background-color: $canvas-trip-detailed;

  &__center-container {
    display: flex;
    flex-grow: 1;
  }

  &__datepicker {
    display: none;
  }

  &__export-btn {
    margin-left: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    margin-top: 10px;
  }

  &__map {
    position: relative;
    height: 80%;
    margin-top: 5%;
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 10px;
  }

  &__no-trip {
    margin-top: 200px;
    text-align: center;
  }

  &__left-side {
    width: 50%;
    height: 40vh;
    margin-right: 10px;
  }

  &__right-side {
    position: relative;
    width: 50%;
  }

  &__timeline {
    margin-top: 10px;
  }

  .no-click-event {
    pointer-events: none;

    .datepicker {
      pointer-events: initial;
    }
  }
}
</style>

<i18n locale="fr">
{
  "addComment": "Ajouter un commentaire",
  "addStopInfo": "Ajouter une info sur un arrêt",
  "modifyTrip": "Modifier la course",
  "nextDays": "Appliquer aux jours suivants",
  "noTrip": "Cette course n'a pas lieu à cette date",
  "sendMessage": "Envoyer un message aux appareils",
  "viewMore": "Voir plus",
}
</i18n>

<i18n locale="en">
{
  "addComment": "Add a comment",
  "addStopInfo": "Add a stop info",
  "modifyTrip": "Modify the trip",
  "nextDays": "Apply to the next days",
  "noTrip": "Trip not taking place on this date",
  "sendMessage": "Send a message",
  "viewMore": "View more",
}
</i18n>
