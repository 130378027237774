import { Permission } from '@/store';

export default {
  name: 'NavBarsMixin',

  data: () => ({
    Permission,
  }),

  computed: {
    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },
  },

  methods: {
    /**
     * @param {string} name
     * @param {Partial<import('vue-router').Location>} [args]
     * @return {import('vue-router').Location}
     */
    getGroupRoute(name, args = {}) {
      return { name, params: { groupId: this.group._id }, ...args };
    },
  },
};
