<template>
  <div class="message">
    <div class="message__row">
      <div class="message__toolbar">
        <Btn type="primary" class="message__toolbar-item" @click="showModalMessageNew()">
          {{ $t('newMessage') }}
        </Btn>

        <TableSearchBar
          ref="tableSearchBar"
          class="message__toolbar-item"
          :search-list="messages[selectedTab]"
          :search-fields="searchFields"
          @filteredList="setShownMessages"
        />
      </div>

      <div class="message__toolbar">
        <Tabs v-show="!actionToolbar" class="message__toolbar-item" :tabs="tabs" />
      </div>

      <div class="message__toolbar">
        <template v-if="actionToolbar">
          <Btn v-show="selectedTab === BoxDirection.INBOX" type="secondary" @click="toggleCheckedStatus">
            {{ $t('markAs.' + titleStatus) }}
          </Btn>
          <Btn type="secondary" @click="showModalMessageArchive">
            {{ $t('archive') }}
          </Btn>
        </template>
        <!--
        <Btn type="secondary" :disabled="actionToolbar">
          {{ $t("download") }}
        </Btn>
        -->
      </div>
    </div>

    <div v-if="!loading && !messages[selectedTab].length" class="message__body--empty">
      <div class="message__body-message">
        <div>{{ $t('noData') }}</div>
        <div>{{ $t('createNewMessage') }}</div>
      </div>
      <img alt="illustration" class="illustration" src="@/assets/img/no_data_illustration.svg" />
    </div>

    <Datagrid
      ref="dataGrid"
      :model-i18n="$i18n"
      :loading="firstLoad"
      :data="shownMessages"
      :datagrid="getDatagrid(selectedTab)"
      :build-cell-injectors="buildCellInjectors"
      :build-header-cell-injectors="buildHeaderCellInjectors"
      :show-header="false"
      :reload-on-refresh="false"
      paginated
    />

    <OutboxModalDetails
      v-if="modalMessageDetail.shown === BoxDirection.OUTBOX"
      :message="modalMessageDetail.message"
      @close="modalMessageDetail.shown = false"
    />

    <InboxModalDetails
      v-if="modalMessageDetail.shown === BoxDirection.INBOX"
      :message="modalMessageDetail.message"
      @close="closeModalMessageDetail"
      @markUnread="setStatusUnread"
    />

    <ModalMessageNew
      v-if="modalMessageNew.shown"
      :recipients="modalMessageNew.recipients"
      :message="modalMessageNew.message"
      @sent="handleSent"
      @close="closeModalMessageNew"
    />

    <Modal v-if="modalMessageArchiveShown" @close="closeModalMessageArchive">
      <template #title>
        {{ $t('archive') }}
      </template>

      <template #body>
        <div class="message__modal-body">
          {{ $t('confirmArchiveMessage') }}
        </div>
      </template>

      <template #cta>
        <Btn type="danger" @click="submitModalMessageArchive">
          {{ $t('confirm') }}
        </Btn>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import DataGridColumnSelector from '@/components/Table/DataGrid/DataGridColumnSelector.vue';
import Datagrid from '@/components/Table/DataGrid/index.vue';
import TableSearchBar from '@/components/Table/TableSearchBar.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalMessageNew, { RecipientType } from '@/components/ui/ModalMessageNew.vue';
import Tabs from '@/components/ui/Tabs.vue';
import { getDatagrid } from '@/pages/MessageListPage/Messages.conf.js';
import * as Messages from '@/store/messages';

import InboxModalDetails from './InboxModalDetails.vue';
import { columnTypes, searchFields } from './Messages.conf.js';
import OutboxModalDetails from './OutboxModalDetails.vue';

const { BoxDirection, StatusEnum } = Messages;

/** @enum {string} */
const Status = {
  READ: 'read',
  UNREAD: 'unread',
};

export default {
  name: 'MessageView',

  components: {
    Btn,
    DataGridColumnSelector,
    Datagrid,
    InboxModalDetails,
    Modal,
    ModalMessageNew,
    OutboxModalDetails,
    TableSearchBar,
    Tabs,
  },

  provide() {
    return {
      modelI18n: this.$i18n,
    };
  },

  data: () => ({
    /** @type {Boolean} */
    checkedAll: false,

    /** @type {Boolean} */
    actionToolbar: false,

    /** @type {enum} */
    BoxDirection,

    /** @type {enum} */
    RecipientType,

    /** @type {enum} */
    StatusEnum,

    /** @type {Array} */
    searchFields,

    /** @type {Function} */
    getDatagrid,

    /** @type {boolean} */
    loading: false,

    /** @type {Object} */
    messages: {
      inbox: [],
      outbox: [],
    },

    /** @type {Array} */
    shownMessages: [],

    /** @type {boolean} */
    modalMessageArchiveShown: false,

    /** @type {Object} */
    modalMessageDetail: {
      message: {},
      shown: null,
    },

    /** @type {Object} */
    modalMessageNew: {
      message: {},
      recipients: [],
      shown: false,
    },
    firstLoad: true,

    /** @type {BoxDirection} */
    selectedTab: BoxDirection.INBOX,
  }),

  computed: {
    /** @return {Array} */
    tabs() {
      const inboxTab = {
        active: this.selectedTab === BoxDirection.INBOX,
        label: `${this.$t('inbox')} (${this.unreadCounter})`,
        handleClick: () => this.selectTab(BoxDirection.INBOX),
      };
      const outboxTab = {
        active: this.selectedTab === BoxDirection.OUTBOX,
        label: this.$t('outbox'),
        handleClick: () => this.selectTab(BoxDirection.OUTBOX),
      };
      return [inboxTab, outboxTab];
    },

    /** @return {string} */
    unreadCounter() {
      return this.$store.getters['messages/nbUnreadMessages'];
    },

    /** @return {Message[]} */
    checkedMessages() {
      return this.messages[this.selectedTab].filter(({ checked }) => checked);
    },

    /** @return {Status} */
    titleStatus() {
      if (this.checkedMessages.some(message => message.status === StatusEnum.RECEIVED)) {
        return Status.READ;
      }
      return Status.UNREAD;
    },

    /** @return {Object} */
    buildHeaderCellInjectors() {
      const bindCheckAll = checked => {
        this.checkedAll = checked;
        const shownIds = this.shownMessages.map(({ _id }) => _id);
        this.messages[this.selectedTab] = this.messages[this.selectedTab].map(msg => {
          if (shownIds.includes(msg._id)) {
            msg.checked = checked;
          }
          return msg;
        });
        this.toggleActionToolbar(checked);
      };
      return {
        [columnTypes.CHECKBOX]: () => ({
          change: bindCheckAll,
          checked: this.checkedAll,
        }),
      };
    },

    /** @return {Object} */
    buildCellInjectors() {
      const bindChangeHandler = apiData => checked => {
        if (this.checkedAll && !checked) this.checkedAll = false;
        apiData.checked = checked;
        this.toggleActionToolbar(checked);
      };

      const bindClickHandler = apiData => () => this.showModalMessageDetail(apiData);

      const bindArchiveHandler = apiData => () => {
        this.resetCheckedMessages();
        apiData.checked = true;
        this.showModalMessageArchive();
      };

      const bindReplyHandler = apiData => () => {
        this.showModalMessageNew({
          recipients: [
            {
              id: apiData.senderId,
              type: RecipientType.DEVICE,
            },
          ],
        });
      };

      const bindReadHandler = apiData => () => {
        this.updateStatus(apiData, StatusEnum.RECEIVED);
      };

      const bindReadNewHandler = apiData => () => {
        this.updateStatus(apiData, StatusEnum.READ);
      };

      const bindCopyHandler = apiData => () => {
        const recipients = apiData.recipients.map(({ senderId }) => ({
          id: senderId,
          type: RecipientType.DEVICE,
        }));

        const message = {
          content: apiData.content,
          urgent: apiData.urgent || apiData.priority === 'high',
        };
        this.showModalMessageNew({
          message,
          recipients,
        });
      };

      return {
        [columnTypes.CHECKBOX]: ({ apiData }) => ({
          change: bindChangeHandler(apiData),
        }),
        [columnTypes.CONTENT]: ({ apiData }) => ({
          click: bindClickHandler(apiData),
        }),
        [columnTypes.ACTION]: ({ apiData }) => ({
          copy: bindCopyHandler(apiData),
          archive: bindArchiveHandler(apiData),
          readMessage: bindReadHandler(apiData),
          readNewMessage: bindReadNewHandler(apiData),
          reply: bindReplyHandler(apiData),
        }),
      };
    },
  },

  watch: {
    '$store.state.messages.hotInbox': {
      immediate: false,
      deep: true,
      async handler() {
        if (!this.firstLoad && this.messages.inbox.length !== this.$store.state.messages.hotInbox.length) {
          this.getMessages();
        }
      },
    },
  },

  async created() {
    await this.getMessages();
    this.firstLoad = false;
  },

  methods: {
    /** Trigger message update from api on sent message
    /*  Wait in order to let the api process send request
     */
    handleSent() {
      setTimeout(this.getMessages, 500);
    },

    /** @param {Boolean} checked - If any message is checked */
    toggleActionToolbar(checked) {
      this.actionToolbar = checked || this.checkedMessages.length;
    },

    toggleCheckedStatus() {
      const status = this.titleStatus === Status.READ ? StatusEnum.READ : StatusEnum.RECEIVED;
      this.updateStatus(this.checkedMessages, status);
      this.resetCheckedMessages();
    },

    /** @param {Message[]} messages - Messages to display */
    setShownMessages(messages) {
      this.shownMessages = messages;
    },

    closeModalMessageArchive() {
      this.modalMessageArchiveShown = false;
      this.resetCheckedMessages();
    },

    closeModalMessageDetail() {
      this.modalMessageDetail.shown = null;
      this.updateStatus(this.modalMessageDetail.message, StatusEnum.READ);
    },

    closeModalMessageNew() {
      this.modalMessageNew = {
        message: {},
        recipients: [],
        shown: false,
      };
    },

    /**
     * Get all messages sent or received from a group.
     */
    async getMessages() {
      this.loading = true;

      // Store messages data.
      const messages = await this.$store.dispatch('messages/getMessages');
      this.messages = await this.formatMessages(messages);

      this.shownMessages = this.messages[this.selectedTab];

      this.loading = false;
    },

    /**
     * Thanks to messages, format all data for the user.
     * @param {Array<Message>} messages
     * @return {Promise<Array>} formatted messages.
     */
    async formatMessages(messages) {
      const formattedMessages = {
        [BoxDirection.INBOX]: [],
        [BoxDirection.OUTBOX]: [],
      };

      // Collect all messages elements and store them in formattedMessage.
      const formatMessage = async message => {
        if (!message.sender?.client) return;
        const formattedMessage = {};

        const loadSender = async () => {
          // Message Sender
          try {
            const sender = await this.$store.dispatch('messages/setFormattedSender', message);
            formattedMessage.senderName = sender.senderName;
            formattedMessage.senderId = sender.senderId;
          } catch (error) {
            console.warn('-=- messages/setFormattedSender / error: ', error);
          }
        };

        const loadRecipients = async () => {
          // Message recipients
          try {
            formattedMessage.recipients = await this.$store.dispatch(
              'messages/setFormattedRecipients',
              message
            );
          } catch (error) {
            console.warn('-=- messages/setFormattedRecipients / error: ', error);
          }
        };

        // Start async tasks
        const promises = [loadSender(), loadRecipients()];

        formattedMessage._id = message._id;

        formattedMessage.checked = false;

        formattedMessage.tripId = message.sender?.trip?.trip_id;

        formattedMessage.tab = message.sender.client === 'driver' ? BoxDirection.INBOX : BoxDirection.OUTBOX;

        formattedMessage.content = Messages.getFormattedDetails(message);

        formattedMessage.tripName =
          message.sender?.trip?.formatted_trip_name || message.sender?.trip?.trip_id;

        formattedMessage.sendDate = Messages.getSendDate(message);

        formattedMessage.status = Messages.getFormattedStatus(message);

        formattedMessage.priority = message.priority || Messages.Priority.NORMAL;

        formattedMessage.urgent = message.urgent || false;

        await Promise.all(promises);

        // Store all data in new formattedMessage.
        formattedMessages[formattedMessage.tab].push(formattedMessage);
      };

      await Promise.all(
        messages.map(async message => {
          try {
            await formatMessage(message);
          } catch (e) {
            console.error('message formatting failed', e);
          }
        })
      );

      return formattedMessages;
    },

    /**
     * Mark message(s) as Read / Unread.
     */
    async setStatus(message, status) {
      if (message.status === status) return;
      const date = Date.now() / 1000;
      message.status = status;
      const patch = {
        messageId: message._id,
        message: {
          read: status === StatusEnum.RECEIVED ? false : date,
        },
      };

      // Check delivered date
      if (message.recipients[0].delivered === false || !message.recipients[0]) {
        patch.message.delivered = date;
      }

      await this.$store.dispatch('messages/patch', patch);
    },

    /**
     * @param {Message[]} messages - Messages to be modified
     * @param {enum} status
     */
    async updateStatus(messages, status) {
      const messagesArr = Array.isArray(messages) ? messages : [messages];
      await Promise.all(messagesArr.map(msg => this.setStatus(msg, status)));
      this.$store.dispatch('messages/setHotInboxMessages');
    },

    /**
     * Mark message as Unread.
     */
    setStatusUnread() {
      this.modalMessageDetail.shown = null;
      this.updateStatus(this.modalMessageDetail.message, StatusEnum.RECEIVED);
    },

    /**
     * Change selected tab.
     * @param {BoxDirection} tab - new tab.
     */
    selectTab(tab) {
      this.$refs.tableSearchBar.resetSearch();
      this.selectedTab = tab;
      this.shownMessages = this.messages[tab];
    },

    resetCheckedMessages() {
      this.messages[this.selectedTab].map(msg => {
        msg.checked = false;
        return msg;
      });
      this.checkedAll = false;
      this.actionToolbar = false;
    },

    /**
     * Show modalMessageArchive to archive a message.
     */
    showModalMessageArchive() {
      this.modalMessageArchiveShown = true;
    },

    /**
     * Show modalMessageDetail to view a detailed message.
     * @param {Message}
     */
    showModalMessageDetail(message) {
      this.modalMessageDetail.message = message;
      this.modalMessageDetail.shown = this.selectedTab;
    },

    /**
     * Show modalMessageNew to create a message.
     * @param {string} [recipient] - Pre-fill new message with this recipient.
     */
    showModalMessageNew({ message = {}, recipients = [] } = {}) {
      this.modalMessageNew.message = message;
      this.modalMessageNew.recipients = recipients;
      this.modalMessageNew.shown = true;
    },

    /**
     * Submit modalMessageArchive to archive a message.
     */
    submitModalMessageArchive() {
      const checkedIds = new Set([...this.checkedMessages.map(({ _id }) => _id)]);
      this.closeModalMessageArchive();
      checkedIds.forEach(id => this.$store.dispatch('messages/archive', id));
    },
  },
};
</script>

<style lang="scss" scoped>
.datagrid {
  &__table th,
  td {
    font-size: 14px;
    line-height: 21px;
  }

  &__table-row--main {
    &:nth-child(even) {
      background-color: inherit;
    }

    &:has(.read) {
      background-color: #f5f5f5;
    }

    &:has(.received) {
      font-weight: $font-weight-semi-bold;
    }
  }
}

.message {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  padding: $view-standard-padding;
  font-size: 14px;
  font-family: $font-poppins;

  &--delivered {
    font-weight: $font-weight-semi-bold;
  }

  &__modal-body {
    margin-bottom: 10px;
    text-align: center;
  }

  &__row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__toolbar {
    display: flex;

    &-item {
      margin-right: 10px;
      margin-left: 0;

      & > button,
      input {
        margin-left: 0;
      }
    }
  }

  &__body {
    &-message {
      position: absolute;
      top: 115px;
      left: 200px;
      color: $secondary;
      font-weight: $font-weight-semi-bold;
      font-size: 21px;
      line-height: 31px;
    }

    &--empty {
      position: relative;
      padding: 0 50px;

      .illustration {
        width: auto;
        height: auto;
        max-height: 800px;
      }
    }
  }
}
</style>

<i18n locale="fr">
{
  "markAs": {
    "read": "Marquer comme lu",
    "unread": "Marquer comme non lu"
  },
  "priority": {
    "priority": "Priorité",
    "high": "Haute",
    "normal": "Normale"
  },
  "status": {
    "status": "Statut",
    "notReceived": "Non reçu",
    "read": "Lu",
    "received": "Reçu"
  },
  "action": "Action",
  "cancel": "Annuler",
  "confirm": "Confirmer",
  "confirmArchiveMessage": "Êtes-vous sûr de vouloir archiver le(s) message(s) ?",
  "content": "Message",
  "createNewMessage": "Envoyez un nouveau message avec ce bouton.",
  "duplicate": "Dupliquer",
  "inbox": "Boite de réception",
  "mail": "Messagerie",
  "noData": "Aucune donnée disponible.",
  "newMessage": "Nouveau message",
  "outbox": "Messages envoyés",
  "recipient": "Destinataire",
  "refresh": "Rafraîchir",
  "sendDate": "Date d'envoi",
  "sender": "Expéditeur",
  "title": "Titre",
  "trackedRoutes": "Lignes suivies",
  "trackedTrips": "Courses suivies",
  "trip": "Course",
  "unknown": "Inconnu"
}
</i18n>

<i18n locale="en">
{
  "markAs": {
    "read": "Mark as read",
    "unread": "Mark as unread"
  },
  "priority": {
    "priority": "Priority",
    "high": "High",
    "normal": "Normal"
  },
  "status": {
    "status": "Status",
    "notReceived": "Not received",
    "read": "Read",
    "received": "Received"
  },
  "action": "Action",
  "cancel": "Cancel",
  "confirm": "Confirm",
  "confirmArchiveMessage": "Are you sure you want to archive the message(s)?",
  "content": "Message",
  "createNewMessage": "Send a new message with this button.",
  "duplicate": "Duplicate",
  "inbox": "Inbox",
  "mail": "Messages",
  "newMessage": "New message",
  "noData": "No data available.",
  "outbox": "Messages sent",
  "recipient": "Recipient",
  "refresh": "Refresh",
  "sendDate": "Sent",
  "sender": "Sender",
  "title": "Title",
  "trackedRoutes": "Tracked routes",
  "trackedTrips": "Tracked trips",
  "trip": "Trip",
  "unknown": "Unknown"
}
</i18n>

<i18n locale="cz">
{
  "markAs": {
    "read": "Označit jako přečtené",
    "unread": "Označit jako nepřečtené"
  },
  "priority": {
    "priority": "Priorita",
    "high": "Vysoká",
    "normal": "Normální"
  },
  "status": {
    "notReceived": "Nedoručeno",
    "read": "Přečteno",
    "received": "Doručeno",
    "status": "Stav"
  },
  "confirmArchiveMessage": "Opravdu chcete zprávu/y archivovat?",
  "title": "Nadpis",
  "trackedRoutes": "Sledované trasy",
  "trackedTrips": "Sledované jízdy",
  "cancel": "Zrušit",
  "sender": "Odesílatel",
  "archive": "Archiv",
  "confirm": "Potvrdit",
  "content": "Detaily",
  "duplicate": "Duplikovat",
  "recipient": "Příjemce",
  "trip": "Formátovaný název jízdy",
  "sendDate": "Odeslané",
  "outbox": "Odeslané zprávy",
  "mail": "Zprávy",
  "refresh": "Obnovit",
  "newMessage": "Nová zpráva",
  "inbox": "Přijaté",
  "unknown": "Nerozpoznáno"
}
</i18n>

<i18n locale="de">
{
  "markAs": {
    "read": "Als gelesen markieren",
    "unread": "Als ungelesen markieren"
  },
  "priority": {
    "priority": "Priorität",
    "high": "Hoch",
    "normal": "Normal"
  },
  "status": {
    "notReceived": "Nicht erhalten",
    "read": "Gelesen",
    "received": "Empfangen",
    "status": "Status"
  },
  "confirmArchiveMessage": "Willst du diese Nachricht(en) wirklich archivieren?",
  "title": "Titel",
  "trackedRoutes": "Überwachte Strecken",
  "trackedTrips": "Überwachte Fahrten",
  "cancel": "Abbrechen",
  "sender": "Absender",
  "archive": "Archiv",
  "confirm": "Bestätigen",
  "content": "Details",
  "duplicate": "Duplizieren",
  "recipient": "Empfänger",
  "trip": "Formatierter Fahrtname",
  "sendDate": "Gesendet",
  "outbox": "Gesendete Nachrichten",
  "mail": "Nachrichten",
  "refresh": "Neu laden",
  "newMessage": "Neue Nachricht",
  "inbox": "Posteingang",
  "unknown": "Unbekannt"
}
</i18n>

<i18n locale="es">
{
  "markAs": {
    "read": "Marcar como leído",
    "unread": "Marcar como no leído"
  },
  "priority": {
    "priority": "Prioridad",
    "high": "Alta",
    "normal": "Normal"
  },
  "status": {
    "notReceived": "No recibido",
    "read": "Leído",
    "received": "Recibido",
    "status": "Estado"
  },
  "confirmArchiveMessage": "¿Seguro que quieres archivar los mensajes?",
  "title": "Título",
  "trackedRoutes": "Rutas registradas",
  "trackedTrips": "Viajes registrados",
  "cancel": "Cancelar",
  "sender": "Remitente",
  "archive": "Archivo",
  "confirm": "Confirmar",
  "content": "Detalles",
  "duplicate": "Duplicar",
  "recipient": "Destinatario",
  "trip": "Nombre de servicio formateado",
  "sendDate": "Enviado",
  "outbox": "Mensajes enviados",
  "mail": "Mensajes",
  "refresh": "Actualizar",
  "newMessage": "Nuevo mensaje",
  "inbox": "Bandeja de entrada",
  "unknown": "Desconocido"
}
</i18n>

<i18n locale="it">
{
  "markAs": {
    "read": "Segna come letto",
    "unread": "Segna come non letto"
  },
  "priority": {
    "priority": "Priorità",
    "high": "Alta",
    "normal": "Normale"
  },
  "status": {
    "notReceived": "Non ricevuto",
    "read": "Letto",
    "received": "Ricevuto",
    "status": "Stato"
  },
  "confirmArchiveMessage": "Sei sicuro di volevo archiviare il(i) messaggio(i)?",
  "title": "Titolo",
  "trackedRoutes": "Percorsi tracciati",
  "trackedTrips": "Viaggi tracciati",
  "cancel": "Annulla",
  "sender": "Mittente",
  "archive": "Archivia",
  "confirm": "Conferma",
  "content": "Dettagli",
  "duplicate": "Duplica",
  "recipient": "Destinatario",
  "trip": "Nome formattato del servizio",
  "sendDate": "Inviati",
  "outbox": "Messaggi inviati",
  "mail": "Messaggi",
  "refresh": "Aggiorna",
  "newMessage": "Nuovo messaggio",
  "inbox": "Posta in arrivo",
  "unknown": "Sconosciuto"
}
</i18n>

<i18n locale="pl">
{
  "markAs": {
    "read": "Zaznacz jako przeczytane",
    "unread": "Zaznacz jako nieprzeczytane"
  },
  "priority": {
    "priority": "Priorytet",
    "high": "Wysoki",
    "normal": "Normalny"
  },
  "status": {
    "notReceived": "Nie otrzymano",
    "read": "Przeczytano",
    "received": "Otrzymano",
    "status": "Status"
  },
  "confirmArchiveMessage": "Na pewno chcesz zarchiwizować wiadomość/ci?",
  "title": "Tytuł",
  "trackedRoutes": "Śledzone trasy",
  "trackedTrips": "Śledzone podróże",
  "cancel": "Anuluj",
  "sender": "Nadawca",
  "archive": "Archiwizuj",
  "confirm": "Potwierdź",
  "content": "Szczegóły",
  "duplicate": "Duplikuj",
  "recipient": "Odbiorca",
  "trip": "Sformatowana nazwa usługi",
  "sendDate": "Wysłano",
  "outbox": "Wysłane wiadomości",
  "mail": "Wiadomości",
  "refresh": "Odśwież",
  "newMessage": "Nowa wiadomość",
  "inbox": "Skrzynka odbiorcza",
  "unknown": "Nieznany"
}
</i18n>
