<template>
  <Modal class="outbox-modal-details" @close="$emit('close')">
    <template #title>
      <div class="header__title">
        {{ $t('header') }}
      </div>
    </template>

    <template #body>
      <div class="outbox-modal-details__line">
        <div class="outbox-modal-details__value">
          <span class="outbox-modal-details__title">
            {{ $t('from') }}
          </span>
          <router-link
            v-if="message.senderId"
            class="outbox-modal-details__link"
            :to="{
              name: GroupRoute.DEVICE_DETAILLED,
              params: { deviceId: message.senderId },
              query: { date: parseDateToQuery(message.sendDate) },
            }"
          >
            {{ `${message.senderName || ''} <${message.senderId}>` }}
          </router-link>

          <template v-else>
            {{ message.senderName || '-' }}
          </template>
        </div>
        <div class="outbox-modal-details__value">
          <span class="outbox-modal-details__title">
            {{ $t('receivedDate') }}
          </span>
          <span>
            {{ $d(message.sendDate, 'dateShort') }}
          </span>
        </div>
      </div>

      <div class="outbox-modal-details__line">
        <div class="outbox-modal-details__value">
          <span class="outbox-modal-details__title">
            {{ $t('to') }}
          </span>
          <span
            :title="
              message.recipients.length > 1
                ? message.recipients.map(({ senderName }) => senderName).join('\n')
                : null
            "
          >
            {{ message.recipients[0].senderName }}
            {{ message.recipients.length > 1 ? `+ ${message.recipients.length - 1}` : '' }}
          </span>
        </div>
        <div class="outbox-modal-details__value">
          <span class="outbox-modal-details__title">
            {{ $t('time') }}
          </span>
          {{ $d(message.sendDate, 'timeShort') }}
        </div>
      </div>

      <div class="outbox-modal-details__line">
        <div class="outbox-modal-details__value">
          <span class="outbox-modal-details__title">
            {{ $t('trip') }}
          </span>
          <router-link
            v-if="message.tripId"
            class="outbox-modal-details__link"
            :to="{
              name: GroupRoute.TRIP_DETAILED,
              params: { tripId: message.tripId },
              query: { date: parseDateToQuery(message.sendDate) },
            }"
          >
            {{ message.tripName || message.tripId || '-' }}
          </router-link>
          <span v-else>
            {{ message.tripName || message.tripId || '-' }}
          </span>
        </div>
        <div class="outbox-modal-details__value">
          <span class="outbox-modal-details__title">
            {{ $t('priority') }}
          </span>
          <span v-if="message.urgent || message.priority === 'high'">
            {{ $t('urgent') }}
            <font-awesome-icon
              icon="fa-circle-exclamation"
              :style="{
                color: '#f2994a',
              }"
            />
          </span>
          <span v-else>
            {{ $t('normal') }}
          </span>
        </div>
      </div>

      <div class="outbox-modal-details__line outbox-modal-details__line--vertical">
        <span class="outbox-modal-details__title">{{ $t('details') }}</span>
        <p class="outbox-modal-details__content" v-html="message.content" />
      </div>
    </template>

    <!-- Keep empty template to avoid default content -->
    <template #cta><span></span></template>

    <template #secondary-btn>{{ $t('close') }}</template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import { parseDateToQuery } from '@/store/messages';
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'OutboxModalDetails',

  components: {
    Modal,
  },

  props: {
    message: {
      required: true,
      type: Object,
    },
  },

  emits: ['close'],

  data: () => ({
    GroupRoute,
    parseDateToQuery,
  }),
};
</script>

<style lang="scss" scoped>
.outbox-modal-details {
  &__value {
    width: 50%;
  }

  &__link {
    text-decoration: underline;
  }

  &__line {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    max-width: 600px;
    padding: 15px;
    text-overflow: ellipsis;

    &--vertical {
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    margin-right: 5px;
    font-weight: $font-weight-semi-bold;
  }

  &__content {
    width: 100%;
    text-align: start;
  }
}
</style>

<i18n locale="fr">
{
  "close": "Fermer",
  "to": "À",
  "details": "Détails",
  "urgent": "Urgent",
  "priority": "Priorité",
  "time": "Heure",
  "header": "Message envoyé",
  "from": "De",
  "receivedDate": "Date",
  "trip": "Course"
}
</i18n>

<i18n locale="en">
{
  "close": "Fermer",
  "to": "To",
  "details": "Details",
  "urgent": "Urgent",
  "priority": "Priority",
  "time": "Time",
  "header": "Message sent",
  "from": "From",
  "receivedDate": "Date",
  "trip": "Trip"
}
</i18n>

<i18n locale="cz">
{
  "from": "Od",
  "receivedDate": "Doručeno",
  "trip": "Formátovaný název jízdy"
}
</i18n>

<i18n locale="de">
{
  "from": "Von",
  "receivedDate": "Empfangen",
  "trip": "Formatierter Fahrtname"
}
</i18n>

<i18n locale="es">
{
  "header": "",
  "from": "De",
  "receivedDate": "Recibido",
  "trip": "Nombre de servicio formateado"
}
</i18n>

<i18n locale="it">
{
  "header": "",
  "from": "Da",
  "receivedDate": "Ricevuto",
  "trip": "Nome formattato del servizio"
}
</i18n>

<i18n locale="pl">
{
  "header": "",
  "from": "Od",
  "receivedDate": "Otrzymano",
  "trip": "Sformatowana nazwa usługi"
}
</i18n>
