import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));

export const ADMIN_GROUPS_LS_COLUMNS = 'vehiclesPage/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  GROUP_ID: 'group_id',
  NAME: 'name',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'GroupsAdmin',
    hasActions: true,
    columnSelectionLocalStorageKey: ADMIN_GROUPS_LS_COLUMNS,
    searchFields: ['group_id', 'name'],
    defaultSortBy: [{ key: ColumnKey.GROUP_ID, order: SortOrder.ASC }],
    showColumnSelector: false,
    rowIdKey: '_id',
    columns: [
      // GROUP ID
      new DataGridColumn({
        key: ColumnKey.GROUP_ID,
        title: 'column.groupId',
        cellBuilder([value, object], { placeholder, valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: value,
              editMode,
              placeholder,
              type: ColumnKey.GROUP_ID,
              value,
            },
          };
        },
      }),

      // NAME
      new DataGridColumn({
        key: ColumnKey.NAME,
        title: 'column.name',
        cellBuilder([value, object], { placeholder, valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              link: `/${object._id}`,
              editMode,
              placeholder,
              type: ColumnKey.NAME,
              value,
            },
          };
        },
      }),
    ],
  });
};
