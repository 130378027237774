<template>
  <div class="route-filter">
    <label class="form-group__label">{{ $t('routesDisplayTitle') }}</label>

    <div class="form-group">
      <ToggleSwitch
        id="filter-routes"
        :checked="isNotFiltered"
        class="settings-info__toggle-switch"
        @change="checked => (isNotFiltered = checked)"
      />
      <span class="switch-label">{{ $t('routesDisplaySwitch') }}</span>
    </div>

    <div v-if="!isNotFiltered">
      <div class="form-group__sub-label">{{ $t('routesDisplayhint') }}</div>

      <Selector
        id="route-filter"
        v-model:value="routeFilter"
        mode="tags"
        :open-direction="openDirection"
        :options="routeFilterOptions"
        :placeholder="$t('line')"
      >
        <template #tag="{ option, handleTagRemove, disabled }">
          <div
            class="multiselect-tag"
            :style="{ color: option.routeTextColor, backgroundColor: option.routeColor }"
          >
            {{ option.label }}
            <span
              v-if="!disabled"
              class="multiselect-tag-remove"
              @mousedown.prevent="handleTagRemove(option, $event)"
            >
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
      </Selector>
    </div>
  </div>
</template>

<script>
import Selector from '@/components/ui/Selector.vue';
import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';

export default {
  name: 'RouteFilterSelector',

  components: {
    Selector,
    ToggleSwitch,
  },

  props: {
    routesFiltered: {
      type: Array,
      default: () => [],
    },
    openDirection: {
      type: String,
      default: 'bottom',
    },
  },

  emits: ['update:routesFiltered'],

  data: () => ({
    routeFilter: [],
    routeFilterOptions: [],
    isNotFiltered: true,
  }),

  computed: {
    routesFilteredList() {
      if (this.isNotFiltered) return [];
      return this.routeFilter;
    },
  },

  watch: {
    routesFilteredList: {
      deep: true,
      handler() {
        this.$emit('update:routesFiltered', this.routesFilteredList);
      },
    },
  },

  async created() {
    await this.fetchRoutes();
    if (this.routesFiltered.length > 0) {
      this.isNotFiltered = false;
      this.routeFilter = this.routesFiltered;
    }
  },

  methods: {
    /**
     * Get all routes from API and set data routes
     */
    async fetchRoutes() {
      const routes = Object.freeze(await this.$store.dispatch('gtfs/getRoutesMap'));
      this.routeFilterOptions = Object.values(routes).map(route => {
        return {
          label: route.route_short_name,
          value: route.route_id,
          routeColor: `#${route.route_color}`,
          routeTextColor: `#${route.route_text_color}`,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.route-filter {
  margin-bottom: 15px;

  .multiselect-tags-search {
    background-color: transparent;
  }

  .multiselect.is-active {
    border: 1px solid #b3b3b3 !important;
    border-radius: 2px;
    box-shadow: 0 0 10px rgb(79 79 79 / 31%) !important;
  }

  .switch-label {
    margin-left: 10px;
  }

  .form-group__input--multiselect {
    input:focus {
      box-shadow: none !important;
    }
  }
}
</style>

<i18n locale="fr">
  {
    "line": "Lignes",
    "routesDisplayTitle": "Affichage des lignes",
    "routesDisplaySwitch": "Toutes les lignes sont affichées dans l'application voyageurs",
    "routesDisplayhint": "Sélectionnez les lignes à masquer"
  }
  </i18n>

<i18n locale="en">
  {
    "line": "Routes",
    "routesDisplayTitle": "Routes display",
    "routesDisplaySwitch": "All routes are displayed in the passenger app",
    "routesDisplayhint": "Select the routes to be hidden"
  }
  </i18n>
