<template>
  <li class="language dropdown" :class="{ 'dropdown--open': dropdownOpen }">
    <button
      class="nav__item dropdown__button"
      aria-haspopup="true"
      aria-expanded="false"
      @click="dropdownToggle()"
      @mousedown.prevent
    >
      {{ $t(current) }}
      <span :class="`fi fi-${$t('flag', current)}`" />
      <font-awesome-icon :icon="`fa-sort-${dropdownOpen === true ? 'up' : 'down'}`" />
    </button>

    <ul class="dropdown__menu">
      <li v-for="locale in locales" :key="locale" class="dropdown__item" @click="current = locale">
        <span :class="`fi fi-${$t('flag', locale)}`" />
        &nbsp;
        <span>{{ $t(locale) }}</span>
      </li>
    </ul>
  </li>
</template>

<script>
import { setLocale } from 'yup';
import i18n from '@/i18n';
import yupFr from '@/locales/yup/fr';
import yupEn from '@/locales/yup/en';

export default {
  name: 'Language',

  data: () => ({
    /** @type {Boolean} */
    dropdownOpen: false,
  }),

  computed: {
    current: {
      /** @return {string} */
      get() {
        return i18n.global.locale;
      },

      /** @param {string} locale */
      set(locale) {
        i18n.global.locale = locale;
        if (locale === 'fr') {
          setLocale(yupFr);
        } else {
          setLocale(yupEn);
        }

        localStorage.setItem('locale', locale);
      },
    },

    /** @return {Array<string>} */
    locales() {
      return Object.keys(this.$i18n.messages);
    },
  },

  methods: {
    /**
     * Close dropdown.
     */
    closeDropdown() {
      this.dropdownOpen = false;
      window.removeEventListener('click', this.closeDropdown);
    },

    /**
     * Open dropdown.
     */
    openDropdown() {
      this.dropdownOpen = true;
      window.removeEventListener('click', this.closeDropdown);
      setTimeout(() => window.addEventListener('click', this.closeDropdown), 10);
    },

    /**
     * Toggle dropdown.
     */
    dropdownToggle() {
      if (this.dropdownOpen === false) {
        this.openDropdown();
      } else {
        this.closeDropdown();
      }
    },
  },
};
</script>

<style lang="scss">
.language {
  &.dropdown--open {
    .dropdown__button {
      background-color: rgb(0 0 0 / 12%);
      color: $text-light;
    }
  }

  .dropdown__item {
    cursor: pointer;
  }

  .dropdown__menu {
    right: 0;
  }

  .fa-sort-up {
    vertical-align: middle;
    margin-top: 8px;
    margin-left: 4px;
    color: $text-light;
  }

  .fa-sort-down {
    vertical-align: middle;
    margin-bottom: 8px;
    margin-left: 4px;
    color: $text-light;
  }
}
</style>

<i18n>
{
  "fr": {
    "languages": "Langues",
    "flag": "fr"
  },
  "en": {
    "languages": "Languages",
    "flag": "gb",
    "fr": "Fr",
    "en": "En",
    "cz": "Cz",
    "it": "It",
    "pl": "Pl",
    "de": "De",
    "es": "Es"
  },
  "es": {
    "languages": "Idioma",
    "flag": "es"
  },
  "de": {
    "languages": "Sprache",
    "flag": "de"
  },
  "cz": {
    "languages": "Jazyk",
    "flag": "cz"
  },
  "pl": {
    "languages": "Język",
    "flag": "pl"
  },
  "it": {
    "languages": "Lingua",
    "flag": "it"
  }
}
</i18n>
