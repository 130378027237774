/**
 * @member {import('../models/DataGrid.models').DataGrid} datagrid
 */

/** @enum {Symbol} */
export const SortTypes = {
  ASC: Symbol('asc'),
  DESC: Symbol('desc'),
};

export default {
  name: 'Sortable',

  data() {
    return {
      sortColumn: null,
      sorting: false,
      sortType: null,
    };
  },

  created() {
    this.resetDefaultSort();
  },

  methods: {
    /**
     * Sort data by column
     *
     * @param {import('../models/DataGrid.models').DataGridColumn} column
     */
    sort(column) {
      if (!this.isSortable(column)) return null;

      const isSameColumn = this.sortColumn && column.isEqual(this.sortColumn);

      if (!isSameColumn) {
        this.sortType = SortTypes.ASC;
      } else if (this.sortType === SortTypes.ASC) {
        this.sortType = SortTypes.DESC;
      } else if (column.isEqual(this.datagrid.defaultSortColumn)) {
        this.sortType = SortTypes.ASC;
      } else {
        return this.datagrid.defaultSortColumn ? this.resetDefaultSort() : this.unsort();
      }

      this.sortColumn = column;
      this.sorting = true;
      return null;
    },

    /**
     * Is sortable ?
     *
     * @param {import('../models/DataGrid.models').DataGridColumn} column
     * @return {Boolean}
     */
    isSortable(column) {
      return this.datagrid.isSortable() && column.isSortable();
    },

    /**
     * Unsort data
     *
     */
    unsort() {
      this.sorting = false;
      this.sortColumn = null;
      this.sortType = null;
    },

    /**
     * Reset default sort
     *
     */
    resetDefaultSort() {
      this.sortColumn = this.datagrid.defaultSortColumn;
      this.sortType = this.datagrid.defaultSortType ?? SortTypes.ASC;
      this.sorting = true;
    },
  },
};
