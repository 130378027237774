/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

import Api from '@/api';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';

/** @typedef {typeof state} State */
const state = {
  /** @type {?string} */
  dateGtfs: null,

  /** @type {Array<ActivityLogEntry>} */
  entries: [],
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,
  state,

  getters: {
    /**
     * @callback GetEntryCallback
     * @param {string} deviceId
     * @param {number} ts
     * @return {?ActivityLogEntry}
     */
    /** @return {GetEntryCallback} */
    getEntry: (state, getters) => (deviceId, ts) => {
      const index = getters.getEntryIndex(deviceId, ts);
      if (index === -1) return null;

      return state.entries[index];
    },

    /**
     * @callback GetEntryIndexCallback
     * @param {string} deviceId
     * @param {number} ts
     * @return {number}
     */
    /** @return {GetEntryIndexCallback} */
    getEntryIndex: state => (deviceId, ts) => {
      const entries = state.entries.filter(
        entry => entry.device_id === deviceId && (!entry.checkout || ts < entry.checkout.ts)
      );
      let currentSelectedEntry = null;
      entries.forEach(entry => {
        if (entry.check_in.ts < ts && entry.checkout && entry.checkout.ts > ts) {
          currentSelectedEntry = entry;
        }
      });
      // handles the no-checkout scenario
      if (entries.length > 0 && !currentSelectedEntry) {
        [currentSelectedEntry] = entries;
        entries.forEach(entry => {
          if (ts > entry.check_in.ts && currentSelectedEntry.check_in.ts < entry.check_in.ts) {
            currentSelectedEntry = entry;
          }
        });
      }
      return state.entries.indexOf(currentSelectedEntry);
    },
  },

  mutations: {
    /**
     * @param state
     * @param {ActivityLogEntry} entry
     */
    addEntry(state, entry) {
      state.entries.push(entry);
    },

    /**
     * @param state
     * @param {Object} payload
     * @param {number} payload.index
     * @param {Partial<ActivityLogEntry>} payload.patch
     */
    patchEntry(state, { index, patch }) {
      if (state.entries[index]) {
        Object.assign(state.entries[index], patch);
      }
    },

    /**
     * @param state
     * @param {Object} payload
     * @param {string} payload.dateGtfs
     * @param {Array<ActivityLogEntry>} payload.entries
     */
    setEntries(state, { dateGtfs, entries }) {
      state.dateGtfs = dateGtfs;
      state.entries = entries;
    },
  },

  actions: {
    /**
     * @param context
     * @param {string} dateGtfs
     */
    async loadEntries({ state, commit, rootGetters }, dateGtfs) {
      const load = dateGtfs !== state.dateGtfs || dateObjToGtfsFormat(new Date()) === dateGtfs;

      if (load) {
        commit('setEntries', {
          dateGtfs,
          entries: await Api.getActivityLog(rootGetters.group._id, dateGtfs),
        });
      }
    },

    /**
     * @param context
     * @param {Array<import('./devices').Device>} data
     */
    async updateEntries({ state, getters, commit, rootState, rootGetters }, data) {
      // Only live-update "today"
      if (dateObjToGtfsFormat(new Date()) !== state.dateGtfs) return;

      await Promise.all(
        data.map(async update => {
          const device = rootState.devices.list[update.device_id];
          if (
            device &&
            'current_activity_log_assignation' in update &&
            update.current_activity_log_assignation !== device.current_activity_log_assignation
          ) {
            // Update previous to get checkout info
            if (device.current_activity_log_assignation != null) {
              /** @type {number} */
              const index = getters.getEntryIndex(device.device_id, device.ts);
              const patch = await Api.getActivityLogEntry(
                rootGetters.group._id,
                device.current_activity_log_assignation
              );
              commit('patchEntry', { index, patch });
            }

            // Get new to add to list
            if (update.current_activity_log_assignation != null) {
              const entry = await Api.getActivityLogEntry(
                rootGetters.group._id,
                update.current_activity_log_assignation
              );
              commit('addEntry', entry);
            }
          }
        })
      );
    },
  },
});

/**
 * @typedef {Object} ActivityLogEntry
 * @property {boolean} [anonymized]
 * @property {{ts: number}} check_in
 * @property {string} device_id
 * @property {string} driver_id
 * @property {string} start_date
 * @property {string} vehicle_id
 * @property {Checkout} [checkout]
 * @property {Array<DutyTrip>} [trips]
 */

/**
 * @typedef {Object} DutyTrip
 * @property {string} formatted_name
 * @property {string} id
 * @property {string} start_date
 */

/**
 * @typedef {Object} Checkout
 * @property {number} ts
 * @property {boolean} [check_empty]
 * @property {string} [comments]
 */
