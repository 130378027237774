<template>
  <Modal
    class="modal-integration-connector"
    :class="{ 'modal-integration-connector__scrollable': hasField('hosts') }"
    @close="$emit('close')"
  >
    <template #title>
      <div class="modal-integration-connector__header">
        <div class="modal-integration-connector__header-image-container">
          <div class="modal-integration-connector__header-badge">
            <img
              class="modal-integration-connector__header-image"
              :src="integrationType.icon ? integrationType.icon : DefaultIcon"
            />
          </div>
        </div>
        <div class="modal-integration-connector__header-title-container">
          <span>{{ $t(integrationType.labelKey) }}</span>
        </div>
      </div>
    </template>
    <template #body>
      <template v-if="integrationType.connectors.length > 1">
        <div class="form-group">
          <label class="form-group__label" for="connector-select">Type</label>
          <Selector
            id="connector-select"
            v-model:value="connectorType"
            :options="integrationType.connectors"
            track-by="id"
            object
            value-prop="id"
            label-prop="labelKey"
            mode="single"
            required
          >
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <span class="multiselect-single-label-text">
                  {{ $t(value.labelKey) }}
                </span>
              </div>
            </template>
            <template #option="{ option }">
              {{ $t(option.labelKey) }}
            </template>
          </Selector>
        </div>
      </template>
      <ConnectorFieldInput
        v-if="hasField('api_url')"
        name="api_url"
        :label="$t('api_url')"
      ></ConnectorFieldInput>
      <ConnectorFieldInput
        v-if="hasField('api_key')"
        name="api_key"
        :label="$t('api_key')"
      ></ConnectorFieldInput>
      <ConnectorFieldIpAddress v-if="hasField('ip_address')" name="ip_address"></ConnectorFieldIpAddress>
      <ConnectorFieldPort v-if="hasField('port')" name="port"></ConnectorFieldPort>
      <ConnectorFieldGtfsField v-if="hasField('gtfs_field')" name="gtfs_field"></ConnectorFieldGtfsField>
      <ConnectorFieldHosts v-if="hasField('hosts')" name="hosts"></ConnectorFieldHosts>
      <ConnectorFieldsetRouter v-if="hasField('router')" name="router"></ConnectorFieldsetRouter>
    </template>
    <template #cta>
      <Btn :disabled="!meta.valid" type="primary" @click="handleSaveClick">
        {{ $t('save') }}
      </Btn>
    </template>
  </Modal>
</template>

<script setup>
import cloneDeep from 'clone-deep';
import { useForm } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import * as yup from 'yup';

import DefaultIcon from '@/assets/img/integrations/default.png';
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import Selector from '@/components/ui/Selector.vue';
import ConnectorFieldGtfsField, { GTFS_FIELDS } from '@/pages/IntegrationsPage/ConnectorFieldGtfsField.vue';
import ConnectorFieldHosts from '@/pages/IntegrationsPage/ConnectorFieldHosts.vue';
import ConnectorFieldInput from '@/pages/IntegrationsPage/ConnectorFieldInput.vue';
import ConnectorFieldIpAddress from '@/pages/IntegrationsPage/ConnectorFieldIpAddress.vue';
import ConnectorFieldPort from '@/pages/IntegrationsPage/ConnectorFieldPort.vue';
import ConnectorFieldsetRouter from '@/pages/IntegrationsPage/ConnectorFieldsetRouter.vue';

const props = defineProps({
  modelValue: {
    /** @type {import('vue').PropType<import('/@store/integrations').Integration>} */
    type: Object,
    default: () => undefined,
  },
  /** @type {import('vue').PropType<import('/@store/integrations').IntegrationType>} */
  integrationType: {
    type: Object,
    required: true,
  },
});

// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits(['update:modelValue', 'close']);

const connectorType = ref();

/**
 * @param {string} field
 *
 * @returns {boolean}
 */
function hasField(field) {
  if (connectorType.value?.defaultConfiguration === undefined) {
    return false;
  }
  return field in connectorType.value.defaultConfiguration;
}

/**
 * @param {string} field
 */
function fieldValidation(field) {
  if (connectorType.value?.validation === undefined) {
    return false;
  }
  return connectorType.value.validation[field];
}

const validationSchema = computed(() => {
  const spec = {};

  if (hasField('api_url')) {
    spec.api_url = yup.string().required().url();
  }

  if (hasField('ip_address')) {
    spec.ip_address = yup.string().required().ip();
  }

  if (hasField('port')) {
    spec.port = yup.number().min(1024).max(65535);
  }

  if (hasField('gtfs_field')) {
    spec.gtfs_field = yup.string().required().oneOf(GTFS_FIELDS);
  }

  if (hasField('hosts')) {
    spec.hosts = yup.array().of(yup.string().required().ipOptionalPort()).required().min(1);
  }

  if (connectorType.value?.defaultConfiguration) {
    for (const field in connectorType.value.defaultConfiguration) {
      const validation = fieldValidation(field);
      if (typeof validation === 'function') {
        spec[field] = validation(spec[field]);
      } else if (validation) {
        spec[field] = validation;
      }
    }
  }

  return yup.object(spec);
});

const { values, meta, setValues, resetForm } = useForm({
  validationSchema,
  keepValuesOnUnmount: true,
});

watch(
  () => props.integrationType,
  integrationType => {
    if (integrationType.connectors.length === 1) {
      [connectorType.value] = integrationType.connectors;
    }
  },
  { immediate: true }
);

watch(
  [connectorType, () => props.modelValue],
  ([connectorTypeValue, modelValue]) => {
    if (modelValue) {
      connectorType.value = modelValue.connector;
    }

    const configuration = modelValue
      ? modelValue.configuration
      : cloneDeep(connectorTypeValue?.defaultConfiguration ?? {});

    resetForm();
    setValues(configuration);
  },
  { immediate: true }
);

function handleSaveClick() {
  Object.keys(values).forEach(key => (values[key] === undefined ? delete values[key] : {}));

  const value = {
    ...props.modelValue,
    type: props.integrationType,
    connector: connectorType.value,
    configuration: values,
  };

  emit('update:modelValue', value);
}
</script>

<style lang="scss">
.modal-integration-connector {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header-badge {
    overflow: hidden;
    width: 35px;
    height: 35px;
    border: 1px solid $border;
    border-radius: 50px;
    text-align: center;
  }

  &__header-image {
    height: 30px;
  }

  &__header-title-container {
    padding-left: 10px;
  }

  &__connector-select-label {
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
  }

  .modal__body {
    overflow-y: initial;
    margin-bottom: 0;
  }

  &__scrollable {
    .modal__body {
      overflow-y: scroll;
    }
  }
}
</style>

<i18n locale="fr">
{
  "api_url": "Adresse URL de l'API",
  "api_key": "Clé d'API"
}

</i18n>

<i18n locale="en">
{
  "api_url": "API URL address",
  "api_key": "API key"
}
</i18n>
