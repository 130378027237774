<template>
  <div class="cell-trip-modification">
    <div v-if="updatesCount > 0" :class="hasEditPermission ? 'cursor' : ''">
      <button
        class="tag"
        :class="includesCancellation ? 'tag--canceled' : 'tag--warning'"
        :title="tooltip"
        @click="showModal"
      >
        {{ $t('tripModification', { count: updatesCount }) }}
      </button>
    </div>
    <Btn v-else :disabled="hasChildren || !hasEditPermission" type="icon-only" @click="showModal">
      <font-awesome-icon icon="fa-plus" />
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import api, { UpdateTypeV2 } from '@/api';
import { ModalType, ScheduleRelationship } from '@/store/trips';

export default {
  name: 'CellTripModification',

  components: {
    Btn,
  },

  props: {
    hasChildren: {
      default: true,
      type: Boolean,
    },

    hasEditPermission: {
      default: true,
      type: Boolean,
    },

    tripId: {
      type: String,
      default: null,
    },

    /** @type {import('vue').Prop<import('@/api').TripUpdatesV2>} */
    updates: {
      type: Object,
      default: null,
    },
  },

  emits: ['showModal'],

  data() {
    return {
      ScheduleRelationship,
      ModalType,
      trip: null,
      skippedStopNames: [],
      stops: [],
    };
  },

  computed: {
    /** @return {Array<number>} */
    skippedStopsSequences() {
      return this.updates.skipped_stop_sequences;
    },

    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters.group.current_file;
    },

    /** @return {boolean} */
    includesCancellation() {
      return this.updates.canceled;
    },

    /** @return {string} */
    tooltip() {
      let tooltipContent = '';
      if (this.updates.delay) {
        tooltipContent = tooltipContent.concat('\n', `${this.$t('delayed', [this.updates.delay / 60])}`);
      }
      if (this.updates.canceled) {
        tooltipContent = tooltipContent.concat('\n', `${this.$t('canceled')}`);
      }
      if (this.updates.skipped_stop_sequences) {
        this.skippedStopNames.forEach(stopName => {
          tooltipContent = tooltipContent.concat('\n', `${this.$t('noService', [stopName])}`);
        });
      }
      return tooltipContent;
    },

    /** @return {number} */
    updatesCount() {
      const updates = { ...this.updates };
      [UpdateTypeV2.COMMENT, UpdateTypeV2.STOP_INFO].forEach(key => {
        // remove comment and stop info from the updates
        if (updates[key]) delete updates[key];
      });
      return Object.values(updates).reduce((acc, value) => {
        if (!value) return acc;
        return Array.isArray(value) ? acc + value.length : acc + 1; // count each entry when value is an array
      }, 0);
    },
  },

  watch: {
    skippedStopsSequences: {
      async handler() {
        if (this.skippedStopsSequences?.length > 0) {
          this.getSkippedStopsNames();
        }
      },
      immediate: true,
    },
  },

  async created() {
    this.stops = await this.$store.dispatch('gtfs/getStopsMap', { gtfsId: this.gtfsId });
  },

  methods: {
    /**
     * @param {string} tripId
     * @return {Promise<import('@/store/gtfs').Trip>}
     */
    async getTrip(tripId) {
      const trip = await api.trips.getTripFromGtfs(this.groupId, this.gtfsId, tripId);
      return trip;
    },

    async getSkippedStopsNames() {
      this.skippedStopNames = [];
      const trip = await this.getTrip(this.tripId);
      // get stop_id for each skipped stop sequence
      const skippedStopIds = trip.stop_times
        .filter(stopTime => this.skippedStopsSequences.includes(stopTime.stop_sequence))
        .map(stopTime => stopTime.stop_id);

      // get stops names from skipped stops id
      skippedStopIds.forEach(stopId => {
        const stop = Object.values(this.stops).find(stop => stop.stop_id === stopId);
        if (stop) {
          this.skippedStopNames.push(stop.stop_name);
        }
      });
    },

    /** Show a modal */
    showModal() {
      if (this.hasEditPermission) {
        this.$emit('showModal');
      }
    },
  },
};
</script>

<style lang="scss">
.cell-trip-modification {
  .cursor {
    button {
      cursor: pointer;
    }
  }
}
</style>

<i18n locale="fr">
{
  "advanced": "Avance de {0} min",
  "andOthers": "et {0} autres",
  "canceled": "Annulée",
  "delayed": "Retard de {0} min",
  "noService": "{0} : ne pas desservir",
  "tripModification": "{count} modification de course | {count} modifications de course"
}
</i18n>

<i18n locale="en">
{
  "advanced": "Advance of {0} min",
  "andOthers": "and {0} others",
  "canceled": "Canceled",
  "delayed": "Delay of {0} min",
  "noService": "{0}: do not serve",
  "tripModification": "{count} trip modification | {count} trip modifications"
}
</i18n>
