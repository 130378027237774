<template>
  <div class="incident-list">
    <NoIncident v-if="emptyPage" :zendesk-connector-active="zendeskConnectorActive" />
    <table v-else class="table">
      <thead>
        <tr class="table__head">
          <th
            class="table__head-cell"
            @click="sortBy('trip_short_name', e => (incidentTrips[e.id] || {})['trip_short_name'])"
          >
            {{ $t('columns.trip_short_name') }}
            <font-awesome-icon v-if="sort.attribute === 'trip_short_name'" :icon="'fa-sort-' + sort.order" />
          </th>

          <th
            class="table__head-cell"
            @click="sortBy('incident_label', e => (incidentInfos[e.id] || {})['incident_label'])"
          >
            {{ $t('columns.incident_code') }}
            <font-awesome-icon v-if="sort.attribute === 'incident_label'" :icon="'fa-sort-' + sort.order" />
            <ColumnFilter
              :v-if="loaded"
              :column="'incident_label'"
              :opened="dropdownOpened === 'incident_label'"
              :options="arrayToObject(filteredAttributesValues['incident_label'])"
              :checked="arrayToTrueObject(incidentFilter['incident_label'])"
              @change="(checked, option) => editIncidentFilter('incident_label', checked, option)"
              @open="dropdownToggle('incident_label')"
            />
          </th>

          <th class="table__head-cell" @click="sortBy('id')">
            {{ $t('columns.incident_id') }}
            <font-awesome-icon v-if="sort.attribute === 'id'" :icon="'fa-sort-' + sort.order" />
          </th>

          <th class="table__head-cell" @click="sortBy('subject')">
            {{ $t('columns.subject') }}
            <font-awesome-icon v-if="sort.attribute === 'subject'" :icon="'fa-sort-' + sort.order" />
          </th>

          <th
            class="table__head-cell"
            @click="sortBy('requester', e => (incidentInfos[e.id] || {})['requester'])"
          >
            {{ $t('columns.requester') }}
            <font-awesome-icon v-if="sort.attribute === 'requester'" :icon="'fa-sort-' + sort.order" />
            <ColumnFilter
              v-if="loaded"
              :column="'requester'"
              :opened="dropdownOpened === 'requester'"
              :options="arrayToObject(filteredAttributesValues['requester'])"
              :checked="arrayToTrueObject(incidentFilter['requester'])"
              @change="(checked, option) => editIncidentFilter('requester', checked, option)"
              @open="dropdownToggle('requester')"
            />
          </th>

          <th
            class="table__head-cell"
            @click="sortBy('formatted_trip_name', e => getFormattedTripName(e.id))"
          >
            {{ $t('columns.formattedTripName') }}
            <font-awesome-icon
              v-if="sort.attribute === 'formatted_trip_name'"
              :icon="'fa-sort-' + sort.order"
            />
          </th>

          <th
            class="table__head-cell"
            @click="sortBy('trip_id', e => (incidentInfos[e.id] || {})['trip_id'])"
          >
            {{ $t('columns.trip_id') }}
            <font-awesome-icon v-if="sort.attribute === 'trip_id'" :icon="'fa-sort-' + sort.order" />
          </th>

          <th
            class="table__head-cell"
            @click="sortBy('team_id', e => (incidentTrips[e.id] || {})['team_id'])"
          >
            {{ $t('columns.team_id') }}
            <font-awesome-icon v-if="sort.attribute === 'team_id'" :icon="'fa-sort-' + sort.order" />
            <ColumnFilter
              v-if="loaded"
              :column="'team_id'"
              :opened="dropdownOpened === 'team_id'"
              :options="arrayToObject(filteredAttributesValues['team_id'])"
              :checked="arrayToTrueObject(incidentFilter['team_id'])"
              @change="(checked, option) => editIncidentFilter('team_id', checked, option)"
              @open="dropdownToggle('team_id')"
            />
          </th>
          <th class="table__head-cell" @click="sortBy('created_at')">
            {{ $t('columns.date_creation') }}
            <font-awesome-icon v-if="sort.attribute === 'created_at'" :icon="'fa-sort-' + sort.order" />
          </th>

          <th class="table__head-cell" @click="sortBy('updated_at')">
            {{ $t('columns.date_updated') }}
            <font-awesome-icon v-if="sort.attribute === 'updated_at'" :icon="'fa-sort-' + sort.order" />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(incident, index) in shownIncident" :key="index" class="table__row">
          <td class="table__cell">
            <section v-if="(incidentInfos[incident.id] || {}).trip_id">
              <router-link
                v-if="hasViewTripPermission"
                :to="{
                  name: GroupRoute.TRIP_DETAILED,
                  params: { tripId: (incidentInfos[incident.id] || {}).trip_id },
                  query: {
                    date: (incidentInfos[incident.id] || {}).start_date
                      ? dateToParam((incidentInfos[incident.id] || {}).start_date)
                      : undefined,
                  },
                }"
              >
                {{ (incidentTrips[incident.id] || {}).trip_short_name }}
              </router-link>
              <span v-else>{{ (incidentTrips[incident.id] || {}).trip_short_name }}</span>
            </section>
          </td>

          <td class="table__cell">
            {{ (incidentInfos[incident.id] || {}).incident_label }}
          </td>

          <td class="table__cell">
            <a
              v-if="$store.getters.hasPermission(Permission.EDIT_INCIDENTS)"
              :href="`https://${group.option_trip_incidents.domain}/tickets/${incident.id}`"
              target="_blank"
            >
              {{ incident.id }}
            </a>
            <span v-else>{{ incident.id }}</span>
          </td>

          <td class="table__cell">
            {{ incident.subject }}
          </td>

          <td class="table__cell">
            {{ (incidentInfos[incident.id] || {}).requester }}
          </td>

          <td class="table__cell">
            <section v-if="(incidentInfos[incident.id] || {}).trip_id">
              <router-link
                v-if="hasViewTripPermission"
                :to="{
                  name: GroupRoute.TRIP_DETAILED,
                  params: { tripId: (incidentInfos[incident.id] || {}).trip_id },
                  query: {
                    date: (incidentInfos[incident.id] || {}).start_date
                      ? dateToParam((incidentInfos[incident.id] || {}).start_date)
                      : undefined,
                  },
                }"
              >
                {{ getFormattedTripName(incident.id) }}
              </router-link>
              <span v-else>{{ getFormattedTripName(incident.id) }}</span>
            </section>
          </td>

          <td class="table__cell">
            <section v-if="(incidentInfos[incident.id] || {}).trip_id">
              <router-link
                v-if="hasViewTripPermission"
                :to="{
                  name: GroupRoute.TRIP_DETAILED,
                  params: { tripId: (incidentInfos[incident.id] || {}).trip_id },
                  query: {
                    date: (incidentInfos[incident.id] || {}).start_date
                      ? dateToParam((incidentInfos[incident.id] || {}).start_date)
                      : undefined,
                  },
                }"
              >
                {{ (incidentInfos[incident.id] || {}).trip_id }}
              </router-link>
              <span v-else>{{ (incidentInfos[incident.id] || {}).trip_id }}</span>
            </section>
          </td>

          <td class="table__cell">
            {{ (incidentTrips[incident.id] || {}).team_id }}
          </td>

          <td class="table__cell">
            {{ $d(new Date(incident.created_at), 'datetimeShort') }}
          </td>

          <td class="table__cell">
            {{ $d(new Date(incident.updated_at), 'datetimeShort') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { trips } from '@/api';
import { dateGtfsFormatToObj, getISODate } from '@/libs/helpers/dates';
import { Permission } from '@/store';
import { GroupRoute } from '@/libs/routing';

import ColumnFilter from './ColumnFilter.vue';
import NoIncident from './NoIncident.vue';

/**
 * @param {string} description
 * @return {IncidentInfos}
 */
const parseDescription = description => {
  const lastLine = (() => {
    const lines = description.split('\n');
    return lines[lines.length - 1];
  })();

  try {
    const infos = JSON.parse(lastLine);

    if (infos.gtfs_id != null) return infos;
  } catch (e) {
    console.error('parsing description failed', e);
  }
  return null;
};

/** @enum {string} */
const Order = {
  ASC: 'down',
  DESC: 'up',
};

/** @enum {string} */
const FilteredAttributes = {
  TEAM_ID: 'team_id',
  REQUESTER: 'requester',
  INCIDENT_LABEL: 'incident_label',
};

export default {
  name: 'IncidentList',

  components: {
    ColumnFilter,
    NoIncident,
  },
  data: () => ({
    GroupRoute,
    Permission,

    dropdownOpened: null,

    /** @type {Boolean} */
    emptyPage: false,

    /** @type {Object.<FilteredAttributes, Object.<string, boolean>>} */
    filteredAttributesValues: {},
    // enum binding
    FilteredAttributes,
    /** @type {{[tripDescriptor: string]: string}} */
    formattedTripNames: {},
    incidentFilter: {
      team_id: [],
      requester: [],
      incident_label: [],
    },
    /** @type {Array<import('@/api').TripIncident>} */
    list: [],
    loaded: false,

    /** @type {{[incidentId: string]: import('@/store/gtfs').Trip}} */
    incidentTrips: {},
    shownIncident: [],

    sort: {
      /** @type {string} */
      attribute: null,
      /** @type {Order} */
      order: null,
    },
    /** @type {Boolean} */
    zendeskConnectorActive: true,
  }),

  computed: {
    /** @return {(gtfsId: string) => {[tripId: string]: import('@/store/gtfs').Trip}} */
    getGtfsTrips() {
      return gtfsId => this.$store.getters['gtfs/getCachedGtfsTable'](gtfsId, 'trips');
    },

    /** @return {boolean} */
    hasViewTripPermission() {
      return this.$store.getters.hasPermission(Permission.VIEW_TRIP_VIEW);
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {{[incidentId: string]: IncidentInfos}} */
    incidentInfos() {
      return this.list.reduce((acc, incident) => {
        const infos = incident.description ? parseDescription(incident.description) : null;

        if (infos) {
          acc[incident.id] = infos;
        }

        return acc;
      }, {});
    },
  },

  watch: {
    async incidentInfos() {
      await Promise.all(
        Object.entries(this.incidentInfos).map(async ([incidentId, infos]) => {
          const name = await this.$store.dispatch('gtfs/formatTripName', {
            gtfsId: infos.gtfs_id,
            tripId: infos.trip_id,
            date: dateGtfsFormatToObj(infos.start_date),
          });
          const index = infos.gtfs_id + infos.trip_id + infos.start_date;

          if (name) {
            if (index in this.formattedTripNames) {
              this.formattedTripNames[index] = name;
            } else {
              this.formattedTripNames[index] = name;
            }
          } else if (index in this.formattedTripNames) {
            delete this.formattedTripNames[index];
          }

          if (incidentId in this.incidentTrips) {
            this.incidentTrips[incidentId] = this.getTrip(incidentId);
          } else {
            this.incidentTrips[incidentId] = this.getTrip(incidentId);
          }
        })
      );
      if (!this.loaded) {
        this.setFilteredAttributesValues('team_id', this.incidentTrips);
        this.setFilteredAttributesValues('requester', this.incidentInfos);
        this.setFilteredAttributesValues('incident_label', this.incidentInfos);
        this.setShownIncident();
        this.loaded = true;
      }
    },
  },

  async created() {
    if (
      !this.$store.getters.group.option_trip_incidents ||
      this.$store.getters.group.option_trip_incidents.length === 0
    ) {
      this.emptyPage = true;
      this.zendeskConnectorActive = false;
    } else {
      const list = await trips.getIncidents(this.group._id);
      if (list.length === 0) {
        this.emptyPage = true;
      } else {
        list.sort((a, b) => (b.updated_at > a.updated_at ? 1 : -1));
        this.list = list;
        this.setShownIncident();
      }
    }
  },

  methods: {
    /**
     * Turn an array to object {value: value}
     * @param {array} array
     */
    arrayToObject(array) {
      if (!this.loaded) return {};
      return array.reduce((o, val) => {
        o[val] = val;
        return o;
      }, {});
    },

    /**
     * Turn an array to object {value: true}
     * @param {array} array
     */
    arrayToTrueObject(array) {
      if (!this.loaded) return {};
      return array.reduce((o, val) => {
        o[val] = true;
        return o;
      }, {});
    },

    /**
     * check all box in the dropdown param
     * @param {string} dropdown
     */
    checkAll(dropdown) {
      this.incidentFilter[dropdown] = this.filteredAttributesValues[dropdown].map(val => val);
    },

    /**
     * @param {string} startDate
     * @return {string}
     */
    dateToParam(startDate) {
      return getISODate(dateGtfsFormatToObj(startDate));
    },

    /**
     * Close the opened dropdown
     */
    dropdownClose() {
      this.dropdownOpened = '';
      window.removeEventListener('click', this.dropdownClose);
    },

    /**
     * Open a dropdown
     * @param {string} dropdownName
     */
    dropdownOpen(dropdownName) {
      this.dropdownOpened = dropdownName;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },
    /**
     * Call dropdownOpen or dropdownClose
     * @param {string} dropdownName
     */
    dropdownToggle(dropdownName) {
      if (this.dropdownOpened !== dropdownName) {
        this.dropdownOpen(dropdownName);
      } else {
        this.dropdownClose();
      }
    },

    /**
     * delete or add an option filter object
     * @param {string} column
     * @param {boolean} checked
     * @param {string} option
     */
    editIncidentFilter(column, checked, option) {
      if (!option) {
        if (!checked) {
          this.uncheckAll(column);
        } else {
          this.checkAll(column);
        }
      } else if (!checked) {
        this.incidentFilter[column] = this.incidentFilter[column].filter(item => item !== option);
      } else {
        this.incidentFilter[column].push(option);
      }
      this.setShownIncident();
    },

    /**
     * @param {string} incidentId
     * @return {?string}
     */
    getFormattedTripName(incidentId) {
      const infos = this.incidentInfos[incidentId];

      if (infos) {
        return this.formattedTripNames[infos.gtfs_id + infos.trip_id + infos.start_date];
      }
      return null;
    },

    /**
     * @param {string} incidentId
     * @return {?import('@/store/gtfs').Trip}
     */
    getTrip(incidentId) {
      const infos = this.incidentInfos[incidentId];
      if (!infos) return null;

      const gtfsTrips = this.getGtfsTrips(infos.gtfs_id);
      return gtfsTrips[infos.trip_id];
    },

    /**
     * Set a dict[key]:list of differente filter attribute from an object[attribute] in an object
     * @param {string} attribute
     * @param {object} object
     */
    setFilteredAttributesValues(attribute, object) {
      const filteredAttributesValues = {};
      filteredAttributesValues[attribute] = {};
      Object.values(object).forEach(incident => {
        if (incident && incident[attribute]) {
          filteredAttributesValues[attribute][incident[attribute]] = true;
        } else {
          filteredAttributesValues[attribute]['-'] = true;
        }
      });
      const sorted = Object.keys(filteredAttributesValues[attribute]);
      const collator = new Intl.Collator(this.$i18n.locale, { numeric: true });

      sorted.sort((a, b) => collator.compare(a, b));

      this.filteredAttributesValues[attribute] = sorted;
      this.checkAll(attribute);
    },

    /**
     * Set the accident shown on page.
     */
    setShownIncident() {
      this.shownIncident = Object.values(this.list).filter(
        incident =>
          !Object.entries(this.incidentFilter).find(([attribute, values]) => {
            if (this.incidentTrips[incident.id]) {
              switch (attribute) {
                case FilteredAttributes.TEAM_ID:
                  return !values.includes(
                    this.incidentTrips[incident.id][attribute] != null
                      ? this.incidentTrips[incident.id][attribute]
                      : '-'
                  );
                case FilteredAttributes.INCIDENT_LABEL:
                case FilteredAttributes.REQUESTER:
                  return !values.includes(
                    this.incidentInfos[incident.id][attribute] != null
                      ? this.incidentInfos[incident.id][attribute]
                      : '-'
                  );
                default:
                  return null;
              }
            }
            return null;
          })
      );
    },

    /**
     * @param {string} attribute
     * @param {(incident: import('@/api').TripIncident) => *} accessor
     * Sort the list with the value of the function given in param.
     */
    sortBy(attribute, accessor) {
      this.sortStatus(attribute);

      // eslint-disable-next-line no-param-reassign
      accessor = accessor || (elem => elem[attribute]);
      const collator = new Intl.Collator(this.$i18n.locale, { numeric: true });

      if (this.sort.order === Order.ASC) {
        this.list.sort((a, b) => collator.compare(accessor(a), accessor(b)));
      } else {
        this.list.sort((a, b) => collator.compare(accessor(b), accessor(a)));
      }
      this.setShownIncident();
    },

    /**
     * @param {string} attribute
     * Save de order and attribute in sort data
     */
    sortStatus(attribute) {
      if (!attribute || this.sort.order === Order.ASC) {
        this.sort.order = Order.DESC;
      } else {
        this.sort.order = Order.ASC;
      }
      this.sort.attribute = attribute || 'updated_at';
    },

    /**
     * uncheck all box in the dropdown param
     * @param {string} dropdownName
     */
    uncheckAll(dropdownName) {
      this.incidentFilter[dropdownName] = [];
    },
  },
};

/**
 * @typedef {Object} IncidentInfos
 * @property {string} gtfs_id
 * @property {string} trip_id
 * @property {string} start_date
 * @property {string} incident_label
 * @property {string} requester
 */
</script>

<style lang="scss">
.incident-list {
  .table__head-cell {
    cursor: pointer;
  }
}
</style>

<i18n locale="fr">
{
  "columns": {
    "trip_short_name": "Nom de course",
    "incident_code": "Code incident",
    "incident_id": "Identifiant d’incident",
    "subject": "Sujet",
    "requester": "Agent",
    "formattedTripName": "Nom de course formaté",
    "trip_id": "Identifiant de course",
    "team_id": "Équipe",
    "date_creation": "Date de création",
    "date_updated": "Date de mise à jour"
  }
}
</i18n>

<i18n locale="en">
{
  "columns": {
    "trip_short_name": "Service name",
    "incident_code": "Incident code",
    "incident_id": "Incident ID",
    "subject": "Subject",
    "requester": "Agent",
    "formattedTripName": "Formatted trip name",
    "trip_id": "Service ID",
    "team_id": "Team",
    "date_creation": "Created",
    "date_updated": "Updated"
  }
}
</i18n>

<i18n locale="cz">
{
  "columns": {
    "date_updated": "Aktualizováno",
    "subject": "Téma",
    "date_creation": "Vytvořeno",
    "trip_id": "ID jízdy",
    "trip_short_name": "Název linky",
    "formattedTripName": "Formátovaný název jízdy",
    "requester": "Agent",
    "incident_code": "Kód incidentu",
    "incident_id": "ID incidentu",
    "team_id": "Tým"
  }
}
</i18n>

<i18n locale="de">
{
  "columns": {
    "date_updated": "Aktualisiert",
    "subject": "Betreff",
    "date_creation": "Erstellt",
    "trip_id": "Fahrt-ID",
    "trip_short_name": "Fahrtname",
    "formattedTripName": "Formatierter Fahrtname",
    "requester": "Mitarbeiter",
    "incident_code": "Vorfall-Code",
    "incident_id": "Vorfall-ID",
    "team_id": "Team"
  }
}
</i18n>

<i18n locale="es">
{
  "columns": {
    "date_updated": "Actualizado",
    "subject": "Asunto",
    "date_creation": "Creado",
    "trip_id": "ID de servicio",
    "trip_short_name": "Nombre del servicio",
    "formattedTripName": "Nombre de servicio formateado",
    "requester": "Agente",
    "incident_code": "Código de incidente",
    "incident_id": "ID del incidente",
    "team_id": "Equipo"
  }
}
</i18n>

<i18n locale="it">
{
  "columns": {
    "date_updated": "Aggiornato",
    "subject": "Oggetto",
    "date_creation": "Creato",
    "trip_id": "ID servizio",
    "trip_short_name": "Nome del servizio",
    "formattedTripName": "Nome formattato del servizio",
    "requester": "Agente",
    "incident_code": "Codice dell'incidente",
    "incident_id": "ID incidente",
    "team_id": "Team"
  }
}
</i18n>

<i18n locale="pl">
{
  "columns": {
    "date_updated": "Zaktualizowano",
    "subject": "Temat",
    "date_creation": "Utworzono",
    "trip_id": "Identyfikator usługi",
    "trip_short_name": "Nazwa usługi",
    "formattedTripName": "Sformatowana nazwa usługi",
    "requester": "Agent",
    "incident_code": "Kod incydentu",
    "incident_id": "Identyfikator incydentu",
    "team_id": "Zespół"
  }
}
</i18n>
