<template>
  <div class="duty-list">
    <div class="duty-list__header">
      <div class="duty-list__header-side">
        <Btn type="primary" :route="{ name: GroupRoute.MESSAGE_LIST }">
          {{ $t('sendMessage') }}
        </Btn>

        <Btn
          type="secondary"
          class="duty-list__datepicker-btn"
          @click="$refs['duty-list-datepicker'].openMenu()"
        >
          <font-awesome-icon icon="fa-calendar-days" />
          {{ $d(selectedDate, 'dateShort') }}
          <Datepicker
            ref="duty-list-datepicker"
            v-model:value="selectedDate"
            input-class="duty-list__datepicker"
            :disabled-dates="calendarDisabledDates"
          />
        </Btn>
      </div>

      <div class="duty-list__header-side">
        <Btn type="secondary" @click="downloadDutyData">
          {{ $t('download') }}
        </Btn>
      </div>
    </div>

    <DataGridVuetify
      v-if="dutyList.length > 0"
      ref="dataGrid"
      v-model:renderedDataLength="renderedDataLength"
      :title="$t('duties', { count: renderedDataLength })"
      :data="dutyList"
      :datagrid="datagrid"
      :loading="loading"
      :reload-on-refresh="!isToday"
    />

    <div v-else class="duty-list__no-data">
      <div>
        <div class="duty-list__no-data-text">
          {{ $t('noDuties') }}
        </div>
        <div>
          <Btn type="secondary" @click="goToPreviousDay">{{ $t('previousDay') }}</Btn>
          <Btn type="secondary" @click="goToNextDay">{{ $t('nextDay') }}</Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'clone-deep';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import Datepicker from '@/components/ui/Datepicker.vue';
import { dateObjToGtfsFormat, getISODate, timestampFormatHHMM } from '@/libs/helpers/dates';
import { toCSV, triggerDownloadCSV } from '@/libs/csv';
import { GroupRoute } from '@/libs/routing';
import { ColumnKey, DutyStatus, getDatagrid } from '@/pages/DutyListPage/DutyList.conf.js';
import { NO_DATA } from '@/components/Table/DataGridVuetify/models/DataGrid.models';

dayjs.extend(utc);

/** @type {number} */
const UPDATE_FREQUENCE = 60000;

export default {
  name: 'DutyList',

  components: { Btn, DataGridVuetify, Datepicker },

  props: {
    /** @type {import('vue').Prop<{date?: string}>} */
    query: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    ColumnKey,
    GroupRoute,

    /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
    datagrid: getDatagrid(),
    /** @type {Array<FormattedDuties>} */
    dutyList: [],
    /** @type {Boolean} */
    loading: true,
    /** @type {number} */
    renderedDataLength: null,
    /** @type {NodeJS.Timeout} */
    updateDuties: null,
  }),

  computed: {
    ...(() => {
      const mapping = {
        /** @return {{[deviceId: string]: import('@/store/devices').Device}} */
        devicesList: state => state.devices.list,
        /** @return {{[driverId: string]: import('@/store/drivers').Driver}} */
        driversList: state => state.drivers.list,
        /** @return {{[vehicleId: string]: import('@/store/vehicles').Vehicle}} */
        vehiclesList: state => state.vehicles.list,
      };

      return /** @type {typeof mapping} */ (mapState(mapping));
    })(),

    /** @return {import('@/components/ui/Datepicker.vue').DisabledDates */
    calendarDisabledDates() {
      const minDate = dayjs(new Date()).subtract(1, 'year').toDate();
      const maxDate = new Date();
      return {
        minDate,
        maxDate,
      };
    },

    /** @return {string} */
    dateGtfs() {
      return dateObjToGtfsFormat(this.selectedDate);
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {boolean} */
    isToday() {
      return dateObjToGtfsFormat(this.selectedDate) === dateObjToGtfsFormat(new Date());
    },

    /** @return {Date} */
    selectedDate: {
      /** @return {Date} */
      get() {
        if (this.query.date) {
          const date = dayjs(this.query.date).hour(23).minute(59).second(59).utc().toDate();
          return date;
        }
        return new Date();
      },

      /** @param {Date} value */
      set(value) {
        this.$router.push({
          name: GroupRoute.DUTY_LIST,
          params: {
            groupId: this.group.group_id,
          },
          query: {
            date: getISODate(value),
          },
        });
      },
    },
  },

  watch: {
    dateGtfs: {
      immediate: true,
      handler() {
        this.loadDutyList();
      },
    },
  },

  beforeUnmount() {
    clearInterval(this.updateDuties);
  },

  created() {
    this.$store.dispatch('devices/getDevices');
    this.$store.dispatch('drivers/loadList');
    this.$store.dispatch('vehicles/loadList');

    this.loadDutyList();
    this.updateDutyList();
  },

  methods: {
    /**
     * Get data from dataGrid and create download
     */
    async downloadDutyData() {
      const result = this.$refs.dataGrid.exportData();
      if (result.data.length > 0) {
        // Iterate through data to format some properties
        result.data.map(item => {
          // Format to time if ts
          item.status = this.$t(item.status);
          return item;
        });

        // Create translation for headerRow
        const headerRow = [];
        result.translatedKeys.forEach(name => {
          headerRow.push(name);
        });

        // creating csv
        const data = [
          headerRow,
          ...result.data.map(row => Object.values(row).map(value => value?.toString() || '-')),
        ];
        const link = toCSV(data);
        // Trigger download
        triggerDownloadCSV(link, this.$t('dutyListDownloadName', [dateObjToGtfsFormat(this.selectedDate)]));
      }
    },

    /** @return {Array<FormattedDuties>} */
    formatDutyList() {
      const entries = cloneDeep(this.$store.state.activityLog.entries);
      entries.forEach((entry, index) => {
        // Format data for dataGrid and export
        entry.device = this.devicesList[entry.device_id]?.name || entry.device_id;

        entry.startTime = timestampFormatHHMM(entry.check_in.ts, { tz: this.group.tz });

        entry.endTime = entry.checkout
          ? timestampFormatHHMM(entry.checkout?.ts, { tz: this.group.tz })
          : null;

        entry.status = entry.checkout ? this.$t(DutyStatus.OVER) : this.$t(DutyStatus.IN_PROGRESS);
        entry.unformattedStatus = entry.checkout ? DutyStatus.OVER : DutyStatus.IN_PROGRESS;

        entry.comment = entry.checkout?.comments;

        entry.emptyVehicle = entry.checkout?.check_empty ? 'Ok' : NO_DATA;

        // Format data for export
        entry.tripsFormatted = entry.trips.map(trip => trip.formatted_name).join(', ');
        entry.tripsWithLinks = entry.trips.map(trip => {
          return {
            text: trip.formatted_name,
            link: {
              name: GroupRoute.TRIP_DETAILED,
              params: { tripId: trip.id },
              query: { date: trip.start_date ?? null },
            },
          };
        });

        // Get drivers information from driver id
        if (this.driversList && entry.driver_id) {
          entry.driver = this.$store.getters['drivers/getDriverInfosById'](entry.driver_id);
        }
        // Get vehicles information from vehicle id
        if (this.vehiclesList && entry.vehicle_id) {
          entry.vehicle = this.$store.getters['vehicles/getVehicleInfosById'](entry.vehicle_id);
        }
      });

      this.dutyList = entries;
    },

    goToPreviousDay() {
      const date = new Date(this.selectedDate);
      date.setDate(date.getDate() - 1);
      this.selectedDate = date;
    },

    goToNextDay() {
      const date = new Date(this.selectedDate);
      date.setDate(date.getDate() + 1);
      this.selectedDate = date;
    },

    async loadDutyList() {
      this.loading = true;
      await this.$store.dispatch('activityLog/loadEntries', this.dateGtfs);
      this.formatDutyList();
      this.loading = false;
    },

    /** Get activity log every minute */
    updateDutyList() {
      // Regular updates only on current date
      if (this.isToday) {
        this.updateDuties = setInterval(() => {
          this.loadDutyList();
        }, UPDATE_FREQUENCE);
      }
    },
  },
};

/**
 * @typedef {Object} FormattedDuties
 * @extends ActivityLogEntry
 * @property {string} _id
 * @property {string} device
 * @property {string} startTime
 * @property {string} endTime
 * @property {string} status
 * @property {string} unformattedStatus
 * @property {string} comment
 * @property {string} emptyVehicle
 * @property {string} tripsFormatted
 * @property {string} driver
 * @property {string} vehicle
 * @property {Array<import('@/components/common/DropList.vue').DropListValue>} tripsWithLinks
 */
</script>

<style lang="scss">
.duty-list {
  padding: $view-standard-padding;

  &__datepicker {
    display: none;
  }

  &__datepicker-btn {
    margin-left: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  }

  &__header-side {
    display: flex;
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 25vh;
    text-align: center;
  }

  &__no-data-text {
    margin-bottom: 20px;
  }
}
</style>

<i18n locale="fr">
{
  "duties": " prise de service | prises de service",
  "dutyListDownloadName": " liste-prises-de-service_{0}.csv",
  "nextDay": "Jour suivant",
  "noDuties": "Pas de prise de service à cette date.",
  "previousDay": "Jour précédent",
  "sendMessage": "Envoyer un message",
  "over": "Terminé",
  "inProgress": "En cours"
}
</i18n>

<i18n locale="en">
{
  "duties": " duties | duties",
  "dutyListDownloadName": "duty-list_{0}.csv",
  "nextDay": "Next day",
  "noDuties": "No duties on this date.",
  "previousDay": "Previous day",
  "sendMessage": "Send a message",
  "over": "Over",
  "inProgress": "In progress"
}
</i18n>
