<template>
  <div class="vehicles">
    <div class="vehicles__header">
      <div class="vehicles__header-side">
        <Btn type="primary" :disabled="lineInEdition" @click="addNewVehicle">
          {{ $t('newVehicle') }}
        </Btn>

        <Btn type="secondary" :disabled="lineInEdition" @click="showModal(null, ModalType.IMPORT_VEHICLE)">
          {{ $t('import') }}
        </Btn>
      </div>

      <div class="vehicles__header-side">
        <Btn
          v-if="$store.getters.hasPermission(Permission.EXPORT_VEHICLES)"
          type="secondary"
          class="vehicles__btn"
          @click="downloadData"
        >
          {{ $t('download') }}
        </Btn>
      </div>
    </div>

    <DataGridVuetify
      v-model:renderedDataLength="renderedDataLength"
      :title="$t('vehicles', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="vehiclesFormatted"
      :datagrid="datagrid"
      :loading="loading"
      :is-in-edition-mode="inEditionId === NEW_LINE_ID"
      :line-id-in-edition="inEditionId"
    >
      <template #actions="propsAction">
        <ActionCell
          :edit-mode="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id)"
          :actions="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id) ? [] : ['edit', 'archive']"
          :object="propsAction.object"
          @archive="dataLine => showModal(dataLine, ModalType.ARCHIVE_VEHICLE)"
          @edit="dataLine => toggleEditionMode(dataLine, true)"
          @save="saveEdits"
          @switchOffEditionMode="dataLine => toggleEditionMode(dataLine, false)"
        />
      </template>
    </DataGridVuetify>

    <ModalComment
      v-if="modalShown === ModalType.COMMENT"
      :comment="modalVehicleInitialState.comment ? modalVehicleInitialState.comment : null"
      :title-name="modalVehicleInitialState.fleet_number"
      @close="closeModal"
      @submit="setVehicleComment"
    />

    <ModalImport v-if="modalShown === ModalType.IMPORT_VEHICLE" @close="closeModalImport" />

    <ModalArchiveRestore
      v-if="modalShown === ModalType.ARCHIVE_VEHICLE"
      :body="$t('confirmArchiveVehicle')"
      :title="$t('archiveVehicle')"
      @close="closeModal"
      @submit="submitModalRemove"
    />
  </div>
</template>

<script>
import ModalComment from '@/components/common/ModalComment.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import { ColumnKey, getDatagrid } from '@/pages/VehicleListPage/VehicleList.conf.js';
import { Permission } from '@/store';

import ModalImport from './ModalImportVehicle.vue';

/** @enum {string} */
export const ModalType = {
  COMMENT: 'comment',
  ARCHIVE_VEHICLE: 'archive_vehicle',
  IMPORT_VEHICLE: 'import_vehicle',
};

export const NEW_LINE_ID = 'new-line';

export default {
  name: 'VehicleList',

  components: {
    ActionCell,
    Btn,
    DataGridVuetify,
    ModalComment,
    ModalImport,
    ModalArchiveRestore,
  },

  /** @return {{modelI18n: import('vue-i18n').IVueI18n}} */
  provide() {
    return {
      modelI18n: this.$i18n,
    };
  },

  data() {
    return {
      NEW_LINE_ID,
      ColumnKey,
      ModalType,
      Permission,

      /** @type {import('@/components/Table/DDataGridVuetifyataGrid/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),
      /** @type {?String} */
      inEditionId: null,
      /** @type {Boolean} */
      loading: true,
      /** @type {?import('@/store/vehicles').Vehicle} */
      modalVehicleInitialState: null,
      /** @type {ModalType} */
      modalShown: '',
      /** @type {number} */
      renderedDataLength: null,
      /** @type {Array<import('@/store/vehicles').Vehicle>} */
      vehiclesFormatted: [],
      /** @type {?import('@/store/vehicles').Vehicle} */
      vehicleInEditionOriginalState: null,
    };
  },

  computed: {
    /** @return {{[key in ColumnTypes]: (data: {apiData: import('@/store/vehicles').Vehicle}) => Object}} */
    buildCellInjectors() {
      /**
       * @param {import('@/store/vehicles').Vehicle} apiDataRow
       * @return {({ name: string }) => void}
       */
      const bindActionModal = (apiDataRow, name) => () => {
        this.showModal(apiDataRow, name);
      };

      /**
       * @param {import('@/store/vehicles').Vehicle} apiDataRow
       * @return {({ value: object, field: string }) => void}
       */
      const bindValueChanged =
        apiDataRow =>
        ({ value, field }) => {
          this.updateValue(apiDataRow, value, field);
        };

      return {
        [ColumnKey.COMMENT]: ({ apiData }) => ({ showModal: bindActionModal(apiData, ModalType.COMMENT) }),
        [ColumnKey.FLEET_NUMBER]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.LICENSE_PLATE]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.TEAM]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
      };
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {Array<import('@/store/vehicles').Vehicle>} */
    vehicleList() {
      return Object.values(this.$store.state.vehicles.list);
    },

    /** @return {Boolean} */
    lineInEdition() {
      return this.inEditionId != null;
    },
  },

  created() {
    this.onCreate();
  },

  methods: {
    addNewVehicle() {
      if (!this.vehiclesFormatted.find(v => v.id === NEW_LINE_ID)) {
        this.inEditionId = NEW_LINE_ID;
        this.vehiclesFormatted.unshift({
          id: NEW_LINE_ID,
          comment: '',
          fleet_number: '',
          license_plate: '',
          team_id: null,
        });
      }
    },

    downloadData() {
      window.open(`/api/v3/groups/${this.group._id}/vehicle-list`);
    },

    /** Close the opened modal */
    closeModal() {
      this.modalShown = '';
      this.modalVehicleInitialState = null;
    },

    /** Close the import modal and refresh data */
    closeModalImport() {
      this.closeModal();
      this.getDataList();
    },

    async onCreate() {
      this.loading = true;
      await this.$store.dispatch('vehicles/loadList');
      this.getDataList();

      this.loading = false;
    },

    /** @param {string} id */
    resetLine(id) {
      const elementIndex = this.vehiclesFormatted.findIndex(el => el.id === id);
      if (id === NEW_LINE_ID) {
        this.vehiclesFormatted.splice(elementIndex, 1);
      } else {
        // give the element its original values back
        this.vehiclesFormatted[elementIndex] = { ...this.vehicleInEditionOriginalState };
      }
    },

    /**
     * Edit vehicle
     * @param {import('@/store/vehicles').Vehicle} apiDataRow
     */
    async saveEdits(apiDataRow) {
      if (apiDataRow.id === NEW_LINE_ID) {
        delete apiDataRow.id;
        if (apiDataRow.fleet_number === '') {
          apiDataRow.fleet_number = this.$t('newVehicle');
        }
        await this.$store.dispatch('vehicles/createVehicle', apiDataRow);
        await this.$store.dispatch('vehicles/loadList');
        this.getDataList();
      } else {
        const vehicle = { ...apiDataRow };
        delete vehicle.archived;
        delete vehicle.teamName;
        delete vehicle.id;
        await this.$store.dispatch('vehicles/updateVehicle', {
          vehicleId: apiDataRow.id,
          vehicle,
        });
      }
      this.inEditionId = null;
    },

    /** @param {import('@/store/vehicles').Vehicle['comment']} comment */
    async setVehicleComment(comment) {
      this.modalVehicleInitialState.comment = comment;
      const apiDataRow = this.vehiclesFormatted.find(
        element => element.id === this.modalVehicleInitialState.id
      );
      this.updateValue(apiDataRow, comment, 'comment');
      // the comment is not saved immediately in case of a new vehicle or edition mode
      if (
        this.modalVehicleInitialState.id !== NEW_LINE_ID &&
        this.modalVehicleInitialState.id !== this.inEditionId
      ) {
        await this.saveEdits(apiDataRow);
      }
      this.closeModal();
    },

    /**
     * Show a modal
     * @param {import('@/store/vehicles').Vehicle} apiDataRow
     * @param {ModalType} modalName
     */
    showModal(apiDataRow, modalName) {
      this.modalVehicleInitialState = { ...apiDataRow };
      this.modalShown = modalName;
    },

    /**
     * Delete vehicle
     */
    async submitModalRemove() {
      await this.$store.dispatch('vehicles/deleteVehicle', this.modalVehicleInitialState.id);
      this.getDataList();
      this.closeModal();
    },

    /**
     * Switch a line to edition mode or get out of edition mode and cancel previous changes made and not saved
     * @param {import('@/store/vehicles').Vehicle} apiDataRow
     * @param {boolean} editionMode
     */
    toggleEditionMode(apiDataRow, editionMode) {
      if (editionMode) {
        // if another edition was in progress, cancel it
        if (this.inEditionId) {
          this.resetLine(this.inEditionId);
        }
        this.inEditionId = apiDataRow.id;
        // keep a copy of the element about to be edited in case of edit cancellation
        this.vehicleInEditionOriginalState = { ...apiDataRow };
      } else {
        this.resetLine(apiDataRow.id);
        this.inEditionId = null;
        this.vehicleInEditionOriginalState = null;
      }
    },

    /**
     * Update a value - triggered upon focus lost on an EditableCell
     * @param {import('@/store/vehicles').Vehicle} apiDataRow
     * @param {string} value
     * @param {keyof import('@/store/vehicles').Vehicle} field
     */
    updateValue(apiDataRow, value, field) {
      apiDataRow[field] = value;
    },

    getDataList() {
      const data = this.vehicleList
        .map(vehicle => {
          const newObject = vehicle;
          // handle data from V2 version
          newObject.team_id =
            vehicle.team_id && !['', 'None'].includes(vehicle.team_id) ? vehicle.team_id : null;
          newObject.comment = vehicle.comment !== 'None' ? vehicle.comment : null;
          newObject.teamName = this.$store.getters.getTeamNameById(newObject.team_id);
          return newObject;
        })
        .sort((a, b) => {
          if (a.fleet_number.toLowerCase() > b.fleet_number.toLowerCase()) return 1;
          if (a.fleet_number.toLowerCase() < b.fleet_number.toLowerCase()) return -1;
          return 0;
        });
      this.vehiclesFormatted = data;
    },
  },
};
</script>

<style lang="scss">
.vehicles {
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__header-side {
    display: flex;
  }
}
</style>

<i18n locale="fr">
{
  "archiveVehicle": "Archiver le véhicule",
  "confirmArchiveVehicle": "Êtes-vous sûr de vouloir archiver ce véhicule ?",
  "edit": "Modifier",
  "import": "Importer",
  "newVehicle": "Nouveau véhicule",
  "vehicles": "véhicule | véhicules"
}
</i18n>

<i18n locale="en">
{
  "archiveVehicle": "Archive the vehicle",
  "confirmArchiveVehicle": "Do you want to archive the vehicle?",
  "edit": "Edit",
  "import": "Import",
  "newVehicle": "New vehicle",
  "vehicles": "vehicle | vehicles"
}
</i18n>
