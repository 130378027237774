<template>
  <div class="reports">
    <AnimatedDots v-if="isLoading" class="reports__loading reports__loading-dots" :type-v2="true" />

    <NoReportData v-else-if="!hasData" />

    <template v-else>
      <div class="reports__abstract">
        <NumberDisplay :title="$t('totalTrips')" :number="totalTrackedTrips" />
        <RadialBarChart :title="$t('percentageTripsRadial')" :value="averageTrackedTrips" />
      </div>
      <div class="reports__barcharts">
        <BarChart3
          :height="chartHeight"
          :options="dayChart.options"
          :categories="dayChart.categories"
          :series="dayChart.series"
          :title="$t('chartDayTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />
        <BarChart3
          :height="chartHeight"
          :options="routeChart.options"
          :categories="routeChart.categories"
          :series="routeChart.series"
          :title="$t('chartRouteTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import deepmerge from 'deepmerge';

import { stats as ApiStats } from '@/api';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import BarChart3 from '@/components/ui/BarChart3.vue';
import NumberDisplay from '@/components/ui/NumberDisplay.vue';
import RadialBarChart from '@/components/ui/RadialBarChart.vue';
import NoReportData from '@/components/ui/NoReportData.vue';

import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { GroupBy } from '@/libs/reports';

export default {
  name: 'ReportsTripTracking',

  components: {
    AnimatedDots,
    BarChart3,
    NumberDisplay,
    RadialBarChart,
    NoReportData,
  },

  props: {
    /** @type {Object} */
    chartHeight: {
      type: Number,
      default: 620,
    },
    /** @type {Object} */
    chartWidth: {
      type: Number,
      default: 620,
    },
    /** @type {Vue.PropOptions<{start: number, end: number}>} */
    dateInterval: {
      type: Object,
      required: true,
    },
  },
  emits: ['hasData'],
  data: () => ({
    /** @type {Array<import('@/api').TripTracking>} */
    data: [],

    dayChart: {
      categories: [],
      series: [],
      options: {},
      data: [],
    },

    routeChart: {
      categories: [],
      series: [],
      options: {},
      data: [],
    },

    /** @type {number} */
    averageTrackedTrips: null,
    /** @type {number} */
    totalTrackedTrips: null,

    isLoading: false,
  }),
  computed: {
    hasData() {
      const result = this.totalTrackedTrips > 0;
      this.$emit('hasData', result);
      return result;
    },
  },

  watch: {
    dateInterval: {
      immediate: true,
      handler(value, old) {
        const currValue = value || {};
        const oldValue = old || {};

        if (currValue.start !== oldValue.start || currValue.end !== oldValue.end) {
          this.loadData();
        }
      },
    },
  },

  methods: {
    /**
     * @param {import('@/api').TripTracking} item
     * @return {Object}
     */

    getGroupedInfos(item, type) {
      switch (type) {
        case GroupBy.DAY: {
          return {
            percent_tracked: item.percent_tracked,
            scheduled: item.scheduled,
            date: item.start_date,
            tracked: item.tracked,
          };
        }

        case GroupBy.ROUTE: {
          return {
            gtfs_name: item.gtfs_name,
            percent_tracked: item.percent_tracked,
            route_short_name: item.route_short_name,
            scheduled: item.scheduled,
            tracked: item.tracked,
          };
        }
        default:
          return {};
      }
    },

    async loadData() {
      this.isLoading = true;

      const startDate = dateObjToGtfsFormat(new Date(this.dateInterval.start * 1000));
      const endDate = dateObjToGtfsFormat(new Date(this.dateInterval.end * 1000));

      const [responseDay, responseRoute] = await Promise.all([
        ApiStats.getTripTracking(this.$store.getters.group._id, 'day', startDate, endDate),
        ApiStats.getTripTracking(this.$store.getters.group._id, 'route', startDate, endDate),
      ]);

      const sumOfTrips = responseDay.reduce(
        (acc, trip) => {
          acc.totalScheduledTrips += trip.scheduled;
          acc.totalTrackedTrips += trip.tracked;
          return acc;
        },
        { totalScheduledTrips: 0, totalTrackedTrips: 0 }
      );

      this.totalTrackedTrips = sumOfTrips.totalTrackedTrips;
      this.averageTrackedTrips = Math.floor(
        (sumOfTrips.totalTrackedTrips / sumOfTrips.totalScheduledTrips) * 100
      );

      // Day chart
      // the response is not empty, even when there is no data
      const isDayResponseEmpty = responseDay.reduce((acc, day) => {
        return day.scheduled !== 0 ? false : acc;
      }, true);
      if (isDayResponseEmpty) {
        this.dayChart.data = [];
      } else {
        this.dayChart.data = responseDay.map(item => this.getGroupedInfos(item, GroupBy.DAY));
        this.dayChart.series = this.getSeries(this.dayChart.data);
        this.dayChart.options = this.getOptions(this.dayChart.data, 'day');
        this.dayChart.categories = this.dayChart.data.map(el =>
          this.$d(dateGtfsFormatToObj(el.date), 'dayMonth').replace(/\./g, '')
        );
      }

      // Route chart
      // the response is not empty, even when there is no data
      const isRouteResponseEmpty = responseRoute.reduce((acc, route) => {
        return route.scheduled !== 0 ? false : acc;
      }, true);
      if (isRouteResponseEmpty) {
        this.routeChart.data = [];
      } else {
        this.routeChart.data = responseRoute
          .map(item => this.getGroupedInfos(item, GroupBy.ROUTE))
          .sort(this.sortRoutesAlphaNum);

        this.routeChart.series = this.getSeries(this.routeChart.data);
        this.routeChart.options = this.getOptions(this.routeChart.data, 'route');
        this.routeChart.categories = this.routeChart.data.map(el => el.route_short_name);
      }

      this.isLoading = false;
    },

    /**
     * @param {Array<import('@/api').TripTracking>} data
     * @param {'day'|'route'} chartType
     * @return {Object}
     */
    getOptions(data, chartType) {
      const genericOptions = {
        dataLabels: {
          formatter: (val, { dataPointIndex, w }) => {
            const entry = data[dataPointIndex + w.config.page * w.config.barsPerScreen];
            if (!entry) return null; // prevent console error on screen nav
            return `${entry.percent_tracked}%`;
          },
        },
        // set fixed yaxis
        yaxis: {
          max: 110,
        },
      };
      if (chartType === 'day') {
        const dayChartOptions = {
          tooltip: {
            custom: ({ dataPointIndex, w }) => {
              const entry = data[dataPointIndex + w.config.page * w.config.barsPerScreen];

              return `
              <ul class="apexcharts-custom-tooltip">
                <li>${this.$t('scheduledTrips', [entry.scheduled])}</li>
                <li>${this.$t('trackedTrips', [entry.tracked])}</li>
                <li>${this.$t('percentageTracked', [entry.percent_tracked])}</li>
              </ul>
              `;
            },
          },
        };
        return deepmerge(genericOptions, dayChartOptions);
      }
      if (chartType === 'route') {
        const routeChartOptions = {
          xaxis: {
            labels: {
              hideOverlappingLabels: false,
              showDuplicates: true,
              maxHeight: 200,
              style: {
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            custom: ({ dataPointIndex, w }) => {
              const entry = data[dataPointIndex + w.config.page * w.config.barsPerScreen];

              return `
              <ul class="apexcharts-custom-tooltip">
                <li>${entry.gtfs_name}</li>
                <li>${this.$t('scheduledTrips', [entry.scheduled])}</li>
                <li>${this.$t('trackedTrips', [entry.tracked])}</li>
                <li>${this.$t('percentageTracked', [entry.percent_tracked])}</li>
              </ul>
              `;
            },
          },
        };
        return deepmerge(genericOptions, routeChartOptions);
      }
      return null;
    },

    /**
     * @param {Array<import('@/api').TripTracking>} data
     * @return {Array<import('@/components/ui/BarChart2.vue').ApexChartSerie>}
     */
    getSeries(data) {
      return [
        {
          data: data.map(tripTrackingObject => tripTrackingObject.percent_tracked),
          color: ({ dataPointIndex, w }) => {
            const entry = data[dataPointIndex + w.config.page * w.config.barsPerScreen];
            if (!entry) return '';
            const percentTracked = entry.percent_tracked;
            if (percentTracked < 50) return '#EB5757'; // $danger
            if (percentTracked <= 80) return '#F99C49'; // $warn

            return '#00B871'; // $primary-light
          },
        },
      ];
    },
  },
};
</script>

<i18n locale="fr">
{
  "noData": {
    "head": "Aucune donnée disponible.",
    "tip": "Veuillez modifier l'indicateur ou la période."
  },
  "chartDayTitle": "Courses suivies par jour",
  "chartRouteTitle": "Courses suivies par ligne",
  "percentageTracked": "{0}% de courses suivies",
  "percentageTrips": "% des courses suivies",
  "percentageTripsRadial": "Pourcentage des courses suivies",
  "scheduledTrips": "Nombre de courses : {0}",
  "totalTrips": "Total des courses suivies",
  "trackedTrips": "Courses suivies : {0}",
  "yaxisTitle": "% des courses suivies"
}
</i18n>

<i18n locale="en">
{
  "chartDayTitle": "Tracked trips per day",
  "chartRouteTitle": "Tracked trips per route",
  "percentageTracked": "Percentage: {0}%",
  "percentageTrips": "% of tracked trips",
  "percentageTripsRadial": "Percentage of tracked trips",
  "scheduledTrips": "Number of trips: {0}",
  "trackedTrips": "Number of tracked trips: {0}",
  "totalTrips": "Total of tracked trips",
  "yaxisTitle": "% tracked trips"
}
</i18n>
