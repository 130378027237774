<template>
  <div class="droplist">
    <!-- First line -->
    <div class="droplist__line">
      <div class="droplist__first-item">
        <router-link v-if="content[0].link" :to="content[0].link">
          <span :title="content[0].text">{{ content[0].text }}</span>
        </router-link>
        <span v-else :title="content[0].text">{{ content[0].text }}</span>
      </div>
      <div v-if="content.length > 1" class="droplist__button" @click.stop="displayValues = !displayValues">
        {{ displayValues ? '-' : `+${content.length - 1}` }}
      </div>
    </div>

    <!-- Other lines -->
    <template v-if="displayValues">
      <div v-for="(line, idx) of content.slice(1)" :key="idx" class="droplist__line">
        <router-link v-if="line.link" :to="line.link">
          <span :title="line.text">{{ line.text }}</span>
        </router-link>
        <span v-else :title="line.text">{{ line.text }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DropList',
  props: {
    /** @type {Vue.PropOptions<Array<DropListValue>>} */
    values: {
      type: Array,
      required: true,
    },
  },
  data: () => ({ displayValues: false }),
  computed: {
    content() {
      return this.values.length > 0 ? this.values : ['-'];
    },
  },
};

/**
 * @typedef {Object} DropListValue
 * @property {string} text
 * @property {import("vue-router").RouterLinkProps} [link]
 */
</script>

<style lang="scss">
.droplist {
  width: min-content;
  max-width: 100%;

  &__line {
    display: flex;
    flex-wrap: nowrap;
  }

  &__first-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button {
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
