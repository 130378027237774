<template>
  <div class="login">
    <nav>
      <div class="container-link">
        <a class="link-public-site" href="https://web.pysae.com/">{{ $t('backToPage') }}</a>
        <Language class="language-menu" />
      </div>
    </nav>
    <div class="logo" />
    <div class="container">
      <div class="container-title">
        <span>{{ $t('connection') }}</span>
      </div>
      <div v-if="showError" id="msg" class="alert alert-danger">
        {{ showError }}
      </div>
      <form class="panel-body" @submit.prevent="submit">
        <div class="form-group">
          <label for="mail">{{ $t('email') }}</label>
          <br />
          <input id="mail" v-model="email" type="email" name="email" />
        </div>

        <div class="form-group">
          <label for="pass">{{ $t('password') }}</label>
          <br />
          <input id="pass" v-model="password" type="password" name="password" />
          <router-link class="forgot-pass" :to="{ name: AuthRoute.PASSWORD_RESET }">
            {{ $t('forgotPassword') }}
          </router-link>
        </div>
        <div class="container-submit">
          <Btn class="primary" type="primary">
            {{ $t('submit') }}
          </Btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Api from '@/api';
import Language from '@/components/common/Language.vue';
import Btn from '@/components/ui/Btn.vue';
import { AuthRoute } from '@/libs/routing';

export default {
  name: 'Login',

  components: {
    Btn,
    Language,
  },

  data: () => ({
    email: null,
    AuthRoute,
    password: null,
    showError: null,
  }),

  methods: {
    /** Send log information to the api */
    async submit() {
      this.showError = null;
      const result = await Api.sendAuth(this.email, this.password);
      if (result.status === 200) {
        window.location = this.$route.query.next || '/op2/';
      } else {
        this.showError = this.$t('wrongLogin');
      }
    },
  },
};
</script>
<style lang="scss">
.login {
  input {
    width: 100%;
    padding: 5px;
    border: solid 1px $border;
  }

  nav {
    height: 50px;
    background: $primary-light;
  }

  .forgot-pass {
    color: $primary-light;
    font-size: 10px;
    cursor: pointer;

    &:hover {
      color: $primary-light;
    }
  }
}
</style>
<i18n locale="fr">
{
  "backToPage": "Retour au site internet",
  "connection": "Connexion",
  "email": "Email",
  "forgotPassword": "Mot de passe oublié ?",
  "password": "Mot de passe",
  "submit": "Valider",
  "wrongLogin": "Mauvais utilisateur ou mot de passe"
}
</i18n>

<i18n locale="en">
{
  "backToPage": "Back to the homepage",
  "connection": "Connection",
  "email": "Email",
  "forgotPassword": "Forgot password ?",
  "password": "Password",
  "submit": "Confirm",
  "wrongLogin": "Wrong username or password"
}
</i18n>

<i18n locale="cz">
{
  "forgotPassword": "Zapomněli jste heslo?",
  "password": "Heslo",
  "submit": "Potvrdit"
}
</i18n>

<i18n locale="de">
{
  "forgotPassword": "Kennwort vergessen?",
  "password": "Kennwort",
  "submit": "Bestätigen"
}
</i18n>

<i18n locale="es">
{
  "forgotPassword": "¿Has olvidado la contraseña?",
  "password": "Contraseña",
  "submit": "Confirmar"
}
</i18n>

<i18n locale="it">
{
  "forgotPassword": "Hai dimenticato la password?",
  "password": "Password",
  "submit": "Conferma"
}
</i18n>

<i18n locale="pl">
{
  "forgotPassword": "Nie pamiętasz hasła?",
  "password": "Hasło",
  "submit": "Potwierdź"
}
</i18n>
