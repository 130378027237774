<template>
  <div class="dropdown" :class="{ 'dropdown--open': opened }" @click.stop>
    <!-- Filter button -->
    <Btn
      type="secondary"
      :class="{ active: !allOptionsChecked }"
      :smaller="true"
      @click="$emit('open', !opened)"
    >
      <font-awesome-icon icon="fa-filter" />
    </Btn>

    <!-- Data list -->
    <ul class="dropdown__menu">
      <li class="dropdown__item dropdown__item--separator">
        <Checkbox
          :id="`filter-${column}-check-all`"
          :checked="allOptionsChecked"
          @change="checked => $emit('change', checked)"
        >
          <template #label>
            {{ $t(allOptionsChecked ? 'uncheckAll' : 'checkAll') }}
          </template>
        </Checkbox>
      </li>

      <li v-for="(label, option) in options" :key="option" class="dropdown__item">
        <Checkbox
          :id="`filter-${column}-option-${option}`"
          :checked="!!checked[option]"
          @change="checked => $emit('change', checked, option)"
        >
          <template #label>
            {{ label }}
          </template>
        </Checkbox>
      </li>
    </ul>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import Checkbox from '@/components/ui/Checkbox.vue';

export default {
  name: 'ColumnFilter',

  components: {
    Btn,
    Checkbox,
  },

  props: {
    checked: {
      type: Object,
      required: true,
    },

    column: {
      type: String,
      required: true,
    },

    opened: {
      type: Boolean,
      required: true,
    },

    options: {
      type: Object,
      required: true,
    },
  },

  emits: ['change', 'open'],

  computed: {
    /** @return {boolean} */
    allOptionsChecked() {
      return this.nbChecked === this.nbOptions;
    },

    /** @return {number} */
    nbChecked() {
      return Object.keys(this.checked).length;
    },

    /** @return {number} */
    nbOptions() {
      return Object.keys(this.options).length;
    },
  },
};

/**
 * @event change
 * @param {boolean} checked
 * @param {?string} option
 */
</script>

<style lang="scss" scoped>
.secondary {
  color: $text-neutral;
}
</style>

<i18n locale="fr">
{
  "checkAll": "Tout cocher",
  "uncheckAll": "Tout décocher"
}
</i18n>

<i18n locale="en">
{
  "checkAll": "Check all",
  "uncheckAll": "Uncheck all"
}
</i18n>

<i18n locale="cz">
{
  "uncheckAll": "Zrušit výběr",
  "checkAll": "Ověřit vše"
}
</i18n>

<i18n locale="de">
{
  "uncheckAll": "Auswahl für alle aufheben",
  "checkAll": "Alle auswählen"
}
</i18n>

<i18n locale="es">
{
  "uncheckAll": "Desmarcar todo",
  "checkAll": "Verificar todo"
}
</i18n>

<i18n locale="it">
{
  "uncheckAll": "Deseleziona tutto",
  "checkAll": "Contrassegna tutto"
}
</i18n>

<i18n locale="pl">
{
  "uncheckAll": "Cofnij zaznaczenie wszystkich",
  "checkAll": "Zaznacz wszystkie"
}
</i18n>
