/** @enum {string} */
export const GroupRoute = {
  DUTY_LIST: 'duty-list',
  PASSENGERS_MESSAGE: 'passengers-message',
  DASHBOARD: 'dashboard',
  DEVICE_LIST: 'device-list',
  DEVICE_DETAILLED: 'device-detailled',
  DRIVER_LIST: 'driver-list',
  GROUP: 'group',
  GTFS_RT: 'gtfs-rt',
  INCIDENT_LIST: 'incident-list',
  INTEGRATIONS: 'integrations',
  LIVE_MAP: 'live-map',
  MESSAGE_LIST: 'message-list',
  NOT_FOUND: 'not-found',
  PASSENGERS_APP: 'passengers-app',
  PASSENGERS_SCREEN: 'passengers-screen',
  PROFILE: 'profile',
  PROFILE_GENERAL: 'profile-general',
  PROFILE_PASSWORD: 'profile-password',
  REPORTS: 'reporting',
  REPORTING_TRIP_KM: 'trip-km',
  REPORTING_TRAVEL_TIME: 'travel-time',
  REPORTING_TRIP_TRACKING: 'trip-tracking',
  REPORTING_PASSENGER_COUNTS: 'passenger-counts',
  REPORTING_CONNECTED_DEVICES: 'connected-devices',
  REPORTING_PASSENGERS_APP: 'passengers-app-sessions',
  REPORTING_OLD_PUNCTUALITY: 'old-punctuality',
  REPORTING_PUNCTUALITY: 'punctuality',
  RESOURCES: 'resources',
  TRANSPORT_PLAN_LIST: 'transport-plan-list',
  SETTINGS: 'settings',
  STOP_LIST: 'stop-list',
  STOP_DETAILED: 'stop-detailed',
  TRIP_LIST: 'trip-list',
  TRIP_LIST_OLD: 'trip-list-old',
  TRIP_DETAILED: 'trip-detailed',
  USER_LIST: 'user-list',
  VEHICLE_LIST: 'vehicle-list',
};

/** @enum {string} */
export const AdminRoute = {
  ADMIN: 'admin',
};

/** @enum {string} */
export const AuthRoute = {
  LOGIN: 'login',
  PASSWORD_CHANGE: 'password-change',
  PASSWORD_RESET: 'password-reset',
};

/** @enum {string} */
export const HomeRoute = {
  GROUP: 'group',
  HOME: 'home',
};
