import { defineAsyncComponent } from 'vue';
import { DataGrid, DataGridColumn } from '@/components/Table/DataGrid/models/DataGrid.models';
import { SortTypes } from '@/components/Table/DataGrid/mixins/Sortable.js';
import { GroupRoute } from '@/libs/routing.js';

import { BoxDirection, StatusEnum } from '@/store/messages';

const dateToQuery = date => new Date(date).toISOString().split('T')[0];

export const columnTypes = {
  CHECKBOX: 'checkbox',
  DEVICE: 'device',
  TRIP: 'trip',
  CONTENT: 'content',
  SEND_DATE: 'sendDate',
  ACTION: 'action',
  PRIORITY: 'priority',
  STATUS: 'status',
  RECIPIENTS: 'recipients',
};

export const searchFields = ['content', 'tripName'];

const StatusCell = defineAsyncComponent(() => import('@/pages/MessageListPage/StatusCell.vue'));
const PriorityCell = defineAsyncComponent(() => import('@/pages/MessageListPage/PriorityCell.vue'));
const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));
const DropListCell = defineAsyncComponent(() => import('@/components/Table/cells/DropListCell.vue'));
const ActionCell = defineAsyncComponent(() => import('@/components/Table/cells/ActionCell.vue'));
const CellDate = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));
const CheckboxCell = defineAsyncComponent(() => import('@/components/ui/Checkbox.vue'));

/** @enum {Function} */
const SortValueBuilders = {
  DATE({ props }) {
    return props.date;
  },

  DEFAULT({ props }) {
    if (props && props.value && Array.isArray(props.values)) return props.values[0]?.text.toLowerCase() ?? '';
    return props && props.value ? props.value : '';
  },
  SENDER({ props }) {
    return props && props.displayValue ? props.displayValue : '';
  },
};

// CHECKBOX
const checkbox = boxDirection =>
  new DataGridColumn({
    apiFieldNames: ['_id', 'status', 'checked'],
    cellBuilder([id, status, checked], { change }) {
      return {
        component: CheckboxCell,
        events: { change },
        props: {
          checked,
          id,
        },
      };
    },
    headerCellBuilder({ change, checked }) {
      return {
        component: CheckboxCell,
        events: { change },
        props: {
          checked,
          id: 'header',
        },
      };
    },
    localeKey: '',
    filterable: false,
    sortable: false,
    selectable: false,
    columnType: columnTypes.CHECKBOX,
    columnStyle: { width: '20px' },
  });

// TRIP
const trip = boxDirection =>
  new DataGridColumn({
    apiFieldNames: ['tripName', 'tripId', 'sendDate', 'status', 'recipients'],
    cellBuilder([tripName, tripId, sendDate, status, recipients]) {
      /** @type {Array<import('@/components/common/DropList.vue').DropListValue>} */
      let values = [];

      if (boxDirection === BoxDirection.INBOX)
        values = [
          {
            text: tripName,
            link: tripId && {
              name: GroupRoute.TRIP_DETAILED,
              params: { tripId },
              query: { date: dateToQuery(sendDate) },
            },
          },
        ];
      else if (recipients) {
        values = recipients.map(({ formattedTripName }) => {
          if (!formattedTripName) return { text: '-' };

          return {
            text: formattedTripName,
            link: tripId && {
              name: GroupRoute.TRIP_DETAILED,
              params: { tripId },
              query: { date: dateToQuery(sendDate) },
            },
          };
        });
      }

      if (!values || values.length === 0) return { value: '-', events: {} };
      return {
        component: DropListCell,
        events: {},
        props: {
          highlight: boxDirection === BoxDirection.INBOX && status !== StatusEnum.READ,
          values: values ?? [{ text: '-' }],
        },
      };
    },
    columnType: columnTypes.TRIP,
    selectable: false,
    sortable: false,
    filterable: false,
    localeKey: 'trip',
    sortValueBuilder: SortValueBuilders.DEFAULT,
  });

// MESSAGE CONTENT
const content = boxDirection =>
  new DataGridColumn({
    apiFieldNames: ['content', 'status'],
    cellBuilder([content, status], { click }) {
      return {
        component: ClickableCell,
        events: { click },
        props: {
          highlight: boxDirection === BoxDirection.INBOX && status !== StatusEnum.READ,
          displayValue: content,
        },
      };
    },
    columnType: columnTypes.CONTENT,
    sortable: true,
    selectable: false,
    filterable: false,
    localeKey: 'content',
    sortValueBuilder: SortValueBuilders.DEFAULT,
    style: { 'text-align': 'left', cursor: 'pointer' },
  });

// SEND DATE
const sendDate = boxDirection =>
  new DataGridColumn({
    apiFieldNames: ['sendDate', 'status'],
    cellBuilder([sendDate, status], { valueChanged }) {
      return {
        component: CellDate,
        events: { valueChanged },
        props: {
          highlight: boxDirection === BoxDirection.INBOX && status !== StatusEnum.READ,
          date: sendDate ? new Date(sendDate) : null,
          options: {},
        },
      };
    },
    columnType: columnTypes.SEND_DATE,
    sortable: true,
    selectable: false,
    filterable: false,
    localeKey: 'sendDate',
    sortValueBuilder: SortValueBuilders.DATE,
  });

// DEVICE
const device = boxDirection =>
  new DataGridColumn({
    apiFieldNames: ['senderName', 'senderId', 'sendDate', 'status'],
    cellBuilder([senderName, senderId, sendDate, status]) {
      return {
        component: ClickableCell,
        events: {},
        props: {
          highlight: boxDirection === BoxDirection.INBOX && status !== StatusEnum.READ,
          link: {
            name: GroupRoute.DEVICE_DETAILLED,
            params: { deviceId: senderId },
            query: { date: dateToQuery(sendDate) },
          },
          displayValue: senderName,
        },
      };
    },
    columnType: columnTypes.DEVICE,
    selectable: false,
    sortable: true,
    filterable: false,
    localeKey: 'sender',
    sortValueBuilder: SortValueBuilders.SENDER,
  });

// STATUS
const status = boxDirection =>
  new DataGridColumn({
    apiFieldNames: ['status'],
    // eslint-disable-next-line no-empty-pattern
    cellBuilder([status], {}, i18n) {
      return {
        component: StatusCell,
        events: {},
        props: {
          status,
          title: i18n.t(`message.status.${status}`),
        },
        value: i18n.t(`message.status.${status}`),
      };
    },
    selectable: false,
    sortable: false,
    filterable: true,
    columnType: columnTypes.STATUS,
    localeKey: 'status.status',
    sortValueBuilder: SortValueBuilders.DEFAULT,
  });

// ACTION
const action = boxDirection =>
  new DataGridColumn({
    apiFieldNames: ['status'],
    cellBuilder([status], { readMessage, readNewMessage, reply, copy, archive }) {
      const actions =
        boxDirection === BoxDirection.OUTBOX
          ? ['copy', 'archive']
          : [status === StatusEnum.READ ? 'readMessage' : 'readNewMessage', 'reply', 'archive'];
      return {
        component: ActionCell,
        events: {
          readMessage,
          readNewMessage,
          reply,
          copy,
          archive,
        },
        props: {
          actions,
        },
      };
    },
    isActionCell: true,
    columnType: columnTypes.ACTION,
    selectable: false,
    sortable: false,
    filterable: false,
    localeKey: 'action',
  });

// PRIORITY
const priority = boxDirection =>
  new DataGridColumn({
    apiFieldNames: ['priority', 'urgent'],
    // eslint-disable-next-line no-empty-pattern
    cellBuilder([priority, urgent], {}, i18n) {
      return {
        component: PriorityCell,
        events: {},
        props: {
          priority,
          urgent,
        },
        value: i18n.t(`message.priority.${priority}`),
      };
    },
    columnType: columnTypes.PRIORITY,
    selectable: false,
    filterable: true,
    sortable: false,
    localeKey: 'priority.priority',
    sortValueBuilder: SortValueBuilders.DEFAULT,
  });

// RECIPIENTS
const recipients = boxDirection =>
  new DataGridColumn({
    apiFieldNames: ['recipients'],
    cellBuilder([recipients]) {
      if (!recipients || recipients.length === 0) return { value: '-', events: {} };
      return {
        component: DropListCell,
        events: {},
        props: {
          values: recipients?.map(({ senderName }) => {
            return {
              text: senderName ?? '-',
            };
          }),
        },
      };
    },
    columnType: columnTypes.RECIPIENTS,
    sortable: false,
    selectable: false,
    filterable: false,
    localeKey: 'recipient',
    sortValueBuilder: SortValueBuilders.DEFAULT,
  });

export const getDatagrid = boxDirection => {
  const columns = (
    boxDirection === BoxDirection.INBOX
      ? [checkbox, device, trip, content, sendDate, action]
      : [checkbox, priority, recipients, trip, content, status, sendDate, action]
  ).map(col => col(boxDirection));
  return new DataGrid({
    columnSelectable: false,
    counter: false,
    columns,
    defaultSortColumn: sendDate(boxDirection),
    defaultSortType: SortTypes.DESC,
  });
};
