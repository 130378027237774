<template>
  <div class="data-period">
    <div class="form-group">
      <div>
        <span class="form-group__label">{{ $t('driverData') }}</span>
      </div>
      <div class="data-period__indicator">
        <font-awesome-icon icon="fa-circle-info" class="data-period__icon" />
        {{ $t('oldestDate') }}
        <span class="data-period__bold">
          {{ oldestDutyDataAvailableDate }}
        </span>
      </div>
      <div>{{ $t('dataAnonymized') }}</div>
      <div class="data-period__selector-box">
        <div>
          <div class="data-period__timeline-label">
            12
            <br />
            {{ $t('months') }}
          </div>
          <div
            ref="data-range"
            class="data-period__data-range"
            @mousemove="getValueDuringMouseMove"
            @mouseover="showRangeHoverValue = true"
            @mouseleave="showRangeHoverValue = false"
          >
            <RangeInput
              :value="values.retention_period"
              :max-value="RANGE_END_VALUE"
              :min-value="RANGE_START_VALUE"
              :step="2"
              @input="updateRangeValue"
            />
          </div>
          <div class="data-period__timeline-label">
            36
            <br />
            {{ $t('months') }}
          </div>
        </div>
        <div
          v-show="showRangeHoverValue"
          class="data-period__range-hover-value"
          :style="{ left: hoverPosition }"
        >
          {{ currentValueHovered }} {{ $t('months') }}
        </div>
        <div
          v-show="!showRangeHoverValue"
          class="data-period__range-hover-value"
          :style="{ left: selectorPosition }"
        >
          {{ values.retention_period }} {{ $t('months') }}
        </div>
        <div class="data-period__indicative-container">
          <font-awesome-icon icon="fa-circle-info" class="data-period__icon" />
          {{ $t('indicativeDate') }}
          <div>
            <span class="data-period__bold">{{ indicativeDate }}</span>
          </div>
        </div>
      </div>
      <Btn type="primary" :disabled="!hasChanged" @click="save">
        <font-awesome-icon :icon="hasChanged ? 'fa-floppy-disk' : 'fa-check'" />
        {{ hasChanged ? $t('save') : $t('saved') }}
      </Btn>
      <Btn type="secondary" :disabled="!hasChanged" @click="cancel">
        {{ $t('cancel') }}
      </Btn>
    </div>
  </div>
</template>

<script>
import Api from '@/api';
import Btn from '@/components/ui/Btn.vue';
import RangeInput from '@/components/ui/RangeInput.vue';

import SettingsMixin from './SettingsMixin';

/** @type {number} */
const HOVER_LEFT_SHIFT = 45;
/** @type {number} */
const MAX_RIGHT_SHIFT = 530;
/** @type {number} */
const MIN_LEFT_SHIFT = 18;
/** @type {number} */
const RANGE_END_VALUE = 36;
/** @type {number} */
const RANGE_START_VALUE = 12;
/** @type {number} */
const SELECTOR_LEFT_SHIFT = 160;

export default {
  name: 'DataPeriod',
  components: { Btn, RangeInput },
  mixins: [SettingsMixin],

  data() {
    return {
      RANGE_END_VALUE,
      RANGE_START_VALUE,

      /** @type {?number} */
      currentValueHovered: null,
      /** @type {?string} */
      hoverPosition: null,
      /** @type {?number} */
      oldestDutyTs: null,
      /** @type {number} */
      rangeWidth: null,
      /** @type {boolean} */
      showRangeHoverValue: false,
    };
  },

  computed: {
    /** @returns {string} */
    indicativeDate() {
      const date = new Date();
      date.setMonth(date.getMonth() - this.values.retention_period);
      return date.toLocaleDateString(this.$i18n.locale);
    },

    /** @returns {string} */
    oldestDutyDataAvailableDate() {
      return this.oldestDutyTs
        ? new Date(this.oldestDutyTs * 1000).toLocaleDateString(this.$i18n.locale)
        : ' - ';
    },

    /** @return {string} */
    selectorPosition() {
      const percentage =
        (this.values.retention_period / ((RANGE_END_VALUE - RANGE_START_VALUE) / 100) - RANGE_START_VALUE) /
        100;
      return `${this.rangeWidth * percentage - SELECTOR_LEFT_SHIFT}px`;
    },
  },

  mounted() {
    /** Get input range width */
    if (this.$refs['data-range']) {
      this.rangeWidth = /** @type {HTMLElement} */ (this.$refs['data-range']).offsetWidth;
    }
  },

  created() {
    if (!this.values.retention_period) {
      this.values.retention_period = RANGE_END_VALUE;
    }
    this.setOldestDutyTs();
  },

  methods: {
    cancel() {
      if (this.group.retention_period) {
        this.values.retention_period = this.group.retention_period;
      } else {
        this.values.retention_period = RANGE_END_VALUE;
      }
    },

    /**
     * Returns default values
     * @returns {Object}
     */
    getDefaultValues() {
      return {
        /** @type {number} */
        retention_period: null,
      };
    },

    /** Retrieve oldest duty available for the group and set oldest duty timestamp */
    async setOldestDutyTs() {
      const entries = await Api.getOldestDutyData(this.group._id);
      entries.length > 0 ? (this.oldestDutyTs = entries[0].check_in.ts) : (this.oldestDutyTs = null);
    },

    /**
     * Get value selected during mouse move
     * @param {MouseEvent} event
     */
    getValueDuringMouseMove(event) {
      // Get range width
      const rangeWidth = /** @type {HTMLElement} */ (this.$refs['data-range']).offsetWidth;
      // Get position of mouse on the range
      const offsetXMouse = event.pageX - /** @type {HTMLElement} */ (this.$refs['data-range']).offsetLeft;
      // Set position for hover
      this.hoverPosition = `${event.offsetX + HOVER_LEFT_SHIFT}px`;
      // Handle click and hold beyond right limit scenario
      if (event.offsetX > MAX_RIGHT_SHIFT) {
        this.hoverPosition = `${MAX_RIGHT_SHIFT + HOVER_LEFT_SHIFT}px`;
      }
      // Handle click and hold beyond left limit scenario
      if (event.offsetX < MIN_LEFT_SHIFT) {
        this.hoverPosition = `${MIN_LEFT_SHIFT + HOVER_LEFT_SHIFT}px`;
      }
      // Get percentage traveled by the mouse on the range to get months value
      const percentOfBar = (offsetXMouse / rangeWidth) * 100;
      this.currentValueHovered = this.roundValue(
        percentOfBar * ((RANGE_END_VALUE - RANGE_START_VALUE) / 100) + RANGE_START_VALUE
      );
      // Keep currentValueHovered from going above max range value
      if (this.currentValueHovered > RANGE_END_VALUE) {
        this.currentValueHovered = RANGE_END_VALUE;
      }
      // Keep currentValueHovered from going under min range value
      if (this.currentValueHovered < RANGE_START_VALUE) {
        this.currentValueHovered = RANGE_START_VALUE;
      }
    },

    /**
     * Round value to an even number
     * @param {number} value
     * @returns {number}
     */
    roundValue(value) {
      const roundedValue = Math.round(value);
      if (roundedValue % 2 === 0) {
        return roundedValue;
      }
      return roundedValue - 1;
    },
    updateRangeValue(value) {
      this.values.retention_period = value;
    },
  },
};
</script>

<style lang="scss">
.data-period {
  &__bold {
    font-weight: $font-weight-semi-bold;
  }

  &__data-range {
    display: inline-block;
    vertical-align: top;
    width: 80%;
    margin: 10px;
    padding-top: 10px;
  }

  &__icon {
    padding: 0 2px;
    font-size: 1.2em;
  }

  &__indicative-container {
    display: inline-block;
    width: 95%;
    margin: 15px 0;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $border;
  }

  &__indicator {
    display: inline-block;
    margin: 15px 0;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: change-color($primary-light, $alpha: 0.1);
  }

  &__range-hover-value {
    position: relative;
    width: 75px;
    color: $primary-light;
    font-weight: $font-weight-semi-bold;
    text-align: left;
  }

  &__selector-box {
    width: 675px;
    margin: 15px 0;
    box-shadow: 3px 3px 15px rgb(0 0 0 / 20%);
    text-align: center;
  }

  &__timeline-label {
    display: inline-block;
    width: 50px;
    padding-top: 10px;
    color: $primary-light;
  }
}
</style>

<i18n locale="fr">
{
  "dataAnonymized": "Sélectionnez la période de temps après laquelle les données des conducteurs seront anonymisées :",
  "driverData": "Données conducteur",
  "indicativeDate": "Date indicative du dernier service disponible :",
  "months": "mois",
  "oldestDate": "Date de la prise de service la plus ancienne enregistrée dans la base de donnée : ",
  "saved": "Enregistré"
}
</i18n>

<i18n locale="en">
{
  "dataAnonymized": "Select the the time period after which drivers data will be anonymized:",
  "driverData": "Driver data",
  "indicativeDate": "Indicative date of last available service:",
  "months": "months",
  "oldestDate": "Date of oldest driver service in the database: ",
  "saved": "Saved"
}
</i18n>
