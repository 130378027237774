<template>
  <div class="gtfs-rt">
    <header>
      <Btn
        type="primary"
        :disabled="!hasEditPassengersMessagesPermission"
        :route="{ name: GroupRoute.PASSENGERS_MESSAGE }"
      >
        {{ $t('newPassengersMessage') }}
      </Btn>
      <div class="gtfs-rt__indicator ui-btn secondary">
        <span class="round-dot" />
        {{ $t('gtfsIndicator') }}
      </div>
    </header>
    <main>
      <section>
        <div class="baseline">
          {{ $t('baselineLeft.segment1') }}
          <span class="baseline__strong">{{ $t('baselineLeft.segment2') }}</span>
          <span class="baseline__offsetline">{{ $t('baselineLeft.segment3') }}</span>
        </div>
        <div class="url-group">
          <div class="url-group__title">
            {{ $t('urlGroupTitle.feed') }}
          </div>
          <CopyContent :content="urlsList.feed" />
        </div>
        <div class="url-group">
          <div class="url-group__title">
            {{ $t('urlGroupTitle.informationType') }}
          </div>
          <CopyContent
            input-id="gtfsrt-tripupdates"
            :label="$t('urlGroupLabel.tripUpdate')"
            label-icon="fa-clipboard-list"
            :content="urlsList.tripUpdates"
          />
          <CopyContent
            input-id="gtfsrt-vehicleposition"
            :label="$t('urlGroupLabel.vehiclePosition')"
            label-icon="fa-location-arrow"
            :content="urlsList.vehiclePosition"
          />
          <CopyContent
            input-id="gtfsrt-alerts"
            :label="$t('urlGroupLabel.alert')"
            label-icon="fa-triangle-exclamation"
            :content="urlsList.alerts"
          />
        </div>
        <div class="url-group">
          <div class="url-group__title">
            {{ $t('urlGroupTitle.yourGtfsRtText') }}
          </div>
          <CopyContent
            class="text"
            :content="gtfsRtPlainText"
            :plain-text="true"
            :refresh-button="true"
            @refresh="getGtfsRtPlainText"
          />
        </div>
        <div class="url-group">
          <div class="url-group__title">
            {{ $t('urlGroupTitle.yourGtfs') }}
          </div>
          <CopyContent :content="urlsList.gtfs" />
        </div>
      </section>
      <section>
        <div class="baseline">
          {{ $t('baselineRight.segment1') }}
          <span class="baseline__strong baseline__offsetline">{{ $t('baselineRight.segment2') }}</span>
        </div>

        <img alt="illustration" src="@/assets/img/gtfs-rt-illustration.svg" />
        <Btn type="secondary" link-url="https://developers.google.com/transit/gtfs-realtime">
          <font-awesome-icon icon="fa-arrow-up-right-from-square" />
          <span>{{ $t('moreInformation') }}</span>
        </Btn>
      </section>
    </main>
  </div>
</template>

<script>
import api from '@/api';
import Btn from '@/components/ui/Btn.vue';
import CopyContent from '@/components/ui/CopyContent.vue';
import { baseAppUrl } from '@/libs/helpers/url';
import { GroupRoute } from '@/libs/routing';
import { Permission } from '@/store';

export default {
  name: 'GtfsRT',

  components: {
    CopyContent,
    Btn,
  },

  data() {
    return {
      GroupRoute,

      /** @type {string} */
      gtfsRtPlainText: '',
    };
  },

  computed: {
    /** @return {?string} */
    groupId() {
      return this.$store.getters.group._id;
    },

    /** @return {boolean} */
    hasEditPassengersMessagesPermission() {
      return this.$store.getters.hasPermission(Permission.EDIT_PASSENGERS_MESSAGES);
    },

    /** @return {UrlsList} */
    urlsList() {
      const baseUrl = `${baseAppUrl}/api/v2/groups/${this.groupId}`;
      return {
        alerts: `${baseUrl}/gtfs-rt/alert`,
        feed: `${baseUrl}/gtfs-rt`,
        gtfs: `${baseUrl}/gtfs/pub`,
        tripUpdates: `${baseUrl}/gtfs-rt/trip-update`,
        vehiclePosition: `${baseUrl}/gtfs-rt/vehicle-position`,
      };
    },
  },

  created() {
    this.getGtfsRtPlainText();
  },

  methods: {
    async getGtfsRtPlainText() {
      const plaintextGtfsRt = await api.gtfs.getGtfsPlainText(this.groupId);
      this.gtfsRtPlainText = JSON.stringify(plaintextGtfsRt, null, 2);
    },
  },
};

/**
 * @typedef {Object} UrlsList
 * @property {string} alerts
 * @property {string} feed
 * @property {string} gtfs
 * @property {string} tripUpdates
 * @property {string} vehiclePosition
 */
</script>

<style lang="scss">
.gtfs-rt {
  &__indicator {
    display: flex;
    cursor: default;

    &:hover {
      background: $background !important;
    }

    .round-dot {
      align-self: center;
      width: 12px;
      height: 12px;
      margin: 2px 10px 0 0;
      border-radius: 50%;
      background: $primary-light;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    height: 120px;
    padding: $view-standard-padding;

    .ui-btn {
      height: 44px;
    }
  }

  main {
    display: flex;
    padding: 0 60px 0 35px;
  }

  .baseline {
    color: $secondary;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;

    &__offsetline {
      display: block;
    }

    &__strong {
      color: $primary-light;
    }
  }

  section {
    width: 50%;

    &:nth-child(1) {
      .baseline {
        &__offsetline {
          width: 200px;
          margin-bottom: 35px;
          margin-left: 185px;
        }
      }
    }

    &:nth-child(2) {
      .baseline {
        margin-left: auto;
        text-align: end;
      }

      a {
        display: block;
        margin-left: auto;
      }

      img {
        display: block;
        width: 70%;
        margin: 80px 0 80px auto;
      }
    }
  }

  .url-group {
    margin-bottom: 40px;

    &__title {
      margin-bottom: 15px;
      color: $text-dark;
      font-weight: $font-weight-semi-bold;
    }
  }

  // Override styles of CopyContent component to adapt them for this view:
  .copy-content.text {
    .copy-content__buttons-container {
      flex-direction: column;
    }
  }
}
</style>

<i18n locale="fr">
{
  "baselineLeft": {
    "segment1": "En temps réel, ",
    "segment2": "mis à jour toutes les 5 secondes",
    "segment3": "pour votre réseau !"
  },
  "baselineRight": {
    "segment1": "Fournissez à vos voyageurs des données mises à jour",
    "segment2": "en temps réel !"
  },
  "urlGroupLabel": {
    "tripUpdate": "Modification de courses",
    "vehiclePosition": "Position des véhicules",
    "alert": "Incidents"
  },
  "urlGroupTitle": {
    "feed": "Votre flux GTFS-RT",
    "informationType": "Votre GTFS-RT par type d'information",
    "yourGtfs": "Votre GTFS",
    "yourGtfsRtText": "Votre GTFS-RT en version texte"
  },
  "gtfsIndicator": "GTFS-RT actif",
  "moreInformation": "Plus d'informations",
  "newPassengersMessage": "Nouveau message voyageurs"
}
</i18n>

<i18n locale="en">
{
  "baselineLeft": {
    "segment1": "Real time, ",
    "segment2": "updated every 5 seconds",
    "segment3": "for all your network !"
  },
  "baselineRight": {
    "segment1": "Provide to your passengers trip url updates",
    "segment2": "in real time !"
  },
  "urlGroupLabel": {
    "tripUpdate": "Trip update",
    "vehiclePosition": "Vehicle position",
    "alert": "Alert"
  },
  "urlGroupTitle": {
    "feed": "Your GTFS-RT feed",
    "informationType": "Your GTFS-RT by information type",
    "yourGtfs": "Your GTFS",
    "yourGtfsRtText": "Your GTFS-RT in text version"
  },
  "gtfsIndicator": "GTFS-RT active",
  "moreInformation": "More information",
  "newPassengersMessage": "New passengers message"
}
</i18n>
