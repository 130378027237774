import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import store, { Permission } from '@/store';

const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));
const DateCell = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));
const CheckboxCell = defineAsyncComponent(() => import('@/components/ui/Checkbox.vue'));
const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));
const EntityCell = defineAsyncComponent(() => import('@/components/Table/cells/EntityCell.vue'));
const TimeRangeCell = defineAsyncComponent(() => import('@/components/Table/cells/TimeRangeCell.vue'));

export const PASSENGERS_MESSAGE_LS_COLUMNS = 'passengersMessagePage/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  ACTIVE: 'active',
  HEADER: 'header_text',
  DESCRIPTION: 'description',
  TIME_RANGE: 'timeRange',
  CAUSE: 'causeFormatted',
  EFFECT: 'effectFormatted',
  PUSH: 'pushFormatted',
  ENTITY: 'entities',
  URL: 'url',
  ACTION: 'action',
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(PASSENGERS_MESSAGE_LS_COLUMNS));

  // Helpers
  const defaultSelected = columnKey => !ls || ls.includes(columnKey);

  return new DataGrid({
    name: 'passengersMessageDatagrid',
    searchFields: ['header_text'],
    defaultSortBy: [{ key: ColumnKey.TIME_RANGE, order: SortOrder.DESC }],
    hasActions: true,
    columnSelectionLocalStorageKey: PASSENGERS_MESSAGE_LS_COLUMNS,
    columns: [
      // ACTIVE
      ...(store.getters.hasPermission(Permission.EDIT_PASSENGERS_MESSAGES)
        ? [
            new DataGridColumn({
              key: ColumnKey.ACTIVE,
              title: 'column.active',
              defaultSelected: defaultSelected(ColumnKey.ACTIVE),
              selectable: false,
              sortable: false,
              cellBuilder([value, object], { click }) {
                return {
                  component: CheckboxCell,
                  events: { click },
                  props: {
                    checked: value,
                    id: object._id,
                    type: 'active',
                  },
                };
              },
            }),
          ]
        : []),

      // HEADER
      new DataGridColumn({
        key: ColumnKey.HEADER,
        title: 'column.header',
        defaultSelected: defaultSelected(ColumnKey.HEADER),
        selectable: false,
        cellBuilder([value, object]) {
          return {
            component: EditableCell,
            events: {},
            props: {
              value,
              type: 'header',
            },
          };
        },
      }),

      // DESCRIPTION
      new DataGridColumn({
        key: ColumnKey.DESCRIPTION,
        title: 'column.description',
        defaultSelected: defaultSelected(ColumnKey.DESCRIPTION),
        cellBuilder([value, object]) {
          return {
            component: EditableCell,
            events: {},
            props: {
              value,
              type: 'description',
            },
          };
        },
      }),

      // TIME RANGE
      new DataGridColumn({
        key: ColumnKey.TIME_RANGE,
        title: 'column.timeRange',
        defaultSelected: defaultSelected(ColumnKey.TIME_RANGE),
        selectable: false,
        cellBuilder([value, object]) {
          return {
            component: TimeRangeCell,
            events: {},
            props: {
              timeRange: object.active_date_times?.length > 0 ? object.active_date_times[0] : null,
            },
          };
        },
      }),

      // CAUSE
      new DataGridColumn({
        key: ColumnKey.CAUSE,
        title: 'column.cause',
        defaultSelected: defaultSelected(ColumnKey.CAUSE),
        filterable: true,
        sortable: false,
        cellBuilder([value, object]) {
          return {
            component: EditableCell,
            events: {},
            props: {
              value,
            },
          };
        },
      }),

      // EFFECT
      new DataGridColumn({
        key: ColumnKey.EFFECT,
        title: 'column.effect',
        defaultSelected: defaultSelected(ColumnKey.EFFECT),
        filterable: true,
        sortable: false,
        cellBuilder([value, object]) {
          return {
            component: EditableCell,
            events: {},
            props: {
              value,
            },
          };
        },
      }),

      // LAST PUSH
      new DataGridColumn({
        key: ColumnKey.PUSH,
        title: 'column.push',
        defaultSelected: defaultSelected(ColumnKey.PUSH),
        sortable: false,
        cellBuilder([value, object]) {
          return {
            component: DateCell,
            events: {},
            props: {
              date: value,
            },
          };
        },
      }),

      // ENTITY
      new DataGridColumn({
        key: ColumnKey.ENTITY,
        title: 'column.entity',
        defaultSelected: defaultSelected(ColumnKey.ENTITY),
        sortable: false,
        cellBuilder([value, object], { optionsEntities, showModal }) {
          if (!value) return null;
          return {
            component: EntityCell,
            events: { showModal },
            props: {
              entities: value,
              optionsEntities,
            },
          };
        },
      }),

      // URL
      new DataGridColumn({
        key: ColumnKey.URL,
        title: 'column.url',
        defaultSelected: defaultSelected(ColumnKey.URL),
        cellBuilder([value, object]) {
          return {
            component: ClickableCell,
            events: {},
            props: {
              displayValue: value,
              url: value,
            },
          };
        },
      }),
    ],
  });
};
