<template>
  <div class="datagrid" :class="loading ? `datagrid-loading` : ''">
    <div v-if="showHeader" class="datagrid__header">
      <div class="header__start">
        <v-tabs v-if="localTabs" v-model="activeTab" slider-color="#00b871" class="datagrid_tabs">
          <v-tab
            v-for="tab in localTabs"
            :key="tab.value"
            size="small"
            :value="tab.value"
            :prepend-icon="tab.icon"
          >
            {{ tab.name }} ({{ tab.counter }})
          </v-tab>
        </v-tabs>

        <slot name="headerStart" />
      </div>
      <div class="header__center">
        <div v-if="datagrid.hasCounter()" class="header__counter">
          {{
            $tc('resultCount', dataGridData.dataRows.length, {
              count: dataGridData.dataRows.length,
            })
          }}
        </div>

        <slot name="headerCenter" />
      </div>
      <div class="header__end">
        <TableSearchBar
          v-if="datagrid.getSearchFields() !== null"
          ref="datagridSearchBar"
          :search-fields="datagrid.getSearchFields()"
          :search-list="data"
          :id-key="rowIdKey"
          @filteredList="setIdListResultSearched"
        />
        <DataGridFilterEraser
          v-if="datagrid.getSaveFilter()"
          :filters="columnFilters"
          :ls-filters-name="`settings.op.${datagrid.getName()}`"
          @eraseFilters="removeFilters"
        />
        <div v-if="datagrid.isColumnSelectable() && showColumnSelector" class="header__column-selector">
          <DataGridColumnSelector
            :display-on-left="showHeader"
            :options="datagrid.getSelectableColumns()"
            :local-storage-key="datagrid.getColumnSelectionLocalStorageKey()"
          />
        </div>

        <slot name="headerEnd" />
      </div>
    </div>

    <div class="datagrid__body">
      <template v-if="(reloadOnRefresh && loading) || (loading && data.length === 0)">
        <div class="datagrid__loading">
          <AnimatedDots />
        </div>
      </template>

      <template v-else-if="error">
        <div class="datagrid__error">
          {{ error }}
        </div>
      </template>

      <!-- Table -->
      <table v-else class="datagrid__table">
        <thead class="datagrid__table-header">
          <tr>
            <slot name="tableHeader">
              <DataGridHeaderCell
                v-for="(column, key) in datagrid.getColumns()"
                :key="key"
                v-slot="{ sortClass }"
                :sortable="isSortable(column)"
                :sorting="sortColumn && sortColumn.isEqual(column)"
                :sort-type="sortType"
                :class="getColumnClass(column)"
                :column-style="column.getColumnStyle()"
              >
                <div class="datagrid__inner-header-cell" @click="sort(column)">
                  <DataGridCell v-if="column.headerCellBuilder" :data-cell="getHeaderCell(column)" />

                  <template v-else>
                    <div v-if="!!merging[column.getType()]" class="datagrid__col-merged">
                      <span>{{ $t('merged') }}</span>
                    </div>

                    <div :class="sortClass">
                      <span :title="modelI18n.t(column.getLocaleKey())">
                        {{ modelI18n.t(column.getLocaleKey()) }}
                      </span>
                    </div>
                  </template>
                </div>

                <DataGridColumnFilter
                  v-if="column.isFilterable() && columnFilters[column.getType()]"
                  v-model:filters="columnFilters[column.getType()]"
                  :i18n-prefix="column.getI18nPrefix()"
                  @toggleAll="([check]) => toggleAllFilters(column, check)"
                />
              </DataGridHeaderCell>
            </slot>
          </tr>
          <tr v-if="dataSummary" class="datagrid__summary">
            <td colspan="2">
              {{ dataSummary.total }}
            </td>
            <td colspan="2">
              {{ dataSummary.average }}
            </td>
          </tr>
        </thead>

        <tbody class="datagrid__table-body">
          <slot name="tableBody">
            <template v-for="(dataGridDataRow, rowKey) in partialRenderedRows" :key="rowKey">
              <tr :id="dataGridDataRow.id" class="datagrid__table-row datagrid__table-row--main">
                <td
                  v-for="(dataGridDataCell, columnKey) in dataGridDataRow.getDataCells()"
                  :key="columnKey"
                  class="datagrid__table-column"
                  :class="getColumnClass(dataGridDataCell.getColumn())"
                  :style="dataGridDataCell.getColumn().getStyle()"
                >
                  <DataGridCell
                    v-if="isVisible(dataGridDataCell.getColumn())"
                    :data-cell="dataGridDataCell"
                  />
                </td>
              </tr>

              <template v-if="dataGridDataRow.showChildren">
                <tr
                  v-for="(childRow, index) in getTabChildRows(dataGridDataRow.getChildren())"
                  :key="`${rowKey}_child_${index}`"
                  class="datagrid__table-row datagrid__table-row--child"
                >
                  <td
                    v-for="(dataGridDataCell, columnKey) in childRow.getDataCells()"
                    :key="columnKey"
                    class="datagrid__table-column"
                    :class="getColumnClass(dataGridDataCell.getColumn())"
                    :style="dataGridDataCell.getColumn().getStyle()"
                  >
                    <DataGridCell :data-cell="dataGridDataCell" />
                  </td>
                </tr>
              </template>
            </template>
          </slot>
        </tbody>
      </table>
      <Pagination
        v-if="paginated"
        class="pagination"
        :page-size="30"
        :items="renderedRows"
        @changePage="onChangePage"
      />
      <!-- End of table -->

      <!-- Slot to display custom component in case of no data -->
      <slot></slot>
    </div>

    <slot name="footer" />
  </div>
</template>

<script>
import { toRaw } from 'vue';
import TableSearchBar from '@/components/Table/TableSearchBar.vue';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';

import DataGridCell from './DataGridCell.vue';
import DataGridColumnFilter from './DataGridColumnFilter.vue';
import DataGridColumnSelector from './DataGridColumnSelector.vue';
import DataGridHeaderCell from './DataGridHeaderCell.vue';
import DataGridFilterEraser from './DataGridFilterEraser.vue';
import ColumnSelectable from './mixins/ColumnSelectable';
import Sortable, { SortTypes } from './mixins/Sortable';
import { DataGridData, DataGridDataCell, DataGridDataRow } from './models/DataGrid.data.models';
import { DataGrid } from './models/DataGrid.models';
import Pagination from '../../ui/Pagination.vue';

/** @enum {string} */
export const StateMergingChildren = {
  SHOWN: 'shown',
  HIDDEN: 'hidden',
};

export default {
  name: 'DataGridVuetify',

  components: {
    AnimatedDots,
    DataGridCell,
    DataGridColumnSelector,
    DataGridColumnFilter,
    DataGridHeaderCell,
    Pagination,
    DataGridFilterEraser,
    TableSearchBar,
  },

  mixins: [Sortable, ColumnSelectable],

  /** @return {{modelI18n: import('vue-i18n').IVueI18n}} */
  provide() {
    return {
      modelI18n: this.modelI18n,
    };
  },

  props: {
    buildHeaderCellInjectors: {
      default: () => ({}),
      type: Object,
    },

    /** @type {Vue.PropOptions<{[columnKey: string]: Function}>} */
    buildCellInjectors: {
      default: () => ({}),
      type: Object,
    },

    datagrid: {
      required: true,
      type: DataGrid,
    },

    /** @type {import('vue').Prop<Array>} */
    data: {
      required: true,
      type: Array,
    },

    /** @type {import('vue').Prop<{[x: string]: any[]}>} */
    dataFilters: {
      required: false,
      type: Object,
      default: null,
    },

    /** @type {import('vue').Prop<DataSummary>} */
    dataSummary: {
      required: false,
      default: null,
      type: Object,
    },

    paginated: {
      required: false,
      default: false,
      type: Boolean,
    },

    rowIdKey: {
      required: false,
      default: null,
      type: String,
    },

    error: {
      default: null,
      type: String,
    },

    /** @type {Vue.PropOptions<{[key: string]: boolean}>} */
    merging: {
      default: () => ({}),
      type: Object,
    },

    /** @type {Vue.PropOptions<import('vue-i18n').IVueI18n>} */
    modelI18n: {
      required: true,
      type: Object,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    showColumnSelector: {
      default: true,
      type: Boolean,
    },

    showHeader: {
      default: true,
      type: Boolean,
    },

    /** @type {import('vue').Prop<Array<Tab>>} */
    tabs: {
      required: false,
      default: null,
      type: Array,
    },
    reloadOnRefresh: {
      required: false,
      default: true,
      type: Boolean,
    },
  },

  data: () => ({
    /** @type {{[columnKey: string]: import('./models/DataGrid.models').ColumnFilterState}} */
    columnFilters: {},
    pagedItemsIndexes: null,
    activeTab: null,
    /** @type {Array<Tab>} */
    localTabs: [],
    /** @type {Array<string>} */
    idListResultSearched: null,
  }),

  computed: {
    /** @return {DataGridData} */
    dataGridData() {
      const dataGridData = new DataGridData();
      const columns = this.datagrid.getColumns();
      this.data.forEach(row => {
        const children = [];
        if (row.trips) {
          children.push(...row.trips.map(trip => new DataGridDataRow(this.getDataCells(trip, columns))));
        }

        const id = row[this.rowIdKey];
        const dataGridDataRow = new DataGridDataRow(this.getDataCells(row, columns), children, id);
        if (row.trip_status === StateMergingChildren.SHOWN) {
          dataGridDataRow.showChildren = true;
        }

        dataGridData.push(dataGridDataRow);
      });

      return dataGridData;
    },

    /** @return {Array<DataGridDataRow>} */
    renderedRows() {
      if (this.tabs && this.activeTab) {
        this.calculateTabsData();
        const selectedTab = this.localTabs.find(tab => tab.value === this.activeTab);
        return toRaw(selectedTab.dataListRendered);
      }
      return this.calculateRenderedRows(this.dataGridData.dataRows);
    },
    /**
     * Slice rendered row to improve perfs
     */
    partialRenderedRows() {
      if (this.paginated && this.pagedItemsIndexes && this.renderedRows?.length > 0) {
        return this.renderedRows.slice(this.pagedItemsIndexes.start, this.pagedItemsIndexes.end);
      }
      return this.renderedRows;
    },

    /** @return {Array<import('./models/DataGrid.models').DataGridColumn>} */
    visibleColumns() {
      if (!this.datagrid.isColumnSelectable()) {
        return this.datagrid.getColumns();
      }

      return this.datagrid.getColumns().filter(
        gridCol =>
          // @ts-ignore TS2339: `selectedColumns` comes from mixin
          !gridCol.isSelectable() || this.selectedColumns.find(sc => gridCol.isEqual(sc))
      );
    },
    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },
  },

  watch: {
    dataGridData() {
      this.updateColumnFilters();
    },
    tabs: {
      deep: true,
      immediate: true,
      handler() {
        this.localTabs = this.tabs;
      },
    },
  },

  created() {
    this.updateColumnFilters();
    if (this.tabs) {
      this.activeTab = this.tabs.find(tab => tab.isDefaultActive)?.value || this.tabs[0]?.value;
    }
  },

  methods: {
    /**
     * @param {Object} row
     * @param {Array<import('./models/DataGrid.models').DataGridColumn>} columns
     * @return {Array<DataGridDataCell>}
     */
    getDataCells(row, columns) {
      return columns.map(column => {
        const columnName = column.getType();
        const columnApiFieldNames = column.getApiFieldNames();

        // Api fields values
        const values = columnApiFieldNames.map(c => row[c]);

        // Useful extra data to pass to the cellBuilder function
        const extra = this.buildCellInjectors[columnName]
          ? this.buildCellInjectors[columnName]({ column, apiData: row })
          : {};

        // Build data cell
        return new DataGridDataCell(column.buildCell(values, extra, this.$i18n));
      });
    },

    getHeaderCell(col) {
      if (!col.buildHeaderCell) return null;
      const extra = this.buildHeaderCellInjectors[col.getType()]() ?? {};
      return new DataGridDataCell(col.buildHeaderCell(extra, this.$i18n));
    },

    /**
     * @param {import('./models/DataGrid.models').DataGridColumn} column
     * @param {boolean} check - State to put checkbox into.
     */
    toggleAllFilters(column, check) {
      Object.keys(this.columnFilters[column.getType()].state).forEach(key => {
        this.columnFilters[column.getType()].state[key] = check;
      });
    },

    updateColumnFilters() {
      /** @type {{[columnKey: string]: Set<string>}} */
      const columnFiltersSorted = {};
      const datagridName = this.datagrid.getName();
      const saveFilter = this.datagrid.getSaveFilter();
      this.datagrid.getFilterableColumns().forEach(column => {
        columnFiltersSorted[column.getType()] = new Set();

        if (!(column.getType() in this.columnFilters)) {
          this.columnFilters[column.getType()] = {
            state: {},
            list: [],
            saveFilter,
            datagridName,
            groupId: this.groupId,
            columnName: column.getLocaleKey(),
            isActive: false,
          };
        }
      });

      this.dataGridData.dataRows.forEach(dataRow => {
        dataRow.getDataCells().forEach(cellData => {
          const column = cellData.getColumn();
          if (column.isFilterable()) {
            // If has pre-calculated filters (case where filters list are changing too recurently),
            // use them instead of the cellData values
            const preCalculatedFilters = (this.dataFilters && this.dataFilters[column.getType()]) || null;
            let filterValue = preCalculatedFilters || cellData.filterValue || cellData.value;

            if (!Array.isArray(filterValue)) {
              filterValue = [filterValue];
            }

            filterValue.forEach(value => {
              columnFiltersSorted[column.getType()].add(value);

              if (!(value in this.columnFilters[column.getType()].state)) {
                const checked = !this.columnFilters[column.getType()].isActive;
                this.columnFilters[column.getType()].state[value] = checked;
              }
            });
          }
        });
      });

      Object.entries(columnFiltersSorted).forEach(([columnType, filter]) => {
        // Clean filters from removed values
        Object.keys(this.columnFilters[columnType].state).forEach(key => {
          if (!filter.has(key)) {
            delete this.columnFilters[columnType].state[key];
          }
        });

        // Sort filter values
        this.columnFilters[columnType].list = [...filter.values()].sort();
      });
    },
    onChangePage(pageOfItems) {
      this.pagedItemsIndexes = pageOfItems;
    },
    /**
     * Recreate an array of data from dataGridData with formated values defined in conf.js in order to export data afterward
     * @return {?ExportDataObject} an object containing an array of data and localKeys for translations
     */
    exportData() {
      const exportData = /** @type {ExportDataObject} */ ({
        localKeys: [],
        data: [],
      });
      const dataCellName = [];
      if (this.dataGridData?.dataRows && this.dataGridData.dataRows.length > 0) {
        // Get every type of data (cells) except action
        const firstItem = this.dataGridData.dataRows[0].dataCells;
        firstItem.forEach(cell => {
          if (cell.column.columnType !== 'action') {
            dataCellName.push(cell.column.columnType);
            exportData.localKeys.push(cell.column.localeKey);
          }
        });

        // create new object with names & add to new list
        this.dataGridData.dataRows.forEach(row => {
          const newObject = {};
          dataCellName.forEach((name, index) => {
            newObject[name] = row.dataCells[index].value;
          });
          exportData.data.push(newObject);
        });
        return exportData;
      }
      return null;
    },

    /**
     * delete a filter, or every filter if no filter passed in param
     * @param {import('@/components/Table/DataGrid/models/DataGrid.models').ColumnFilterState} filter
     */
    removeFilters(filter = null) {
      // get All filterable columns
      let columnsToReset = this.datagrid.getColumns().filter(column => column.filterable);
      // If remove only 1 filter, update list to reset
      if (filter) {
        columnsToReset = columnsToReset.filter(gridCol => filter.columnName === gridCol.localeKey);
      }
      columnsToReset.forEach(column => this.toggleAllFilters(column, true));
    },

    /**
     * update filters from local storage
     * @param {Object} filters
     */
    updateFilters(filters) {
      Object.entries(filters).forEach(([key, value]) => {
        const column = this.datagrid.getColumns().find(col => col.localeKey === key);
        this.columnFilters[column.getType()].isActive = true;
        this.columnFilters[column.getType()].state = value;
      });
    },
    /**
     * Calculate rendered rows depending on sorting & filters & searchFilter
     * @param {Array<DataGridDataRow>} rows
     * @return {Array<DataGridDataRow>}
     */
    calculateRenderedRows(rows) {
      let renderedRows = rows.filter(
        row =>
          !row.getDataCells().find(cellData => {
            const column = cellData.getColumn();
            const filterValue = cellData.filterValue || cellData.value;
            return (
              column.isFilterable() &&
              (Array.isArray(filterValue)
                ? !filterValue.some(v => this.columnFilters[column.getType()].state[v])
                : !this.columnFilters[column.getType()].state[filterValue])
            );
          })
      );

      // If has searchBar result, need to filter again
      if (this.idListResultSearched)
        renderedRows = renderedRows.filter(row => this.idListResultSearched.includes(row.id));

      // @ts-ignore TS2339: `sorting` comes from mixin
      if (!this.sorting) {
        return renderedRows;
      }

      const sortRowValue = row => {
        // @ts-ignore TS2339: `sortColumn` comes from mixin
        const dataCell = row.getDataCell(this.sortColumn);
        // @ts-ignore TS2339: `sortColumn` comes from mixin
        return dataCell ? this.sortColumn.getSortValue(dataCell) : '';
      };

      return renderedRows.sort((rowA, rowB) => {
        const valueA = sortRowValue(rowA);
        const valueB = sortRowValue(rowB);
        if (valueA === Symbol.for('firstRow')) {
          return -1;
        }
        if (valueB === Symbol.for('firstRow')) {
          return 1;
        }
        // @ts-ignore TS2339: `sortType` comes from mixin
        const sortType = this.sortType === SortTypes.ASC ? -1 : 1;
        return valueA < valueB ? sortType : -sortType;
      });
    },
    /**
     * Set LocalTab based on datagrid data using calculateRenderedRows
     */
    calculateTabsData() {
      this.localTabs = this.localTabs.map(tab => {
        let dataRows = this.dataGridData.dataRows;

        // Filter dataRows only if has filterValues
        if (tab.filterField && tab.filterValues?.length > 0) {
          // filter values based on field filtered & values required (from Tabs)
          dataRows = this.dataGridData.dataRows.filter(row =>
            row.getDataCells().find(cellData => {
              const column = cellData.getColumn();
              if (column.columnType === tab.filterField) {
                // if row has children, check if one of them has the value
                if (cellData.props.hasChildren) {
                  const children = row.getChildren();
                  return children.some(child => tab.filterValues.includes(child.getDataCell(column).value));
                }
                // else check if row has the value
                return tab.filterValues.includes(cellData.value);
              }
              return false;
            })
          );
        }
        // use this filtered data list & calculate rendered rows based on filter/search/order from Datagrid
        tab.dataListRendered = this.calculateRenderedRows(dataRows);
        tab.counter = tab.dataListRendered?.length;
        return tab;
      });
    },

    /**
     * Get children rows of grouped trip based on tab filter
     * @param {Array<DataGridDataRow>} childRows
     * @return {Array<DataGridDataRow>}
     */
    getTabChildRows(childRows) {
      // if no tab, return all children
      if (!this.tabs || !this.activeTab) return childRows;

      const selectedTab = this.localTabs.find(tab => tab.value === this.activeTab);

      // if tab has no filter, return all children
      if (!selectedTab.filterField || !selectedTab.filterValues?.length) return childRows;

      // filter children based on tab filter
      const filteredChildren = childRows.filter(row =>
        row.getDataCells().find(cellData => {
          const column = cellData.getColumn();
          if (column.columnType === selectedTab.filterField) {
            return selectedTab.filterValues.includes(cellData.value);
          }
          return false;
        })
      );
      return filteredChildren;
    },
    /**
     * Set an id list from searched bar, if no search list is null
     */
    setIdListResultSearched(filteredList) {
      let resultSearch = null;
      // TODO : relocate this logic in TableSearchaBar when reworking Datagrid
      // TableSearchBar should return null if no search, but for now we need to do it here
      if (filteredList.length !== this.data.length) {
        resultSearch = filteredList.map(elem => elem[this.rowIdKey]);
      }
      this.idListResultSearched = resultSearch;
    },
  },
};

/**
 * @typedef {Object} DataSummary
 * @property {string} [average]
 * @property {string} [total]
 */

/**
 * @typedef {Object} ExportDataObject
 * @property {Array<Object>} data
 * @property {Array<string>} localKeys
 */

/**
 * @typedef {Object} Tab
 * @property {string} value
 * @property {string} name
 * @property {string} [icon]
 * @property {number} [counter]
 * @property {string} filterField
 * @property {Array<string> | Array<boolean>} [filterValues]
 * @property {Array<DataGridDataRow>} [dataListRendered]
 * @property {boolean} isDefaultActive
 */
</script>

<style lang="scss">
.datagrid {
  $border-default: 1px solid $border;

  &.datagrid-loading {
    .pagination {
      opacity: 0;
    }

    .datagrid__body {
      box-shadow: none;
    }
  }

  &__col-merged {
    padding-top: 5px;

    > span {
      padding: 1px 4px;
      border-radius: 5px;
      background-color: $text-neutral;
      color: $text-light;
      font-size: 0.8em;
    }
  }

  &__error,
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10em;
  }

  &__error {
    background: $transparent-danger;
  }

  &__loading {
    background: $background-variant;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 0;
  }

  &__inner-header-cell {
    display: inline-block;
    vertical-align: middle;
    font-weight: $font-weight-semi-bold;
  }

  &__summary {
    box-shadow: 0 0 0 1px $border;

    td:first-child {
      text-align: start;
    }

    td:last-child {
      text-align: end;
    }
  }

  .header__start,
  .header__center,
  .header__end {
    display: flex;
    align-items: center;

    & > * {
      &:not(:first-child) {
        padding-left: 0.3em;
      }

      &:not(:last-child) {
        padding-right: 0.3em;
      }
    }
  }

  &__table {
    --cell-px: 0.6em;
    --cell-py: 0.7em;
    --head-cell-px: var(--cell-px);
    --head-cell-py: 1.3em;
    --head-h: 3.5em;

    width: 100%;
    min-width: 100%;
    border-collapse: collapse;
    background-color: $canvas;
    table-layout: fixed;

    .hidden-column {
      display: none;
    }

    &-header {
      position: sticky;
      top: 0;
      z-index: $datagrid-header-index;
      border-bottom: $border-default;
      background: $background-variant;
      color: $text-dark-variant;

      tr {
        height: var(--head-h);
      }
    }

    &-row {
      border-bottom: $border-default;
      line-height: 1em;

      &--child {
        background-color: change-color($primary-light, $alpha: 0.08);
      }
    }

    &-column {
      padding: var(--cell-py) var(--cell-px);
    }

    th,
    td {
      padding: 0.8em 1em;
      font-size: calc(0.6em + 0.3vw);

      &:first-child {
        padding-left: 2em;
      }

      &:last-child {
        padding-right: 2em;
      }
    }
  }

  .header__start {
    justify-content: flex-start;
  }

  .header__center {
    justify-content: center;
  }

  .header__end {
    justify-content: flex-end;
  }

  &__body {
    border: $border-default;
    border-radius: 5px;
    box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
  }

  .header__column-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  // hide table header for "action cell"
  th.action-column {
    width: 10px;
    padding-right: 0;
    font-size: 0; // best solution to hide header
  }

  // hide action buttons by default
  td.action-column {
    display: none;
    padding: 0;

    .action-cell {
      padding-right: 2em;

      .action-buttons {
        z-index: 2;
        padding-left: 70px;
        background: linear-gradient(90deg, rgb(0 0 0 / 0%) 0%, $background 20%, $background 100%);
      }
    }
  }

  // change background color of row on hover + display action buttons
  &__table-row--main:hover {
    background-color: $background;

    td.action-column {
      display: table-cell;

      .action-cell {
        margin-left: -400px;
      }
    }
  }
}
</style>

<i18n locale="fr">
{
  "merged": "Regroupés",
  "resultCount": "Résultat: {count} ligne | Résultat: {count} lignes"
}
</i18n>

<i18n locale="en">
{
  "merged": "Merged",
  "resultCount": "Result: {count} row | Result: {count} rows"
}
</i18n>
