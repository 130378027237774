<template>
  <div class="datagrid-column-filter dropdown" :class="{ 'dropdown--open': dropdownOpened }">
    <font-awesome-icon
      icon="fa-filter"
      :class="{ 'datagrid-column-filter__active': !allChecked }"
      @click="dropdownToggle"
    />
    <div v-if="dropdownOpened" @click.stop>
      <div class="dropdown__menu filter">
        <div class="filter-search">
          <font-awesome-icon icon="fa-search" class="filter-search__icon" />
          <input v-model="searchInput" class="filter-search__input" :placeholder="$t('search')" type="text" />
        </div>

        <div class="filter__actions">
          <a href="#select-all" @click.prevent="toggleAll">
            {{ $t(allChecked ? 'uncheckAll' : 'checkAll') }}
          </a>
        </div>

        <ul class="filter__list">
          <li v-for="value in values" :key="value">
            <v-checkbox
              :id="value"
              v-model="states[value]"
              :ripple="false"
              :title="value"
              color="success"
              hide-details
            >
              <template #label>
                {{ i18nPrefix ? $t(`${i18nPrefix}.${value}`) : value }}
              </template>
            </v-checkbox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { normalize, RE_ESCAPE } from '@/libs/helpers/strings';
import { DatagridLocalStorageHelper } from '@/libs/datagridLocalStorageHelper';

export default {
  name: 'DataGridColumnFilter',

  props: {
    /** @type {Vue.PropOptions<import('@/components/Table/DataGrid/models/DataGrid.models').ColumnFilterState>} */
    filters: {
      required: true,
      type: Object,
    },
    i18nPrefix: {
      required: false,
      type: String,
      default: null,
    },
  },

  emits: ['toggle-all'],

  data: () => ({
    dropdownOpened: false,
    searchInput: '',
    lsHelper: null,
    fetchedState: null,
  }),

  computed: {
    /** @return {import('@/components/Table/DataGrid/models/DataGrid.models').ColumnFilterState} */
    copiedFilters() {
      const copiedFilters = this.filters;
      if (this.filters.saveFilter && this.fetchedState) copiedFilters.state = this.fetchedState;
      return copiedFilters;
    },
    /** @return {boolean} */
    allChecked() {
      return Object.values(this.copiedFilters.state).every(checked => checked);
    },

    /** @return {{[key: string]: boolean}} */
    states() {
      return this.copiedFilters.state || {};
    },

    /** @return {Array<string>} */
    values() {
      const list = this.copiedFilters.list || [];

      if (this.searchInput !== '') {
        const input = normalize(this.searchInput).replace(RE_ESCAPE, '\\$&');
        const re = new RegExp(input, 'i');
        return list.filter(value => re.test(normalize(value)));
      }

      return list;
    },
  },

  watch: {
    allChecked() {
      this.copiedFilters.isActive = !this.allChecked;
    },
    states: {
      deep: true,
      handler() {
        if (this.filters.saveFilter && Object.keys(this.states).length !== 0) {
          if (this.allChecked) this.lsHelper.removeFilter();
          else this.lsHelper.addOrUpdateFilter(this.states);
        }
      },
    },
  },
  created() {
    if (this.filters.saveFilter) {
      const localStorageKey = `settings.op.${this.filters.datagridName}`;
      this.lsHelper = new DatagridLocalStorageHelper({
        groupId: this.filters.groupId,
        localStorageKey,
        propertyKey: this.filters.columnName,
        filters: this.copiedFilters.state,
      });
      this.fetchedState = this.lsHelper.getLocalFilter();
    }
  },

  methods: {
    /**
     * Close the opened dropdown
     */
    dropdownClose() {
      this.dropdownOpened = false;
      window.removeEventListener('click', this.dropdownClose);
    },

    /**
     * Open a dropdown
     */
    dropdownOpen() {
      this.dropdownOpened = true;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },

    /**
     * Call dropdownOpen or dropdownClose
     */
    dropdownToggle() {
      if (!this.dropdownOpened) {
        this.dropdownOpen();
      } else {
        this.dropdownClose();
      }
    },

    toggleAll() {
      this.$emit('toggle-all', [!this.allChecked]);
    },
  },
};
</script>

<style lang="scss">
.datagrid-column-filter {
  display: inline-block;
  padding: 0 3px;
  text-align: left;
  cursor: pointer;

  &__active {
    padding: 2px;
    border: 1px solid $primary-light;
    border-radius: 4px;
    background-color: $transparent-primary;
    color: $primary-light;
  }

  &.dropdown {
    position: initial;
  }

  li {
    white-space: nowrap;
  }

  .dropdown__menu {
    left: 5px;
    max-width: 300px;
    margin-top: 5px;
  }

  .filter {
    padding: 10px 5px 5px;

    &__actions {
      margin: 5px 0;
      padding: 5px;
      border-top: 1px solid $border;
      border-bottom: 1px solid $border;
    }

    &__list {
      overflow: auto;
      max-height: 300px;
      padding: 0 5px;

      > li {
        overflow: hidden;
        padding-bottom: 3px;
        text-overflow: ellipsis;
      }
    }
  }

  .filter-search {
    position: relative;
    display: flex;
    align-items: center;

    &__icon {
      position: absolute;
      padding-left: 7px;
    }

    &__input {
      width: 100%;
      min-width: 15em;
      height: 25px;
      padding: 4px 10px 4px 25px;
      border: 1px solid $border;
      border-radius: 0.3em;
      background-color: $background;
      color: inherit;
      font: inherit;
    }
  }

  .secondary {
    color: $text-neutral;
  }

  .v-input {
    font-size: 12px;
  }
}
</style>
