<template>
  <div class="history-map">
    <div class="c-map__layers">
      <MapLayersDropdown
        v-if="showMapLayersDropdown"
        v-model="layers"
        :display-options="optionsToShow"
        on-history-map
        class="history-map__dropdown"
        @openModal="modalShown = true"
      />
      <VehicleTrackSwitch
        v-if="showTrackDisplaySwitch"
        v-model:displayTrack="displayWholeVehicleTrack"
        class="history-map__track-switch"
      />
    </div>
    <MapboxMap
      v-if="gtfsId"
      :bounds="
        (showTrackDisplaySwitch && displayWholeVehicleTrack) || !showTrackDisplaySwitch ? bounds : null
      "
      :center="center"
      :gtfs-id="gtfsId"
      :stops="mapStops"
      :trips="mapTrips"
      :stops-options="stopsLayerOptions"
      border-radius="8px"
      @click:stop="onMapStopClick"
      @load="onMapLoad"
      @mouseenter:stop="$emit('mouseenter:stop', $event)"
      @mouseleave:stop="$emit('mouseleave:stop', $event)"
      @mouseenter:trip="$emit('mouseenter:trip', $event)"
      @mouseleave:trip="$emit('mouseleave:trip', $event)"
      @update:bounds="$emit('update:bounds', $event)"
    >
      <MapboxDevices
        v-if="isHistoryLayerLoaded"
        :devices="mapDevice ? [mapDevice] : []"
        :gtfs-id="gtfsId"
        :ts="ts"
        :map="map"
      />

      <MapboxHistoryLayer
        v-if="map"
        :device-trail-source="deviceTrailSource"
        :map="map"
        @isLoaded="isHistoryLayerLoaded = true"
      />
    </MapboxMap>
  </div>
</template>

<script>
import MapboxTraffic from '@mapbox/mapbox-gl-traffic';

import { LngLatBounds } from 'mapbox-gl';
import { MapboxHelper } from '@/components/map/mapboxHelper';

import MapboxHistoryLayer from '@/components/map/MapboxHistoryLayer.vue';
import MapboxMap from '@/components/map/MapboxMap.vue';
import VehicleTrackSwitch from '@/pages/TripDetailedPage/VehicleTrackSwitch.vue';

import MapLayersDropdown from './MapLayersDropdown.vue';
import MapboxDevices from './MapboxDevices.vue';

const mapboxTrafficControl = new MapboxTraffic({ showTrafficButton: false });

export default {
  name: 'HistoryMap',

  components: {
    MapboxDevices,
    MapboxMap,
    MapboxHistoryLayer,
    MapLayersDropdown,
    VehicleTrackSwitch,
  },
  props: {
    bounds: {
      type: LngLatBounds,
      default: null,
    },

    /** @type {import('vue').Prop<?[number, number]>} */
    // @ts-ignore TS2322: compatibility between `Array` and `[number, number]`
    center: {
      type: Array,
      default: null,
    },

    /** @type {import('vue').Prop<?HistoryMapDevice>} */
    device: {
      type: Object,
      default: null,
    },

    showMapLayersDropdown: {
      type: Boolean,
      default: false,
    },

    showTrackDisplaySwitch: {
      type: Boolean,
      default: false,
    },

    /** @type {import('vue').Prop<Array<import('./MapboxMap.vue').MapStop>>} */
    stops: {
      type: Array,
      required: true,
    },

    /** @type {import('vue').Prop<import('./MapboxMap.vue').MapTrip>} */
    trip: {
      type: Object,
      default: null,
    },

    ts: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'displayWholeVehicleTrack',
    'mouseenter:stop',
    'mouseleave:stop',
    'mouseenter:trip',
    'mouseleave:trip',
    'update:bounds',
    'click:stop',
    'load',
  ],

  data: () => ({
    /** @type {import('@/components/map/MapLayersDropdown.vue').LayerOptions} */
    layers: {
      vehicles: true,
      vehiclesLabel: false,
      stops: true,
      stations: true,
      traffic: false,
      linesShapes: true,
      stopsZones: true,
    },
    /** @type {import('@/components/map/MapLayersDropdown.vue').DisplayOptions} */
    optionsToShow: {
      vehicles: false,
      stops: true,
      stations: true,
      traffic: true,
      linesShapes: true,
      stopsZones: true,
    },
    /** @type {mapboxgl.Map} */
    map: null,
    isHistoryLayerLoaded: false,
    displayWholeVehicleTrack: false,
    mapLoaded: false,
  }),

  computed: {
    /** @return {?number} */
    deviceEventIndex() {
      if (!this.device) return null;

      const index = this.device.events.findIndex(e => e.latlng && e.ts >= this.ts);

      return index !== -1 ? index : null;
    },

    /** @return {Readonly<GeoJSON.Feature<GeoJSON.LineString>>} */
    deviceTrailSource() {
      const coordinates = [];
      const secondsToShow = this.displayWholeVehicleTrack ? Infinity : 180;

      if (this.device && this.device.events && this.deviceEventIndex) {
        let index = this.deviceEventIndex;

        while (index >= 0 && this.device.events[index].ts > this.ts - secondsToShow) {
          const event = this.device.events[index];

          if (event.ts <= this.ts && event.latlng) {
            coordinates.push([event.latlng[1], event.latlng[0]]);
          }

          index -= 1;
        }
      }

      return {
        geometry: {
          type: 'LineString',
          coordinates,
        },
        type: 'Feature',
        properties: {},
      };
    },

    /** @return {?string} */
    gtfsId() {
      return this.$store.getters['gtfs/getGtfsAt'](this.ts);
    },

    /** @return {?import('./MapboxDevices.vue').MapDevice} */
    mapDevice() {
      if (this.device && this.device.events && this.deviceEventIndex) {
        return {
          device: this.device.events[this.deviceEventIndex],
          id: this.device.id,
          highlight: false,
        };
      }
      return null;
    },

    /** @return {<Array<import('./MapboxMap.vue').MapStop>} */
    mapStops() {
      return this.stops;
    },

    /** @return {Array<import('./MapboxMap.vue').MapTrip>} */
    mapTrips() {
      if (this.layers.linesShapes && this.trip) {
        return [this.trip];
      }
      return [];
    },
    /** @return {import('@/components/map/MapboxMap.vue').StopsOptions} */
    stopsLayerOptions() {
      return {
        stationsMarkers: this.layers.stations,
        stopsMarkers: this.layers.stops,
        stopsZones: this.layers.stopsZones,
      };
    },
  },

  watch: {
    displayWholeVehicleTrack() {
      if (this.displayWholeVehicleTrack) {
        this.$emit('displayWholeVehicleTrack', this.displayWholeVehicleTrack);
      }
    },
    layers: {
      deep: true,
      handler() {
        this.layersLSSave();
      },
    },
    'layers.traffic': function layersShowTraffic(show) {
      if (!this.mapLoaded) return;
      if (show !== mapboxTrafficControl.options.showTraffic) {
        mapboxTrafficControl.toggleTraffic();
      }
    },
  },

  created() {
    this.layersLSLoad();
  },

  methods: {
    /** @param {mapboxgl.MapLayerMouseEvent} event */
    onMapStopClick(event) {
      if (event?.features?.length > 0) {
        this.$emit('click:stop', event.features[0].properties.id);
      }
    },
    /** @param {{map: mapboxgl.Map}} event */
    onMapLoad({ map }) {
      map.addControl(mapboxTrafficControl);

      map.once('idle', () => {
        this.map = map;
        this.mapLoaded = true;

        this.$emit('load', { map });

        if (this.layers.traffic !== mapboxTrafficControl.options.showTraffic) {
          mapboxTrafficControl.toggleTraffic();
        } else {
          mapboxTrafficControl.render();
        }
      });
    },
    /**
     * Save layers to localStorage.
     */
    layersLSSave() {
      MapboxHelper.saveLayerOptionstoLS('settings.op.tripDetailed.map.layerOptions', this.layers);
    },
    /*
     * Load layers from localStorage.
     */
    layersLSLoad() {
      const savedOptions = MapboxHelper.getLayersOptionsFromLS('settings.op.tripDetailed.map.layerOptions');
      if (savedOptions) this.layers = savedOptions;
    },
  },
};

/**
 * @typedef {Object} HistoryMapDevice
 * @property {Array<import('@/store/devices').Event>} events
 * @property {string} id
 */
</script>

<style lang="scss" scoped>
.history-map {
  &__dropdown {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: $map-dropdown;
  }

  &__track-switch {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: $map-dropdown;
  }
}
</style>
