<template>
  <div class="stop-list">
    <div class="stop-list__header">
      <div class="stop-list__header-side">
        <Btn
          type="primary"
          :route="{
            name: GroupRoute.REPORTING_PUNCTUALITY,
            path: GroupRoute.REPORTING_PUNCTUALITY,
            query: { startDate: defaultStartDateGtfs, endDate: defaultEndDateGtfs },
          }"
        >
          {{ $t('punctualityReport') }}
        </Btn>

        <!-- Dropdown transport plans -->
        <div class="stop-list__plan-selector" :class="{ 'dropdown--open': dropdownOpened }">
          <Btn type="secondary" @click="dropdownToggle" @mousedown.prevent>
            <span v-if="selectedGtfs">{{ selectedGtfs.name }}</span>
            <font-awesome-icon :icon="dropdownOpened ? 'fa-angle-up' : 'fa-angle-down'" />
          </Btn>

          <ul class="dropdown__menu">
            <li
              v-for="gtfs in lastTenPublishedGtfs"
              :key="gtfs.id"
              class="dropdown__item"
              :class="{ 'dropdown--selected': selectedGtfs === gtfs }"
              @click="changeTransportPlan(gtfs)"
            >
              {{ gtfs.name }}
            </li>
          </ul>
        </div>
      </div>

      <!--  Download all QR codes -->
      <div class="stop-list__header-side">
        <form ref="form" :action="API_URL_QR" method="POST">
          <span v-for="(data, i) in qrData" :key="`selected_${i}`">
            <input name="content" type="hidden" :value="data.content" />

            <input name="filename" type="hidden" :value="data.filename" />

            <input name="label" type="hidden" :value="data.label" />
          </span>

          <Btn type="secondary" :disabled="loading">
            {{ $t('downloadQRCodes') }}
          </Btn>
        </form>
      </div>
    </div>

    <DataGridVuetify
      v-model:renderedDataLength="renderedDataLength"
      :title="$t('stops', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="stopListFormatted"
      :datagrid="datagrid"
      :loading="loading"
    >
      <template #actions="propsAction">
        <div class="action-cell">
          <Btn type="icon-only" :link-url="downloadLink(propsAction.object)" :title="$t('downloadQRCode')">
            <font-awesome-icon icon="fa-qrcode" />
          </Btn>
        </div>
      </template>
    </DataGridVuetify>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import api from '@/api';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { objToParams } from '@/libs/helpers/url';
import { GroupRoute } from '@/libs/routing';
import { ColumnKey, getDatagrid } from '@/pages/StopListPage/StopList.conf.js';
import { Permission } from '@/store';
import { LocationType } from '@/store/gtfs';
import { API_URL_QR, getScreenQRData } from '@/libs/qrCode';

export default {
  name: 'StopList',

  components: {
    Btn,
    DataGridVuetify,
  },

  data() {
    return {
      API_URL_QR,
      ColumnKey,
      GroupRoute,
      Permission,

      /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),
      /** @type {boolean} */
      dropdownOpened: false,
      /** @type {Array<import('@/api').GtfsSchedule>} */
      lastTenPublishedGtfs: null,
      /** @type {Boolean} */
      loading: true,
      /** @type {number} */
      renderedDataLength: null,
      /** @type {Array<import('@/api').GtfsSchedule>} */
      selectedGtfs: null,
      /** @type {Array<StopListItem} */
      stopListFormatted: [],
    };
  },

  computed: {
    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {{[key in ColumnTypes]: (data: {apiData: StopListItem) => Object}} */
    buildCellInjectors() {
      return {
        [ColumnKey.STOP_NAME]: () => ({ groupId: this.group._id }),
        [ColumnKey.PARENT_STATION]: () => ({ groupId: this.group._id }),
      };
    },

    /** @return {string} */
    defaultStartDateGtfs() {
      return dateObjToGtfsFormat(dayjs(new Date()).subtract(8, 'day').toDate());
    },

    /** @return {string} */
    defaultEndDateGtfs() {
      return dateObjToGtfsFormat(new Date());
    },

    /** @return {Array<import('@/libs/qrCode').QRData>} */
    qrData() {
      return this.stopListFormatted.map(stop => getScreenQRData(this.group._id, stop._id, stop.stop_name));
    },
  },

  watch: {
    selectedGtfs() {
      if (this.selectedGtfs) {
        this.createStopList(this.selectedGtfs.current_file);
      }
    },
  },

  created() {
    this.onCreate();
  },

  methods: {
    /** @param {import('@/api').GtfsSchedule} gtfs */
    changeTransportPlan(gtfs) {
      this.selectedGtfs = gtfs;
      this.dropdownOpened = false;
    },

    async createStopList(gtfsId) {
      this.loading = true;
      let stopList = [];
      let routeList = [];
      let tripList = [];

      await Promise.all([
        this.$store.dispatch('gtfs/getStopsMap', { gtfsId }).then(stopListStore => {
          stopList = Object.values(stopListStore);
        }),

        this.$store.dispatch('gtfs/getTripsMap', { gtfsId }).then(tripListStore => {
          tripList = Object.values(tripListStore);
        }),

        this.$store.dispatch('gtfs/getRoutesMap', { gtfsId }).then(routeListStore => {
          routeList = routeListStore;
        }),
      ]);

      // Get the corresponding routes for each stop
      // { stopId: [ routeId ] }
      const routesByStopId = tripList.reduce((acc, trip) => {
        trip.stop_times.forEach(stopTime => {
          if (stopTime.stop_id && !acc[stopTime.stop_id]) {
            acc[stopTime.stop_id] = [trip.route_id];
          } else if (stopTime.stop_id && !acc[stopTime.stop_id].includes(trip.route_id)) {
            acc[stopTime.stop_id].push(trip.route_id);
          }
        });
        return acc;
      }, {});

      // Create list of formatted stops
      const stopListFormattedWithStations = stopList.map(stop => {
        if (stop.location_type !== parseInt(LocationType.STATION)) {
          // We need ._id for the TableSearchBar component
          stop._id = stop.stop_id;

          // Format the parent station for the stop
          if (stop.parent_station) {
            stop.parentStationId = stop.parent_station;
            const parentStationStop = stopList.find(
              notFormattedStop => notFormattedStop.stop_id === stop.parent_station
            );
            stop.parentStationName = parentStationStop ? parentStationStop.stop_name : null;
          }

          stop.stopName = stop.stop_name || stop.stop_id;

          // Get the routes for the stop
          stop.routes = routesByStopId[stop.stop_id]
            ? routesByStopId[stop.stop_id].reduce((routesAcc, route) => {
                if (routeList[route]) {
                  routesAcc.push(routeList[route]);
                }
                return routesAcc;
              }, [])
            : [];

          stop.routesFormatted = stop.routes.map(route => route.route_short_name || route.route_id);

          // Get the zone radius for the stop
          stop.stopRadius =
            this.group.stop_distance_threshold_exceptions &&
            this.group.stop_distance_threshold_exceptions[stop.stop_id]
              ? this.group.stop_distance_threshold_exceptions[stop.stop_id]
              : this.group.stop_distance_threshold;
        }
        return stop;
      });

      // Filter to remove stations
      this.stopListFormatted = stopListFormattedWithStations.filter(
        stop => stop.location_type !== parseInt(LocationType.STATION)
      );

      this.loading = false;
    },

    /**
     * @param {StopListItem}
     * @return {string} */
    downloadLink(stop) {
      return `${API_URL_QR}?${objToParams(getScreenQRData(this.group._id, stop.stop_id, stop.stopName))}`;
    },

    /**
     * Close the open dropdown
     */
    dropdownClose() {
      this.dropdownOpened = false;
      window.removeEventListener('click', this.dropdownClose);
    },

    /**
     * Open the dropdown
     */
    dropdownOpen() {
      this.dropdownOpened = true;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },

    /**
     * Call dropdownOpen or dropdownClose
     */
    dropdownToggle() {
      if (!this.dropdownOpened) {
        this.dropdownOpen();
      } else {
        this.dropdownClose();
      }
    },

    async onCreate() {
      // Get the last 10 unique published transport plans
      const publications = await api.gtfs.getGtfsPublications(this.$store.getters.group.group_id);
      const gtfsList = await api.gtfs.getGtfs(this.$store.getters.group.group_id);
      let uniquePublications = publications.reduce((acc, pub) => {
        if (!acc.find(item => item.current_file === pub.current_file)) {
          acc.push(pub);
        }
        return acc;
      }, []);
      // Order publications by timestamp
      uniquePublications = uniquePublications.sort((a, b) => (a.ts > b.ts ? -1 : 1));
      this.lastTenPublishedGtfs = uniquePublications.slice(0, 10);
      this.lastTenPublishedGtfs.forEach(gtfs => {
        gtfs.name = gtfsList[gtfsList.findIndex(gtfsFile => gtfs.current_file === gtfsFile._id)].name;
      });

      // Set current transport plan as default
      this.selectedGtfs = this.lastTenPublishedGtfs[0];
    },
  },
};

/**
 * @typedef {Object} StopListItem
 * @property {string} _id
 * @property {Array<import('@/store/gtfs').Route>}
 * @property {string} stop_code
 * @property {string} stop_name
 * @property {string} stopName
 * @property {number} stopRadius
 * @property {Array} routes
 * @property {Array<string>} routesFormatted
 * @property {string} [parentStationId]
 * @property {string} [parentStationName]
 */
</script>

<style lang="scss" scoped>
.stop-list {
  padding: $view-standard-padding;

  &__header {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__header-side {
    display: flex;
    gap: 10px;
  }

  .action-cell {
    display: flex;
    justify-content: end;
    margin-right: 20px;
    text-align: end;
  }

  .dropdown {
    &__item {
      cursor: pointer;

      &:hover {
        color: $primary-light;
      }
    }

    &--selected {
      color: $primary-light;
      font-weight: $font-weight-semi-bold;
    }
  }
}
</style>

<i18n locale="fr">
{
  "downloadQRCode": "Télécharger le QR code",
  "downloadQRCodes": "Télécharger tous les QR codes",
  "punctualityReport": "Rapport de ponctualité",
}
</i18n>

<i18n locale="en">
{
  "downloadQRCode": "Download the QR code",
  "downloadQRCodes": "Download all QR Codes",
  "punctualityReport": "Go to Punctuality report",
}
</i18n>
