<template>
  <span
    class="route-badge"
    :style="{
      'background-color': `#${route.route_color || 'FFFFFF'}`,
      color: `#${route.route_text_color || '000000'}`,
    }"
    :title="routeLabel"
  >
    {{ routeLabel }}
  </span>
</template>

<script>
export default {
  name: 'RouteBadge',

  props: {
    /** @type {Vue.PropOptions<import('@/store/gtfs').Route>} */
    route: {
      type: Object,
      required: true,
    },
  },

  computed: {
    /** @return {string} */
    routeLabel() {
      return this.route.route_short_name || this.route.route_long_name || this.route.route_id;
    },
  },
};
</script>

<style lang="scss">
.route-badge {
  flex-shrink: 0;
  overflow: hidden;
  max-width: 5em;
  padding: 0.2em 0.5em;
  border-radius: 0.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
