<template>
  <div class="cell-device">
    <ul v-if="devices?.length > 1">
      <li v-for="(value, index) in devices" :key="index">
        {{ value != null ? value.name || value.id : '-' }}
      </li>
    </ul>

    <router-link
      v-else-if="devices?.length === 1"
      :to="{
        name: GroupRoute.DEVICE_DETAILLED,
        params: { groupId, deviceId: devices[0].id },
        query: { date: getISODate(date) },
      }"
    >
      {{ devices[0].name || devices[0].id }}
    </router-link>
  </div>
</template>

<script>
import { getISODate } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'CellDevice',

  props: {
    date: {
      type: Date,
      required: true,
    },

    /** @type {Vue.PropOptions<Array<import('@/api').TripListDevice>>} */
    devices: {
      type: Array,
      default: null,
    },

    groupId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      GroupRoute,
    };
  },

  methods: {
    getISODate(date) {
      return getISODate(date);
    },
  },
};
</script>

<style lang="scss">
.cell-device {
  line-height: 1.5em;

  li {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
