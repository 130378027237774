<template>
  <component :is="dataCell.component" v-bind="dataCell.props" v-on="dataCell.events">
    {{ dataCell.value }}
  </component>
</template>

<script>
import { DataGridDataCell } from '@/components/Table/DataGrid/models/DataGrid.data.models';

export default {
  name: 'DataGridCell',

  props: {
    dataCell: {
      required: true,
      type: DataGridDataCell,
    },
  },
};
</script>
