// Port of @pysae-driver/core request function with error handling.

export class InvalidResponseError extends Error {
  /**
   * @param {string} message
   * @param {Object} data
   * @param {Response} response
   */
  constructor(message, data, response) {
    super(message);
    this.data = data;
    this.response = response;
  }
}

export class PysaeApiErrorContent {
  /**
   * @param {string} code
   * @param {string} details
   */
  constructor(code, details) {
    this.code = code;
    this.details = details;
  }
}

export class PysaeApiError extends InvalidResponseError {}

/**
 * @param {RequestInfo|URL} input
 * @param {RequestInit|undefined} init
 * @return {Promise<Response>}
 */
export async function request(input, init = undefined) {
  const response = await fetch(input, init);

  if (!response.ok) {
    let data;
    try {
      data = await response.json();
    } catch {
      // Do nothing
    }

    let method = 'GET';
    let url = '';
    if (input instanceof Request) {
      method = input.method;
      url = input.url;
    } else if (input instanceof URL) {
      url = input.toString();
    } else {
      url = input;
    }

    const pysaeContentCandidate = data;
    if (pysaeContentCandidate && pysaeContentCandidate.code && pysaeContentCandidate.details) {
      throw new PysaeApiError(
        `Error status for ${method} ${url} (${response.status})`,
        new PysaeApiErrorContent(pysaeContentCandidate.code, pysaeContentCandidate.details),
        response
      );
    }

    throw new InvalidResponseError(`Error status for ${method} ${url} (${response.status})`, data, response);
  }

  return response;
}
