<template>
  <Modal class="modal-trip-detailed-config" :width="1200" @close="$emit('close')">
    <template #title>
      <span>{{ trip.formatted_name }}</span>
    </template>

    <template #body>
      <div class="modal-trip-detailed-config__info">
        {{ $tc('tripDetailParam.criterias', { count: paramsLeftToSelect }) }}
      </div>
      <div class="modal-trip-detailed-config__main">
        <div v-for="(column, index) in dividedParams" :key="index" class="modal-trip-detailed-config__column">
          <div v-for="(param, index2) in column" :key="index2" class="modal-trip-detailed-config__line">
            <v-checkbox
              v-model="params[param]"
              class="modal-trip-detailed-config__checkbox"
              :disabled="disableCheckbox(param)"
              color="success"
              hide-details
            >
              <template #label>
                <span class="modal-trip-detailed-config__label">
                  {{ $t(`tripDetailParam.${param}`) }}
                </span>
              </template>
            </v-checkbox>
            <TripInfoItem
              :info-type="param"
              :trip-id="trip.id"
              :info="getInfo(param, trip, date, groupId, groupTz)"
            />
          </div>
        </div>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" @click="confirm">
        {{ $t('save') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

import { DefaultSelectedParams, getInfo, TripDetailParams } from './TripInfoShared.js';
import TripInfoItem from './TripInfoItem.vue';

export default {
  name: 'ModalTripDetailConfig',

  components: {
    Btn,
    Modal,
    TripInfoItem,
  },

  props: {
    date: {
      type: Date,
      required: true,
    },

    groupId: {
      type: String,
      required: true,
    },

    trip: {
      type: Object,
      required: true,
    },
  },

  emits: ['close'],

  data: () => ({
    DefaultSelectedParams,
    getInfo,
    TripDetailParams,

    params: [],
  }),

  computed: {
    /** @return {string} */
    groupTz() {
      return this.$store.getters.group.tz;
    },

    /** @type {Array<TripDetailParams>} */
    localStorageParams() {
      return JSON.parse(localStorage.getItem('tripDetailedPage/config'));
    },

    /** @type {Array<TripDetailParams>} */
    dividedParams() {
      const columnSize = Math.floor(Object.keys(this.params).length / 3) + 1;
      const dividedParams = {};
      for (let i = 0; i < Object.keys(this.params).length; i += columnSize) {
        const column = Object.keys(this.params).slice(i, i + columnSize);
        dividedParams[i] = column;
      }
      return dividedParams;
    },

    /** @returns {number} */
    paramsLeftToSelect() {
      return 12 - this.selectedParamsCount >= 0 ? 12 - this.selectedParamsCount : 0;
    },

    /** @returns {number} */
    selectedParamsCount() {
      return Object.values(this.params).filter(value => value === true).length;
    },
  },

  created() {
    if (this.localStorageParams) {
      this.params = Object.keys(TripDetailParams).reduce((acc, elem) => {
        acc[TripDetailParams[elem]] = this.localStorageParams.includes(TripDetailParams[elem]);
        return acc;
      }, {});
    } else {
      this.params = Object.keys(TripDetailParams).reduce((acc, elem) => {
        acc[TripDetailParams[elem]] = !!Object.prototype.hasOwnProperty.call(DefaultSelectedParams, elem);
        return acc;
      }, {});
    }
  },

  methods: {
    confirm() {
      /** @type {Array<TripDetailParams>} */
      const paramsArray = Object.keys(this.params).reduce((acc, elem) => {
        if (this.params[elem] === true) {
          acc.push(elem);
        }
        return acc;
      }, []);
      localStorage.setItem('tripDetailedPage/config', JSON.stringify(paramsArray));
      this.$emit('close');
    },

    disableCheckbox(param) {
      return this.paramsLeftToSelect === 0 && this.params[param] === false;
    },
  },
};
</script>

<style lang="scss">
.modal-trip-detailed-config {
  &__checkbox {
    position: relative;
    display: flex;
  }

  &__column {
    overflow: hidden;
    width: 33%;
    text-overflow: ellipsis;
  }

  &__info {
    margin: 0 0 10px 5px;
    color: $text-neutral;
    font-style: italic;
    font-size: 12px;
  }

  &__label {
    display: inline-block;
    overflow: hidden;
    width: 120px;
    color: $text-neutral;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  &__line {
    display: flex;
    align-items: center;
    min-height: 25px;
    margin: 8px 0;

    .trip-info-item .droplist {
      &__line {
        min-width: 140px;
      }

      &__first-item {
        max-width: 120px;
      }
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
  }

  .trip-info-item:not(.item-team) {
    overflow: hidden;
    width: 180px;
  }
}
</style>
