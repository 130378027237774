<template>
  <router-link
    v-if="route"
    class="ui-btn ui-btn--link"
    :class="[type, { 'link-disabled': $attrs.disabled, smaller, small, outlined }]"
    :title="title"
    :to="$attrs.disabled ? {} : route"
  >
    <slot />
  </router-link>

  <a
    v-else-if="linkUrl"
    class="ui-btn ui-btn--link"
    :class="[type, { 'link-disabled': $attrs.disabled, smaller, small, outlined }]"
    :href="linkUrl"
    target="_blank"
    :title="title"
  >
    <slot />
  </a>

  <button
    v-else
    class="ui-btn"
    :class="[type, { smaller, small, outlined }, { 'ui-btn--progress': dataLoading }]"
    :type="submit ? 'submit' : ''"
    :title="title"
    @click="callback($event)"
  >
    <slot />
    <font-awesome-icon v-if="redirectIcon" icon="fa-arrow-right" class="ui-btn__redirect-icon" />
  </button>
</template>

<script>
export default {
  name: 'Btn',

  props: {
    dataLoading: {
      type: Boolean,
      default: false,
    },
    linkUrl: {
      type: String,
      default: null,
    },
    /** @type {Vue.PropOptions<import('vue-router').Route>} */
    route: {
      type: Object,
      default: null,
    },
    small: {
      default: false,
      type: Boolean,
    },
    smaller: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      default: null,
    },
    submit: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
      validator: val =>
        [
          'primary',
          'secondary',
          'secondary-active',
          'tertiary',
          'danger',
          'icon-only',
          'link-style',
          'dark',
          'redirect',
        ].includes(val),
    },
    redirectIcon: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  methods: {
    callback(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">
.ui-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-family: $font-poppins;
  cursor: pointer;

  &__redirect-icon {
    margin-left: 8px;
  }

  &--link {
    display: inline-block;
    width: max-content;
    padding: 10.5px 15px;
    text-align: center;
  }

  &--progress {
    border: solid $primary-light 2px !important;
    background-color: $white !important;
    color: $primary-light !important;
    cursor: not-allowed;
    transition: background-color ease-in-out 0.25s;

    &:hover {
      background-color: $white !important;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.dark {
    background-color: $secondary;
    color: $text-light;

    &:hover {
      background-color: darken($secondary, 2%);
    }
  }

  &.icon-only {
    width: 22px;
    height: 22px;
    padding: 0;
    border: solid 1px $border-variant;
    border-radius: 5px;
    background-color: $background;
    color: $text-dark-variant;

    svg {
      margin-right: 0;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &:hover:not(:disabled, .link-disabled) {
    background-color: $border;
  }

  &:not(.icon-only) {
    svg {
      font-size: 14px;
      line-height: 20px;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &:disabled,
  &.link-disabled {
    opacity: 0.65;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.link-style {
    background-color: transparent;
    text-align: start;

    &:hover {
      text-decoration: underline;
    }
  }

  &.outlined {
    border: 1px solid $border-variant;
    background: transparent;
  }

  &.primary {
    border: 1px solid $primary-light;
    background: $primary-light;
    color: $text-light;

    &:hover:not(:disabled, .link-disabled) {
      border-color: darken($primary-light, 5%);
      background-color: darken($primary-light, 5%);
    }
  }

  &.secondary {
    border: 1px solid $border-variant;
    background-color: $background;
    color: $text-dark-variant;

    &.active {
      background-color: $secondary;
      color: $text-light;
    }

    &:hover:not(:disabled, .link-disabled, .active) {
      background-color: $border;
    }
  }

  &.secondary-active {
    border: 1px solid $primary-light;
    background-color: $transparent-primary;
    color: $primary-light;

    &:hover:not(:disabled, .link-disabled, .active) {
      background-color: $transparent-primary-dark;
    }
  }

  &.tertiary {
    background-color: transparent;
    color: $text-dark-variant;

    &:hover:not(:disabled, .link-disabled) {
      background-color: $background;
    }
  }

  &.danger {
    background-color: $danger;
    color: $text-light;

    &:hover:not(:disabled, .link-disabled) {
      background-color: darken($danger, 20%);
    }
  }

  &.redirect {
    background-color: transparent;
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;

    &:hover:not(:disabled, .link-disabled) {
      background-color: transparent;
      color: $text-dark-variant;
    }
  }

  &.smaller {
    padding: 2px 5px;
  }

  &.small {
    padding: 6px 12px;
  }
}
</style>
