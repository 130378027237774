<template>
  <div>
    <div class="form-group">
      <label class="form-group__label" for="name">{{ $t('groupName') }}</label>
      <input id="name" v-model="values.name" class="form-group__input" name="name" required type="text" />
    </div>

    <div class="form-group">
      <label for="driver_trip_format">
        <span class="form-group__label">{{ $t('driver_trip_format') }}</span>
        <p class="form-group__sub-label">{{ $t('driver_trip_format-help') }}</p>
      </label>
      <input
        id="driver_trip_format"
        v-model="values.driver_trip_format"
        class="form-group__input"
        name="driver_trip_format"
        :placeholder="defaults.driver_trip_format"
        type="text"
      />
    </div>

    <div class="form-group">
      <span class="form-group__label" for="driver_ontime_interval">{{ $t('driver_ontime_interval') }}</span>

      <div class="input-group">
        <div class="input-group-prepend">
          <label class="input-group-text" for="driver_ontime_interval-start">{{ $t('start') }}</label>
        </div>

        <input
          id="driver_ontime_interval-start"
          v-model.number="values.driver_ontime_interval[0]"
          class="form-group__input form-group__input--inline--grouped"
          :class="{
            'form-group__input--invalid': missingFirstDriverOntimeInterval,
          }"
          name="driver_ontime_interval-start"
          type="number"
          :placeholder="defaults.driver_ontime_interval[0]"
        />

        <div class="input-group-prepend">
          <label class="input-group-text" for="driver_ontime_interval-end">{{ $t('end') }}</label>
        </div>

        <input
          id="driver_ontime_interval-end"
          v-model.number="values.driver_ontime_interval[1]"
          class="form-group__input form-group__input--inline--grouped"
          :class="{
            'form-group__input--invalid': missingSecondDriverOntimeInterval,
          }"
          name="driver_ontime_interval-end"
          type="number"
          :placeholder="defaults.driver_ontime_interval[1]"
        />
      </div>
    </div>

    <div class="form-group">
      <label for="delay_device_online">
        <span class="form-group__label">{{ $t('delay_device_online') }}</span>
        <p class="form-group__sub-label">
          {{ $t('delay_device_online-help') }}
        </p>
      </label>
      <input
        id="delay_device_online"
        v-model.number="values.delay_device_online"
        class="form-group__input"
        name="delay_device_online"
        type="number"
        :placeholder="defaults.delay_device_online"
      />
    </div>

    <div class="form-group">
      <label for="delay_device_offline_visible">
        <span class="form-group__label">{{ $t('delay_device_offline_visible') }}</span>
        <p class="form-group__sub-label">
          {{ $t('delay_device_offline_visible-help') }}
        </p>
      </label>
      <input
        id="delay_device_offline_visible"
        v-model.number="values.delay_device_offline_visible"
        class="form-group__input"
        name="delay_device_offline_visible"
        type="number"
        :placeholder="defaults.delay_device_offline_visible"
      />
    </div>

    <div class="form-group">
      <label for="incoming_distance_threshold">
        <span class="form-group__label">{{ $t('incoming_distance_threshold') }}</span>
        <p class="form-group__sub-label">
          {{ $t('incoming_distance_threshold-help') }}
        </p>
      </label>
      <input
        id="incoming_distance_threshold"
        v-model.number="values.incoming_distance_threshold"
        class="form-group__input"
        name="incoming_distance_threshold"
        type="number"
        :placeholder="defaults.incoming_distance_threshold"
      />
    </div>

    <div class="form-group">
      <label for="shape_distance_threshold">
        <span class="form-group__label">{{ $t('shape_distance_threshold') }}</span>
        <p class="form-group__sub-label">
          {{ $t('shape_distance_threshold-help') }}
        </p>
      </label>
      <input
        id="shape_distance_threshold"
        v-model.number="values.shape_distance_threshold"
        class="form-group__input"
        name="shape_distance_threshold"
        type="number"
        :placeholder="defaults.shape_distance_threshold"
      />
    </div>

    <div class="form-group">
      <label for="display-group">
        <span class="form-group__label">{{ $t('notifications') }}</span>
        <p class="form-group__sub-label">
          <ToggleSwitch
            id="display-group"
            :checked="values.notifications"
            class="settings-info__toggle-switch"
            @change="checked => (values.notifications = checked)"
          />
          {{ $t('displayNotifications') }}
        </p>
      </label>
    </div>

    <Btn type="primary" :disabled="!hasChanged || hasError" @click="save">
      <font-awesome-icon icon="fa-floppy-disk" />
      <span>{{ $t('save') }}</span>
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';
import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';
import { groupDefaults } from '@/store';

import SettingsMixin from './SettingsMixin';

export default {
  name: 'GeneralSettingsPage',
  components: { Btn, ToggleSwitch },
  mixins: [SettingsMixin],

  data: () => ({
    defaults: groupDefaults,
  }),

  computed: {
    /** @return {boolean} */
    hasError() {
      return (
        this.values.name === '' ||
        this.missingFirstDriverOntimeInterval ||
        this.missingSecondDriverOntimeInterval
      );
    },

    /** @return {boolean} */
    missingFirstDriverOntimeInterval() {
      // HTMLInput will set empty string when value is removed.
      return (
        (this.values.driver_ontime_interval[0] == null || this.values.driver_ontime_interval[0] === '') &&
        this.values.driver_ontime_interval[1] != null &&
        this.values.driver_ontime_interval[1] !== ''
      );
    },

    /** @return {boolean} */
    missingSecondDriverOntimeInterval() {
      // HTMLInput will set empty string when value is removed.
      return (
        this.values.driver_ontime_interval[0] != null &&
        this.values.driver_ontime_interval[0] !== '' &&
        (this.values.driver_ontime_interval[1] == null || this.values.driver_ontime_interval[1] === '')
      );
    },
  },

  methods: {
    /**
     * Returns default values
     *
     * @returns {Object}
     */
    getDefaultValues() {
      return {
        name: '',
        /** @type {number} */
        delay_device_offline_visible: null,
        /** @type {number} */
        delay_device_online: null,
        /** @type {Array<number>} */
        driver_ontime_interval: [],
        driver_trip_format: '',
        /** @type {number} */
        incoming_distance_threshold: null,
        /** @type {number} */
        shape_distance_threshold: null,
        notifications: true,
      };
    },
  },
};
</script>

<i18n locale="fr">
{
  "notifications": "Notifications",
  "displayNotifications": "Afficher les notifications de message reçu",
  "groupName": "Nom du réseau",
  "driver_trip_format": "Format des noms de courses",
  "driver_trip_format-help": "Définit le format des courses à afficher sur les appareils (%dt : Départ théorique - %th : Girouette - %tn : Nom de la course court - %rsn : Nom de la ligne court - %rln : Nom de la ligne long - %fs : Premier arrêt - %ls : Dernier arrêt - %bl : Identifiant de service)",
  "driver_ontime_interval": "Intervalle considéré à l'heure",
  "delay_device_online": "Seuil de connexion des véhicules (s)",
  "delay_device_online-help": "Delai maximum après avoir reçu des données pour considérer un véhicule comme connecté.",
  "delay_device_offline_visible": "Seuil d'affichage des véhicules déconnectés (s)",
  "delay_device_offline_visible-help": "Delai maximum après avoir reçu des données pour afficher un véhicule.",
  "incoming_distance_threshold": "Distance d'approche (m)",
  "incoming_distance_threshold-help": "Distance d'un véhicule en amont d'un arrêt à partir de laquelle il est considéré à l'approche.",
  "shape_distance_threshold": "Distance d'accrochage aux tracés (m)",
  "shape_distance_threshold-help": "Distance maximum entre un véhicule et un tracé pour que ce dernier soit pris en compte par l'algorithme de détection automatique de course.",
  "start": "Début (s)",
  "end": "Fin (s)"
}
</i18n>

<i18n locale="en">
{
  "notifications": "Notifications",
  "displayNotifications": "Display message notifications",
  "groupName": "Group name",
  "driver_trip_format": "Trip name format",
  "driver_trip_format-help": "Set formatting for trip name to show on devices (%dt: Theoretical departure - %th: Trip headsign - %tn: Trip short name - %rsn: Route short name - %rln: Route long name - %fs: First stop - %ls: Last stop - %bl: Block id)",
  "driver_ontime_interval": "Driver on time interval",
  "delay_device_online": "Online device threshold (s)",
  "delay_device_online-help": "Threshold after last message from a device to consider it offline.",
  "delay_device_offline_visible": "Visible offline device threshold (s)",
  "delay_device_offline_visible-help": "Threshold after last message from a device to show it.",
  "incoming_distance_threshold": "Incoming distance threshold (m)",
  "incoming_distance_threshold-help": "Upstream vehicle distance to consider it as incoming.",
  "shape_distance_threshold": "Shape distance threshold (m)",
  "shape_distance_threshold-help": "Maximum distance between vehicle and shape to consider it in automatic trip detection algorithm.",
  "start": "Start (s)",
  "end": "End (s)"
}
</i18n>
