<template>
  <Modal modal-class="modal-archive" @close="close">
    <template #title>
      {{
        gtfs.archived === ArchiveStatus.ARCHIVED
          ? $t('restoreGtfs', [gtfs.name])
          : $t('archiveGtfs', [gtfs.name])
      }}
    </template>

    <template #cta>
      <Btn type="primary" @click="changeStatus">
        <font-awesome-icon
          :icon="gtfs.archived === ArchiveStatus.ARCHIVED ? 'fa-boxes-packing' : 'fa-download'"
        />
        {{ gtfs.archived === ArchiveStatus.ARCHIVED ? $t('restore') : $t('archive') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import { useToast } from 'vue-toastification';

import api from '@/api';
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

import { ArchiveStatus } from './index.vue';

const toast = useToast();

export default {
  name: 'ModalArchiveRestore',

  components: {
    Btn,
    Modal,
  },

  props: {
    groupId: {
      required: true,
      type: String,
    },

    /** @type {Vue.PropOptions<import('./index.vue').GtfsFormatted>} */
    gtfs: {
      required: true,
      type: Object,
    },
  },

  emits: ['close', 'closeWithRefresh'],

  data() {
    return {
      ArchiveStatus,
    };
  },

  methods: {
    async archiveGtfs() {
      await api.gtfs.archiveGtfs(this.groupId, this.gtfs._id);
      const toastId = toast.success(this.$t('archivedToast'), {
        icon: 'fas fa-check',
        position: 'bottom-right',
      });
      this.$emit('closeWithRefresh');
      setTimeout(() => toast.dismiss(toastId), 3000);
    },

    changeStatus() {
      if (this.gtfs.archived === ArchiveStatus.ARCHIVED) {
        this.restoreGtfs();
      } else {
        this.archiveGtfs();
      }
    },

    close() {
      this.$emit('close');
    },

    async restoreGtfs() {
      await api.gtfs.restoreGtfs(this.groupId, this.gtfs._id);
      const toastId = toast.success(this.$t('restoredToast'), {
        icon: 'fas fa-check',
        position: 'bottom-right',
      });
      this.$emit('closeWithRefresh');
      setTimeout(() => toast.dismiss(toastId), 3000);
    },
  },
};
</script>

<style lang="scss">
.modal-archive {
  .ui-btn {
    svg {
      margin-right: 8px;
    }
  }
}
</style>

<i18n locale="fr">
{
 "archivedToast": "Plan de transport archivé",
 "archiveGtfs": "Archiver {0}",
 "restoreGtfs": "Restaurer {0}",
 "restoredToast": "Plan de transport restauré"
}
</i18n>

<i18n locale="en">
{
 "archivedToast": "Transport plan archived",
 "archiveGtfs": "Archive {0}",
 "restoreGtfs": "Restore {0}",
 "restoredToast": "Transport plan restored"
}
</i18n>
