<template>
  <Modal modal-class="modal-trip-incident" @close="$emit('close')">
    <template #title>
      <span>{{ formattedTripName }} - {{ $t('createIncident') }}</span>
    </template>

    <template #body>
      <div>
        <div class="form-group">
          <label class="form-group__label" for="incidentLabel">{{ $t('incidentLabel') }}</label>
          <Selector
            id="incident_label"
            v-model:value="incidentLabel"
            :options="incidentLabelValues"
            mode="single"
          />
        </div>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" :disabled="!incidentLabel" @click="$emit('submit', incidentLabel)">
        {{ $t('submit') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import Selector from '@/components/ui/Selector.vue';

export default {
  name: 'ModalTripIncident',

  components: {
    Btn,
    Modal,
    Selector,
  },

  props: {
    gtfsId: {
      type: String,
      required: true,
    },

    tripId: {
      type: String,
      required: true,
    },
  },

  emits: ['close', 'submit'],

  data: () => ({
    formattedTripName: '',
    incidentLabel: null,
  }),

  computed: {
    /** @return {?Array<string | {label: string, caption: string}>} */
    incidentLabelValues() {
      const oti = this.$store.getters.group.option_trip_incidents;
      if (oti && oti.incident_label_values) {
        const incidentOptions = oti.incident_label_values.map(incident => ({
          label: incident.caption || incident,
          value: incident.label || incident,
        }));
        return incidentOptions;
      }
      return undefined;
    },
  },

  watch: {
    tripId: {
      immediate: true,
      handler() {
        this.updateFormattedTripName();
      },
    },
  },

  methods: {
    async updateFormattedTripName() {
      this.formattedTripName = await this.$store.dispatch('gtfs/formatTripName', {
        gtfsId: this.gtfsId,
        tripId: this.tripId,
        date: new Date(),
      });
    },
  },
};
</script>

<style lang="scss">
.modal-trip-incident {
  .modal__body {
    overflow-y: initial;
  }
}
</style>

<i18n locale="fr">
{
  "createIncident": "Créer un incident",
  "incidentLabel": "Code incident"
}
</i18n>

<i18n locale="en">
{
  "createIncident": "Create an incident",
  "incidentLabel": "Incident code"
}
</i18n>

<i18n locale="cz">
{
  "createIncident": "Vytvořit incident",
  "incidentLabel": "Kód incidentu"
}
</i18n>

<i18n locale="de">
{
  "createIncident": "Vorfall erstellen",
  "incidentLabel": "Vorfall-Code"
}
</i18n>

<i18n locale="es">
{
  "createIncident": "Crear un incidente",
  "incidentLabel": "Código de incidente"
}
</i18n>

<i18n locale="it">
{
  "createIncident": "Crea un incidente",
  "incidentLabel": "Codice dell'incidente"
}
</i18n>

<i18n locale="pl">
{
  "createIncident": "Utwórz zdarzenie",
  "incidentLabel": "Kod incydentu"
}
</i18n>
