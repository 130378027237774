<template>
  <div class="datagrid__filter-eraser">
    <v-menu v-model="menu" offset="8" :close-on-content-click="false">
      <template #activator="{ props }">
        <Btn
          :disabled="!hasActiveFilters"
          v-bind="props"
          :type="hasActiveFilters ? 'secondary-active' : 'secondary'"
        >
          <font-awesome-icon icon="fa-filter" />
          <span>{{ buttonText }}</span>
        </Btn>
      </template>

      <v-card class="mx-auto rounded-lg" width="320">
        <v-toolbar flat>
          <v-btn icon @click="$emit('eraseFilters')">
            <v-icon>fa:fas fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('clearAll') }}</v-toolbar-title>
        </v-toolbar>
        <div class="datagrid__filter-eraser__chip-list">
          <template v-for="(filter, index) in filters" :key="index">
            <v-chip
              v-if="filter.isActive"
              label
              close-icon="fa:fas fa-times"
              closable
              color="#4f4f4f"
              @click:close="$emit('eraseFilters', filter)"
            >
              {{ $t(filter.columnName) }}
            </v-chip>
          </template>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'DataGridFilterEraser',

  components: {
    Btn,
  },

  props: {
    lsFiltersName: {
      type: String,
      required: true,
    },
    /** @type {Vue.PropOptions<import('@/components/Table/DataGrid/models/DataGrid.models').ColumnFilterState>} */
    filters: {
      required: true,
      type: Object,
    },
  },

  emits: ['eraseFilters'],

  data() {
    return { hasActiveFilters: false, activeFilterCounter: 0, menu: false };
  },
  computed: {
    buttonText() {
      let text = this.$t('filters');
      if (this.activeFilterCounter > 0) text += ` (${this.activeFilterCounter})`;
      return text;
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        if (Object.keys(this.filters).length > 0) {
          const activeFilters = Object.keys(this.filters).filter(key => this.filters[key].isActive);
          this.hasActiveFilters = activeFilters.length > 0;
          this.activeFilterCounter = activeFilters.length;
        }
      },
    },
    hasActiveFilters() {
      if (!this.hasActiveFilters) this.menu = false;
    },
  },
};
</script>

<style lang="scss">
.datagrid__filter-eraser {
  button {
    width: 130px;
    border-radius: 46px;
  }

  &__chip-list {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 6px;
    margin: 12px;
  }
}
</style>

<i18n locale="fr">
  {
    "filters": "Filtres",
    "clearAll": "Tout effacer"
  }
  </i18n>

<i18n locale="en">
  {
    "filters": "Filters",
    "clearAll": "Clear all"
  }
  </i18n>
