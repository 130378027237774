<template>
  <div class="barchart">
    <div v-if="title" class="barchart__title">
      {{ title }}
    </div>
    <div class="barchart__apexchart">
      <VueApexCharts
        ref="apexchart"
        :height="height"
        :width="width"
        :options="mergedOptions"
        :series="drawnData"
      />
      <div v-if="yaxisTitle" class="barchart__yaxis-title">
        {{ yaxisTitle }}
      </div>
      <div class="barchart__nav-block">
        <button
          class="barchart__nav-button"
          :class="{ 'barchart__nav-button--hidden': page === 0 }"
          @click="page -= 1"
        >
          <font-awesome-icon icon="fa-chevron-left" />
        </button>
        <button
          class="barchart__nav-button"
          :class="{ 'barchart__nav-button--hidden': page + 1 === lastPage }"
          @click="page += 1"
        >
          <font-awesome-icon icon="fa-chevron-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import deepmerge from 'deepmerge';
import VueApexCharts from 'vue3-apexcharts';

/**
 * @typedef {Object} ApexChartSerie
 * @property {Array<number>} data
 * @property {(data: {value: number, seriesIndex: number, dataPointIndex: number }) => string} color
 */

/**
 * @typedef {Object} ScreenNavEvent
 * @property {string} ref
 * @property {'prev'|'next'} direction
 */

const localOptions = {
  chart: {
    type: 'bar',
    offsetX: -25,
    stacked: true,
    toolbar: { show: false },
    animations: { enabled: false },
  },
  noData: { text: '' },
  fill: { opacity: 1 },
  legend: { show: false },
  xaxis: {
    labels: {
      show: true,
      rotateAlways: true,
      style: {
        color: '#333333', // $text-dark
        fontFamily: "'Poppins', Arial, sans-serif", // $font-poppins
        fontSize: '13px',
        fontWeight: 500,
      },
    },
    axisBorder: {
      width: '120%',
      offsetX: -17,
      offsetY: 1,
    },
    axisTicks: { show: false },
  },
  yaxis: {
    axisBorder: {
      show: true,
      offsetX: -18,
    },
    labels: { show: false },
    forceNiceScale: false,
  },
  states: {
    hover: {
      filter: { type: 'none' },
    },
    active: {
      filter: { type: 'none' },
    },
  },
  grid: {
    yaxis: {
      lines: { show: false },
    },
  },
  plotOptions: {
    bar: {
      dataLabels: {
        position: 'center',
        hideOverflowingLabels: true,
      },
    },
  },
  dataLabels: {
    style: {
      fontSize: '9px',
      fontFamily: "'Poppins', Arial, sans-serif", // $font-poppins
      fontWeight: '900',
    },
  },
  tooltip: { followCursor: false },
};

export default {
  name: 'BarChart3',
  components: { VueApexCharts },
  props: {
    /**
     * ApexChart Options object
     * @type {Vue.PropOptions<Object>}
     */
    options: {
      type: Object,
      default: () => ({}),
    },
    barsPerScreen: {
      type: Number,
      default: 15,
    },
    /** @type {Vue.PropOptions<Array<ApexChartSerie>>} */
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    yaxisTitle: {
      type: String,
      default: '',
    },
    width: {
      type: Number || String,
      default: '100%',
    },
    height: {
      type: Number || String,
      default: 'auto',
    },
  },
  emits: ['screen-nav'],
  data() {
    return {
      /** @type {Object} */
      localOptions,
      /** @type {Number} */
      page: 0,
      /** @type {Number} */
      lastPage: Math.ceil(this.categories.length / 15),
    };
  },
  computed: {
    /** @return {Object} */
    mergedOptions() {
      const startElement = this.page * this.barsPerScreen;
      const categories = this.categories.slice(startElement, startElement + this.barsPerScreen);

      // "deepmerge" is rightsided
      const options = deepmerge(this.localOptions, this.options);
      options.xaxis.categories = categories;

      // if no yaxis.max defined by options, calculate for data scope
      if (!options.yaxis.max) {
        // calculate max value for set of data displayed
        const maxList = [];
        this.drawnData.forEach(dataList => {
          dataList.data.forEach((value, index) => {
            if (!maxList[index]) maxList[index] = 0;
            maxList[index] += value;
          });
        });
        // set the max yaxis
        options.yaxis.max = Math.max(...maxList);
      }

      options.plotOptions.bar.columnWidth = `${categories.length * 4.6}%`;

      // "page" and "barsPerScreen" must be accessible in parent component
      // especially for tooltip.
      options.page = this.page;
      options.barsPerScreen = this.barsPerScreen;

      return options;
    },
    /** @return {Array<Object>} */
    drawnData() {
      const startElement = this.page * this.barsPerScreen;
      const series = this.series.map(ele => ({
        ...ele,
        data: ele.data.slice(startElement, startElement + this.barsPerScreen),
      }));

      return series;
    },
  },
  watch: {
    '$i18n.locale': function i18nLocale() {
      this.localOptions.noData.text = this.$t('loading');
    },
  },
  created() {
    this.localOptions.noData.text = this.$t('loading');
  },
  methods: {},
};
</script>

<style lang="scss">
.barchart {
  padding: 0 20px;
  border-radius: 9px;
  background: $background;

  &__title {
    height: 80px;
    padding: 20px 0 0 50px;
    font-weight: $font-weight-semi-bold;
    font-size: 21px;
    font-family: $font-poppins;
  }

  &__apexchart {
    position: relative;

    .apexcharts-canvas {
      margin: auto;
    }

    .apexcharts-tooltip {
      max-width: 250px;
      margin-left: -60px;
      color: $text-dark;
      font-size: 10px;
    }

    .apexcharts-bar-area {
      fill-opacity: 1;
    }
  }

  &__nav-block {
    position: absolute;
    top: 43%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__nav-button {
    display: block;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 45%;
    background: $canvas;
    color: $primary-light;

    &:hover {
      background: $text-neutral;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__yaxis-title {
    position: absolute;
    top: 5px;
    left: 3px;
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;
    font-size: 9px;
    text-transform: uppercase;
  }
}
</style>

<i18n locale="fr">
{
  "loading": "Chargement des données..."
}
</i18n>

<i18n locale="en">
{
  "loading": "Loading..."
}
</i18n>
