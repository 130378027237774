<template>
  <div class="copy-content">
    <label v-if="label" :for="inputId" class="copy-content__label">
      <font-awesome-icon v-if="labelIcon" :icon="labelIcon" />
      {{ label }}
    </label>
    <div
      :class="plainText ? 'copy-content__body--plain-text' : 'copy-content__body--one-line'"
      class="copy-content__body"
    >
      <input
        v-if="!plainText"
        :id="inputId"
        v-model="contentLocal"
        :readonly="true"
        class="copy-content__input"
      />
      <textarea v-else :id="inputId" v-model="contentLocal" :readonly="true" />
      <div class="copy-content__buttons-container">
        <Btn v-if="linkUrl" :link-url="linkUrl" type="icon-only">
          <font-awesome-icon icon="fa-arrow-up-right-from-square" />
        </Btn>
        <Btn type="icon-only" :title="$t('copyText')" @click="copyTextToClipboard">
          <font-awesome-icon icon="fa-copy" />
        </Btn>
        <Btn v-if="refreshButton" type="icon-only" :title="$t('refresh')" @click="refresh">
          <font-awesome-icon icon="fa-rotate-right" />
        </Btn>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

import Btn from '@/components/ui/Btn.vue';

const toast = useToast();

export default {
  name: 'CopyContent',

  components: {
    Btn,
  },

  props: {
    content: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    labelIcon: {
      type: String,
      default: null,
    },
    linkUrl: {
      default: null,
      type: String,
    },
    plainText: {
      default: false,
      type: Boolean,
    },
    refreshButton: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['refresh'],

  data() {
    return {
      toast,
    };
  },

  computed: {
    // props not accepted in v-model
    contentLocal() {
      return this.content;
    },
  },

  methods: {
    copyTextToClipboard() {
      navigator.clipboard.writeText(this.content).then(() => {
        const toastId = this.toast.success(this.$t('textCopied'), { position: 'bottom-right' });
        setTimeout(() => toast.dismiss(toastId), 3000);
      });
    },

    refresh() {
      this.$emit('refresh');
    },
  },
};
</script>

<style lang="scss">
.copy-content {
  margin: 0 0 20px;

  &__label {
    display: block;
    margin-bottom: 4px;

    svg {
      margin-right: 5px;
    }
  }

  &__body {
    display: flex;

    &--one-line {
      height: 30px;
    }

    &--plain-text {
      margin-bottom: 20px;

      textarea {
        width: 515px;
        width: 75%;
        height: 220px;
        border: 1px solid $border;
        border-radius: 4px;
        color: $text-dark-variant;
        font-family: $font-poppins;
        resize: none;

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #fff;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
          height: 20px;
          border-radius: 10px;
          background-color: $text-neutral;
        }
      }
    }

    input {
      width: 515px;
      width: 75%;
      padding: 5px;
      border: 1px solid $border;
      border-radius: 4px;
      color: $text-dark-variant;
      font-family: $font-poppins;
    }
  }

  &__buttons-container {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    .ui-btn + .ui-btn {
      margin-left: 0 !important;
    }
  }
}
</style>

<i18n locale="fr">
{
  "refresh": "Actualiser",
  "textCopied": "Texte copié dans le presse-papiers",
  "copyText": "Copier le texte dans le presse-papiers"
}
</i18n>

<i18n locale="en">
{
  "refresh": "Refresh",
  "textCopied": "Text copied to clipboard",
  "copyText": "Copy text to clipboard"
}
</i18n>
